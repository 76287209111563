import {AzaButton} from "../components/mui/AzaButton";
import {useMemo, useState} from "react";
import {GoogleAdsOAuth} from "../widgets/GoogleOAuth/GoogleOAuth";
import {AzaTypography} from "../components/mui/AzaTypography";
import {AzaPaper} from "../components/mui/AzaPaper";
import {useSiteId} from "../app/globalHooks";
import {AzaDialog, AzaDialogActions, AzaDialogContent} from "../components/mui/AzaDialog";
import {
    GoogleLogin,
    googleLogout,
    GoogleOAuthProvider,
    hasGrantedAllScopesGoogle,
    useGoogleLogin
} from "@react-oauth/google";

export const SampleOAuth = () => {

    const site_id = useSiteId()

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        // <AzaPaper sx={{p: 2}}>
        <GoogleAdsOAuth/>
        // </AzaPaper>
    )

    // return (
    //
    //     <>
    //         <AzaGrid container direction={"column"} spacing={2}>
    //             <AzaGrid item>
    //                 <AzaTypography variant={"h4"}>SampleOAuth</AzaTypography>
    //                 <AzaPaper
    //                     sx={{p: 2,}}
    //                 >
    //                     <AzaTypography variant={"h5"}>Modal + iframe : (Does not work)</AzaTypography>
    //                     <AzaButton variant="outlined" onClick={handleOpen}>
    //                         Open Modal
    //                     </AzaButton>
    //
    //                     <GoogleOAuthIFrame open={open} onClose={handleClose}/>
    //                 </AzaPaper>
    //             </AzaGrid>
    //             <AzaGrid item>
    //                 <AzaPaper
    //                     sx={{p: 2,}}
    //                 >
    //                     <AzaTypography variant={"h5"}>Redirection</AzaTypography>
    //                     <AzaButton variant="outlined" onClick={redirectGoogleOAuth}>
    //                         Connect Google Ads account
    //                     </AzaButton>
    //                 </AzaPaper>
    //             </AzaGrid>
    //             <AzaGrid item>
    //                 <GoogleOAuth/>
    //             </AzaGrid>
    //         </AzaGrid>
    //     </>)
}

const GoogleOAuthIFrame = ({open, onClose}) => {
    const site_id = useSiteId()

    const url = useMemo(() => {
        if (!site_id) return ""
        return `http://localhost:8080/shopifytracker/googleoauth/initial?site_id=${site_id}&google_ads=true`
    }, [site_id])

    if (!site_id) return <></>

    return (<AzaDialog
        open={open}
        onClose={onClose}
        maxWidth="md"
    >
        <AzaDialogContent>
            <iframe
                title="externalSite"
                src={url}
                width="100%"
                height="400px"
                style={{border: "none"}}
            />
        </AzaDialogContent>
        <AzaDialogActions>
            <AzaButton onClick={onClose} color="primary">
                Close
            </AzaButton>
        </AzaDialogActions>
    </AzaDialog>)
}
const GoogleOAuth = () => {

    const responseGoogle = (response) => {
        console.log(response);
        let hasAccess = hasGrantedAllScopesGoogle(response, 'auth/siteverification', 'auth/content', 'auth/adwords',)

        console.log("hasAccess", hasAccess)
    }

    return (<GoogleOAuthProvider
        clientId={"360198434036-rg13d8j9g0e755lsrga72iblvn07rna1.apps.googleusercontent.com"}
    >
        <AzaPaper sx={{p: 2,}}>
            <AzaTypography variant={"h5"}>Google OAuth</AzaTypography>
            <GoogleLogin
                render={(renderProps) => (<button
                    type="button"
                    className=""
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                >
                    Sign in with google
                </button>)}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy="single_host_origin"
            />
            <AzaButton onClick={googleLogout}>Log out</AzaButton>
            <GoogleOAuthCustom/>
        </AzaPaper>
    </GoogleOAuthProvider>)
}

const GoogleOAuthCustom = () => {
    const login = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/content https://www.googleapis.com/auth/siteverification https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/adwords',
        // hint : <email address>,
    });

    return (<AzaButton onClick={login}>Log in</AzaButton>

    )

}