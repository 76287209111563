import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import AdAssets from "./AdAssets";
import Audience from "./Audience";
// import Networks from "../createNewCampaignGroup/Networks";
import Settings from "./Settings";
import CompletionBlock from "../createNewCampaignUtile/CompletionBlock";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import {AzaStep, AzaStepLabel, AzaStepper} from "../../../components/mui/AzaStepper";
import {AzaButton} from "../../../components/mui/AzaButton";
import {
    selectCheckerSiteId,
    selectAssetScope,
    selectProductAssetSelected,
    selectImages,
    selectErrorsCounter,
    selectProductInfoSelected,
    selectCampaignParams,
    selectLogo,
    actionSetAssetsWasLoaded,
    selectAssetsWasLoaded,
    selectOnLoadingState,
    selectIsMultipleProducts,
} from "../reducers/createNewCampaignSlice";
import {selectSiteListLoaded} from "../../../app/globalSlice";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";
import {useAdminModeProvider} from "../../../features/adminMode/adminModeContext";
import {createCampaign} from "../../../utils/backendHelper";
import {useFileStoreProvider} from "../../../features/fileStoreContext";
import campaignParamsEditor from "../createNewCampaignUtile/campaignParamsEditor";
import {PATH_CAMPAIGNS} from "../../../utils/constant";
import {AzaDialog, AzaDialogActions, AzaDialogContent, AzaDialogContentText, AzaDialogTitle} from "../../../components/mui/AzaDialog";
import {useCreateNewCampaign} from "../createNewCampaignUtile/createNewCampaignHook";
import {useCampaignValidation} from "../../campaignValidation/campaignValidationHook";


export default function HorizontalCreationStepper() {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [openErrorWarning, setOpenErrorWarning] = useState(false);
    const [creating, setCreating] = useState(false);
    const {isAdmin} = useAdminModeProvider();
    const {t} = useTranslation();
    const checkerSiteId = useSelector(selectCheckerSiteId);
    const site_id = useSiteId();
    const {user} = useUser();
    const completion = 'completion';
    const assetsScopeObj = useSelector(selectAssetScope);
    const productAssets = useSelector(selectProductAssetSelected);
    const imgToValidate = useSelector(selectImages);
    const siteListLoaded = useSelector(selectSiteListLoaded);
    const errorsCounter = useSelector(selectErrorsCounter);
    const campaignParams = useSelector(selectCampaignParams);
    const {allFiles} = useFileStoreProvider();
    const productName = useSelector(selectProductInfoSelected).prodName;
    const logoDataFromRedux = useSelector(selectLogo);
    const navigate = useNavigate();
    const isDataLoaded = useSelector(selectAssetsWasLoaded);
    const isOnLoading = useSelector(selectOnLoadingState);
    const multipleProducts = useSelector(selectIsMultipleProducts);
    const isMultipleProducts = multipleProducts.is_multiple_product;
    const {resetData} = useCreateNewCampaign();
    const {validateActiveStep} = useCampaignValidation();

    const steps = useMemo(() => {
        return [
            {
                label: t('creation-steps.ad-asset'),
                stepComponent: <AdAssets/>,
                name: 'ad_assets',
            },
            {
                label: t('creation-steps.audience'),
                stepComponent: <Audience/>,
                name: 'audience',
            },
            // {
            //     label: t('creation-steps.networks'),
            //     stepComponent: <Networks/>,
            //     name: 'networks',
            // },
            {
                label: t('creation-steps.settings'),
                stepComponent: <Settings/>,
                name: 'settings',
            },
        ]
    }, [t]) ;

    const handleNext = useCallback(() => {
        if (validateActiveStep(steps[activeStep].name, activeStep)) {
            if (activeStep === steps.length - 1) {
                setCreating(true);
                let campaign_params_extended = {...campaignParams};
                const images = allFiles('images');
                const logo = allFiles('logo')
                const image_fields = {};

                if(!!logoDataFromRedux.files.length) {
                    campaign_params_extended['logo'] = logo.map((image, idx) => {
                        const image_name = `logo_${idx}`;
                        image_fields[image_name] = image
                        return image_name
                    })
                }

                if(images?.length > 0 && !!imgToValidate.files) {
                    campaign_params_extended['images'] = images.map((image, idx) => {
                        const image_name = `image_${idx}`;
                        image_fields[image_name] = image
                        return image_name
                    })
                }

                if(assetsScopeObj.scopeName === 'Site' || isMultipleProducts) {
                    // remove a key from object and get all that is inside of this key
                    const {site, ...rest} = campaign_params_extended;
                    campaign_params_extended = {...rest, ...site};
                } else {
                    const editedProductParams = campaignParamsEditor(productAssets, 'Product');
                    const {site, ...rest} = campaign_params_extended;
                    campaign_params_extended = {...rest, ...editedProductParams, business_name: productName};
                }

                const data = {
                    params: JSON.stringify(campaign_params_extended),
                    ...image_fields,
                }

                createCampaign(user, site_id, data).then(() => {
                    resetData();
                    navigate(PATH_CAMPAIGNS);
                }).catch((err) => {
                    console.log(err)
                }).finally(()=> {
                    setCreating(false);
                });
            } else {
                // alert('WAS IT CHECKED?');
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setOpenErrorWarning(true);
        }
    }, [
        isMultipleProducts,
        activeStep,
        allFiles,
        assetsScopeObj.scopeName,
        campaignParams,
        imgToValidate.files,
        logoDataFromRedux.files.length,
        navigate,
        productAssets,
        productName,
        resetData,
        site_id,
        steps,
        user,
        validateActiveStep]);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [setActiveStep]);

    const handleCloseErrorWarning = useCallback(() => {
        setOpenErrorWarning(false);
    }, []);

    useEffect(() => {
        if(!siteListLoaded) {
            return;
        }

        if (checkerSiteId !== site_id && isDataLoaded) {
            dispatch(actionSetAssetsWasLoaded(false));
        }

        if (user && site_id && !isOnLoading && !isDataLoaded) {
            resetData();
            setActiveStep(0);
        }
    }, [setActiveStep, user, site_id, isOnLoading, isDataLoaded, siteListLoaded, dispatch, resetData, checkerSiteId]);

    return (
        <AzaBox sx={{width: '100%'}}>
            <AzaPaper variant="outlined" sx={{mb: '30px'}}>
                <AzaStepper
                    activeStep={activeStep}
                    sx={{
                        height: '54px',
                        width: '100%'
                    }}
                >
                    {steps.map((step) => (
                        <AzaStep key={step.label} label={step.label}>
                            <AzaStepLabel>{step.label}</AzaStepLabel>
                        </AzaStep>
                    ))}
                </AzaStepper>
            </AzaPaper>
            <AzaGridContainer>
                <AzaGridItem xs={12} md={9} sx={{mt: 2}}>
                    {steps[activeStep].stepComponent}
                </AzaGridItem>
                <AzaGridItem xs={12} md={3} sx={{position: 'relative'}}>
                    <AzaBox sx={{position: 'sticky', top: '74px'}}>
                        <CompletionBlock
                            title={steps[activeStep].label + ' ' + completion}
                            recommendation={steps[activeStep].recommendation}
                            error={activeStep === 0 ? errorsCounter[activeStep][assetsScopeObj.scopeName.toLowerCase()] : errorsCounter[activeStep]}
                            currentStep={activeStep}
                        />
                        {!isAdmin && <AzaBox sx={{pt: 2}}>
                            <AzaAlert severity={"info"}>
                                {t('campaign.creation.commingsoon')}
                            </AzaAlert>
                        </AzaBox>}
                        <AzaBox sx={{display: 'flex', pt: 2}}>
                            <AzaButton
                                sx={{flexGrow: 1}}
                                variant="outlined"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                {t('common.back')}
                            </AzaButton>
                            <AzaBox sx={{ height: '15px', width: '7%' }} />
                            {(isAdmin || activeStep < steps.length - 1) &&
                            <AzaButton sx={{flexGrow: 1}} onClick={handleNext} variant={"contained"} disabled={creating}>
                                {activeStep === steps.length - 1 ? t('create-campaign') : t('common.next')}
                            </AzaButton>}
                            {(!isAdmin && activeStep === steps.length - 1) &&
                            <AzaButton sx={{flexGrow: 1}}  variant={"contained"} disabled>
                                {activeStep === steps.length - 1 ? t('create-campaign') : t('common.next')}
                            </AzaButton>}
                        </AzaBox>
                    </AzaBox>
                </AzaGridItem>
            </AzaGridContainer>
            <AzaDialog
                open={openErrorWarning}
                onClose={handleCloseErrorWarning}
            >
                <AzaDialogTitle onClose={handleCloseErrorWarning}>
                    {t('common.warning')}
                </AzaDialogTitle>
                <AzaDialogContent>
                    <AzaDialogContentText>
                        {t('common.warning-errors')}
                    </AzaDialogContentText>
                </AzaDialogContent>
                <AzaDialogActions>
                    <AzaButton variant={"contained"}  onClick={handleCloseErrorWarning}>Ok</AzaButton>
                </AzaDialogActions>
            </AzaDialog>
        </AzaBox>
    );
}
