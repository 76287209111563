import {useTranslation} from "react-i18next";
import {useAsyncStatus, useSiteId, useUser} from "../../app/globalHooks";
import {backendFetch, methodType} from "../../utils/backendHelper";
import {API_OPENAI_USER_INTEREST, API_SITE_DATA} from "../../utils/constant";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaButton} from "../../components/mui/AzaButton";
import {CircularProgress} from "@mui/material";

export const UserInterest = () => {
    const {t} = useTranslation();
    const site_id = useSiteId();
    const {user, loading: loadingUser} = useUser()
    const [siteData, setSiteData] = useState({})
    const [canFindUserInterest, setCanFindUserInterest] = useState(false)
    const [searchingUserInterest, setSearchingUserInterest] = useState(false)
    const [message, setMessage] = useState("")
    const [taskId, setTaskId] = useState(null)
    const {status} = useAsyncStatus(taskId)


    const fetchSiteData = useCallback(() => {
        if (!user || loadingUser || !site_id) return;
        backendFetch({path: API_SITE_DATA, user, queryParams: {site_id}})
            .then(json_data => {
                // Store the api result in the local state
                setSiteData(json_data)
                setMessage(t("site-settings.user-interest-fetched"))
            })
    }, [loadingUser, site_id, t, user]);


    const userInterest = useMemo(() => {
        if (Object.keys(siteData).length === 0) {
            setCanFindUserInterest(false)
            return []

        }
        if (!siteData?.["user_interest"]) {
            setCanFindUserInterest(true)
            return []
        }
        const output = siteData?.["user_interest"] ? siteData?.["user_interest"] : {}
        setCanFindUserInterest(output.length === 0)
        if (output.length > 0) {
            setMessage("")
        }
        return output

    }, [siteData])

    const findUserInterest = useCallback(() => {
        setSearchingUserInterest(true)
        backendFetch({
            path: API_OPENAI_USER_INTEREST,
            method: methodType.POST,
            user,
            data: {site_id}
        })
            .then(res => {
                // The status indicate if the task is created or already running
                if (res.status === "ok") {
                    setMessage(t("site-settings.fetching-user-interest"))
                    // store the task id
                    setTaskId(res["async"].id)
                } else {
                    setMessage(t("site-settings.already-running"))
                    setTaskId(null)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }, [site_id, t, user])

    useEffect(() => {
        if (status === "success") {

            fetchSiteData();
            setTaskId(null)
            setSearchingUserInterest(false)
        }
    }, [fetchSiteData, status, t])

    return (
        <div>
            <h1>{t("site-settings.user-interest")}</h1>
            <AzaButton
                variant={"contained"}
                disabled={!canFindUserInterest}
                onClick={findUserInterest}
            >{t("site-settings.find-user-interest")}</AzaButton>
            <AzaGridContainer direction={"column"}>
                <AzaGridItem>
                    {message}
                </AzaGridItem>
                <AzaGridItem>
                    {searchingUserInterest && <CircularProgress/>}
                </AzaGridItem>
                {/*{JSON.stringify(userInterest, null, 2)}*/}
                {Object.entries(userInterest).map(([level, level_interests]) => (
                    <React.Fragment key={level}>
                        <AzaGridItem>
                            {t("site-settings.user-interest-level")}: {level}
                        </AzaGridItem>
                        <AzaGridItem>
                            {/*{JSON.stringify(level_interests, null, 2)}*/}
                        </AzaGridItem>
                        {Object.entries(level_interests).map(([id, name]) => (
                            <AzaGridItem key={id}>
                                {id} - {name}
                            </AzaGridItem>
                        ))}
                    </React.Fragment>
                ))}
            </AzaGridContainer>
        </div>
    )
}