import {useDbItem} from "../../utils/rdbHooks";
import {Grid, Paper, TextField, Typography} from "@mui/material";

export const SampleRdbWrite = () => {
    const {item: data, loading, error, updateField} = useDbItem("sample/", "data")

    // very basic loading and error handling
    if (loading || error) return <></>
    if (!data) return <></>

    return (
        <Paper elevation={3}>
            <Grid container direction={"column"}>
                <Grid item>
                    <Grid container direction={"row"}>
                        <Grid item>
                            <Typography variant="h5">

                                Sample RealTimeDB Write
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction={"row"}>
                        <Grid item>
                            <Typography> Nom: </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                size={"small"}

                                value={data.name}
                                onChange={(e) => {
                                    updateField("name", e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction={"row"}>
                        <Grid item>
                            <Typography> Age: </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                size={"small"}
                                value={data.age}
                                onChange={(e) => {
                                    updateField("age", e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>

        </Paper>
    )
}