/*
 *  doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2124447745/ZohoForm
 */

import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {AzaButton, AzaIconButton} from "../mui/AzaButton";
import {AzaModal} from "../mui/AzaModal";
import {AzaBox} from "../mui/AzaBox";
import {AzaCloseRounded} from "../mui/AzaIcons";

import {selectZohoForms} from "../../app/globalSlice";
import {useCustomerId, useSiteId, useUser} from "../../app/globalHooks";

const ZohoForm = ({formName, params}) => {
    const [azaDialogOpen, setAzaDialogOpen] = useState(false)
    const {t, i18n} = useTranslation();
    const closeZohoForm = () => {
        setAzaDialogOpen(false)
    }

    const zohoform = useSelector(selectZohoForms)[formName];
    const zohoform_width = zohoform?.width ?? 900;
    const zohoform_height = zohoform?.width ?? 800;
    const zohoform_title = t(zohoform?.title ?? formName);

    params['zf_lang'] = i18n.language;

    const zohoRequestUrl = useMemo(() => {
        let params_string = "";
        for (const [key, value] of Object.entries(params)) {
            params_string += `&${key}=${value}`;
        }
        return `${zohoform?.url}?${params_string}`
    }, [zohoform, params]);

    const horizontalPosition = ((window.innerWidth - zohoform_width) / 2 + 50) > 0 ? (window.innerWidth - zohoform_width) / 2 + 50 : 50;

    return (
        <>
            <AzaButton
                onClick={() => {
                    setAzaDialogOpen(true)
                }}
                size={"small"}
                onClose={closeZohoForm}>
                {zohoform_title}
            </AzaButton>
            <AzaModal
                open={azaDialogOpen}
                onClose={closeZohoForm}
            >
                <AzaBox sx={{display: "flex", "align-items": "center", "justify-content": "center"}}>
                    <iframe
                        title={formName}
                        width={zohoform_width}
                        height={zohoform_height}
                        src={zohoRequestUrl}
                        style={{border: 'none'}}
                    />
                    <AzaIconButton
                        onClick={closeZohoForm}
                        sx={{
                            position: 'absolute',
                            right: horizontalPosition,
                            top: 50,
                            color: 'white',
                        }}
                    >
                        <AzaCloseRounded/>
                    </AzaIconButton>
                </AzaBox>
            </AzaModal>
        </>
    )
};

export const RequestChangeBillingInfoZohoForm = () => {

    const site_id = useSiteId();
    const customer_id = useCustomerId();
    const {user} = useUser();
    const email = user.email;

    return (
        <>
            <ZohoForm
                formName={"request_change_billing_info"}
                params={{
                    email: email,
                    site_id: site_id,
                    customer: customer_id,
                }}
            />
        </>
    )
};

export const RequestOtherPaymentZohoForm = ({amount}) => {

    const {user} = useUser();
    const email = user.email;

    return (
        <>
            <ZohoForm
                formName={"request_other_paiement"}
                params={{
                    email: email,
                    amount: amount
                }}
            />
        </>
    )
};
