import {styled, TableSortLabel} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

import React from "react";
import TablePagination from "@mui/material/TablePagination";
import {useTranslation} from "react-i18next";


export const AzaTableContainer = (props) => {
    return ( <TableContainer {...props} />)
}

export const AzaTable = (props) => {
    return ( <Table {...props} />)
}

export const AzaTableHead = (props) => {
    return ( <TableHead {...props} />)
}

export const AzaTableBody  = (props) => {
    return ( <TableBody {...props} />)
}

export const AzaTableRow = styled(TableRow)(({theme}) => ({

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.secondary.lighter,
    },
    '&:hover': {
        backgroundColor: `${theme.palette.primary.lighter} !important`,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// noinspection AllyPlainJsInspection
export const AzaTableRowHead = styled(TableRow)(() => ({}));


// noinspection AllyPlainJsInspection
export const AzaTableCell = styled(TableCell)(() => ({
    padding: '6px 4px',
    borderBottom: 0,
    textAlign: 'center',
    fontSize: 14,
}));

export const AzaTableCellHead = styled(TableCell)(() => ({
    padding: '6px 4px',
    borderBottom: 0,
    textAlign: 'center',
}));

// noinspection AllyPlainJsInspection
export const AzaTableCellBottom = styled(TableCell)(() => ({
    padding: '6px 4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
}));

export const AzaTableSortLabel = styled(TableSortLabel)(() => ({ }));

export const AzaTablePagination = styled((props) => {
    const {t} = useTranslation();
    return <TablePagination
        labelRowsPerPage={t("component.azatablepagination.rowsperpage")}
        labelDisplayedRows={({from, to, count}) =>  t("component.azatablepagination.of", {from, to, count})}
        {...props}
    />
})(() => ({ }));
