import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {AzaButton} from "../../../../../components/mui/AzaButton";
import {useSiteId, useUser} from "../../../../../app/globalHooks";
import {generateSiteAssets} from "../../../../../pages/authenticated/assetsEdition/utils";
import {
    selectProductInfoSelected,
    actionSetNewGeneratedAssets,
    actionSetAssetsLoaded,
    selectAssetScope
} from "../../../reducers/createNewCampaignSlice";
import {AzaSync} from "../../../../../components/mui/AzaIcons";
import {productFilter, filteringData} from "../../../createNewCampaignUtile/editGetAssetsData";
import {AzaDialog, AzaDialogActions, AzaDialogContent} from "../../../../../components/mui/AzaDialog";

const GenerateAssets = () => {
    const {t} = useTranslation();
    const {user} = useUser();
    const site_id = useSiteId();
    const product_id = useSelector(selectProductInfoSelected).prodId;
    const dispatch = useDispatch();
    const assetsScopeData = useSelector(selectAssetScope);
    const [open, setOpen] = useState(false);
    const assetsScope = assetsScopeData.scopeName;

    const updateProductGeneratedAssets = (data) => {
        let transformedData;
        if(assetsScope === 'Product') {
             transformedData = productFilter(data);
        } else {
            transformedData = filteringData(data);
        }
        dispatch(actionSetNewGeneratedAssets({data: transformedData, scope: assetsScope}));
        dispatch(actionSetAssetsLoaded(true));
    }
    const generateAssets = () => {
        let productId = null;
        if(assetsScope === 'Product') {
            productId = product_id;
        }
        dispatch(actionSetAssetsLoaded(false));
        generateSiteAssets(user, site_id, productId, updateProductGeneratedAssets);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        generateAssets();
        setOpen(false);
    }

    return (
        <>
            <AzaButton
                variant="outlined"
                startIcon={<AzaSync sx={{fontSize: '17px!important'}}/>}
                onClick={handleOpen}>
                {t('generate')}
            </AzaButton>
            <AzaDialog open={open} onClose={handleClose}>
                <AzaDialogContent>
                    {t('open-ai.generation-takes-time')}
                </AzaDialogContent>
                <AzaDialogActions>
                    <AzaButton onClick={handleClose}>{t('campaign_management_popup.action_start')}</AzaButton>
                </AzaDialogActions>
            </AzaDialog>
        </>
    );
};

export default GenerateAssets;