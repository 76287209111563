import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {CircularProgress} from "@mui/material";

export const LoadingPage = ({redirect, search, loading}) => {
    const navigate = useNavigate()
    useEffect(() => {
        if (loading !== true) {
            navigate({pathname: redirect, search: search})
        }
    }, [navigate, redirect, search, loading])
    return <CircularProgress/>
}
