import React from 'react';
import {AzaFabButton, AzaIconButton} from "../mui/AzaButton";
import {AzaBox} from "../mui/AzaBox";
import {AzaLinkRouter} from "../mui/AzaLink";
import {AzaAdd, AzaForum, AzaHelp} from "../mui/AzaIcons";
import {AdminModeBranch} from "../../features/adminMode/adminModeComponents";

const MobileFooter = () => {
    return (
        <AzaBox sx={{
            position: 'fixed',
            bottom: '0',
            height: '80px',
            width: '100%',
            backdropFilter: 'blur(2px)',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            zIndex: '1',
        }}>
            <AdminModeBranch needAdmin={true}>
                <AzaIconButton>
                    <AzaHelp/>
                </AzaIconButton>
            </AdminModeBranch>
            <AzaLinkRouter style={{textDecoration: 'none', color: 'inherit'}} to={`/new-campaign`}>
                <AzaFabButton aria-label="add" sx={{minWidth: '48px'}}>
                    <AzaAdd/>
                </AzaFabButton>
            </AzaLinkRouter>
            <AdminModeBranch needAdmin={true}>
                <AzaIconButton>
                    <AzaForum/>
                </AzaIconButton>
            </AdminModeBranch>
        </AzaBox>
    );
};

export default MobileFooter;
