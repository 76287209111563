import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {useTheme, styled, tooltipClasses} from "@mui/material";

import {AzaAutoAwesome, AzaIconClose} from "../../../../../components/mui/AzaIcons";
import {AzaIconButton} from "../../../../../components/mui/AzaButton";
import {AzaTypography} from "../../../../../components/mui/AzaTypography";
import {allGrey} from "../../../../../utils/colors";
import {AzaBox} from "../../../../../components/mui/AzaBox";
import {actionUpdateAssets} from "../../../reducers/createNewCampaignSlice";
import {AzaInputAdornment} from "../../../../../components/mui/AzaInputAdornment";
import {AzaOutlinedInput} from "../../../../../components/mui/AzaOutlinedInput";
import {AzaFormControl} from "../../../../../components/mui/AzaFormControl";
import {AzaAlert} from "../../../../../components/mui/AzaAlert";
import {AzaTooltip} from "../../../../../components/mui/AzaTooltip";

const InputCreation = (props) => {
    const {maxLength, handleRemoveInput, minQuantity, nameOfClass, isMultiline, assetValue, assetData, selectAssetsFunc, inputName} = props;
    const {t} = useTranslation();
    const [value, setValue] = useState(assetValue);
    const [numbIsLeft, setNumbIsLeft] = useState(0 + value.length);
    const [isGeneratedByAI, setIsAIGenerated] = useState(assetData ? assetData?.origin === 'Generated' : false);
    const dispatch = useDispatch();
    const groupAssets = useSelector(selectAssetsFunc);
    const theme = useTheme();
    let cleanedAssets = [];

    if(assetData.scope === 'Product') {
        cleanedAssets = groupAssets[assetData.text_type]
    } else {
        cleanedAssets = groupAssets;
    }

    // if(value.length > maxLength && !error.status) {
    //     setError({status: true, message: `${inputName} is to long`});
    // }

    const handleUpdateSlice = (e, val = value) => {
        let needUpdateSlice = true;
        const updatedInput = {
            // assets name based on lightly transformed 'text_type'
            assets: assetData.text_type.toLowerCase().replace(" ", "_") + 's',
            inputVal: val,
            inputId: assetData.id
        };

        const updatedData = cleanedAssets.map((obj) => {
            if(obj.id === updatedInput.inputId) {
                needUpdateSlice = !(obj.cleaned_text === updatedInput.inputVal);
            }
            return obj.id === updatedInput.inputId ?
                {
                    ...obj,
                    cleaned_text: updatedInput.inputVal,
                    text: updatedInput.inputVal,
                    origin: needUpdateSlice ? 'Customer' : obj.origin,
                    error: {},
                } : obj;
        });

        if (needUpdateSlice) {
            // if scope === 'Product', in Slice file will update both 'product_assets' and 'product_asset_selected'
            dispatch(actionUpdateAssets(updatedData));
        }
    }

    const handleValue = (e) => {
        let inputValue = e.target.value;

        if(isGeneratedByAI) {
            setIsAIGenerated(false);
        }

        if(inputValue.length <= maxLength) {
            setValue(inputValue);
            setNumbIsLeft(0 + inputValue.length);
        } else {
            setValue(inputValue);
            setNumbIsLeft(0 + inputValue.length);
        }
    }

    const handleRemove = () => {
        const elements = document.getElementsByClassName(nameOfClass);
        const elQuantity = elements.length;
        if(elQuantity > minQuantity) {
            handleRemoveInput();
        } else {
            // add warning
            setValue('');
            setIsAIGenerated(false);
            handleUpdateSlice(null, '');
            console.log(`At least ${elQuantity} title should be created`);
        }
    }

    const PopoverTooltip = styled(({ className, ...props }) => {
        const {children} = props;
        return <AzaTooltip {...props} arrow classes={{popper: className}}>{children}</AzaTooltip>
    })(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.secondary.main,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.secondary.main,
            maxWidth: 150,
        },
    }));

    return (
        <>
            <AzaFormControl variant="outlined" sx={{marginBottom: 0}}>
                <AzaOutlinedInput
                    className={nameOfClass}
                    onChange={handleValue}
                    onBlur={handleUpdateSlice}
                    value={value}
                    multiline={isMultiline}
                    data-id_assets={assetData ? assetData.id : 'empty'}
                    rows={4}
                    size="small"
                    error={assetData?.error?.status}
                    placeholder={t('asset-placeholder', {
                        asset: `$t(${inputName})`, value: maxLength,
                        interpolation: {
                            skipOnVariables: false,
                        },
                    })}
                    endAdornment={
                        <AzaInputAdornment position="end">
                            {isGeneratedByAI ?
                                <PopoverTooltip
                                    placement="top"
                                    title={<AzaTypography>{t('open-ai.ai-generated')}</AzaTypography>}>
                                    <AzaBox
                                        component="span"
                                        sx={{
                                            minWidth: '10px',
                                            padding: '3px 7px',
                                            backgroundColor: theme.palette.info.lighter,
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            lineHeight: '1',
                                            color: theme.palette.info.darker,
                                            textAlign: 'center',
                                            borderRadius: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            "&:hover": {
                                                cursor: "pointer"
                                            }
                                        }}
                                    >
                                        <AzaAutoAwesome sx={{height: '18px', width: '22px'}}/> AI
                                    </AzaBox>
                                </PopoverTooltip>
                                 :
                                null
                            }
                            <AzaIconButton
                                onClick={handleRemove}
                                edge="end"
                                sx={{minWidth: '24px'}}
                            >
                                <AzaIconClose />
                            </AzaIconButton>
                        </AzaInputAdornment>
                    }
                />
            </AzaFormControl>
            <AzaTypography sx={{textAlign: 'right', fontSize: '11px', color: numbIsLeft <= maxLength ? allGrey[500] : 'red'}}>
                {numbIsLeft}/{maxLength}
            </AzaTypography>
            {assetData?.error?.status &&
                <AzaAlert severity="error">
                    <AzaBox>
                        {
                            assetData?.error?.message && assetData.error.message.map((el, index) => {
                                return <AzaBox key={index}>{el}</AzaBox>
                            })
                        }
                    </AzaBox>
                </AzaAlert>}
        </>
    )
};

export default InputCreation;