import React from 'react';
import {AdminOnlySettings} from "../../../widgets/siteSettings/adminOnlySettings";
import {SiteLogo} from "../../../containers/siteLogo/SiteLogo";

const SiteSettings = () => {
    return (
        <div>
            Hello, site settings will be here soon. To go to a "Credit settings" page click the credit button in top
            bar &#8594; &#8599;
            <SiteLogo/>
            <AdminOnlySettings/>

        </div>
    );
};

export default SiteSettings;
