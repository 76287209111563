import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {azameo_log_event, backendGet} from "../../utils/backendHelper";

const facebookSliceName = 'facebook'

export const backendFacebookState = {
    notReady: 'still loading the information from the backend',
    ConnectedOK: 'facebook information ok for campaign',
    ConnectedError: 'facebook information complete but problem found',
    ConnectedIncomplete: 'one of the facebook information is missing',
    NotConnected: 'no facebook information found',
}

const facebook_log_response = (func, response) => {
    azameo_log_event('facebook', {'func': func, 'response': response});
}


const initFacebookSdk = () => {
    return new Promise(resolve => {
        window.fbAsyncInit = function () {

            window.FB.init({
                appId: "272601996937502",
                cookie: true,
                xfbml: true,
                version: 'v12.0'
            });

            // auto authenticate with the api if already logged in with facebook
            window.FB.getLoginStatus((response) => {
                if (response.authResponse) {
                    resolve({logged: true});
                } else {
                    resolve({logged: false});
                }
            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    })
}

const getAzameoInfo = createAsyncThunk(`${facebookSliceName}/azameo/info`, async ({
                                                                                      site_id: site_id,
                                                                                      user: user
                                                                                  }, thunkAPI) => {
    const response = await backendGet(user, `/site/${site_id}/facebook/info`, {new: true}).catch((error) => console.log(`failed to fetch info : ${error}`));
    return {"backinfo": response};
})

const initFacebook = createAsyncThunk(`${facebookSliceName}/init`, async () => {
    return await initFacebookSdk();
})

const userInfoFacebook = createAsyncThunk(`${facebookSliceName}/info`, async (none, thunkAPI) => {
    const state = thunkAPI.getState()[`${facebookSliceName}`];
    const FB = window.FB;
    if (!state.logged) {
        return await new Promise(async resolve => {
            resolve({error: false, userinfo: {id: "", name: "", picture: {}}})
        })
    }
    return await new Promise(resolve => {
        FB.api('/me', {fields: ['id', 'name', 'picture']}, ({id, name, picture}) => {
            facebook_log_response('userInfoFacebook', {id, name, picture});
            resolve({error: false, userinfo: {id: id, name: name, picture: picture?.data}});
        });
    })

})

const loginFacebook = createAsyncThunk(`${facebookSliceName}/login`, async (none, thunkAPI) => {
    const state = thunkAPI.getState()[`${facebookSliceName}`];
    const FB = window.FB;
    if (state.logged) {
        return await new Promise(async resolve => {
            resolve({logged: true})
        });
    }

    return await new Promise(async resolve => {
        FB.login((response) => {
                if (response.authResponse) {
                    resolve({logged: true});
                } else {
                    resolve({logged: false});
                }
            },
            {scope: 'public_profile,ads_management,ads_read,business_management,email,pages_read_engagement,pages_show_list,catalog_management'});
    })
})

const logoutFacebook = createAsyncThunk(`${facebookSliceName}/logout`, async (none, thunkAPI) => {
    const state = thunkAPI.getState()[`${facebookSliceName}`];
    const FB = window.FB
    if (!state.logged) {
        return await new Promise(resolve => {
            resolve({logged: false})
        });
    }
    return await new Promise(resolve => {
        FB.api('/me/permissions', 'delete', {}, () => {
            FB.logout(() => {
                resolve({logged: false})
            });
        });
    })
})

const handleFacebookResponse = (response, param) => {
    return new Promise(async resolve => {
        if (param) {
            param = param.concat(response.data);
        } else {
            param = response.data;
        }
        if (response.paging && response.paging.next) {
            resolve(await fetch(response.paging.next).then(resp => resp.json()).then(resp => handleFacebookResponse(resp, param)));
        } else {
            resolve(param);
        }
    })
}

const facebookRequest = async (api, params_list) => {
    const FB = window.FB

    let result = [];

    for (const choice in params_list) {
        const params = {fields: params_list[choice].fields};
        result = result.concat(await new Promise(
            async (resolve) => {
                FB.api(api, 'get', params,
                    (response) => {
                        if (response[params_list[choice].response]) {
                            handleFacebookResponse(response[params_list[choice].response], []).then(
                                response => {
                                    facebook_log_response(api, response);
                                    resolve(response);
                                })
                        } else {
                            resolve([])
                        }
                    }
                )
            }))
    }
    return result;
}

const getBusinesses = createAsyncThunk(`${facebookSliceName}/businessmanger`, async (logged) => {
    if (!logged) {
        return {error: true, business: []}
    }

    const choices = {
        businesses: {
            fields: ['business_users{role,business}'],
            response: 'business_users'
        }
    }

    let result = await facebookRequest(`/me`, choices);

    return {
        error: false,
        business: result.map((element) => {
            element.business.valid = element.role.includes('ADMIN');
            element.business.error = []
            if (!element.role.includes('ADMIN')) {
                element.business.error.push('admin')
            }
            return element.business;
        })
    }
})

const getAdaccounts = createAsyncThunk(`${facebookSliceName}/adaccounts`, async ({business}) => {

    const choices = {
        client: {
            fields: ['client_ad_accounts{id,account_id,name,funding_source,account_status,adspixels}'],
            response: 'client_ad_accounts'
        },
        owned: {
            fields: ['owned_ad_accounts{id,account_id,name,funding_source,account_status,adspixels}'],
            response: 'owned_ad_accounts'
        },
    }

    let result = await facebookRequest(`/${business.id}`, choices);

    return {
        error: false,
        bm: business,
        accounts: result.map((element) => {
            element.error = [];
            element.valid = true;
            if (!element.funding_source) {
                element.valid = false;
                element.error.push('paiement');
            }
            const authorized_status = [1, 7, 9];  // [ACTIVE, PENDING_RISK_REVIEW, IN_GRACE_PERIOD]
            if (!authorized_status.find(a => a === element.account_status)) {
                element.valid = false;
                element.error.push('account_not_authorized');
            }

            return element;
        })
    };
})

const getPixels = createAsyncThunk(`${facebookSliceName}/pixels`, async ({business}) => {

    const choices = {
        client: {
            fields: ['client_pixels{id,name}'],
            response: 'client_pixels'
        },
        owned: {
            fields: ['owned_pixels{id,name}'],
            response: 'owned_pixels'
        },
    };

    let result = await facebookRequest(`/${business.id}`, choices);

    return {
        error: false,
        bm: business,
        pixels: result.map((element) => {
            element.error = [];
            element.valid = true;
            return element
        })
    };
})

const getPages = createAsyncThunk(`${facebookSliceName}/pages`, async ({business}) => {

    const choices = {
        client: {
            fields: ['client_pages{id,name}'],
            response: 'client_pages'
        },
        owned: {
            fields: ['owned_pages{id,name}'],
            response: 'owned_pages'
        },
    };

    let result = await facebookRequest(`/${business.id}`, choices);

    return {
        error: false,
        bm: business,
        pages: result.map((element) => {
            element.error = [];
            element.valid = true;
            return element
        })
    };
})

const getCatalogs = createAsyncThunk(`${facebookSliceName}/catalogs`, async ({business}) => {
    const choices = {
        client: {
            fields: ['client_product_catalogs{id,name}'],
            response: 'client_product_catalogs'
        },
        owned: {
            fields: ['owned_product_catalogs{id,name}'],
            response: 'owned_product_catalogs'
        },
    };

    let result = await facebookRequest(`/${business.id}`, choices);

    return {
        error: false,
        bm: business,
        catalogs: result.map((element) => {
            element.error = [];
            element.valid = true;
            return element
        })
    };
})

const assign_on_behalf_asset = async (asset, permissions, user_id_at_client) => {
    const FB = window.FB
    const params = {
        user: user_id_at_client,
        tasks: permissions
    };

    return await new Promise(resolve => {
        FB.api(`/${asset.id}/assigned_users`, 'post', params, () => {
            resolve(true);
        });
    })

}

const assign_agencies_asset = async (asset, permissions, azameo_business_id) => {
    const FB = window.FB
    const agencies_params = {
        business: azameo_business_id,
        permitted_tasks: permissions
    };

    return await new Promise(resolve => {
        FB.api(`/${asset.id}/agencies`, 'post', agencies_params, () => {
            resolve(true);
        });
    })

}

const connect_pixel_to_addacount = async (pixel, adaccount, business) => {
    const FB = window.FB
    const params = {
        account_id: adaccount.account_id,
        business: business.id
    };

    return await new Promise(resolve => {
        FB.api(`/${pixel.id}/shared_accounts`, 'post', params, () => {
            resolve(true);
        });
    })

}


function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

const connectAzameo = createAsyncThunk(`${facebookSliceName}/azameo/connect`, async ({
                                                                                         site_id,
                                                                                         user,
                                                                                         azameo_business,
                                                                                         business,
                                                                                         addacount,
                                                                                         page,
                                                                                         pixel,
                                                                                         catalog
                                                                                     }, thunkAPI) => {
        const FB = window.FB;

        let catalog_ = null;
        if (catalog.id !== -1) {
            catalog_ = catalog;
        }

        const params_obo_relationship = {existing_client_business_id: business.id};

        const business2business = await new Promise(resolve => {
            FB.api(`/${azameo_business.id}/managed_businesses`, 'post', params_obo_relationship, (response) => {
                resolve({response})
            });
        });

        const user_url = `/site/${site_id}/facebook/user?${encodeQueryData({bmid: business.id})}`;
        const azameo_sys_user_info = await backendGet(user, user_url).catch((error) => console.log(`failed to fetch info : ${error}`));

        const params_obo_catalog = {name: `CatalogAzameo_${addacount.name}`};

        const assigned_addacount = await assign_on_behalf_asset(addacount, 'MANAGE,ADVERTISE', azameo_sys_user_info.userid,);
        await assign_agencies_asset(addacount, ['ADVERTISE', 'ANALYZE'], azameo_business.id)

        const assigned_pixel = await assign_on_behalf_asset(pixel, 'ANALYZE,ADVERTISE', azameo_sys_user_info.userid,);
        let need_connect_pixel_to_catalog = true
        if (addacount?.adspixels.data) {
            for (const ad_account_pixel of addacount.adspixels.data) {
                if (pixel.id === ad_account_pixel.id) {
                    need_connect_pixel_to_catalog = false;
                    break;
                }
            }
        }
        if (need_connect_pixel_to_catalog === true) {
            await connect_pixel_to_addacount(pixel, addacount, business)
        }

        let azameo_catalog = false;
        if (catalog_ === null) {
            azameo_catalog = await new Promise(resolve => {
                FB.api(`/${business.id}/owned_product_catalogs`, 'post', params_obo_catalog, (response) => {
                    FB.api(`/${response.id}`, 'get', {}, (response) => {
                            catalog_ = response;
                            FB.api(`/${response.id}/external_event_sources`, 'post', {'external_event_sources': [pixel.id]}, () => {
                                resolve(true)
                            })
                        }
                    );
                })
            })
        } else {
            azameo_catalog = true;
        }

        const azameo_catalog_permissions = await assign_on_behalf_asset(catalog_, 'MANAGE,ADVERTISE', azameo_sys_user_info.userid);
        await assign_agencies_asset(catalog_, ['ADVERTISE', 'ANALYZE'], azameo_business.id);

        const assigned_page = await assign_on_behalf_asset(page, 'MANAGE,ADVERTISE', azameo_sys_user_info.userid);
        await assign_agencies_asset(page, ['ADVERTISE', 'ANALYZE'], azameo_business.id);

        const save_to_azameo_params = {
            account: addacount.id,
            pixel: pixel.id,
            page: page.id,
            catalog: catalog_.id
        };
        const save_url = `/site/${site_id}/facebook/save?${encodeQueryData(save_to_azameo_params)}`
        const save_to_azamo = await backendGet(user, save_url, save_to_azameo_params).then(res => res.json()).catch((error) => console.log(`failed to fetch info : ${error}`));

        return {
            result: (assigned_addacount && assigned_pixel && azameo_catalog && azameo_catalog_permissions && assigned_page && save_to_azamo),
            system_user: {id: azameo_sys_user_info.userid}
        };
    },
    {
        condition: ({azameo_business, business, addacount, page, pixel}, {getState, extra}) => {
            const currentState = getState()[`${facebookSliceName}`];
            const fetchStatus = currentState.status.connect
            if (fetchStatus === 'fulfilled' || fetchStatus === 'loading') {
                // Already fetched or in progress, don't need to re-fetch
                return false
            }
        },
    })

const add_if_needed = (item, table) => {
    let need_push = true
    const ret_table = (table === undefined) ? [] : table;
    for (const elem of ret_table) {
        if (elem.id === item.id) {
            need_push = false;
            break;
        }
    }
    if (need_push) {
        ret_table.push(item)
    }
    return {has_changed: need_push, new_table: ret_table};
}

const reset_facebook_choices = (state) => {
    const logged = state.logged;
    if (!logged) {
        state.businesses = []
        state.adaccounts = {}
        state.pixels = {}
        state.pages = {}
        state.catalogs = {}
    }
    const info_from_back = state.backinfo;
    for (const currentValue of info_from_back) {
        const baseinfo = {label: currentValue.name, id: currentValue.id, name: currentValue.id, valid: true, error: []}
        switch (currentValue.type) {
            case 'facebook_business_manager':
                if (state.selected.business.id !== baseinfo.id) {
                    state.selected.business = baseinfo;
                }
                break;
            case 'facebook_adaccount':
                if (state.selected.adaccount.id !== baseinfo.id) {
                    state.selected.adaccount = baseinfo;
                }
                break;
            case 'facebook_page':
                if (state.selected.page.id !== baseinfo.id) {
                    state.selected.page = baseinfo;
                }
                break;
            case 'facebook_pixel':
                if (state.selected.pixel.id !== baseinfo.id) {
                    state.selected.pixel = baseinfo
                }
                break;
            case 'facebook_catalog':
                if (state.selected.catalog.id !== baseinfo.id) {
                    state.selected.catalog = baseinfo
                }
                break;
        }
    }
    let ret_add_if_needed = {}
    for (const currentValue of info_from_back) {
        const baseinfo = {label: currentValue.name, id: currentValue.id, name: currentValue.id, valid: true, error: []}
        switch (currentValue.type) {
            case 'facebook_business_manager':
                ret_add_if_needed = add_if_needed(baseinfo, state.businesses);
                if (ret_add_if_needed.has_changed) {
                    state.businesses = ret_add_if_needed.new_table
                }
                break;
            case 'facebook_adaccount':
                ret_add_if_needed = add_if_needed(baseinfo, state.adaccounts[state.selected.business.id]);
                if (ret_add_if_needed.has_changed) {
                    state.adaccounts[state.selected.business.id] = ret_add_if_needed.new_table
                }
                break;
            case 'facebook_page':
                ret_add_if_needed = add_if_needed(baseinfo, state.pages[state.selected.business.id]);
                if (ret_add_if_needed.has_changed) {
                    state.pages[state.selected.business.id] = ret_add_if_needed.new_table
                }
                break;
            case 'facebook_pixel':
                ret_add_if_needed = add_if_needed(baseinfo, state.pixels[state.selected.business.id]);
                if (ret_add_if_needed.has_changed) {
                    state.pixels[state.selected.business.id] = ret_add_if_needed.new_table
                }
                break;
            case 'facebook_catalog':
                ret_add_if_needed = add_if_needed(baseinfo, state.catalogs[state.selected.business.id]);
                if (ret_add_if_needed.has_changed) {
                    state.catalogs[state.selected.business.id] = ret_add_if_needed.new_table
                }
                break;
        }
    }
}

export const facebookSlice = createSlice({
    name: `${facebookSliceName}`,
    initialState: {
        wizard_open: false,
        status: {
            backget: "idle",
            init: "idle",
            login: "idle",
            logout: "idle",
            business: "idle",
            adaccounts: "idle",
            connect: "idle",
            catalogs: "idle",
        },
        system_user: {},
        backinfo: [],
        backinfo_errors: {},
        backendFacebookState: backendFacebookState.notReady,
        userinfo: {},
        logged: false,
        businesses: [],
        adaccounts: {},
        pixels: {},
        pages: {},
        catalogs: {},
        selected: {
            business: {},
            adaccount: {},
            pixel: {},
            page: {},
            catalog: {},
        },
        connected: false,
        facebook_policy_checked: false,
    },
    reducers: {
        setBusiness: (state, action) => {
            if (action.payload) {
                state.selected.business = action.payload;
            } else {
                state.selected.business = {};
            }
            state.selected.adaccount = {};
            state.selected.page = {};
            state.selected.pixel = {};

        },
        setAdaccount: (state, action) => {
            if (action.payload) {
                state.selected.adaccount = action.payload;
            } else {
                state.selected.adaccount = {};
            }
        },
        setPage: (state, action) => {
            if (action.payload) {
                state.selected.page = action.payload;
            } else {
                state.selected.page = {};
            }
        },
        setCatalog: (state, action) => {
            if (action.payload !== undefined) {
                state.selected.catalog = action.payload;
            } else {
                state.selected.catalog = {};
            }
        },
        setPixel: (state, action) => {
            if (action.payload) {
                state.selected.pixel = action.payload;
            } else {
                state.selected.pixel = {};
            }
        },
        setWizardOpen: (state, action) => {
            state.wizard_open = action.payload;
        },
        setFacebookPolicyChecked: (state, action) => {
            state.facebook_policy_checked = true
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAzameoInfo.pending, (state, action) => {
                state.status.backget = 'loading';
                state.businesses = []
                state.adaccounts = {}
                state.pixels = {}
                state.pages = {}
                state.catalogs = {}
                state.selected = {
                    business: {},
                    adaccount: {},
                    pixel: {},
                    page: {},
                    catalog: {},
                }
            })
            .addCase(getAzameoInfo.fulfilled, (state, action) => {
                const {backinfo} = action.payload;
                const to_add = {};

                state.backinfo = backinfo.assets;
                state.backinfo_errors = backinfo.errors;
                state.status.backget = 'succeeded';

                if (state.backinfo.reduce((previousValue, currentValue) => {
                    return previousValue && (currentValue.id !== null)
                }, true)) {
                    state.backendFacebookState = backendFacebookState.ConnectedOK
                    state.connected = true
                } else {
                    state.backendFacebookState = backendFacebookState.NotConnected
                }
                reset_facebook_choices(state)
            })
            .addCase(getAzameoInfo.rejected, (state, action) => {
                state.status.backget = 'failed';
            })
            .addCase(initFacebook.pending, (state, action) => {
                state.status.init = 'loading';
            })
            .addCase(initFacebook.fulfilled, (state, action) => {
                const {logged} = action.payload;
                state.logged = logged;
                reset_facebook_choices(state);
                state.status.init = 'succeeded';
            })
            .addCase(initFacebook.rejected, (state, action) => {
                state.status.init = 'failed';
            })
            .addCase(loginFacebook.pending, (state, action) => {
                state.status.login = 'loading';
            })
            .addCase(loginFacebook.fulfilled, (state, action) => {
                const {logged} = action.payload;
                state.logged = logged;
                state.status.login = 'succeeded';
            })
            .addCase(loginFacebook.rejected, (state, action) => {
                state.status.login = 'failed';
            })
            .addCase(logoutFacebook.pending, (state, action) => {
                state.status.logout = 'loading';
            })
            .addCase(logoutFacebook.fulfilled, (state, action) => {
                const {logged} = action.payload;
                state.logged = logged;
                state.selected = {
                    business: {},
                    adaccount: {},
                    pixel: {},
                    page: {},
                    catalog: {},
                }
                reset_facebook_choices(state);
                state.status.logout = 'succeeded';
                state.status.business = 'idle';
            })
            .addCase(logoutFacebook.rejected, (state, action) => {
                state.status.logout = 'failed';
            })
            .addCase(userInfoFacebook.pending, (state, action) => {
                state.status.userinfo = 'loading';
            })
            .addCase(userInfoFacebook.fulfilled, (state, action) => {
                const {error, userinfo} = action.payload;
                if (!error) {
                    state.status.userinfo = 'succeeded';
                    state.userinfo = userinfo;
                } else {
                    state.status.business = 'failed';
                    state.userinfo = {};
                }
            })
            .addCase(userInfoFacebook.rejected, (state, action) => {
                state.status.userinfo = 'failed';
            })
            .addCase(getBusinesses.pending, (state, action) => {
                state.status.business = 'loading';
            })
            .addCase(getBusinesses.fulfilled, (state, action) => {
                const {error, business} = action.payload;
                if (!error) {
                    state.status.business = 'succeeded';
                    state.businesses = business;
                } else {
                    state.status.business = 'failed';
                    state.businesses = null;
                }
            })
            .addCase(getBusinesses.rejected, (state, action) => {
                state.status.business = 'failed';
            })
            .addCase(getAdaccounts.pending, (state, action) => {
                state.status.adaccounts = 'loading';
            })
            .addCase(getAdaccounts.fulfilled, (state, action) => {
                const {error, bm, accounts} = action.payload;
                if (!error) {
                    state.status.adaccounts = 'succeeded';
                    state.adaccounts[bm.id] = accounts;
                    reset_facebook_choices(state);
                } else {
                    state.status.adaccounts = 'failed';
                    state.adaccounts[bm.id] = null;
                }
            })
            .addCase(getAdaccounts.rejected, (state, action) => {
                state.status.adaccounts = 'failed';
            })
            .addCase(getPixels.pending, (state, action) => {
                state.status.pixels = 'loading';
            })
            .addCase(getPixels.fulfilled, (state, action) => {
                const {error, bm, pixels} = action.payload;
                if (!error) {
                    state.status.pixels = 'succeeded';
                    state.pixels[bm.id] = pixels;
                    reset_facebook_choices(state);
                } else {
                    state.status.pixels = 'failed';
                    state.pixels[bm.id] = null;
                }
            })
            .addCase(getPixels.rejected, (state, action) => {
                state.status.pixels = 'failed'
            })
            .addCase(getPages.pending, (state, action) => {
                state.status.pages = 'loading'
            })
            .addCase(getPages.fulfilled, (state, action) => {
                const {error, bm, pages} = action.payload;
                if (!error) {
                    state.status.pages = 'succeeded';
                    state.pages[bm.id] = pages;
                    reset_facebook_choices(state);
                } else {
                    state.status.pages = 'failed';
                    state.pages[bm.id] = null;
                }
            })
            .addCase(getPages.rejected, (state, action) => {
                console.log(action.payload)
                state.status.pages = 'failed';
            })
            .addCase(getCatalogs.pending, (state, action) => {
                state.status.catalogs = 'loading'
            })
            .addCase(getCatalogs.fulfilled, (state, action) => {
                const {error, bm, catalogs} = action.payload;
                if (!error) {
                    state.status.catalogs = 'succeeded';
                    state.catalogs[bm.id] = [{id: -1, name: 'Let Azameo create one for you', valid: true}, ...catalogs];
                    reset_facebook_choices(state);
                } else {
                    state.status.catalogs = 'failed';
                    state.catalogs[bm.id] = null;
                }
            })
            .addCase(getCatalogs.rejected, (state, action) => {
                console.log(action.payload)
                state.status.pages = 'failed';
            })
            .addCase(connectAzameo.pending, (state, action) => {
                state.status.connect = 'loading';
            })
            .addCase(connectAzameo.fulfilled, (state, action) => {
                state.status.connect = 'succeeded';
                state.connected = action.payload.result;
                state.system_user = action.payload.system_user
            })
            .addCase(connectAzameo.rejected, (state, action) => {
                state.status.connect = 'failed';
            })
    }
})


export const {
    setWizardOpen,
    setBusiness,
    setAdaccount,
    setPage,
    setCatalog,
    setPixel,
    setFacebookPolicyChecked
} = facebookSlice.actions
export {
    getAzameoInfo, connectAzameo,
    initFacebook, loginFacebook, logoutFacebook, userInfoFacebook,
    getBusinesses, getAdaccounts, getPixels, getPages, getCatalogs
}

export const selectLogoutLoading = (state) => {
    return state[`${facebookSliceName}`].status.logout === 'loading';
}
export const selectBackinfo = (state) => state[`${facebookSliceName}`].backinfo;
export const selectConnectionState = (state) => {
    return state[`${facebookSliceName}`].backendFacebookState
}
export const selectErrorSumUpState = (state) => {
    const errors = state[`${facebookSliceName}`].backinfo_errors;

    let has_error = false;
    let msg = ""

    const found_errors = {}

    if (state[`${facebookSliceName}`].status.backget === 'succeeded') {
        for (const error_idx in errors) {
            const error = errors[error_idx]
            if (error.level === 'site') {
                has_error = true;
                found_errors[error.id] = true
            }
        }
    }
    for (const msg_error in found_errors) {
        msg += msg_error + '\n';
    }
    return {has_error, msg};
}
export const selectLogged = (state) => state[`${facebookSliceName}`].logged;
export const selectUserinfo = (state) => state[`${facebookSliceName}`].userinfo;

export const selectBusinesses = (state) => state[`${facebookSliceName}`].businesses;
export const selectBusinessesLoading = (state) => {
    return state[`${facebookSliceName}`].status.business === 'loading';
}
export const selectBusinessesRequested = (state) => {
    return state[`${facebookSliceName}`].status.business !== 'idle';
}
export const selectChoosenBusiness = (state) => state[`${facebookSliceName}`].selected.business;

export const selectAdaccounts = (state) => {
    if (state[`${facebookSliceName}`].selected.business) {
        return state[`${facebookSliceName}`].adaccounts[state[`${facebookSliceName}`].selected.business.id]
    } else {
        return null;
    }
}
export const selectAdaccountsLoading = (state) => {
    return state[`${facebookSliceName}`].status.adaccounts === 'loading';
}
export const selectChoosenAdaccount = (state) => state[`${facebookSliceName}`].selected.adaccount;

export const selectPixels = (state) => {
    if (state[`${facebookSliceName}`].selected.business) {
        return state["facebook"].pixels[state[`${facebookSliceName}`].selected.business.id]
    } else {
        return null;
    }
}
export const selectPixelsLoading = (state) => {
    return state[`${facebookSliceName}`].status.pixels === 'loading';
}
export const selectChoosenPixel = (state) => state[`${facebookSliceName}`].selected.pixel;

export const selectPages = (state) => {
    if (state[`${facebookSliceName}`].selected.business) {
        return state["facebook"].pages[state[`${facebookSliceName}`].selected.business.id]
    } else {
        return null;
    }
}
export const selectPagesLoading = (state) => {
    return state[`${facebookSliceName}`].status.pages === 'loading';
}
export const selectChoosenPage = (state) => state[`${facebookSliceName}`].selected.page;

export const selectCatalogs = (state) => {
    if (state[`${facebookSliceName}`].selected.business) {
        return state["facebook"].catalogs[state[`${facebookSliceName}`].selected.business.id]
    } else {
        return null;
    }
}
export const selectCatalogsLoading = (state) => {
    return state[`${facebookSliceName}`].status.catalogs === 'loading';
}
export const selectChoosenCatalog = (state) => state[`${facebookSliceName}`].selected.catalog;

export const selectConnected = (state) => state[`${facebookSliceName}`].connected;
export const selectConnecting = (state) => {
    return state[`${facebookSliceName}`].status.connect === 'loading';
}

export const selectWizardOpen = (state) => {
    return state[`${facebookSliceName}`].wizard_open;
}

export const selectSystemUser = (state) => {
    return state[`${facebookSliceName}`].system_user;
}

export const selectFacebookPolicyChecked = (state) => {
    return state[`${facebookSliceName}`].facebook_policy_checked;
}

export default facebookSlice.reducer






