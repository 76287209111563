/*
Generate New Input Object for Products
 */
import {assetType} from "../../../../../pages/authenticated/assetsEdition/constants";
import {
    calloutHandler,
    descriptionHandler,
    headlineHandler,
    keywordHandler,
    longHeadlineHandler
} from "./inputConfigData";

const generateTemplate = (idGenerated, scope, siteId, textType, productId, language) => {
    return  {
        cleaned_text: "",
        enabled: true,
        id: idGenerated,
        language: language,
        origin: "Customer",
        product_id: productId,
        scope: scope,
        site_id: siteId,
        text: "",
        text_type: textType,
    };
}

export const generateNewInputObj = (requiredMin, scope, siteId, textType, productId = null, language = 'fr') => {
    const assetsArr = [];
    for (let i = 0; i < requiredMin; i++) {
        let idGenerated = Date.now() + '-' + Math.floor(Math.random() * 1000);
        assetsArr.push(generateTemplate(idGenerated, scope, siteId, textType, productId, language));
    }

    return assetsArr;
};

// export const creatNewProductAssetsGroup = (data) => {
export const creatNewAssetsGroup = (data) => {
    const {scope, siteId, productId, language} = data;
    const assetsGroup = {
        [assetType.HEADLINE]: [],
        [assetType.LONG_HEADLINE]: [],
        [assetType.DESCRIPTION]: [],
        [assetType.CALLOUT]: [],
        [assetType.KEYWORD]: [],
    }

    const minTitleNumber = headlineHandler.minInputs;
    const minLongTitleNumber = longHeadlineHandler.minInputs;
    const minDescriptionNumber = descriptionHandler.minInputs;
    const minCalloutNumber = calloutHandler.minInputs;
    const minKeywordNumber = keywordHandler.minInputs;

    for (const inputType in assetsGroup) {
        switch (inputType) {
            case assetType.HEADLINE:
                assetsGroup[inputType] = generateNewInputObj(minTitleNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.LONG_HEADLINE:
                assetsGroup[inputType] = generateNewInputObj(minLongTitleNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.DESCRIPTION:
                assetsGroup[inputType] = generateNewInputObj(minDescriptionNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.CALLOUT:
                assetsGroup[inputType] = generateNewInputObj(minCalloutNumber, scope, siteId, inputType, productId, language);
                break;
            case assetType.KEYWORD:
                assetsGroup[inputType] = generateNewInputObj(minKeywordNumber, scope, siteId, inputType, productId, language);
                break;
            default:
                console.log(inputType + ' : Unexpected assets type');
        }
    }

    return assetsGroup;
}