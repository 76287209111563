import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import Checkbox from "@mui/material/Checkbox";
import {FormControlLabel, useTheme} from "@mui/material";
import {MoreVert} from "@mui/icons-material";

import {useAdminModeProvider} from "../../../features/adminMode/adminModeContext";
import {useCustomization} from "../../../features/customization/customizationHooks";
import {useWidgetDefinitions} from "./widgetDefinitions";
import {AdminModeBranch} from "../../../features/adminMode/adminModeComponents";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaMenu, AzaMenuItem} from "../../../components/mui/AzaMenu";
import {AzaButton} from "../../../components/mui/AzaButton";

export const WidgetSelector = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {t} = useTranslation();
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const {widgetDefinitions} = useWidgetDefinitions();
    const ITEM_HEIGHT = 90;
    const handleClickOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // It needs to know if the user is in admin mode or not to change the displayed widget list
    const {adminMode} = useAdminModeProvider()
    const {loading, attributionWidgets, updateAttributionWidgets} = useCustomization();

    const handleClick = (event) => {
        const elValue = event.target.value;
        const selectedIndex = attributionWidgets.indexOf(elValue);
        let newSelected = [];

        if(selectedIndex === -1) {
            newSelected = newSelected.concat(attributionWidgets, elValue);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(attributionWidgets.slice(1));
        } else if (selectedIndex === attributionWidgets.length - 1) {
            newSelected = newSelected.concat(attributionWidgets.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                attributionWidgets.slice(0, selectedIndex),
                attributionWidgets.slice(selectedIndex + 1)
            );
        }
        updateAttributionWidgets(newSelected);
    }

    // Filter the list of available widget to not display the one only available in admin mode
    const filteredWidgets = useMemo(() => (
        Object.values(widgetDefinitions).filter(widget => (!widget.admin) || adminMode)
    ), [adminMode, widgetDefinitions])

    if (loading) return <></>

    return (
        <AzaBox>
            <AzaBox
                sx={{
                    position: 'absolute',
                    top: '15px',
                    right: '19px',
                    minWidth: '44px',
                    color: theme.palette.text.secondary,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <AzaButton
                    onClick={handleClickOpen}
                    sx={{
                        textTransform: 'none',
                        color: theme.palette.text.secondary,
                        marginRight: '7px',
                    }}
                >
                    {t('more')}&nbsp;
                    <MoreVert/>
                </AzaButton>

            </AzaBox>
            <AzaMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
            {filteredWidgets.map((widget) => (
                <AzaMenuItem
                    key={widget.field}
                    value={widget.field}
                >
                    <AdminModeBranch needAdmin={widget.admin}>
                        <FormControlLabel
                            sx={{width: '100%', mr: '11px'}}
                            label={t(widget.label)}
                            control={
                                <Checkbox
                                    value={widget.field}
                                    checked={attributionWidgets.indexOf(widget.field) > -1}
                                    onClick={handleClick}
                                />
                            }
                        />
                    </AdminModeBranch>
                </AzaMenuItem>
            ))}
            </AzaMenu>
        </AzaBox>
    );
}
