import React, {useEffect, useRef} from 'react';
import {MessageList} from "./Message";
import MessageInput from "./MessageInput";
import {useScreenSize} from "../../app/globalHooks";
import {AzaContainer} from "../../components/mui/AzaContainer";

import {ChatProvider, useChatProvider} from "./chatContext";
import {AzaStack} from "../../components/mui/AzaStack";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaBox} from "../../components/mui/AzaBox";
import {AzaGridItem} from "../../components/mui/AzaGrid";


const ChatWidget = () => {
    const bottompadding = 40;

    const {isSmallScreen} = useScreenSize();
    const {
        messages, promptDisabled, sending, onSubmitPrompt
    } = useChatProvider();

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef?.current?.focus();
    }, [inputRef, messages]);

    return (<AzaContainer maxWidth={"md"} sx={{height: 0.95}}>
            <AzaStack
                direction={"column"}
                sx={{minHeight: 1 }}
            >
                <AzaGridItem>
                    <AzaBox>
                        <AzaTypography variant={isSmallScreen ? "h6" : "h4"} sx={{paddingBottom: "16px"}}>Azameo AI</AzaTypography>
                    </AzaBox>
                </AzaGridItem>
                <AzaGridItem sm>
                    <AzaStack
                        width="100%"
                        minHeight={"100%"}
                        direction={"column"}
                        spacing={1}
                        justifyContent={"flex-end"}
                        alignItems={"stretch"}
                        sx={{height: "100%"}}
                    >
                        <MessageList messages={messages} bottompadding={bottompadding} loading={sending}/>
                    </AzaStack>
                </AzaGridItem>
                <AzaGridItem style={{
                    width: "100%",
                    position: "sticky",
                    bottom: 0,
                    paddingBottom: bottompadding,
                    marginLeft: 0,
                    marginRight: 0,
                }}>
                    <MessageInput
                        inputRef={inputRef}
                        disabled={sending}
                        onSubmit={onSubmitPrompt}
                        promptdisabled={promptDisabled}
                    />
                </AzaGridItem>
            </AzaStack>
        </AzaContainer>);
}

const ChatContainer = () => {
    return (<ChatProvider>
            <ChatWidget/>
        </ChatProvider>)
};


export default ChatContainer;
