import {AzaBox} from "../../../components/mui/AzaBox";
import CampaignSettings from "./settingsGroup/CampaignSettings";
import BudgetSettings from "./settingsGroup/BudgetSettings";

const Settings = () => {
    return (
        <AzaBox>
            <CampaignSettings/>
            <BudgetSettings/>
        </AzaBox>
    );
};

export default Settings;