import React from 'react';
import {useTranslation} from "react-i18next";

import AzaSettingsBox from "../../../components/azameo/AzaSettingsBox";
import AzaBillsTable from "../creditUtile/AzaBillsTable";
import {useSelector} from "react-redux";
import {selectInvoicesData} from "../../payment/paymentGroup/paymentSlice";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaCircularProgress} from "../../../components/mui/AzaIcons";

const CreditsBills = ({title}) => {
    const {invoicesData, loading} = useSelector(selectInvoicesData);
    const {t} = useTranslation();

    return <AzaSettingsBox
        title={{
            name: title,
            id: 'bill',
        }}
    >
        <AzaBox sx={{width: '100%'}}>
            {loading && <AzaBox><AzaCircularProgress/>{t('container.credits.bills.chargement')}...</AzaBox>}
            {!loading &&
                (invoicesData.data.length > 0 ? <AzaBillsTable invoicesData={invoicesData.data}/> :
                <AzaBox>{t('container.credits.bills.nobill')}</AzaBox>)
            }
        </AzaBox>
    </AzaSettingsBox>
};

export default CreditsBills;
