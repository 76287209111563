const Link = () => {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    }
  };
}

export default Link;
