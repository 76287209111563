import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {AzaBox} from "../../../components/mui/AzaBox";
import AzaCardTemplate from "../../../components/azameo/AzaCardTemplate";
import {selectCampaignName} from "../reducers/editCampaignSlice";
import {AzaButton} from "../../../components/mui/AzaButton";

const EditTitle = () => {
    const {t} = useTranslation();
    const campaignName = useSelector(selectCampaignName);

    const handleUpdate = () => {
        // if needed to update send setUpdate()
        console.log('Functionality to edit components not ready yet');
    }

    return (
        <AzaBox sx={{mb: '30px'}}>
            <AzaCardTemplate title={t('edit.edit-campaign-name')}>
                <AzaBox sx={{mb: '30px'}}>{campaignName}</AzaBox>
                <AzaButton variant="contained" onClick={handleUpdate}>{t('common.buttons.edit')}</AzaButton>
            </AzaCardTemplate>
        </AzaBox>
    );
};

export default EditTitle;