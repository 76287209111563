import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";

import {AzaListItemIcon} from "../../components/mui/AzaList";
import DataDrawerList from "../../components/dataDrawerList/DataDrawerList";
import {
    PATH_ADD_ACCOUNT,
    PATH_BACKEND_SETTINGS,
    PATH_CAMPAIGNS,
    PATH_INTEGRATION,
    PATH_OVERVIEW,
    PATH_SAMPLE_MUI,
    PATH_SUBSCRIPTION,
    PATH_SITE_SETTINGS, PATH_CREDITS_SETTINGS, PATH_OPENAI_GENERATION, PATH_CHAT, PATH_SMART5,
} from "../../utils/constant";
import {AzaDivider} from "../../components/mui/AzaDivider";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AdminModeBranch} from "../../features/adminMode/adminModeComponents";
import {useScreenSize} from "../../app/globalHooks";
import {usePaymentMode} from "../payment/paymentContext";
import {AzaBox} from "../../components/mui/AzaBox";
import {
    AzaBackupTable, AzaCafe, AzaChat,
    AzaCreditCardIcon,
    AzaDashboard, AzaDriveFolderUpload, AzaExtensionIcon, AzaInsertLink,
    AzaPsychology, AzaRadioButtonChecked, AzaSettings,
    AzaTune
} from "../../components/mui/AzaIcons";


const SettingsSubMenu = ({open}) => {

    const {isSmallScreen} = useScreenSize();
    const {t} = useTranslation();

    const iconsSettingsResponsive = [
        {
            name: t('sidebar.title.subscription'),
            icon: <AzaCafe/>,
            link: PATH_SUBSCRIPTION
        },
        {
            name: t('sidebar.title.credits'),
            icon: <AzaCreditCardIcon/>,
            link: PATH_CREDITS_SETTINGS
        },
    ];


    const iconsSettingsDesktop = [
        {
            name: t('sidebar.title.integration'),
            icon: <AzaExtensionIcon/>,
            link: PATH_INTEGRATION,
            admin: true,
        },
        {
            name: t('sidebar.title.adaccounts'),
            icon: <AzaInsertLink/>,
            link: PATH_ADD_ACCOUNT,
            admin: true,
        },
        {
            name: t('sidebar.title.sitesettings'),
            icon: <AzaTune/>,
            link: PATH_SITE_SETTINGS,
            admin: true,
        },
        {
            name: t('sidebar.title.backendsettings'),
            icon: <AzaBackupTable/>,
            link: PATH_BACKEND_SETTINGS,
            admin: true,
        },
        {
            name: t('sidebar.title.openaigeneration'),
            icon: <AzaPsychology/>,
            link: PATH_OPENAI_GENERATION,
            admin: true,
        },
        {
            name: t('sidebar.title.uisample'),
            icon: <AzaDashboard/>,
            link: PATH_SAMPLE_MUI,
            admin: true,
        },
        {
            name: "Smart5",
            icon: <AzaDriveFolderUpload/>,
            link: PATH_SMART5,
            admin: true,
        },
    ];

    return (
        <AdminModeBranch needAdmin={true}>
            <AzaDivider/>
            <AzaBox style={{marginTop: '10px'}}>
                {open && <AzaTypography
                    variant={`body1`}
                    sx={{
                        textAlign: 'left',
                        padding: '2px 20px',
                    }}
                >
                    {t('sidebar.title.settings')}
                </AzaTypography> }
                {!open && <AzaListItemIcon sx={{justifyContent: 'center', width: '100%'}}>
                        <AzaSettings/>
                    </AzaListItemIcon>
                }
            </AzaBox>
            <DataDrawerList
                iconsData={isSmallScreen ? [...iconsSettingsResponsive, ...iconsSettingsDesktop] : iconsSettingsDesktop}
                open={open}
            />
        </AdminModeBranch>
    );
};

const DrawerList = ({open}) => {

    const {t} = useTranslation();

    const {setOpenPayment} = usePaymentMode();

    const paymentAction = useCallback(() => {
        setOpenPayment(true);
    }, [setOpenPayment])

    const iconsTop = [
        {
            name: t('sidebar.title.overview'),
            icon: <AzaDashboard/>,
            link: PATH_OVERVIEW,
            admin: true,
        },
        {
            name: t('sidebar.title.chat'),
            icon: <AzaChat/>,
            link: PATH_CHAT,
        },
        {
            name: t('sidebar.title.mycampaigns'),
            icon: <AzaRadioButtonChecked/>,
            link: PATH_CAMPAIGNS,
        },
        {
            name: t('sidebar.title.payment'),
            icon: <AzaCreditCardIcon/>,
            action: paymentAction,
            admin: true,
        },
    ];


    return (
        <>
            <DataDrawerList iconsData={iconsTop} open={open}/>
            <SettingsSubMenu open={open}/>
        </>
    );
};

export default DrawerList;
