import React from 'react';
import {Link} from "@mui/material";
import {Link as LinkRouter} from "react-router-dom";


export const AzaLink = (props) => {
    return (
        <Link {...props}/>
    );
};

export const AzaLinkRouter = (props) => {
    return (
        <LinkRouter {...props}/>
    );
};


export default AzaLink;
