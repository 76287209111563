import {useState} from 'react';

import {SideBarElements} from "../../pages/authenticated/SideBarElements";
import {AzaBox} from "../../components/mui/AzaBox";
import MobileFooter from "../../components/mobileFooter/MobileFooter";
import {useScreenSize} from "../../app/globalHooks";

const MainContentWrapper = ({children}) => {
    const [overlapHelp, setOverlapHelp] = useState(true);
    const {isSmallScreen} = useScreenSize();
    const marginLeftMain = '65px';

    return (
        <AzaBox sx={{display: 'flex', minHeight:"100vh"}}>
            <SideBarElements setOverlapHelp={setOverlapHelp}/>
            <AzaBox component={`main`} sx={{
                flexGrow: 1,
                p: isSmallScreen ? '24px  9px 85px' : 3,
                width: overlapHelp ? `calc(100% - ${marginLeftMain})` : '100%',
                ml: (overlapHelp && !isSmallScreen) ? marginLeftMain : '0',
            }}
            >
                {children}
            </AzaBox>
            {isSmallScreen ? <MobileFooter/> : null}
        </AzaBox>
    );
};

export default MainContentWrapper;