import {Card, CardActions, CardContent, CardHeader, styled, Typography} from "@mui/material";
import {AzaAlert} from "./AzaAlert";

export const AzaAlertActionCard = styled((props) => {
    const {severity, text, actions} = props;
    return <AzaAlert
                severity={severity}
                action={actions}
                sx={{width:"100%", alignItems:"center"}}>
                <Typography>{text}</Typography>
            </AzaAlert>
})(()=> ({}))

export const AzaCard = styled((props) => {
    const {children, icon, title, actions, ...other} = props;
    return <Card {...other} >
        <CardHeader
            avatar={icon}
            title={<Typography variant={'h6'}>{title}</Typography>}
        />
        <CardContent>
            {children}
        </CardContent>
        <CardActions sx={{marginTop:"auto"}}>
            {actions}
        </CardActions>
    </Card>
})(() => ({
    xs: 4,
    // height: 298,
    display: "flex",
    flexDirection: "column"
}));

export const AzaCardBase = styled((props) => {
    const {children, ...others} = props;

    return(
        <Card {...others}>{children}</Card>
    )
})(() => ({}));

export const AzaCardHeader = styled((props) => {
    const {...others} = props;

    return(
        <CardHeader {...others}/>
    )
})(() => ({}));

export const AzaCardContent = styled((props) => {
    const {children, ...others} = props;

    return(
        <CardContent {...others}>{children}</CardContent>
    )
})(() => ({}));