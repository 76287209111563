import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";

import {useTheme} from "@mui/material";

import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AzaBox} from "../../../components/mui/AzaBox";
import ImagesList from "./ImagesList";
import {imgValidation} from "./imgsValidation";
import {allGrey} from "../../../utils/colors";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import {AzaMoveToInbox} from "../../../components/mui/AzaIcons";


const AddImages = ({inputGroupName, configData, minMaxIndication, images, addImage, removeImage, error}) => {
    const {t} = useTranslation();
    const imgFiles = images;
    const theme = useTheme();

    const filesFromStore = imgFiles?.map(file => (
        <li key={file.path}>
            {file.path} - {file?.size} byte
        </li>
    ));

    // I use this intermediate function just to pass more arguments (didn't find other solution)
    const imgValidationPilot = (file) => {
        return imgValidation(file, imgFiles, configData.maxInputs, t);
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        fileRejections
    } = useDropzone({
        accept: {"image/*": []},
        maxFiles: configData.maxInputs,
        validator: imgValidationPilot,
        onDrop: acceptedFiles => {
            const filesWithPreview = acceptedFiles.map((file) => {
                return Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            });

            addImage(filesWithPreview);
        }
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    return (
        <AzaBox>
            <AzaBox
                sx={{
                    marginBottom: '20px',
                    display: 'flex'
                }}
            >
                <AzaBox sx={{fontWeight: 'bold', mr: '5px'}}>{inputGroupName}</AzaBox>
            </AzaBox>
            <AzaBox sx={{marginBottom: '20px', color: allGrey[500]}}>
                {minMaxIndication}
            </AzaBox>
            <AzaPaper
                elevation={0}
                sx={{
                    color: theme.palette.secondary.main,
                    border: `1px dashed ${error?.status ? theme.palette.error.light : theme.palette.secondary.main}`,
                    '&:hover': {border: `1px solid ${error?.status ? theme.palette.error.light : theme.palette.secondary.main}`},
                    padding: '20px',
                }}
            >
                <AzaBox
                    sx={{
                        padding: '10px',
                        textAlign: 'center',
                        background: theme.palette.secondary.lighter,
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <AzaBox>
                        <AzaMoveToInbox sx={{color: theme.palette.primary.main, fontSize: '33px'}}/>
                    </AzaBox>
                    {isDragActive ? (
                        <p style={{color: 'green', margin: '5px 0 3px'}}>{t('images.drop')}</p>
                    ):(
                      <p style={{margin: '5px 0 3px'}}>{t('images.drag-and-drop')}</p>
                    )}
                    <em>(jpg, jpeg, png)</em>
                </AzaBox>
            </AzaPaper>
            {fileRejections.length > 0 ? <p>{t('images.rejected')}</p> : <></>}
            <ul>{fileRejectionItems}</ul>
            {imgFiles.length > 0 ? <p>{t('images.accepted')}</p> : <></>}
            <ul>
                {filesFromStore}
            </ul>
            {error?.status &&
                <AzaAlert severity="error">
                    <AzaBox>
                        {
                            error?.message && <AzaBox>{error.message}</AzaBox>
                        }
                    </AzaBox>
                </AzaAlert>}

            <ImagesList imgFiles={imgFiles} removeAction={removeImage}/>
        </AzaBox>
    );
};

export default AddImages;

