import React, {useEffect, useState, useMemo} from 'react';

import {Box, Divider, Typography} from "@mui/material";

import {useFormat, useSiteId, useUser} from "../../../app/globalHooks";
import {useDispatch, useSelector} from "react-redux";
import {
    actionResetStripePaymentIntent, checkStripePaymentIntent,
    selectInvoiceId, selectStripePaymentIntent,
    selectStripePaymentIntentResult,
    selectTotals
} from "./paymentSlice";
import {useTranslation} from "react-i18next";
import {AzaSkeleton} from "../../../components/mui/AzaSkeleton";

// noinspection AllyPlainJsInspection
const styledData = {
    infoContainers: {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 0",
    }
};

const DonePayment = ({status, reentry, code}) => {
    const {user} = useUser();
    const site_id = useSiteId();
    const {formatMoney, formatDate, lastInvoiceId} = useFormat();
    const date = new Date();
    const day = date.getTime();
    const invoiceNumb = useSelector(selectInvoiceId);
    const paymentIntent = useSelector(selectStripePaymentIntent);
    const stripeStatus = useSelector(selectStripePaymentIntentResult);
    const {ttc} = useSelector(selectTotals);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!user || !site_id) {
            return;
        }
        if (reentry) {
            if (reentry === 'stripe') {
                if (code !== null &&  status === 'succeeded' && stripeStatus.status === "idle") {
                    dispatch(checkStripePaymentIntent({site_id:site_id, user:user, paiment_intent_id:code}));
                }
                setReady(stripeStatus.status === 'fulfilled');
            }
        } else {
            setReady(true);
        }
    }, [reentry, setReady, stripeStatus, code, status, user, site_id, dispatch]);

    return (
        <div>
            <Box
                component={"h4"}
                sx={{marginTop: '30px'}}
            >
                {status === "succeeded" && t('payment.done.congratulation')}
                {status !== "succeeded" && t('payment.done.echec')}
            </Box>
            <Box>
                { ready && <Typography>{t('payment.done.proceeded')}</Typography> }
                { !ready && <AzaSkeleton width={"100%"} height={60}/> }
                { invoiceNumb.id !== "" && <Typography>{t('payment.done.visualizeinvoice')}</Typography>}
            </Box>
            <Box>
                <br/>
                <Typography>{t('payment.done.invoicesummary')}</Typography>
                <Divider sx={{margin: '10px 0'}}/>
                <Box style={styledData.infoContainers}>
                    { ready && <Typography>{t('payment.done.invoicenumber')}</Typography> }
                    { ready && invoiceNumb.id !== "" && <Typography>{invoiceNumb.display??invoiceNumb.id}</Typography> }
                    { ready && invoiceNumb.id === "" && <Typography>{t('payment.done.invoiceinpreparation')}</Typography> }
                    { !ready && <AzaSkeleton width={"100%"} height={60}/> }
                </Box>
                <Box style={styledData.infoContainers}>
                    { ready && <Typography>{t('payment.done.invoicedate')}</Typography> }
                    { ready && invoiceNumb.id === "" && <AzaSkeleton variant="text" width={"40%"} height={60}/> }
                    { ready && invoiceNumb.id !== "" && <Typography>{formatDate(day)}</Typography> }
                    { !ready && <AzaSkeleton width={"100%"} height={60}/> }
                </Box>
                <Divider sx={{margin: '10px 0'}}/>
                <Box>
                    <Typography>{t('payment.done.description')}</Typography>
                    <Typography></Typography>
                </Box>
                <Box style={styledData.infoContainers}>
                    { ready && <Typography>{t('payment.detail.total')}</Typography> }
                    { ready && <Typography>{formatMoney(ttc)}</Typography>}
                    { !ready && <AzaSkeleton width={"100%"} height={60}/> }
                </Box>
            </Box>
        </div>
    );
};

export default DonePayment;
