import {StripeBox} from "./AzaStripeButton";
import {useTranslation} from "react-i18next";
import {AzaBox} from "../../../components/mui/AzaBox";


export const Pay = ({decrement, increment, }) => {

    const {t} = useTranslation();

    return (
        <>
            <AzaBox
                component={"h4"}
                sx={{marginTop: '30px'}}
            >
                {t("payment.pay.title")}
            </AzaBox>
            <AzaBox>
                <StripeBox
                    paimentDone={increment}
                    paimentCanceled={decrement}
                />
            </AzaBox>
        </>
    );
}
