import {createSlice} from "@reduxjs/toolkit";

const campaignDataSliceName = "campaignIdData";
export const campaignDataSlice = createSlice({
    name: `${campaignDataSliceName}`,
    initialState: {
        campaignID: [],
    },
    reducers: {
        selectedCampaign: (state, action) => {
            state.campaignID = action.payload;
        }
    }
});

export const selectCampaign = (state) => {
    return state[`${campaignDataSliceName}`].campaignID;
}

const {actions} = campaignDataSlice;
export const {selectedCampaign} = actions;