import React from "react";
import {PATH_SAMPLE_MUI, PATH_SAMPLE_MUI_COLOR} from "../utils/constant";

import {SamplePage} from "./SamplePage";
import SampleElements from "./SampleElements";
import {SampleRdb} from "./rdb/SampleRdb";
import SampleFacebook from "./SampleFacebook";
import {SampleBack} from "./back/SampleBack";
import {SampleBrandRoi} from "./brandroi/SampleBrandRoi";
import {SampleAttribution} from "./attribution/SampleAttribution";
import {SampleAzameoTag} from "./SampleAzameoTag";
import {SampleColorsPage} from "./SampleColorsAndTypo";
import {SampleOAuth} from "./SampleOAuth";
import {SampleChat} from "./chat/SampleChat";

// Theses followed path are only for testing purpose and available only in admin mode

export const PATH_SAMPLE_USER = '/sample/user';
export const PATH_SAMPLE_RDB = '/sample/rdb';
export const PATH_SAMPLE_FACEBOOK = '/sample/facebook';
export const PATH_SAMPLE_BACK = '/sample/back';
export const PATH_SAMPLE_BRAND_ROI = '/sample/brandroi';
export const PATH_SAMPLE_ATTRIBUTION = '/sample/attribution';
export const PATH_SAMPLE_AZAMEO_TAG = '/sample/azameotag';
export const PATH_SAMPLE_CHAT_HOOK = '/sample/chathook';

const PATH_SAMPLE_OAUTH = '/sample/oauth';

const sample_routes = [
    {name: 'MUI Sample', path: PATH_SAMPLE_MUI, element: <SamplePage><SampleElements/></SamplePage>},
    {name: "Sample OAuth", path: PATH_SAMPLE_OAUTH, element: <SamplePage><SampleOAuth/></SamplePage>},
    {name: 'MUI Colors & Typo', path: PATH_SAMPLE_MUI_COLOR, element: <SamplePage><SampleColorsPage/></SamplePage>},
    // {name: 'User Sample', path: PATH_SAMPLE_USER, element: <SamplePage><SampleUser/></SamplePage>},
    {name: 'RDB Sample', path: PATH_SAMPLE_RDB, element: <SamplePage><SampleRdb/></SamplePage>},
    {
        name: 'Facebook Sample',
        path: PATH_SAMPLE_FACEBOOK,
        element: <SamplePage><SampleFacebook/></SamplePage>,
        testpath: '/sample/facebook?site_id=2'
    },
    {name: 'Back Sample', path: PATH_SAMPLE_BACK, element: <SamplePage><SampleBack/></SamplePage>},
    {name: 'BrandROI Sample', path: PATH_SAMPLE_BRAND_ROI, element: <SamplePage><SampleBrandRoi/></SamplePage>},
    {name: 'Attribution Sample', path: PATH_SAMPLE_ATTRIBUTION, element: <SamplePage><SampleAttribution/></SamplePage>},
    {name: 'AzameoTag Sample', path: PATH_SAMPLE_AZAMEO_TAG, element: <SamplePage><SampleAzameoTag/></SamplePage>},
    {name: 'Chat Hook Sample', path: PATH_SAMPLE_CHAT_HOOK, element: <SamplePage><SampleChat/></SamplePage>},
]

export default sample_routes;
