import {getAuth, GoogleAuthProvider, signInWithPopup, signOut} from "firebase/auth";

import {firebaseApp} from "../../firebaseConfig";
import {getDatabase} from "firebase/database";

const googleProvider = new GoogleAuthProvider();

export const auth = getAuth(firebaseApp);

export const signOutUser = async () => await signOut(auth);

export const signInWithGooglePopup = () => {
    return signInWithPopup(auth, googleProvider);
}

export const db = getDatabase(firebaseApp);