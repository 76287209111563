/*
* doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2083127297/Admin+Mode
 */
import {createContext, useCallback, useContext, useMemo} from "react";
import {useUser} from "../../app/globalHooks";
import {useDbItem} from "../../utils/rdbHooks";
import {QUERY_PARAM_FORCE_ADMIN, RDB_USER, RDB_USERRIGHTS} from "../../utils/constant";
import {useSearchParams} from "react-router-dom";

export const AdminModeContext = createContext(null);
AdminModeContext.displayName = 'AdminModeContext';

export const useAdminMode = () => {
    const {user, loading: loadingUser} = useUser()
    const {item: userDB, loadingDb, updateField} = useDbItem(RDB_USER, user?.uid)
    const {item: userRights, loadingRights} = useDbItem(RDB_USERRIGHTS, user?.uid)

    const isAdmin = useMemo(() => {
        return !!(userRights?.["admin"])
    }, [userRights])

    const [searchParams, setSearchParams] = useSearchParams();

    const force_admin = decodeURI(searchParams.get(QUERY_PARAM_FORCE_ADMIN)??"false") === 'true' && isAdmin;

    const adminMode = useMemo(() => {
        if (loadingUser || loadingDb || loadingRights) return false;
        // force cast to bool
        return (!!(userDB?.adminMode)) || force_admin ;
    }, [loadingUser, loadingDb, loadingRights, userDB?.adminMode, force_admin])

    // Create a simple function to toggle admin mode
    const toggleAdminMode = useCallback(() => {
        if (adminMode && force_admin){
            searchParams.delete(QUERY_PARAM_FORCE_ADMIN);
            setSearchParams(searchParams);
        } else if (!adminMode && !force_admin){
            searchParams.set(QUERY_PARAM_FORCE_ADMIN, true);
            setSearchParams(searchParams);
        }

        updateField("adminMode", !adminMode)
    }, [adminMode, updateField, force_admin, searchParams, setSearchParams])


    return {isAdmin, adminMode, toggleAdminMode}
}


export const AdminModeProvider = ({children}) => {
    const value = useAdminMode()
    return (
        <AdminModeContext.Provider value={value}>
            {children}
        </AdminModeContext.Provider>
    )
}

export const useAdminModeProvider = () => useContext(AdminModeContext);
