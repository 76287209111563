const ButtonBase = () => {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  };
}

export default ButtonBase;
