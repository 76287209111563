// First implementation to test backend APIs, not intended for production

import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {dataType, methodType, siteBackendFetch, siteBackendFetchRaw} from "../../utils/backendHelper";
import {API_GET_POST_SITE_LOGO} from "../../utils/constant";
import {useSiteId, useUser} from "../../app/globalHooks";
import {AzaButton} from "../../components/mui/AzaButton";
import {setSiteLogo} from "../../app/globalSlice";

export const SiteLogo = () => {

    const {user} = useUser();
    const site_id = useSiteId();
    const dispatch = useDispatch();

    const [logo, setLogo] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const getLogo = useCallback( async () => {

        if (!user || !site_id) return null;

        const sitelogo = await siteBackendFetchRaw({
            user: user,
            site_id: site_id,
            path: API_GET_POST_SITE_LOGO,
            method: methodType.GET,
            queryParams: {data:true},
        });

        setLogo(sitelogo);

    }, [user, site_id, setLogo]);

    useEffect(() => {
        getLogo();
    }, [getLogo]);

    const setLogoCb = useCallback( async () => {
        if (!user || !site_id) return null;

        if (!selectedFile) return null;

        const formData = new FormData();
        formData.append("image_file", selectedFile);

        const sitelogo = await siteBackendFetch({
            user: user,
            site_id: site_id,
            path: API_GET_POST_SITE_LOGO,
            method: methodType.POST,
            data: {image_file: selectedFile},
            type: dataType.form
        });

        if(sitelogo?.status === 'ok') {
            const siteLogoData = {
                error: {
                    status: false,
                    message: '',
                },
                is_updated: true,
                files: [selectedFile],
            };
            dispatch(setSiteLogo(siteLogoData));
        }

    }, [user, site_id, selectedFile, dispatch]);

    const handleChanges = (e) => {
        const fileToManage = e.target.files[0];
        const fileWithBlob = Object.assign(fileToManage, {path: fileToManage.name, preview: URL.createObjectURL(fileToManage)});
        setSelectedFile(fileWithBlob);
    }

    return (
        <>
            <img src={selectedFile ? selectedFile.preview : logo} alt={"SiteLogo"} style={{maxHeight: '100px'}}/>
            <input
                type="file"
                onChange={handleChanges}
            />
            <AzaButton onClick={()=>{setLogoCb()}}> Send file to back </AzaButton>
        </>
    )
}


