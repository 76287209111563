import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

export const AzaList = (props) => {
    const {children, ...others} = props;
    return (
        <List {...others}>{children}</List>
    );
}

export const AzaListItem = (props) => {
    const {children, ...others} = props;
    return (
        <ListItem {...others}>{children}</ListItem>
    );
}

export const AzaListItemButton = (props) => {
    const {children, ...others} = props;
    return (
        <ListItemButton {...others}>{children}</ListItemButton>
    );
}

export const AzaListItemIcon = (props) => {
    const {children, ...others} = props;
    return (
        <ListItemIcon {...others}>{children}</ListItemIcon>
    );
}
export const AzaListItemText = (props) => {
    const {children, ...others} = props;
    return (
        <ListItemText {...others}>{children}</ListItemText>
    );
}

