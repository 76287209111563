import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";

import {useFormat, useSiteId, useUser} from "../../app/globalHooks";
import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from "react-i18next";

import SetUpAmount from "./paymentGroup/SetUpAmount";
import DetailPayment from "./paymentGroup/DetailPayment";
import BillingPayment from "./paymentGroup/BillingPayment";
import PaymentMethods from "./paymentGroup/PaymentMethods";
import DonePayment from "./paymentGroup/DonePayment";

import {
    actionGetBillingInfo,
    actionGetRenewalInfo,
    actionReset,
    actionValidateVATNumber,
    selectBillingInfoCountryCode,
    selectBillingInfoVATNumber,
    selectTotals, actionResetStripePaymentIntent, actionSetRecurring
} from "./paymentGroup/paymentSlice";

import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {paypalInitialOptions} from "../../utils/constant";
import {Pay} from "./paymentGroup/Pay";
import {AzaBox} from "../../components/mui/AzaBox";
import {AzaGridContainer, AzaGridDivider, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaModalStepperButtons} from "./AzaModalStepperButtons";
import {AzaStep, AzaStepContent, AzaStepLabel, AzaStepper} from "../../components/mui/AzaStepper";
import {AzaPaper} from "../../components/mui/AzaPaper";
import {AzaTypography} from "../../components/mui/AzaTypography";

const paypalOptions = {
    ...paypalInitialOptions,
}

const PaymentStepperContent = ({handleNextBtnClick, canNext, noNext, nextButtonText, handleBack, content, noBack, isSmallScreen, noDetails}) => {
    return (
        <AzaGridContainer>
            <AzaGridItem sx={{width:(!isSmallScreen && !noDetails)?450:"100%"}}>
                <AzaBox >
                    {content}
                </AzaBox>
                <AzaModalStepperButtons
                    handleNext={handleNextBtnClick}
                    handleBack={handleBack}
                    nextButtonText={nextButtonText}
                    canNext={canNext}
                    noNext={noNext}
                    noBack={noBack}
                />
            </AzaGridItem>
            {(!isSmallScreen && !noDetails) && (
                <>
                    <AzaGridDivider orientation={"vertical"} variant={"middle"}/>
                    <AzaGridItem sx={{width:450}}>
                        <DetailPayment/>
                    </AzaGridItem>
                </>
            )}

        </AzaGridContainer>
    )
}

const Payment = ({handleClose, reentry, code, status, renewal}) => {
    return (
            <PayPalScriptProvider options={paypalOptions}>
                <PaymentInside
                    handleClose={handleClose}
                    reentry={reentry}
                    code={code}
                    status={status}
                    renewal={renewal}
                />
            </PayPalScriptProvider>

    )
}

const PaymentInside = ({handleClose, reentry, code, status, renewal}) => {
    const {user} = useUser();
    const site_id = useSiteId();

    const [activeStep, setActiveStep] = useState((reentry !== null)?4:0);
    const [nextButtonText, setNextButtonName] = useState('Next');
    const [canNext, setCanNext] = useState(true);
    const {ttc} = useSelector(selectTotals);

    const dispatch = useDispatch();

    const {formatMoney} = useFormat();

    const {t} = useTranslation();

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    useEffect(() => {
        if (renewal===true) {
            dispatch(actionSetRecurring(true));
        }
    }, [renewal, dispatch])

    useEffect(() => {
        if (site_id) {
            setActiveStep(0);
            dispatch(actionResetStripePaymentIntent());
            dispatch(actionReset());
            dispatch(actionGetBillingInfo({user: user, site_id: site_id}));
        }
    }, [user, site_id, dispatch])

    const {loading: loading_cc, country_code} = useSelector(selectBillingInfoCountryCode);
    const {loading: loading_tva, vatNumber} = useSelector(selectBillingInfoVATNumber);

    useEffect(() => {
        if (loading_cc || loading_tva) {
            return;
        }
        dispatch(actionValidateVATNumber({user: user, countrycode: country_code, vatnumber: vatNumber}))
    }, [user, loading_cc, loading_tva, country_code, vatNumber, dispatch])


    const decrementStep = useCallback(() => {
        if (activeStep === 0) {
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [activeStep, setActiveStep])


    const incrementStep = useCallback((skip_next = false) => {
        if (skip_next) {
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [setActiveStep])

    const steps = useMemo(() => [
        {
            label: t('payment.stepper.step.setupamount'),
            stepComponent: <SetUpAmount
                setCanNext={setCanNext}
            />,
            handleBack: () => {
                handleClose(false);
            },
            noBack: true,
        },
        {
            label: t('payment.stepper.step.billing'),
            stepComponent: <BillingPayment
                setCanNext={setCanNext}
            />,
        },
        {
            label: t('payment.stepper.step.paymentmethods'),
            stepComponent: <PaymentMethods
                setCanNext={setCanNext}
                isSmallScreen={isSmallScreen}
                incrementStep={incrementStep}
            />,
            noNext: true,
        },
        {
            label: t('payment.stepper.step.payment'),
            stepComponent: <Pay
                decrement={decrementStep}
                increment={incrementStep}
            />,
            noBack: true,
            noNext: true,
        },
        {
            label: t('payment.stepper.step.done'),
            stepComponent: <DonePayment
                status={status}
                reentry={reentry}
                code={code}
            />,
            nextButtonText: t('common.close'),
            handleNext: () => {
                dispatch(actionReset());
                if (site_id) {
                    dispatch(actionGetRenewalInfo(user, site_id));
                }
                setActiveStep(0);
                handleClose(false);
            },
            noBack: true,
            noDetails: true,
        },
    ], [t, isSmallScreen, incrementStep, decrementStep, handleClose, dispatch, user, site_id, reentry, status, code]);

    useEffect(() => {
        if (reentry !== null) {
            setActiveStep(steps.length - 1);
        }
    }, [reentry, steps, dispatch, site_id, user, status])

    const handleNextBtnClick = useCallback(() => {
        if (!canNext) {
            return
        }
        if (steps[activeStep].handleNext) {
            steps[activeStep].handleNext();
        }
        setActiveStep((prevActiveStep) => (prevActiveStep === steps.length - 1) ? 0 : prevActiveStep + 1);
    }, [steps, activeStep, canNext, setActiveStep])

    const handleBackBtnClick = useCallback(() => {
        if (steps[activeStep].handleBack) {
            steps[activeStep].handleBack();
        }
        decrementStep();
    }, [activeStep, steps, decrementStep])

    useEffect(() => {
        setNextButtonName(steps[activeStep].nextButtonText ?? t('common.next'));
    }, [activeStep, steps, t]);


    return (
            <AzaBox component="div" sx={{display: 'flex', marginBottom: {sm: "50px", md: '0'}}}>
                <AzaPaper
                    elevation={isSmallScreen ? 0 : 15}
                    sx={{
                        m: '0 auto',
                        display: 'flex',
                        width: {sm: '100%', md: 'auto'},
                    }}
                >
                    <AzaBox
                        sx={{
                            borderRight: {sm: 'none', md: '1px solid rgba(0,0,0,0.15)'},
                            padding: {sm: '0', md: '30px'},
                            width: ['auto', 'auto', 'auto'],
                        }}
                    >
                        {isSmallScreen ? (
                            <AzaBox sx={{maxWidth: 450}}>
                                <AzaStepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => {
                                        console.log(step)
                                        return (
                                            <AzaStep key={step.label}>
                                                <AzaStepLabel
                                                    optional={
                                                        index === 0 ? (
                                                            <AzaTypography
                                                                variant="caption">{formatMoney(ttc)}</AzaTypography>
                                                        ) : null
                                                    }
                                                >
                                                    {step.label}
                                                </AzaStepLabel>
                                                <AzaStepContent>
                                                    <PaymentStepperContent
                                                        handleNextBtnClick={handleNextBtnClick}
                                                        handleBack={handleBackBtnClick}
                                                        nextButtonText={nextButtonText}
                                                        canNext={canNext}
                                                        noNext={step.noNext ?? false}
                                                        noBack={step.noBack ?? false}
                                                        content={step.stepComponent}
                                                        isSmallScreen={isSmallScreen}
                                                        noDetails={step.noDetails ?? false}
                                                        label={step.label ? step.label : "not found"}
                                                    />
                                                </AzaStepContent>
                                            </AzaStep>)
                                    })}
                                </AzaStepper>

                            </AzaBox>
                        ) : (
                            <AzaBox sx={{width: '100%'}}>
                                <AzaStepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((step) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        return (
                                            <AzaStep key={step.label} {...stepProps} >
                                                <AzaStepLabel {...labelProps}>{step.label}</AzaStepLabel>
                                            </AzaStep>
                                        );
                                    })}
                                </AzaStepper>
                                <>
                                    <PaymentStepperContent
                                        handleNextBtnClick={handleNextBtnClick}
                                        handleBack={handleBackBtnClick}
                                        nextButtonText={nextButtonText}
                                        canNext={canNext}
                                        noNext={steps[activeStep].noNext ?? false}
                                        noBack={steps[activeStep].noBack ?? false}
                                        content={steps[activeStep].stepComponent}
                                        activeStep={activeStep}
                                        isSmallScreen={isSmallScreen}
                                        noDetails={steps[activeStep].noDetails ?? false}
                                    />
                                </>
                            </AzaBox>
                        )}
                    </AzaBox>
                </AzaPaper>
            </AzaBox>

    );
};

export default Payment;
