import SampleHeader from "./SampleHeader";
import {AzaGridContainer, AzaGridItem} from "../components/mui/AzaGrid";

export const SamplePage = (props) => {
    return (
        // <AdminModeRequired>
        <AzaGridContainer direction={"column"}>
            <AzaGridItem><SampleHeader/></AzaGridItem>
            <AzaGridItem>{props.children}</AzaGridItem>
        </AzaGridContainer>
        // </AdminModeRequired>
    )
}