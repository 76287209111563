import PropTypes from 'prop-types';

// project import
import Default from './default';
import ThemeColorsMain from './themeColorsMain';
import ThemeColorsAdmin from "./themeColorsAdmin";

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors, presetColor, mode) => {
  switch (presetColor) {
    case 'themeColorsMain':
      return ThemeColorsMain(colors, mode);
    case 'themeColorsAdmin':
      return ThemeColorsAdmin(colors, mode);
    default:
      return Default(colors);
  }
};

Theme.propTypes = {
  colors: PropTypes.object,
  presetColor: PropTypes.any
};

export default Theme;
