import {useCallback} from "react";
import {useDispatch} from "react-redux";

import {getCampaignDetails} from "./getCampaignDetails";
import {actionSetOnLoading, actionSetCampaignParams, actionSetIsLoaded, actionSetCampaignName} from "../reducers/editCampaignSlice";

export const useEditCampaign = () => {
    const dispatch = useDispatch();

    const setCampaignData = useCallback((data) => {
        dispatch(actionSetCampaignParams(data));
        dispatch(actionSetOnLoading(false));
        dispatch(actionSetIsLoaded(true));
    }, [dispatch]);

    const getCampaignData = useCallback((user, site_id, campaign_id, campaignName) => {
        dispatch(actionSetOnLoading(true));
        dispatch(actionSetCampaignName(campaignName));
        getCampaignDetails(user, site_id, campaign_id, setCampaignData);
    }, [dispatch, setCampaignData]);

    return {getCampaignData, setCampaignData}
}