import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaDialog, AzaDialogActions, AzaDialogContent, AzaDialogTitle} from "../../components/mui/AzaDialog";
import {AzaButton, AzaIconSettingsButton} from "../../components/mui/AzaButton";
import React, {useState} from "react";
import {AzaAlertActionCard, AzaCard} from "../../components/mui/AzaCard";
import {Mood} from "@mui/icons-material";
import {AzaStepVertical, AzaStepper} from "../../components/mui/AzaStepper";
import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";
import {AzaCountrySelector} from "../../components/azameo/AzaCountrySelector";
import {AzaSwitch} from "../../components/mui/AzaSwitch";
import {AzaTextField} from "../../components/mui/AzaTextField";
import {AzaInputPhone} from "../../components/azameo/AzaInputPhone";
import {RequestOtherPaymentZohoForm} from "../../components/azameo/ZohoForm";
import AzaInputEmail from "../../components/azameo/AzaInputEmail";
import ErrorIcon from "@mui/icons-material/Error";

export const SampleDialog = () => {
    const [azaDialogOpen, setAzaDialogOpen] = useState(false)
    const closeAzaDialog = () => {
        setAzaDialogOpen(false)
    }
    return (
        <>
            <AzaButton onClick={() => {
                setAzaDialogOpen(true)
            }} onClose={closeAzaDialog}>Open AzaDialog</AzaButton>
            <AzaDialog open={azaDialogOpen} onClose={closeAzaDialog}>
                <AzaDialogTitle onClose={closeAzaDialog}>AzaDialogTitle</AzaDialogTitle>
                <AzaDialogContent>
                    AzaDialogContent
                </AzaDialogContent>
                <AzaDialogActions>
                    <AzaButton onClick={closeAzaDialog}>Close</AzaButton>
                    <AzaButton variant={"contained"}>Action</AzaButton>
                </AzaDialogActions>
            </AzaDialog>
        </>
    )
}

export const SampleCard = () => {
    return (
        <AzaCard
            icon={<Mood/>}
            title={"AzaCard - Title"}
            actions={<AzaAlertActionCard
                text={"AzaAlertActionCard with AzaIconSettingsButton"}
                actions={<AzaIconSettingsButton/>}
            />}
        >
            CardContent
        </AzaCard>
    )
}

export const SampleStepper = () => {
    const steps = [
        {
            label: 'Step 1',
            content: <Typography>Step 1 content</Typography>,
            error: false
        },
        {
            label: 'Step 2',
            content: <Typography>Step 2 content</Typography>,
            error: true
        },
        {
            label: 'Step 3',
            content: <Typography>Step 3 content</Typography>,
            error: false
        },
        {
            label: 'Step 4',
            content: <Typography>Step 4 content</Typography>,
            error: false
        },
        {
            label: 'Step 5',
            content: <Typography>Step 5 content</Typography>,
        },

    ]

    const active_step = 2;
    return (
        <>
            <Typography variant={"h6"}>AzaStepper</Typography>
            <AzaStepper activeStep={active_step} orientation="vertical">
                {
                    steps.map((step, idx) => {
                        return (
                            <AzaStepVertical key={idx}
                                     index={idx}
                                     current={active_step}
                                     label={step.label}
                                     error={step.error}
                                     expanded={idx === active_step}
                            >
                                {step.content}
                            </AzaStepVertical>
                        )
                    })
                }
            </AzaStepper>
        </>
    )
}

export const SampleSelectCountry = () => {
    const [country, setCountry] = useState("")
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [required, setRequired] = useState(true)
    const [whitelist, setWhitelist] = useState([])
    const [blacklist, setBlacklist] = useState([])
    const [all, setAll] = useState(true)
    const [multiple, setMultiple] = useState(false)

    const handleChangeMultiple = (event) => {
        setMultiple(event.target.checked);
        if (event.target.checked) {
            setCountry([country])
        } else {
            setCountry(country[0] ?? "")
        }
    }

    return (
        <>
            <Typography variant={"h6"}>Country Selector</Typography>
            <Box>
                <FormGroup>
                    <AzaGridContainer direction={"row"}>

                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setLoading(event.target.checked)}
                                    checked={loading}/>}
                                label={"Loading"}/>
                        </AzaGridItem>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setDisabled(event.target.checked)}
                                    checked={disabled}/>}
                                label={"Disabled"}/>
                        </AzaGridItem>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setRequired(event.target.checked)}
                                    checked={required}/>}
                                label={"Required"}/>
                        </AzaGridItem>
                    </AzaGridContainer>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={handleChangeMultiple}
                                    checked={multiple}/>}
                                label={"Multiple"}/>
                        </AzaGridItem>
                    </AzaGridContainer>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setAll(event.target.checked)}
                                    checked={all}/>}
                                label={"Include All countries choice"}/>
                        </AzaGridItem>
                        <AzaGridItem>
                            <AzaTextField
                                label={"White list country codes"}
                                value={whitelist}
                                required={false}
                                onChange={
                                    (event) => {
                                        setWhitelist((event.target.value === "") ? [] : event.target.value.split(",").map(elem => elem.trim()))
                                    }
                                }
                            />
                        </AzaGridItem>
                        <AzaGridItem>
                            <AzaTextField
                                label={"Black list country codes"}
                                value={blacklist}
                                required={false}
                                onChange={
                                    (event) => {
                                        setBlacklist((event.target.value === "") ? [] : event.target.value.split(",").map(elem => elem.trim()))
                                    }
                                }
                            />
                        </AzaGridItem>
                    </AzaGridContainer>
                </FormGroup>
            </Box>
            <Box sx={{marginTop: "5px"}}>
                <AzaCountrySelector
                    country_code={country}
                    loading={loading}
                    disabled={disabled}
                    onChange={(country_code) => {
                        setCountry(country_code)
                    }}
                    whitelist={whitelist}
                    blacklist={blacklist}
                    allchoice={all}
                    multiple={multiple}
                    required={required}
                />
            </Box>
            <Box sx={{marginTop: "5px"}}>
                <Typography variant={"h6"}>Selected country
                    code(s): {multiple ? country.join(',') : country}</Typography>
            </Box>
        </>
    )
}

export const SampleInputPhone = () => {
    return (
        <>
            <Typography variant={"h6"}>Phone Input</Typography>
            <Box sx={{marginTop: "5px"}}>
                <AzaInputPhone/>
            </Box>
        </>
    )
}

export const SampleAzaInputEmail = () => {
    const [email, setEmail] = useState("")
    const [valid, setValid] = useState(false)

    const onChange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <>
            <Typography variant={"h6"}>Email Input</Typography>
            <Box sx={{marginTop: "5px"}}>
                <AzaInputEmail
                    setValid={setValid}
                    value={email}
                    onChange={onChange}
                />
            </Box>
            <Box sx={{marginTop: "5px"}}>
                <AzaGridContainer direction={"row"}>
                    <AzaGridItem>
                        {valid&&<Checkbox color={"success"} checked={true} label={"Valid"}/>}
                        {!valid&&<Checkbox color={"error"} checked={true} label={"Valid"} checkedIcon={<ErrorIcon/>}/>}
                    </AzaGridItem>
                    <AzaGridItem><Typography variant={"h6"}>Email: {email}</Typography></AzaGridItem>
                </AzaGridContainer>
            </Box>
        </>
    )
}

export const SampleAzaTextField = () => {

    const [value, setValue] = useState("")
    const [helperText, setHelperText] = useState("")
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [required, setRequired] = useState(true)
    const [error, setError] = useState(false)

    return (
        <>
            <Typography variant={"h6"}>AzaTextField</Typography>
            <Typography variant={"body1"}>AzaTextField is a wrapper around TextField from Material UI that add loading
                and add translated helper text in case of required</Typography>
            <Box sx={{marginTop: "5px"}}>
                <FormGroup>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setLoading(event.target.checked)}
                                    checked={loading}/>}
                                label={"Loading"}/>
                        </AzaGridItem>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setDisabled(event.target.checked)}
                                    checked={disabled}/>}
                                label={"Disabled"}/>
                        </AzaGridItem>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setRequired(event.target.checked)}
                                    checked={required}/>}
                                label={"Required"}/>
                        </AzaGridItem>
                    </AzaGridContainer>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem>
                            <AzaTextField
                                label={"Value"}
                                value={value}
                                required={false}
                                onChange={
                                    (event) => {
                                        setValue(event.target.value)
                                    }
                                }
                            />
                        </AzaGridItem>
                    </AzaGridContainer>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem>
                            <FormControlLabel
                                control={<AzaSwitch
                                    onChange={(event) => setError(event.target.checked)}
                                    checked={error}/>}
                                label={"Error"}/>
                        </AzaGridItem>
                        <AzaGridItem>
                            <AzaTextField
                                label={"Helper text"}
                                value={helperText}
                                required={false}
                                onChange={
                                    (event) => {
                                        setHelperText(event.target.value)
                                    }
                                }
                            />
                        </AzaGridItem>
                    </AzaGridContainer>
                </FormGroup>
            </Box>
            <Box sx={{marginTop: "5px"}}>
                <AzaTextField
                    required={required}
                    error={error}
                    helperText={helperText}
                    loading={loading}
                    disabled={disabled}
                    value={value}
                    onChange={
                        (event) => {
                            setValue(event.target.value)
                        }
                    }
                />
            </Box>
        </>
    )
}

export const SampleZohoForm = () => {
    return (
        <>
            <Typography variant={"h6"}>ZohoForm</Typography>
            <Typography variant={"body1"}>ZohoForm will open a modal containing the iframe containing the form from zoho. All the configuration is done via the settings and the form name (urls, key translation, ...)</Typography>
            <Typography variant={"body1"}>Sample is RequestOtherPaymentZohoForm (form name is "request_other_paiement")</Typography>
            <Box sx={{marginTop: "5px"}}>
                <RequestOtherPaymentZohoForm amount={10}/>
            </Box>
        </>
    )
}


