import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, styled} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {CloseRounded} from "@mui/icons-material";
import PropTypes from "prop-types";

const lateralMarginAzaDialog = 24;
export const AzaDialog = styled((props) => {
    const {open, onClose, children, popupWidth = 'md', ...other} = props;
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    return <Dialog {...other}
                   open={open}
                   onClose={onClose}
        // fullWidth
                   fullScreen={isMediumScreen ? true : null}
                   maxWidth={popupWidth}
                   scroll={"body"}
    >{children}</Dialog>
})(({theme}) => ({}));

export const AzaDialogTitle = styled((props) => {
    const {children, onClose, ...other} = props;
    return (
        <DialogTitle {...other} variant={'h5'}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseRounded/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
})(({theme}) => ({
    marginTop: 32,
    marginLeft: lateralMarginAzaDialog,
    marginRight: lateralMarginAzaDialog,
    marginBottom: 8
}));

// why do this? why 'onClose' is required?
AzaDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
export const AzaDialogContent = styled((props) => {
    const {children, ...other} = props;

    return (
        <DialogContent {...other}>
            {children}
        </DialogContent>
    );
})(({theme}) => ({
    marginLeft: lateralMarginAzaDialog,
    marginBottom: 12,
    marginRight: lateralMarginAzaDialog,
}));

export const AzaDialogActions = styled((props) => {
    const {children, ...other} = props;
    return (
        <DialogActions {...other}>
            {children}
        </DialogActions>
    );
})(({theme}) => ({
    marginLeft: lateralMarginAzaDialog,
    marginBottom: 8,
    marginRight: lateralMarginAzaDialog,
}));


export const AzaDialogContentText = styled((props) => {
    const {children, ...other} = props;
    return (
        <DialogContentText {...other}>
            {children}
        </DialogContentText>
    );
})(({theme}) => ({}));

