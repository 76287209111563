import {useSiteId} from "../../../app/globalHooks";
import {useDbItem} from "../../../utils/rdbHooks";
import {RDB_CUSTOMER, RDB_SITE} from "../../../utils/constant";
import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actionSetCurrency, selectCurrency} from "./paymentSlice";


export const usePayment = () => {
    const site_id = useSiteId()
    const {item: siteRDB, loading: loadingSiteDb} = useDbItem(RDB_SITE, site_id);
    const {item: customerRDB, loading: loadingCustDb} = useDbItem(RDB_CUSTOMER, siteRDB?.customer_id);
    const slicedCurrency = useSelector(selectCurrency)
    const dispatch = useDispatch()

    // to see when we use 'defaultCurrency', probably we don't need 'slicedCurrency' dependency here
    const defaultCurrency = useMemo(() => {
        if (loadingCustDb || loadingSiteDb) return ""
        return customerRDB?.data?.currency
    }, [customerRDB?.data?.currency, loadingCustDb, loadingSiteDb, slicedCurrency])

    const currency = useMemo(() => {
        if (loadingCustDb || loadingSiteDb) return ""

        if (customerRDB?.data?.currency !== undefined && slicedCurrency !== customerRDB?.data?.currency){
            // provoke an error/waring => 'Warning: Cannot update a component...'
            dispatch(actionSetCurrency(customerRDB?.data?.currency));
        }

        if (customerRDB?.data?.currency === undefined) {
            return slicedCurrency
        }
        return customerRDB?.data?.currency
    }, [customerRDB?.data?.currency, dispatch, loadingCustDb, loadingSiteDb, slicedCurrency])

    const setCurrency = (currency) => {
        if (customerRDB?.data?.currency === undefined) {
            dispatch(actionSetCurrency(currency))
        }
    }

    return {currency, defaultCurrency, setCurrency,}
};
