import {useDbItem} from "../../utils/rdbHooks";
import {Paper, Typography} from "@mui/material";

export const SampleRdbRead = () => {
    const {item: data, loading, error} = useDbItem("sample/", "data")

    // very basic loading and error handling
    if(loading || error) return <>{JSON.stringify(error)}</>
    if (!data) return <>no data</>

    return (
        <Paper elevation={3}>
            <Typography variant="h5">
                Sample RealTimeDB Read
            </Typography>
            <Typography>
                Loading: {(!!loading).toString()}
            </Typography>
            <Typography>
                Error: {(!!error).toString()}
            </Typography>
            <Typography>
                Nome: {data.name}
            </Typography>
            <Typography>
                Age: {data.age}
            </Typography>
        </Paper>
    )
}