import {Fab, IconButton, styled} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

const buttonStyle = {
    maxWidth: '256px',
    minWidth: '64px',
}

export const AzaButton = styled((props) => {
    const {children, ...others} = props;
    return (
        <LoadingButton {...others}>{children}</LoadingButton>
    );
})(({theme}) => (buttonStyle));

export const AzaIconButton = styled((props) => {
    const {children, ...others} = props;
    return (
        <IconButton {...others}>{children}</IconButton>
    );
})(({theme}) => (buttonStyle));

export const AzaIconSettingsButton = styled((props) => {
    return (
        <AzaIconButton {...props}><MoreVert/></AzaIconButton>
    );
})(({theme}) => (buttonStyle));

export const AzaFabButton = styled((props) => {
    const {children, ...others} = props;
    return (
        <Fab {...others}>{children}</Fab>
    );
})(({theme}) => (buttonStyle));

export const AzaContainedButton = styled((props) => {
    const {children, ...other} = props
    return <AzaButton variant="contained" {...other}>{children}</AzaButton>
})(() => ({
    textTransform: 'none',
    marginRight: '12px',
}));