import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {styled, lighten, darken} from "@mui/material";

import {AzaBox} from "../../../../../components/mui/AzaBox";
import {AzaGridContainer, AzaGridItem} from "../../../../../components/mui/AzaGrid";
import {
    actionSetProductAssets,
    selectListProducts,
    actionSetProductAssetSelected,
    actionSetProductInfoSelected,
    actionSetProductOptionSelected,
    selectProductOptionSelected,
    actionSetMultipleProduct
} from "../../../reducers/createNewCampaignSlice";
import {AzaAutocomplete} from "../../../../../components/mui/AzaAutocomplete";
import {AzaTextFieldBasic} from "../../../../../components/mui/AzaTextField";
import {creatNewAssetsGroup} from "../addAssetsUtility/generateNewInputObj";
import {AzaAutoAwesome} from "../../../../../components/mui/AzaIcons";


const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const SelectProduct = ({productIdsWithAssets, productWithAssets, scope, siteId}) => {
    const {t} = useTranslation();
    const listProducts = useSelector(selectListProducts);
    const optionSelected = useSelector(selectProductOptionSelected);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    // hardcoded
    const language = 'fr'

    const createProdAssetsHelper = useCallback((prodId, selectedOption) => {
        const data = {
            scope: scope,
            siteId: siteId,
            productId: prodId,
            language: language,
        }
        const newProductAssetsGroup = creatNewAssetsGroup(data);
        dispatch(actionSetProductAssetSelected(newProductAssetsGroup));
        dispatch(actionSetProductAssets({...productWithAssets, [prodId]: newProductAssetsGroup}));
        dispatch(actionSetProductOptionSelected(selectedOption));
    }, [scope, siteId, dispatch, productWithAssets]);

    const handleChange = useCallback((e, newValue) => {
        if (newValue) {
            if(newValue.multiple) {
                dispatch(actionSetMultipleProduct({is_multiple_product: true, segments: [newValue.id]}));
            } else {
                dispatch(actionSetMultipleProduct({is_multiple_product: false, segments: []}));
            }
            dispatch(actionSetProductInfoSelected({prodId: newValue.id, prodName: newValue.title}));
            dispatch(actionSetProductOptionSelected(newValue));
            if (productIdsWithAssets.length > 0 && productIdsWithAssets.includes(newValue.id.toString())) {
                dispatch(actionSetProductAssetSelected(productWithAssets[newValue.id]));
            } else if (!newValue.multiple) {
                createProdAssetsHelper(newValue.id, newValue);
            }
        }
    }, [dispatch, productIdsWithAssets, productWithAssets, createProdAssetsHelper]);

    const options = useMemo(() => {
        let prodList = listProducts.map((product) => {
            if (productIdsWithAssets.includes(product.id.toString())) {
                return {
                    groupName: t('product.group-with-assets'),
                    order: 'B',
                    ...product,
                }
            } else {
                return {
                    groupName: t('product.group-empty'),
                    order:'C',
                    ...product,
                }
            }
        });

        const additionalOptions = [
            {
                groupName: t('product.group-multiple-products'),
                order: 'A',
                id: 'TOP_PRODUCT',
                title: t('product.best-sales'),
                multiple: true
            },
            {
                groupName: t('product.group-multiple-products'),
                order: 'A',
                id: 'ALL_PRODUCT',
                title: t('product.all-products'),
                multiple: true
            }
        ];

        prodList = [...prodList, ...additionalOptions];

        return prodList.sort((a, b) => -b.order.localeCompare(a.order));
    }, [listProducts, productIdsWithAssets, t]);

    useEffect(() => {
        if(!optionSelected) {
            // to select correct initial value, not 'Best sales' or 'All product'
            let productIndex = 0;
            for(let i = 0; i < options.length; i++) {
                if(!options[i].multiple) {
                    productIndex = i;
                    break;
                }
            }

            if(productIdsWithAssets[0]) {
                dispatch(actionSetProductInfoSelected({prodId: options[productIndex].id, prodName: options[productIndex].title}));
                dispatch(actionSetProductAssetSelected(productWithAssets[options[productIndex].id]));
                dispatch(actionSetProductOptionSelected(options[productIndex]));
            } else {
                createProdAssetsHelper(options[productIndex].id, options[productIndex]);
                dispatch(actionSetProductInfoSelected({prodId: options[productIndex].id, prodName: options[productIndex].title}));
            }
        }
    }, [optionSelected, productIdsWithAssets, productWithAssets, options, dispatch, createProdAssetsHelper]);

    return (
        <AzaBox sx={{mb: '30px'}}>
            <AzaBox sx={{mb: '20px', display: 'flex'}}>
                <AzaBox sx={{fontWeight: 'bold', mr: '5px'}}>{t('product.title')}</AzaBox>
            </AzaBox>
            <AzaGridContainer>
                <AzaGridItem xs={12} md={6}>
                    <AzaAutocomplete
                        options={options}
                        value={optionSelected}
                        inputValue={inputValue}
                        onChange={handleChange}
                        onInputChange={(e, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        groupBy={(option) => option.groupName}
                        getOptionLabel={(option) => {
                            return (option?.multiple ? option.title : option.title + ' (' + option.id + ')') ?? ""}}
                        renderInput={(params) => <AzaTextFieldBasic {...params} label={t('product.select.placeholder')}/>}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader sx={{display: 'flex'}}>
                                    {params.group === t('product.group-with-assets') && <AzaAutoAwesome sx={{height: '18px', width: '22px', mr:'5px'}}/>}
                                    <AzaBox>{params.group}</AzaBox>
                                </GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>)
                        }
                    />
                </AzaGridItem>
            </AzaGridContainer>
        </AzaBox>
    );
};

export default SelectProduct;