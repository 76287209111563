import {useSiteId, useUser} from "../../../../app/globalHooks";
import {methodType, siteBackendFetch} from "../../../../utils/backendHelper";
import {API_POST_MODIFY_MULTI} from "../../../../utils/constant";
import {AzaDialog, AzaDialogActions, AzaDialogContent} from "../../../../components/mui/AzaDialog";
import {AzaButton} from "../../../../components/mui/AzaButton";
import React from "react";
import {useTranslation} from "react-i18next";

export const ConfirmCampaignActionPopUp = (props) => {
    const {dialogOpen, action, handleClose, chosenCampaigns} = props;
    const {user} = useUser();
    const site_id = useSiteId();
    const {t} = useTranslation();
    const campaignsId = chosenCampaigns.map((campaignData) => campaignData.campaign_id);

    let firstPart = t('campaign_management_popup.first_part');
    let actionPart = t('campaign_management_popup.action', {context: action});
    let lastPart = t('campaign_management_popup.second_part', {count: chosenCampaigns.length});
    const popupText = `${firstPart} ${actionPart} ${lastPart}`;

    const handleConfirm = () => {
        // SUSPEND, RESUME, DELETE
        let status = '';
        switch (action) {
            case 'start':
                status = 'RESUME';
                break;
            case 'stop':
                status = 'SUSPEND';
                break;
            case 'delete':
                status = 'DELETE';
                break;
            default:
                alert('Something went wrong');
                break;
        }

        const campaigns = {}
        for (const el of campaignsId) {
            campaigns[el] = {status: status}
        }
        const data = {
            campaigns
        }

        return siteBackendFetch({
            user: user,
            site_id: site_id,
            path: API_POST_MODIFY_MULTI,
            method: methodType.POST,
            data: data,
        }).then(res => {
            return res.token
        }).catch(error => {
            console.log(error);
        }).finally(() => {
                handleClose();
            }
        );
    }

    return (
        <AzaDialog open={dialogOpen} onClose={handleClose}>
            <AzaDialogContent>
                {popupText}
            </AzaDialogContent>
            <AzaDialogActions>
                <AzaButton onClick={handleClose}>{t('cancel')}</AzaButton>
                <AzaButton onClick={handleConfirm}>{t('ok')}</AzaButton>
            </AzaDialogActions>
        </AzaDialog>
    )
}