export const languages = [
    {code: 'ar', label: 'Arabic'},
    {code: 'bn', label: 'Bangla'},
    {code: 'bg', label: 'Bulgarian'},
    {code: 'ca', label: 'Catalan'},
    {code: 'hr', label: 'Croatian'},
    {code: 'cs', label: 'Czech'},
    {code: 'da', label: 'Danish'},
    {code: 'nl', label: 'Dutch'},
    {code: 'en', label: 'English'},
    {code: 'et', label: 'Estonian'},
    {code: 'fi', label: 'Finnish'},
    {code: 'fr', label: 'French'},
    {code: 'de', label: 'German'},
    {code: 'el', label: 'Greek'},
    {code: 'gu', label: 'Gujarati'},
    {code: 'he', label: 'Hebrew'},
    {code: 'hi', label: 'Hindi'},
    {code: 'hu', label: 'Hungarian'},
    {code: 'is', label: 'Icelandic'},
    {code: 'id', label: 'Indonesian'},
    {code: 'it', label: 'Italian'},
    {code: 'ja', label: 'Japanese'},
    {code: 'kn', label: 'Kannada'},
    {code: 'ko', label: 'Korean'},
    {code: 'lv', label: 'Latvian'},
    {code: 'lt', label: 'Lithuanian'},
    {code: 'ms', label: 'Malay'},
    {code: 'ml', label: 'Malayalam'},
    {code: 'mr', label: 'Marathi'},
    {code: 'no', label: 'Norwegian'},
    {code: 'fa', label: 'Persian'},
    {code: 'pl', label: 'Polish'},
    {code: 'pt', label: 'Portuguese'},
    {code: 'ro', label: 'Romanian'},
    {code: 'ru', label: 'Russian'},
    {code: 'sr', label: 'Serbian'},
    // {code: 'ZH_HANS', label: 'Simplified Chinese'},
    {code: 'zh', label: 'Chinese'},
    {code: 'sk', label: 'Slovak'},
    {code: 'sl', label: 'Slovenian'},
    {code: 'es', label: 'Spanish'},
    {code: 'sv', label: 'Swedish'},
    {code: 'tl', label: 'Tagalog'},
    {code: 'ta', label: 'Tamil'},
    {code: 'te', label: 'Telugu'},
    {code: 'th', label: 'Thai'},
    // {code: 'ZH_HANT', label: 'Traditional Chinese'},
    {code: 'tr', label: 'Turkish'},
    {code: 'uk', label: 'Ukrainian'},
    {code: 'ur', label: 'Urdu'},
    {code: 'vi', label: 'Vietnamese'}
];