import {useTranslation} from "react-i18next";
import {useAsyncStatus, useSiteId, useUser} from "../../app/globalHooks";
import {backendFetch, methodType} from "../../utils/backendHelper";
import {API_OPENAI_COMPETITORS, API_SITE_DATA} from "../../utils/constant";
import {useCallback, useEffect, useMemo, useState} from "react";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaButton} from "../../components/mui/AzaButton";
import {CircularProgress} from "@mui/material";

export const Competitors = () => {
    const {t} = useTranslation();
    const site_id = useSiteId();
    const {user, loading: loadingUser} = useUser()
    const [siteData, setSiteData] = useState({})
    const [canFindCompetitors, setCanFindCompetitors] = useState(false)
    const [searchingCompetitor, setSearchingCompetitor] = useState(false)
    const [message, setMessage] = useState("")
    const [taskId, setTaskId] = useState(null)
    const {status} = useAsyncStatus(taskId)

    const fetchSiteData = useCallback(() => {
        if (!user || loadingUser || !site_id) return;
        backendFetch({path: API_SITE_DATA, user, queryParams: {site_id}})
            .then(json_data => {
                // Store the api result in the local state
                setSiteData(json_data)
            })
    }, [loadingUser, site_id, user]);


    const competitors = useMemo(() => {
        if (Object.keys(siteData).length === 0) {
            setCanFindCompetitors(false)
            return []

        }
        if (!siteData?.["competitors"]) {
            setCanFindCompetitors(true)
            return []
        }
        const output = []
        for (let url in siteData["competitors"]) {
            let reason = siteData["competitors"][url]
            output.push({url, reason})
        }
        setCanFindCompetitors(output.length === 0)
        if (output.length > 0) {
            setMessage("")
        }
        return output

    }, [siteData])


    const findCompetitors = useCallback(() => {
        setSearchingCompetitor(true)
        backendFetch({path: API_OPENAI_COMPETITORS, method: methodType.POST, user, data: {site_id}})
            .then(res => {
                // The status indicate if the task is created or already running
                if (res.status === "ok") {
                    setMessage(t("site-settings.fetching-competitors"))
                    setTaskId(res["async"].id)

                } else {
                    setMessage(t("site-settings.already-running"))
                    setTaskId(null)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }, [site_id, t, user])

    useEffect(() => {
        // console.log("status", status)
        if (status === "success") {

            fetchSiteData();
            setTaskId(null)
            setSearchingCompetitor(false)
        }


    }, [fetchSiteData, status, t])


    return (
        <div>
            <h1>{t("site-settings.competitors")}</h1>
            <AzaButton
                variant={"contained"}
                disabled={!canFindCompetitors}
                onClick={findCompetitors}
            >{t("site-settings.find-competitors")}</AzaButton>
            <AzaGridContainer direction={"column"}>
                <AzaGridItem>
                    {message}
                </AzaGridItem>
                <AzaGridItem>
                    {searchingCompetitor && <CircularProgress/>}
                </AzaGridItem>
                {competitors.map(competitor => (
                    <AzaGridItem key={competitor["url"]}>
                        <a
                            href={competitor["url"]}
                            target={"_blank"}
                            rel="noreferrer"
                        > {competitor["url"]}</a> - {competitor["reason"]}
                    </AzaGridItem>
                ))}
            </AzaGridContainer>
        </div>
    )
}