import React, {useCallback, useEffect, useState} from "react";
import TopBar from "../../containers/topbar/TopBar";
import SideBar from "../../containers/sidebar/SideBar";
import {useGlobalCustomization} from "../../features/customization/customizationHooks";
import {useScreenSize} from "../../app/globalHooks";

export const SideBarElements = (props) => {
    const {setOverlapHelp:setOverlapHelpParent} = props;
    const [open, setOpen] = useState(false);
    const { drawerOpen, updateDrawerOpen} = useGlobalCustomization();
    const [overlapHelp, setOverlapHelp] = useState(true);
    const {isSmallScreen} = useScreenSize();

    const handleDrawerOpen = useCallback(() => {
        if (isSmallScreen){
            setOpen(!open);
        } else {
            updateDrawerOpen(!drawerOpen);
        }
    }, [drawerOpen, updateDrawerOpen, isSmallScreen, open, setOpen])

    const handleMouseOver = () => {
        if (!drawerOpen) {
            setOpen(true);
        }
    }

    const handleMouseLeave = () => {
        if (!drawerOpen) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (!drawerOpen)
        {
            setOverlapHelpParent(true);
            setOverlapHelp(true);
        } else {

            setOverlapHelp(false);
            setOverlapHelpParent(false);
        }
    }, [overlapHelp, setOverlapHelpParent, drawerOpen, open])

    useEffect(() => {
        setOpen(drawerOpen);
        setOverlapHelpParent(!drawerOpen);
    }, [drawerOpen, setOverlapHelpParent, setOpen])

    return (
        <>
            <TopBar open={open} handleDrawerOpen={handleDrawerOpen} smallScreen={isSmallScreen}/>
            <SideBar open={open} handleDrawerHover={handleMouseOver} handleClose={handleMouseLeave}
                     overlapHelp={overlapHelp} mobileMenuClose={() => setOpen(false)}/>
        </>
    )
}
