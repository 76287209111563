import React from "react";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaStack} from "../../components/mui/AzaStack";

import * as aza_icons from "../../components/mui/AzaIcons";
import * as aza_logos from "../../components/logo/Logo";
import AzaSettingsBox from "../../components/azameo/AzaSettingsBox";
import {AzaTypography} from "../../components/mui/AzaTypography";

const AzaIconSample = ({icon, name}) => {
    return (
        <AzaGridItem sm={6} md={4} lg={3} sx={{height:"100px"}}>
            <AzaStack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <AzaGridItem>
                    {icon}
                </AzaGridItem>
                <AzaGridItem>
                    {name}
                </AzaGridItem>
            </AzaStack>
        </AzaGridItem>
    )
};

const AzaIcons = ({list_icons}) => {
    Object.keys(aza_icons).map((icon) => {
        console.log('aza_icons', icon)
    })
  return (
      <AzaGridContainer>
          {
              Object.keys(list_icons).sort().map((icon) => {
                  return (
                      <AzaIconSample
                          icon={React.createElement(list_icons[icon], {style: {height: "30px"}})}
                          name={icon}
                          key={icon}
                      />
                  )
              })
        }
      </AzaGridContainer>
  )
};

const SampleIcons = ({title, text,  list_icons}) => {
    return (
        <AzaSettingsBox
            title={{name:title}}
        >
            <AzaTypography variant={"h5"}>{text}</AzaTypography>
            <br/>
            <AzaIcons list_icons={list_icons}/>
        </AzaSettingsBox>
    )
}

export const SampleAzaIcons = ({title}) => {
    return (<SampleIcons title={title} text={"Table of Icons used in Azameo"} list_icons={aza_icons} />)
}

export const SampleAzaLogos = ({title}) => {
    return (<SampleIcons title={title} text={"Table of Logos available"} list_icons={aza_logos} />)
}
