/*
Detail and doc at: https://azameo.atlassian.net/wiki/spaces/D/pages/2081619969/Attribution+reducer+updater+and+hook
 */

import {useCallback, useEffect, useMemo} from "react";
import {useDbItem} from "../../utils/rdbHooks";
import {useUser} from "../../app/globalHooks";
import {backendFetchPromise} from "../../utils/backendHelper";
import {
    changeAttributionCurrentPeriod,
    changeAttributionPreviousPeriod,
    changeAttributionSiteID,
    changeCurrent,
    changeLastUpdate,
    changeLoaded,
    changeNow,
    changePrevious,
    changeUpdating,
    resetAttribution,
    selectAttributionCurrentPeriod,
    selectAttributionPreviousPeriod,
    selectAttributionSiteID,
    selectLastUpdate,
} from "./attributionSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentPeriod, selectPreviousPeriod, selectSiteId} from "../../app/globalSlice";

export const AttributionUpdater = () => {
    const dispatch = useDispatch()

    // Get the keys from the attribution reducer
    const attributionSiteID = useSelector(selectAttributionSiteID)
    const attributionCurrentPeriod = useSelector(selectAttributionCurrentPeriod)
    const attributionPreviousPeriod = useSelector(selectAttributionPreviousPeriod)
    const attributionLastUpdate = useSelector(selectLastUpdate)

    // Get these key from global reducer
    const globalSiteID = useSelector(selectSiteId)
    const globalCurrentPeriod = useSelector(selectCurrentPeriod)
    const globalPreviousPeriod = useSelector(selectPreviousPeriod)

    // Get the last update for this site in the rdb
    const {item: siteData, loading: rdbLoading} = useDbItem("sites/", globalSiteID)

    const rdbLastUpdate = useMemo(() => {
        if (siteData?.["updated"]) {
            // Store an int for easier comparison
            return (Math.floor(siteData?.["updated"]));
        }
        return 0;
    }, [siteData])

    // Get the user from firebase, will be needed for backend api calls
    const {user} = useUser();

    /*
     * Get the attribution for siteID and period
     */
    const getAttribution = useCallback(async (siteID, period, handleChange) => {
        let queryParams = {
            site_id: siteID,
            dstart: period.start,
            dend: period.end,
        }
        console.log(`Making backend request for brandroi for site: ${siteID} startDate: ${period.start} endDate: ${period.end}`);
        // Get the brandroi from the backend
        return backendFetchPromise({user, path: '/site/brandroi', queryParams, method: 'GET'}).then((res) => {
            if (res.status === 200) {
                return res.json()
            } else {
                // For brandroi api , if the period is incorrect , the server will return a 204 without body
                return {}
            }
        }).then((json_data) => {
            handleChange(json_data)
        })
    }, [user])

    const handleChangeCurrent = useCallback((data) => {
        dispatch(changeCurrent(data))
    }, [dispatch])

    const handleChangePrevious = useCallback((data) => {
        dispatch(changePrevious(data))
    }, [dispatch])

    const handleChangeNow = useCallback((data) => {
        dispatch(changeNow(data))
    }, [dispatch])

    /*
    * Refresh the Attribution data from backend brandroi api call
    * Will be called for the 3 periods: now, current and previous
    * At the end the data is put into the redux store with loaded=true and updating=false
     */
    const refreshAttribution = useCallback(() => {
        // First get the attribution for the current period
        let currentPromise = getAttribution(globalSiteID, globalCurrentPeriod, handleChangeCurrent)
        // // Then get the attribution for the previous period
        let previousPromise = getAttribution(globalSiteID, globalPreviousPeriod, handleChangePrevious)
        // // Then get the attribution for now
        const today = (new Date()).toISOStringDateOnly()
        let nowPromise = getAttribution(globalSiteID, {start: today, end: today}, handleChangeNow)
        // Wait for all promises to be resolved
        Promise.all([currentPromise, previousPromise, nowPromise]).then(() => {
            // update the flags
            dispatch(changeLoaded(true))
            dispatch(changeUpdating(false))
        })
    }, [getAttribution, globalSiteID, globalCurrentPeriod, handleChangeCurrent, globalPreviousPeriod, handleChangePrevious, handleChangeNow, dispatch])

    /*
    * Called each time a key has changed or the rdb data has changed
    * Compare the keys, if any of the key has changed refresh the attribution data
    * Also refresh the data if the rdb data has changed
     */
    useEffect(() => {
        // never do anything if the site id is not set
        if (!globalSiteID) return;
        // Never do anything until rdb is loaded
        if (rdbLoading || (rdbLastUpdate === 0)) return;

        // Refresh the attribution if any of the key variables has changed
        if (attributionSiteID !== globalSiteID || attributionCurrentPeriod !== globalCurrentPeriod || attributionPreviousPeriod !== globalPreviousPeriod) {
            console.log("Attribution needs a refresh because the key variables have changed")
            // First reset all data
            dispatch(resetAttribution())
            // Update the local variables
            dispatch(changeAttributionSiteID(globalSiteID))
            dispatch(changeAttributionCurrentPeriod(globalCurrentPeriod))
            dispatch(changeAttributionPreviousPeriod(globalPreviousPeriod))
            dispatch(changeLastUpdate(rdbLastUpdate))
            // Refresh the attribution
            refreshAttribution();
        } else {
            if (rdbLastUpdate > attributionLastUpdate) {
                console.log("Attribution needs a refresh because of rdb update")
                // Set the updating flag to true
                dispatch(changeUpdating(true))
                // Refresh the attribution
                refreshAttribution();
                // Make sure to update the lastUpdate in the redux store
                dispatch(changeLastUpdate(rdbLastUpdate))
            }
        }

    }, [
        // local state version of the key variables
        attributionSiteID, attributionCurrentPeriod, attributionPreviousPeriod,
        // redux version of the key variables
        globalSiteID, globalCurrentPeriod, globalPreviousPeriod,
        // rdb and redux lastUpdate
        rdbLastUpdate, attributionLastUpdate,
        // rdb loading flag
        rdbLoading,
        // functions
        dispatch, refreshAttribution
    ])

    // This component does not render anything
    return <></>
}
