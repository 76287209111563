import {useSiteId, useUser} from "../../../app/globalHooks";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AzaGrid} from "../../../components/mui/AzaGrid";
import {AzaTextField} from "../../../components/mui/AzaTextField";
import {shallowEqual} from "react-redux";
import {AzaButton} from "../../../components/mui/AzaButton";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {createAsset, formatAssets, generateSiteAssets, getSiteAssets, getSiteProducts, saveSiteAssets} from "./utils";
import {assetOrigin, assetScope, assetTypeOrder} from "./constants";
import {AzaMenuItem, AzaMenuList} from "../../../components/mui/AzaMenu";
import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    CircularProgress,
    ClickAwayListener,
    Grow,
    IconButton, MenuItem,
    Paper,
    Popper,
    Select,
    Switch
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const AssetEdition = ({asset, handleSave, lastUpdate, handleDelete}) => {

    const [currentAsset, setCurrentAsset] = useState(asset)
    const [currentUpdate, setCurrentUpdate] = useState(lastUpdate)

    useEffect(() => {
        setCurrentAsset(asset)
        if (currentUpdate !== lastUpdate) {
            setCurrentUpdate(lastUpdate)
        }
    }, [lastUpdate, currentUpdate, asset])

    const isChanged = useMemo(() => {
        if (!currentAsset || !asset) return false
        return !shallowEqual(currentAsset, asset)
    }, [currentAsset, asset])

    const reset = useCallback(() => {
        setCurrentAsset(asset)
    }, [asset])

    return (
        <AzaGrid item xs={12}>
            <AzaGrid
                container
                direction={"row"}
                spacing={2}
            >

                <AzaGrid item xs={9}>
                    <AzaTextField
                        value={currentAsset["text"]}
                        onChange={(e) => setCurrentAsset({...currentAsset, "text": e.target.value})}
                        size={"small"}
                    />
                </AzaGrid>
                <AzaGrid item xs={3}>
                    <IconButton
                        disabled={!isChanged}
                        color="primary"
                        onClick={() => handleSave(currentAsset)}
                    >
                        <SaveIcon/>
                    </IconButton>
                    <IconButton
                        disabled={!isChanged}
                        color="primary"
                        onClick={reset}
                    >
                        <SettingsBackupRestoreIcon/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() => handleDelete(currentAsset)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </AzaGrid>
                {/*<AzaGrid item>*/}
                {/*    {currentAsset["origin"]}*/}
                {/*</AzaGrid>*/}
            </AzaGrid>
        </AzaGrid>
    )
}
const AssetGroupHeader = ({children}) => {
    return <AzaTypography variant={"h5"}>{children}</AzaTypography>
}
const AssetCategoryHeader = ({children}) => {
    return <AzaTypography variant={"h6"}>{children}</AzaTypography>
}
const AssetGroupCategory = ({assets, title, handleSave, handleDelete, lastUpdate}) => {
    return (
        <AzaGrid
            container
            direction={"column"}
            spacing={2}
        >
            <AzaGrid item xs={12}>
                <AssetCategoryHeader>{title}</AssetCategoryHeader>
            </AzaGrid>
            {assets.map(asset => <AssetEdition
                key={asset.id}
                asset={asset}
                handleSave={handleSave}
                handleDelete={handleDelete}
                lastUpdate={lastUpdate}
            />)}
        </AzaGrid>
    )
}

const AddAssetAction = ({addAsset}) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    return (
        <>
            <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color={"primary"}
                variant={"contained"}
            >
                <AddIcon/>
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <AzaMenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {assetTypeOrder.map((assetType) => {
                                        return (
                                            <AzaMenuItem
                                                key={assetType}
                                                onClick={(event) => {
                                                    addAsset(assetType)
                                                    handleClose(event)
                                                }}
                                            >
                                                {assetType}
                                            </AzaMenuItem>
                                        )
                                    })}

                                </AzaMenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>

    )
}


const AssetGroup = ({children, title, assets, handleSave, handleDelete, lastUpdate}) => {
    return (
        <AzaPaper
            elavation={3}
            sx={{
                p: 2,
                minWidth: 700,
            }}
        >
            <AzaGrid container direction={"column"}>
                <AzaGrid item xs>
                    <AssetGroupHeader>{title}</AssetGroupHeader>
                </AzaGrid>

                <AzaGrid item xs>

                    {assetTypeOrder.map(assetType => {
                        if (assets[assetType] && assets[assetType].length > 0) {
                            return <AssetGroupCategory
                                key={assetType}
                                title={assetType}
                                assets={assets[assetType]}
                                handleSave={handleSave}
                                handleDelete={handleDelete}
                                lastUpdate={lastUpdate}
                            />
                        } else {
                            return null
                        }
                    })}
                </AzaGrid>
                <AzaGrid item xs>
                    {children}
                </AzaGrid>
            </AzaGrid>
        </AzaPaper>
    )
}


export const AssetsEdition = () => {
    const {user} = useUser()
    const site_id = useSiteId()

    const [assets, setAssets] = useState([])
    const [lastUpdate, setLastUpdate] = useState(0)
    const [currentNewAssetId, setCurrentNewAssetId] = useState(0)
    const [generating, setGenerating] = useState(false)
    const [currentAssetScope, setCurrentAssetScope] = useState(assetScope.SITE)
    const [listProducts, setListProducts] = useState([])
    const [productId, setProductId] = useState("")

    const handleUpdateAssets = useCallback((newAssets) => {
        // update lastUpdate to force refresh of the components
        setLastUpdate((new Date()).getTime())
        setAssets(newAssets)
    }, [])

    // refresh assets when user or site_id change
    useEffect(() => {
        if (user && site_id) {
            getSiteAssets(user, site_id, handleUpdateAssets)
            getSiteProducts(user, site_id, setListProducts)
        }

    }, [handleUpdateAssets, site_id, user])

    // Extract default assets from the list of all assets
    const defaultAssets = useMemo(() => {
        // filter assets with origin in ["Customer", "Site"]
        let filtered = assets.filter(asset => asset.origin === assetOrigin.SITE || asset.origin === assetOrigin.CUSTOMER)
        // filter the scope
        filtered = filtered.filter(asset => asset.scope === currentAssetScope)

        if (currentAssetScope === assetScope.PRODUCT) {
            filtered = filtered.filter(asset => asset.product_id === productId.toString())
        }
        // put in a dictionary with key = text_type
        return formatAssets(filtered, false)
    }, [assets, currentAssetScope, productId])

    // Extract generated assets from the list of all assets
    const generatedAssets = useMemo(() => {
        // filter assets with origin in ["Generated"]
        let filtered = assets.filter(asset => asset.origin === assetOrigin.GENERATED)
        // filter the scope
        filtered = filtered.filter(asset => asset.scope === currentAssetScope)
        if (currentAssetScope === assetScope.PRODUCT) {
            filtered = filtered.filter(asset => asset.product_id === productId.toString())
        }
        // put in a dictionary with key = text_type
        const dict = {}
        filtered.forEach(asset => {
            if (!dict[asset["text_type"]]) {
                dict[asset["text_type"]] = []
            }
            dict[asset["text_type"]].push(asset)
        })
        return dict
    }, [assets, currentAssetScope, productId])

    const updateAsset = useCallback((asset, oldId) => {
        const index = assets.findIndex(a => a.id === oldId)
        if (index >= 0) {
            const newAssets = [...assets]
            newAssets[index] = asset
            setAssets(newAssets)
        } else {
            // Add to the list of assets
            setAssets([...assets, asset])
        }
    }, [assets])

    const saveAsset = useCallback((asset) => {
        // Some cleaning must be done before saving
        // Make sure that any modified asset origin is set to "Customer"
        asset.origin = assetOrigin.CUSTOMER
        saveSiteAssets(user, asset, (newAsset) => {
            updateAsset(newAsset, asset.id)
        })
    }, [updateAsset, user])

    const deleteAsset = useCallback((asset) => {
        asset.enabled = false
        // remove from the list of assets
        const index = assets.findIndex(a => a.id === asset.id)
        if (index >= 0) {
            const newAssets = [...assets]
            newAssets.splice(index, 1)
            setAssets(newAssets)
        }
    }, [assets])

    const addAsset = useCallback((assetType) => {
        const newAsset = createAsset(site_id, assetType, currentAssetScope, currentAssetScope === assetScope.PRODUCT ? productId : null)
        newAsset.id = currentNewAssetId
        setCurrentNewAssetId(currentNewAssetId - 1)
        setAssets([...assets, newAsset])
    }, [assets, currentAssetScope, currentNewAssetId, productId, site_id])

    const generateAssets = useCallback(() => {
        setGenerating(true)
        let newAssets;
        if (currentAssetScope !== assetScope.PRODUCT) {
            // need to remove all generated assets with scope = SITE
            newAssets = assets.filter(asset => (asset.origin !== assetOrigin.GENERATED) || (asset.scope !== assetScope.SITE))
        } else {
            // need to remove all generated assets with scope = PRODUCT and the product_id === productId
            newAssets = assets.filter(asset => (asset.origin !== assetOrigin.GENERATED) || (asset.scope !== assetScope.PRODUCT) || (asset.product_id !== productId.toString()))
        }
        setAssets(newAssets)
        let product_id = null;
        if (currentAssetScope === assetScope.PRODUCT) {
            product_id = productId
        }
        generateSiteAssets(user, site_id, product_id, (generatedAssets) => {
            // add generated assets to the list of assets
            setAssets([...newAssets, ...generatedAssets])
            setGenerating(false)
        })

    }, [assets, currentAssetScope, productId, site_id, user])

    return (
        <>
            <h1>Assets Edition</h1>
            <AzaGrid container spacing={2}>
                <AzaGrid item xs={12} lg={6}>
                    <AzaGrid item xs={12} lg={6}>
                        Product Assets: <Switch
                        checked={currentAssetScope === assetScope.PRODUCT}
                        onChange={
                            (e) => setCurrentAssetScope(e.target.checked ? assetScope.PRODUCT : assetScope.SITE)
                        }
                    />
                    </AzaGrid>
                    {currentAssetScope === assetScope.PRODUCT && (
                        <AzaGrid item xs={12} lg={6}>
                            Select Product:
                            <Select
                                value={productId}
                                label="Product"
                                onChange={(e) => setProductId(e.target.value)}
                            >
                                {listProducts.map((product) => (
                                    <MenuItem key={product.id} value={product.id.toString()}>{product.title}</MenuItem>
                                ))}
                            </Select>
                        </AzaGrid>
                    )}
                    <AssetGroup
                        title={"Default Assets"}
                        assets={defaultAssets}
                        handleSave={saveAsset}
                        handleDelete={deleteAsset}
                        lastUpdate={lastUpdate}
                    >
                        <AzaGrid
                            container
                            direction={"row"}
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <AzaGrid item xs></AzaGrid>
                            <AzaGrid item>
                                <AddAssetAction addAsset={addAsset}/>
                            </AzaGrid>
                            <AzaGrid item>
                                <AzaButton
                                    disabled={generating}
                                    variant="contained"
                                    onClick={generateAssets}
                                >
                                    <DeveloperBoardIcon/>
                                </AzaButton>
                            </AzaGrid>
                        </AzaGrid>
                    </AssetGroup>

                </AzaGrid>

                <AzaGrid item xs>
                    {generating ? <CircularProgress/>
                        : <AssetGroup
                            title={"Asset variants"}
                            assets={generatedAssets}
                            handleSave={saveAsset}
                            lastUpdate={lastUpdate}
                            handleDelete={deleteAsset}
                        >
                        </AssetGroup>
                    }

                </AzaGrid>
            </AzaGrid>

        </>
    )
}
