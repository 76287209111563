import {Step, StepContent, StepIcon, StepLabel, Stepper, styled, Typography} from "@mui/material";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {AzaIconNumber} from "./AzaIcons";

export const AzaStepContent = styled((props) => {
    return (
        <StepContent {...props}/>
    )
})(() => ({
    paddingTop: "12px",
}))

export const AzaStepIcon = styled((props) => {
    const {error, current, index} = props;

    if (error) {
        return (
            <StepIcon icon={<Cancel fontSize={"medium"} color={"error"}/>}/>
        )
    }

    if (index > current) {
        return (
            <StepIcon icon={<AzaIconNumber count={index + 1} color={"disabled"}/>}/>
        )
    }

    if (index === current) {
        return (
            <StepIcon icon={<AzaIconNumber count={index + 1} color={"primary"}/>}/>
        )
    }

    return (
        <StepIcon icon={<CheckCircle fontSize={"medium"} color={"primary"}/>}/>
    )
})(() => ({}))

export const AzaStepLabelComplex = (props) => {
    const {error, index, current, children} = props;

    const content = (error, children) => {
        if (error) {
            return (
                <Typography variant={'body2'} color={"error"}>
                    {children}
                </Typography>
            )
        }
        if (index < current) {
            return (
                <Typography variant={'body2'} color={'black'}>
                    {children}
                </Typography>
            )
        }
        if (index === current) {
            return (
                <Typography variant={'body2'} color={'black'} fontWeight={'bold'}>
                    {children}
                </Typography>
            )
        }
        return (
            <Typography variant={'body2'}>
                {children}
            </Typography>
        )
    }

    return (
        <StepLabel
            icon={<AzaStepIcon error={error} index={index} current={current}/>}
        >

            {content(error, children)}

        </StepLabel>
    )
}

export const AzaStepLabel = (props) => {
    return <StepLabel {...props}/>
}

export const AzaStepVertical = (props) => {
    const {error, index, current, label, children, ...others} = props;
    return (

        <Step  {...others}>
            <AzaStepLabelComplex error={error} index={index} current={current}>{label}</AzaStepLabelComplex>
            <AzaStepContent>
                {children}
            </AzaStepContent>
        </Step>
    )
}

export const AzaStep = (props) => {
    const {children, ...others} = props;
    return (
        <Step  {...others}>
            {children}
        </Step>
    )
}

export const AzaStepper = (props) => {
    return (
        <Stepper {...props}/>
    )
}