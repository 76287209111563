import {alpha} from "@mui/material/styles";

const adminOverrides = (theme) => {return ({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.dark
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.palette.primary.dark,
                },
            },
        },
        MuiSvgIcon:{
            styleOverrides: {
                root: {
                    color: theme.palette.primary.light
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.dark,
                }
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.light,
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.dark,
                        "&.Mui-disabled": {
                        backgroundColor: alpha(
                            theme.palette.primary.dark,
                            theme.palette.action.hoverOpacity),
                    }
                },
            }
        },
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.dark,
                    "&.Mui-disabled": {
                        backgroundColor: alpha(
                            theme.palette.primary.dark,
                            theme.palette.action.hoverOpacity),
                    }
                },
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.light,
                },
                "&.Mui-disabled": {
                    backgroundColor: alpha(
                        theme.palette.primary.lighter,
                        theme.palette.action.hoverOpacity),
                }
            }
        }
    }})};

export default adminOverrides;
