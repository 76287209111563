import React from 'react';
import {AzaSkeleton} from "../mui/AzaSkeleton";
import {AzaGrid} from "../mui/AzaGrid";
import {AzaBox} from "../mui/AzaBox";

const CustomizableSkeleton = (props) => {
    const {numbCols, numbRows} = props;

    const Rows = () => {
        return [...Array(numbRows)].map((item, index) => (
            <AzaSkeleton key={index} height={60}  animation="wave"/>
        ));
    }
    const CustomizedSkeleton = () => {
        return (
            <AzaGrid container wrap="nowrap">
                { [...Array(numbCols)].map((item, index) => (
                    <AzaBox key={index} sx={{ width: '100%', marginRight: 0.5, my: 2 }}>
                        <AzaBox sx={{ pt: 0.5 }}>
                            <Rows/>
                        </AzaBox>
                    </AzaBox>
                ))}
            </AzaGrid>
        );
    }

    return (
        <AzaBox>
            <AzaSkeleton variant="rectangular" width={210} height={40}  animation="wave"/>
            <CustomizedSkeleton/>
        </AzaBox>
    );
};

export default CustomizableSkeleton;