import {useMemo} from "react";
import {useTranslation} from "react-i18next";

export const useWidgetDefinitions = () => {

    const {t} = useTranslation();

    const widgetDefinitions = useMemo(() => {
        return {
            "impressions": {
                label: t('campaigns_data.impressions'),
                field: "impressions",
                admin: false,
                format: "formatNumber",
            },
            "clicks": {
                label: t('campaigns_data.clicks'),
                field: "clicks",
                admin: false,
                format: "formatNumber",
            },
            "attributed_conversions": {
                label: t('campaigns_data.attributed_conversions'),
                field: "attributed_conversions",
                admin: false,
                format: "formatMoney",
            },
            "site_conversion_rate": {
                label:  t('campaigns_data.site_conversion_rate'),
                field: "site_conversion_rate",
                admin: false,
                format: "formatPercent",
            },
            "campaign_conversion_rate": {
                label: t('campaigns_data.campaign_conversion_rate'),
                field: "campaign_conversion_rate",
                admin: false,
                format: "formatPercent",
            },
            "attributed_nb_conversions": {
                label: t('campaigns_data.attributed_nb_conversions'),
                field: "attributed_nb_conversions",
                admin: false,
                format: "formatNumber",
            },
            "roi": {
                label: t('campaigns_data.roi'),
                field: "roi",
                admin: false,
                format: "formatFloat",
            },
            "cpm": {
                label: t('campaigns_data.cpm'),
                field: "cpm",
                admin: false,
                format: "formatFloat",
            },
            "buyer": {
                label: t('campaigns_data.buyer'),
                field: "buyer",
                admin: false,
                format: "formatNumber",
            },
            "multiple_buyer": {
                label: t('campaigns_data.multiple-buyer'),
                field: "multiple_buyer",
                admin: false,
                format: "formatNumber",
            },
            "visits": {
                label: t('campaigns_data.visits'),
                field: "visits",
                admin: false,
                format: "formatNumber",
            },
            "visitors": {
                label: t('campaigns_data.visitors'),
                field: "visitors",
                admin: false,
                format: "formatNumber",
            },
            "viewers": {
                label: t('campaigns_data.viewers'),
                field: "viewers",
                admin: false,
                format: "formatNumber",
            },
            "profit": {
                label: t('campaigns_data.profit'),
                field: "profit",
                admin: true,
                format: "formatFloat",
            },
            "marge": {
                label:t('campaigns_data.marge'),
                field: "marge",
                admin: true,
                format: "formatPercent",
            },
        }
    }, [t]);

    return {widgetDefinitions}
}
