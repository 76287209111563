import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AzaSelect} from "../components/mui/AzaSelect";
import {AzaMenuItem} from "../components/mui/AzaMenu";
import {AzaBox} from "../components/mui/AzaBox";
import {useTheme} from "@mui/material";
import {AzaTypography} from "../components/mui/AzaTypography";
import {AzaCaretDownOutlined} from "../components/mui/AzaIcons";
import {AzaGridContainer, AzaGridItem} from "../components/mui/AzaGrid";

const SelectValue = ({value, isEditing}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (<AzaTypography color={theme.palette.primary.main} sx={{fontWeight: "bold"}}>{t(value.label)} {!isEditing &&
        <AzaCaretDownOutlined/>}</AzaTypography>)
}
export const InlineEditSelect = ({options, defaultValue, onChange}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const {t} = useTranslation();

    const handleValueChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
        setIsEditing(false);
    };

    return (<>
        {isEditing ? (<AzaSelect
            value={value}
            onChange={handleValueChange}
            onBlur={() => setIsEditing(false)}
            onClose={() => setIsEditing(false)}
            autoFocus
            open={isEditing}  // This prop controls whether the Select is open or not
            sx={{minWidth: 'auto'}}
            renderValue={(selected) => <SelectValue value={selected} isEditing={isEditing}/>}
            variant="standard"
        >
            {options.map((option, index) => (<AzaMenuItem key={index} value={option} disabled={option.disabled}>
                {t(option.label)}
            </AzaMenuItem>))}
        </AzaSelect>) : (<AzaBox
            display="inline-block"
            onClick={() => setIsEditing(true)}
            sx={{cursor: 'pointer'}}
        >
            <SelectValue value={value} isEditing={isEditing}/>
        </AzaBox>)}
    </>);
}
export const Phrase = ({items}) => {
    return (<AzaGridContainer
        direction={"row"}
        justifyContent="flex-start"
        alignItems="center"
        sx={{
            minHeight: "50px",
        }}
        spacing={1}
    >
        {items.map((item, index) => (<AzaGridItem key={index}>
            {typeof item === "string" ? (<AzaTypography variant={"body1"}>
                {item}
            </AzaTypography>) : (item)}
        </AzaGridItem>))}
    </AzaGridContainer>)
}
