import {Alert, AlertTitle, styled} from "@mui/material";
import {Cancel} from "@mui/icons-material";

export const AzaAlert = styled((props) => {
    const {overloaded_variant, ...other} = props;
    const variant = (overloaded_variant) ? overloaded_variant : "outlined";
    return (
        <Alert
            iconMapping={{error: <Cancel fontSize="inherit"/>}}
            variant={variant}
            {...props}
        />
    );
})(({theme}) => ({}))

export const AzaAlertTitle = (props) => {
    return (
        <AlertTitle {...props}/>
    )
}