// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMoTT-x_94hqzHoyzbjYMTfn9APoLeR0M",
  authDomain: "dashboard-7f019.firebaseapp.com",
  projectId: "dashboard-7f019",
  storageBucket: "dashboard-7f019.appspot.com",
  messagingSenderId: "548143867061",
  appId: "1:548143867061:web:ed4d417afe7721197bcafc",
  measurementId: "G-2T5WVB1HZH",
  databaseURL:"https://dashboard-7f019-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
