import {useDbItem} from "../utils/rdbHooks";
import {RDB_USER, RDB_USERRIGHTS} from "../utils/constant";
import {useCallback, useEffect, useMemo} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import {firebaseApp} from "../firebaseConfig";

export const useThemeMode = () => {
    const auth = useMemo(() => getAuth(firebaseApp), []);
    const [user,  loading] = useAuthState(auth);
    const {item: userDB, loading: loadingDb, updateField} = useDbItem(RDB_USER, user?.uid)
    const {item: userRights, loading: loadingUseright} = useDbItem(RDB_USERRIGHTS, user?.uid)
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const themeMode = useMemo(() => {
        if (loadingUseright || !!!(userRights?.["admin"])) { // Can't use is Admin because it is used at App root
            return "light";
        }
        if (user && !loadingDb && userDB===null) {
            return prefersDarkMode ? 'dark' : 'light';
        }
        return userDB?.themeMode
    }, [user, loadingDb, userDB, prefersDarkMode, userRights, loadingUseright]);

    const toggleThemeMode = useCallback(() => {
        const new_mode = themeMode === 'dark' ? 'light' : 'dark';
        if (user) {
            updateField("themeMode", new_mode)
        }
    }, [updateField, user, themeMode]);

    useEffect(() => {
        if (loading || loadingDb || user || userDB === null) return;
        if (userDB?.themeMode === undefined) {
            updateField("themeMode", prefersDarkMode ? 'dark' : 'light');
        }
    }, [toggleThemeMode, loadingDb, loading, user, userDB, prefersDarkMode, updateField])

    return {themeMode, toggleThemeMode};
}
