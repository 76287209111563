import React, {useState} from "react";

import {sendPasswordResetEmail} from 'firebase/auth';

import {Grid, TextField} from "@mui/material";

import {auth} from "../../utils/firebase/firebaseHelper";
import {AzaDialog, AzaDialogContent, AzaDialogTitle} from "../../components/mui/AzaDialog";
import {AzaButton} from "../../components/mui/AzaButton";
import {AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaAlert} from "../../components/mui/AzaAlert";
import {useTranslation} from "react-i18next";

const defaultFormField = {
    email: '',
}

const ResetPassword = ({openReset, handleResetPasseClose, defaultemail}) => {
    const [emailField, setEmailField] = useState(defaultemail?{email:defaultemail}:defaultFormField);
    const {email} = emailField;
    const [errorResetEmail, setErrorResetEmail] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const {t, i18n} = useTranslation();

    const handleChange = (e) => {
        const {value} = e.target;
        setEmailField({...emailField, email: value});
        if(errorResetEmail) {
            setErrorResetEmail(false);
        }
    }

    const triggerResetEmail = () => {
        auth.languageCode = String(i18n.resolvedLanguage);
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setEmailField(defaultFormField);
                handleResetPasseClose(true);
            })
            .catch((error) => {
                setErrorResetEmail(true);
                switch (error.code ) {
                    case 'auth/user-not-found':
                        setErrorMsg(t('container.resetpassword.error.notfound'));
                        break;
                    case 'auth/invalid-email':
                        setErrorMsg(t('container.resetpassword.error.emailinvalid'));
                        break;
                    case 'auth/missing-email':
                        setErrorMsg(t('container.resetpassword.error.missingemail'));
                        break;
                    default:
                        setErrorMsg(t('container.resetpassword.error.genericerror'));
                }
            });
    }

    return (
        <div className="resetPassword-main">
            <AzaDialog open={openReset} onClose={handleResetPasseClose}>
                <AzaDialogTitle onClose={handleResetPasseClose}
                    sx={{
                        marginLeft: ['0', '24px', '24px'],
                        marginRight: ['0', '24px', '24px'],
                        fontSize: ['1.3rem', '1.5rem', '1.5rem'],
                    }}
                >
                    {t('container.resetpassword.text')}
                </AzaDialogTitle>
                <AzaDialogContent
                    sx={{
                        marginLeft: ['0', '24px', '24px'],
                        marginRight: ['0', '24px', '24px'],
                    }}
                >
                        <TextField type={'email'} variant="outlined" fullWidth required label={t('container.resetpassword.email')} value={email} onChange={handleChange}
                                   sx={{
                                       marginTop: '15px',
                                       marginBottom: '15px'
                                   }}
                        />
                    {errorResetEmail && <AzaGridItem xs={12}
                                                     sx={{ marginBottom: '15px'}}>
                        <AzaAlert severity="error">{errorMsg}</AzaAlert>
                    </AzaGridItem>}
                    <Grid container
                        sx={{
                            justifyContent: ['center', "flex-end", null]
                        }}
                    >
                        <AzaButton variant="contained" type="button" onClick={triggerResetEmail}>{t('container.resetpassword.resetbutton')}</AzaButton>
                    </Grid>
                </AzaDialogContent>
            </AzaDialog>
        </div>
    )
}

export default ResetPassword;
