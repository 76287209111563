import logoAzameo from '../../assets/images/logo2.png';
import logoMicrosoftAds from "../../assets/images/microsoft_ads.png";
import logoGoogleAds from "../../assets/images/google_ads.png";
import logoFacebook from "../../assets/images/facebook.png";
import logoAIGenerated from '../../assets/images/bot_white.svg';

const Logo = ({style={height:"14px"}, imgLogo=logoAzameo, alt=`Logo`}) => {
    return (
        <img style={style} src={imgLogo} alt={alt}/>
    )
}

export const LogoAzameo = (props) => {return <Logo imgLogo={logoAzameo} alt={'logoAzameo'} {...props}/>}
export const LogoAIGenerated = (props) => {return <Logo imgLogo={logoAIGenerated} alt={'logoAIGenerated'} {...props}/>}
export const LogoFacebook = (props) => {return <Logo imgLogo={logoFacebook} alt={'logoFacebook'} {...props}/>}
export const LogoGoogleAds = (props) => {return <Logo imgLogo={logoGoogleAds} alt={'logoGoogleAds'} {...props}/>}
export const LogoMicrosoftAds = (props) => {return <Logo imgLogo={logoMicrosoftAds} alt={'logoMicrosoftAds'}{...props}/>}
