const InputBase = () => {
  return {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem'
        }
      }
    }
  };
}

export default InputBase;
