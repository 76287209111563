import React from 'react';
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaStack} from "../../components/mui/AzaStack";
import {AzaButton} from "../../components/mui/AzaButton";
import {AzaGridItem} from "../../components/mui/AzaGrid";
import {useChatProvider} from "./chatContext";
import {useTranslation} from "react-i18next";

export const Suggestion = ({suggestion}) => {

    const title = suggestion.title ?? suggestion.prompt;
    const prompt = suggestion.prompt ?? title;
    const description = suggestion.description ?? "";

    const {onSubmitPrompt} = useChatProvider();
    const {t} = useTranslation();

    return (<AzaGridItem>
            <AzaButton
                variant={"outlined"}
                onClick={() => onSubmitPrompt(t(prompt))}
                sx={{
                    maxWidth: 1,
                    height: 0.8
                }}
            >
                <AzaStack>
                    <AzaTypography variant={"h4"}>{t(title)}</AzaTypography>
                    <AzaTypography>{t(description)}</AzaTypography>
                </AzaStack>
            </AzaButton>
        </AzaGridItem>)
};

export const SuggestionsPannel = () => {
    const {suggestions} = useChatProvider()

    return (<AzaStack
        direction={"row-reverse"}
        alignItems={"stretch"}
        spacing={2}
        justifyContent={"flex-start"}
    >
            {suggestions?.map((suggestion, index) => (<Suggestion key={index} suggestion={suggestion}/>))}
        </AzaStack>)
}
