import {useSiteId, useUser} from "../../app/globalHooks";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {API_GOOGLE_ADS_CONNEXION, API_GOOGLE_ADS_LINK} from "../../utils/constant";
import {CircularProgress} from "@mui/material";
import {backendFetch} from "../../utils/backendHelper";
import GoogleButton from "react-google-button";
import {AzaTextField} from "../../components/mui/AzaTextField";
import {AzaAutocomplete} from "../../components/mui/AzaAutocomplete";
import {AzaGrid} from "../../components/mui/AzaGrid";
import {AzaButton} from "../../components/mui/AzaButton";
import {useTranslation} from "react-i18next";


export const GoogleAdsOAuth = () => {
    const site_id = useSiteId()
    const {user} = useUser();

    const [googleConnexion, setGoogleConnexion] = useState(null)

    const refreshGoogleConnexion = useCallback(() => {
        if (!user || !site_id) return
        // Query the backend to know if the user has already linked his Google Ads account
        backendFetch({
            user: user,
            path: API_GOOGLE_ADS_CONNEXION,
            method: "GET",
            queryParams: {site_id}
        }).then((data) => {
            setGoogleConnexion(data)
        }) // TODO : Handle error
    }, [site_id, user])

    useEffect(() => {
        if (!user || !site_id) return
        // only for the first render
        if (googleConnexion === null) {
            refreshGoogleConnexion()
        }
    }, [googleConnexion, refreshGoogleConnexion, site_id, user])


    const loading = useMemo(() => {
        // Compute all states that would still be considered as "currently loading, please wait"

        if (!site_id) return true
        if (!user) return true
        // googleConnexion is at its initial state
        if (googleConnexion === null) return true

        // Otherwise, we are not loading anymore
        return false
    }, [googleConnexion, site_id, user])

    // Handle the loading state
    if (loading) return (
        <AzaButton variant={"outlined"}>
            <CircularProgress size={20}/> loading...
        </AzaButton>
    )

    return (
        <>
            {!(googleConnexion?.["connected"]) ? (
                <GoogleAdsConnexionButton/>
            ) : (
                <GoogleAdsAccountSelection googleConnexion={googleConnexion}
                                           refreshGoogleConnexion={refreshGoogleConnexion}/>
            )}
            {/*{JSON.stringify(googleConnexion, null, 2)}*/}
        </>
    )
}

const GoogleAdsAccountSelection = ({googleConnexion, refreshGoogleConnexion}) => {
    const {t} = useTranslation();

    if (!googleConnexion?.["connected"]) return <></>

    // The account has been selected, just display the selected account
    if (googleConnexion?.["customer_id"]) return (
        <GoogleAdsAccountWidget
            customer_id={googleConnexion?.["customer_id"]}
            customer_name={googleConnexion?.["customer_name"] || t("googleads.auth.account-name-if-empty")}
        />
    )

    // no account has been selected yet, display the list of accounts and the select button
    return (
        <GoogleAdsAccountSelector
            googleConnexion={googleConnexion}
            refreshGoogleConnexion={refreshGoogleConnexion}
        />
    )

}

const GoogleAdsAccountSelector = ({googleConnexion, refreshGoogleConnexion}) => {
    const site_id = useSiteId()
    const {user} = useUser();
    const {t} = useTranslation();

    const [selectCustomer, setSelectCustomer] = useState(null)
    const [linking, setLinking] = useState(false)
    // Shortcut + type enforcement
    const accessible = useMemo(() => {
        // convert the dict into an array of [{customer_id, customer_name}]
        return Object.entries(googleConnexion?.["accessible"] || {})
            .map(([customer_id, customer_name]) => ({customer_id, customer_name}))
    }, [googleConnexion])

    const onLink = useCallback(() => {
        if (!user) return
        if (!site_id) return
        if (!(selectCustomer?.["customer_id"])) return
        setLinking(true)
        // Query the backend to know if the user has already linked his Google Ads account
        backendFetch({
            user: user,
            path: API_GOOGLE_ADS_LINK,
            method: "POST",
            data: {
                customer_id: selectCustomer["customer_id"],
                site_id,
            }
        }).then((data) => {
            console.log(data)
            if (data?.["status"] === "ok") {
                console.log("link success")
            } else {
                console.log("link failed")
                // setLinking(false)
            }
            refreshGoogleConnexion()
        }).catch((error) => {
            console.log(error)
            // TODO : Handle error
            setLinking(false)
        })
    }, [refreshGoogleConnexion, selectCustomer, site_id, user])

    const displayLabel = useCallback((customer) => {
        if (!customer) return ""
        if (!customer.customer_name) return `${t("googleads.oauth.account-name-if-empty")} (${customer.customer_id})`
        return `${customer.customer_name} (${customer.customer_id})`
    }, [t])

    return (
        <AzaGrid
            container
            direction={"row"}
            spacing={1}
            alignItems={"center"}
        >
            <AzaGrid item>
                <AzaAutocomplete
                    value={selectCustomer}
                    label={t("googleads.oauth.select-your-account")}
                    sx={{width: 300}}
                    options={accessible}
                    onChange={(event, newValue) => {
                        setSelectCustomer(newValue)
                    }}
                    getOptionLabel={displayLabel}
                    isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                    renderInput={(props) => <AzaTextField {...props} label={t("googleads.oauth.select-your-account")}/>}
                />
            </AzaGrid>
            <AzaGrid item>
                <AzaButton
                    variant={"contained"}
                    disabled={!selectCustomer || linking}
                    onClick={onLink}
                >
                    Link
                    {
                        linking && <CircularProgress size={20}/>
                    }
                </AzaButton>
            </AzaGrid>
        </AzaGrid>
    )
}

const GoogleAdsAccountWidget = ({customer_id, customer_name}) => {
    const {t} = useTranslation();
    return (
        <AzaButton variant={"outlined"}>
            {customer_name || t("googleads.oauth.account-name-if-empty")} ({customer_id})
        </AzaButton>
    )
}

const GoogleAdsConnexionButton = () => {
    const site_id = useSiteId()
    const {t} = useTranslation();

    const redirectGoogleOAuth = useCallback(() => {
        if (!site_id) return
        // since location href doesn't trigger a re-render, we may have a bug if the url change during the process
        let redirect = window.location.href
        // Encode the redirect url
        redirect = encodeURIComponent(redirect)

        const url = `https://app.azameo.com/googleoauthv2/initial?site_id=${site_id}&redirect=${redirect}`
        console.log("redirectGoogleOAuth", url)

        window.location.href = url
    }, [site_id])

    if (!site_id) return <></>

    return (
        <GoogleButton
            onClick={redirectGoogleOAuth}
            type={"light"}
            label={t('googleads.oauth.link-your-account')}
        />
    )
}