
const campaignParamsEditor = (data, assetsScope) => {
    let editedParams;

    const extractData = (assets) => {
        const assetsList = [];
        assets.forEach((el) => {
            assetsList.push(el['cleaned_text']);
        });

        return assetsList;
    }

    if(assetsScope === 'Product') {
        editedParams = {
            callout: [],
            descriptions: [],
            headlines: [],
            long_headlines: [],
            keywords: {
                keywords: []
            },
        }
        for(const obj in data) {
           switch (obj) {
               case 'Callout':
                   editedParams.callout = extractData(data[obj]);
                  break;
               case 'Description':
                  editedParams.descriptions = extractData(data[obj]);
                  break;
               case 'Headline':
                  editedParams.headlines = extractData(data[obj]);
                  break;
               case 'Long Headline':
                   editedParams.long_headlines = extractData(data[obj]);
                   break;
               case 'Keyword':
                  editedParams.keywords.keywords = extractData(data[obj]);
                  break;
               default:
                   console.log('Error');
           }
        }
    }

    return editedParams;
};

export default campaignParamsEditor;