import React, {useMemo} from 'react';

import LogData from "./LogData";
import {useTranslation} from "react-i18next";



const LogIn = ({children}) => {
    const {t} = useTranslation();
    const logInData = useMemo(() => { return {
        general: {
            title: t('container.open.login.title'),
            check_trigger_text: t('container.open.login.toggle'),
            check_trigger_link: t('container.open.register.title'),
            link: '/register',
            btn_aza: t('container.open.login.loginbutton'),
            btn_google: t('container.open.login.logingoogle'),
            id: 'toRegister',
        },

        form_fields: [
            {
                label: t('login.email'),
                type: "email",
                name: "email",
            },
            {
                label: t('login.password'),
                type: "password",
                name: "password",
            },
        ],
        buttons: [
            {
                name: t('container.open.login.loginbutton'),
                bgc: 'primary',
            },
            {
                name:t('container.open.login.logingoogle'),
                bgc: 'primary',
            },
        ],
    }} ,[t])

    return (
        <LogData logData={logInData} logIn={true}>{children}</LogData>
    )
};

export default LogIn;
