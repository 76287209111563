// Out of the component because we don't want to recreate the function on every render
import {backendFetch, methodType} from "../../../utils/backendHelper";
import {API_OPENAI_FORMAT, API_OPENAI_GENERATION, API_OPENAI_PROMPT, API_OPENAI_RESULT} from "../../../utils/constant";
import {useCallback, useEffect, useState} from "react";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {AzaMenuItem, AzaMenuList} from "../../../components/mui/AzaMenu";
import {AzaGrid} from "../../../components/mui/AzaGrid";
import {AzaButton} from "../../../components/mui/AzaButton";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {CircularProgress} from "@mui/material";

const getPromptTemplates = (user, callback) => {
    backendFetch({path: API_OPENAI_PROMPT, user})
        .then(json_data => {
            callback(json_data)
        }).catch(error => {
        console.log(error)
    });
}
const getFormatTemplates = (user, callback) => {
    backendFetch({path: API_OPENAI_FORMAT, user})
        .then(json_data => {
            callback(json_data)
        }).catch(error => {
        console.log(error)
    });
}
const getGenerationResults = (user, callback) => {
    backendFetch({path: API_OPENAI_RESULT, user})
        .then(json_data => {
            callback(json_data)
        }).catch(error => {
        console.log(error)
    });
}

const putRequestGeneration = (user, site_id, prompt_template_id, format_template_id, callback) => {
    const data = {
        site_id,
        prompt_template_id,
        format_template_id
    }
    backendFetch({data, method: methodType.PUT, path: API_OPENAI_GENERATION, user})
        .then(json_data => {
            callback(json_data)
        }).catch(error => {
        console.log(error)
    });
}


const ListSelector = ({items, selectedId, setSelectedId, render = null}) => {

    if (!items) return <></>
    return (
        <>
            <AzaMenuList>
                {items.map((item) => (
                    <AzaMenuItem
                        key={item.id}
                        onClick={() => {
                            setSelectedId(item.id)
                        }}
                        selected={item.id === selectedId}
                    >
                        {render ? render(item) : item["name"]}
                    </AzaMenuItem>
                ))
                }
            </AzaMenuList>
        </>
    )

}
const TextPre = ({children}) => {

    return (<pre
        style={{
            whiteSpace: "pre-wrap"
        }}
    >
        {children}
        </pre>)
}
export const OpenAIGeneration = () => {
    const {user} = useUser()
    const site_id = useSiteId();

    const [prompts, setPrompts] = useState([]);
    const [promptTemplateId, setPromptTemplateId] = useState(null);

    const [formats, setFormats] = useState([]);
    const [formatTemplateId, setFormatTemplateId] = useState(null);

    const [results, setResults] = useState([]);
    const [resultId, setResultId] = useState(null);


    const [generation, setGeneration] = useState(null);
    const [loading, setLoading] = useState(false);
    const Generate = useCallback(() => {
        setGeneration(null)
        setLoading(true)
        const callback = (json_data) => {
            setGeneration(json_data)
            setLoading(false)
            getGenerationResults(user, setResults)
        }
        putRequestGeneration(user, site_id, promptTemplateId, formatTemplateId, callback);
    }, [user, site_id, promptTemplateId, formatTemplateId])

    useEffect(() => {
        getPromptTemplates(user, setPrompts)
        getFormatTemplates(user, setFormats)
        getGenerationResults(user, setResults)
    }, [user])

    return <>
        <AzaGrid container direction={"row"} spacing={2}>
            <AzaGrid item xs={3}>
                <AzaGrid container direction={"column"} spacing={1}>
                    <AzaGrid item>
                        <AzaTypography variant={"h6"}>Prompt:</AzaTypography>
                    </AzaGrid>
                    <AzaGrid item>
                        <ListSelector
                            items={prompts}
                            selectedId={promptTemplateId}
                            setSelectedId={setPromptTemplateId}
                        />
                    </AzaGrid>
                    {!!promptTemplateId && (
                        <>
                            <AzaGrid item>
                                <AzaTypography variant={"h6"}>Selected:</AzaTypography>
                            </AzaGrid>
                            <AzaGrid item>
                                <AzaPaper sx={{p: 1}} elevation={1}>
                                    <TextPre>{prompts.find(prompt => prompt.id === promptTemplateId)?.template}</TextPre>
                                </AzaPaper>
                            </AzaGrid>
                        </>
                    )}
                </AzaGrid>
            </AzaGrid>
            <AzaGrid item xs={3}>
                <AzaGrid container direction={"column"} spacing={1}>
                    <AzaGrid item>
                        < AzaTypography variant={"h6"}>Output Format:</AzaTypography>
                    </AzaGrid>
                    <AzaGrid item>
                        <ListSelector
                            items={formats}
                            selectedId={formatTemplateId}
                            setSelectedId={setFormatTemplateId}
                        />
                    </AzaGrid>
                    {!!formatTemplateId && (
                        <>
                            <AzaGrid item>
                                <AzaTypography variant={"h6"}>Selected:</AzaTypography>
                            </AzaGrid>
                            <AzaGrid item>
                                <AzaPaper sx={{p: 1}} elevation={1}>
                                    <TextPre>{formats.find(format => format.id === formatTemplateId)?.template}</TextPre>
                                </AzaPaper>
                            </AzaGrid>
                        </>
                    )}
                </AzaGrid>
            </AzaGrid>
            <AzaGrid item xs={3}>
                <AzaGrid container direction={"column"} spacing={1}>
                    <AzaGrid item>
                        < AzaTypography variant={"h6"}>Previous results:</AzaTypography>
                    </AzaGrid>
                    <AzaGrid item>
                        <ListSelector
                            items={results.filter(result => result.format_template_id === formatTemplateId && result.prompt_template_id === promptTemplateId)}
                            selectedId={resultId}
                            setSelectedId={setResultId}
                            render={(t) => {
                                let date = new Date(t["created"]);
                                let cost = parseFloat(t["estimated_cost"]);
                                return (
                                    <AzaGrid container direction={"row"} spacing={1}>
                                        <AzaGrid item>
                                            {date.toISOString().slice(0, 19).replace("T", " ")}
                                        </AzaGrid>
                                        <AzaGrid item>
                                            <AzaTypography sx={{fontWeight: "bold"}}>
                                                ${cost.toFixed(4)}
                                            </AzaTypography>
                                        </AzaGrid>
                                        <AzaGrid item>
                                            {t["json_extractable"] ? <AzaTypography
                                                    sx={{color: "green", fontWeight: "bold"}}>OK</AzaTypography> :
                                                <AzaTypography
                                                    sx={{color: "red", fontWeight: "bold"}}>KO</AzaTypography>}
                                        </AzaGrid>
                                    </AzaGrid>
                                )
                            }}
                        />
                    </AzaGrid>
                    {!!resultId && (
                        <>
                            <AzaGrid item>
                                <AzaTypography variant={"h6"}>Selected:</AzaTypography>
                            </AzaGrid>
                            <AzaGrid item>
                                <AzaPaper sx={{p: 1}} elevation={1}>
                                    <TextPre>{results.find(result => result.id === resultId)?.result}</TextPre>
                                </AzaPaper>
                            </AzaGrid>
                        </>
                    )}
                </AzaGrid>
            </AzaGrid>
            <AzaGrid item xs={3}>
                {loading ? <CircularProgress/> : <></>}
                <AzaGrid container direction={"column"} spacing={1}>
                    <AzaGrid item>
                        <AzaTypography variant={"h6"}>New generation:</AzaTypography>
                        <AzaButton
                            variant={"contained"}
                            disabled={!(promptTemplateId && formatTemplateId) || loading}
                            onClick={Generate}
                        >Generate for site_id: {site_id}</AzaButton>
                    </AzaGrid>
                    {generation?.id && (
                        <>
                            <AzaGrid item>
                                <AzaPaper sx={{p: 1}} elevation={1}>
                                    <TextPre>{generation.result}</TextPre>
                                </AzaPaper>
                            </AzaGrid>
                            <AzaGrid item>
                                <AzaTypography variant={"h6"}>Generated Prompt:</AzaTypography>
                            </AzaGrid>
                            <AzaGrid item>
                                <AzaPaper sx={{p: 1}} elevation={1}>
                                    <TextPre>{generation.prompt}</TextPre>
                                </AzaPaper>
                            </AzaGrid>
                        </>
                    )}
                </AzaGrid>
            </AzaGrid>
        </AzaGrid>

    </>
}