// Extend the Date object with useful functions

/*
* Return a new Date object with the number of days added
* Don't modify the original date
 */
Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days);
    return date;
}

/*
* Return the date in ISO format, without the time
* ex: 2021-01-01
 */
Date.prototype.toISOStringDateOnly = function () {
    return this.toISOString().substring(0, 10);
}