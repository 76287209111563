import React, {useMemo} from 'react';

import {Typography} from "@mui/material";

import AzaSettingsBox from "../../../components/azameo/AzaSettingsBox";
import {useTranslation} from "react-i18next";
import {useFormat, useSiteId} from "../../../app/globalHooks";
import {useDbItem} from "../../../utils/rdbHooks";
import {RDB_CUSTOMER, RDB_SITE} from "../../../utils/constant";
import {usePaymentMode} from "../../payment/paymentContext";


const CreditsBalance = (props) => {
    const {t} = useTranslation();
    const {title} = props;
    const {formatMoney} = useFormat();
    const site_id = useSiteId()
    const {item: siteRDB, loading: loadingSiteDb} = useDbItem(RDB_SITE, site_id);
    const {item: customerRDB, loading: loadingCustDb} = useDbItem(RDB_CUSTOMER, siteRDB?.customer_id);
    const {setOpenPayment} = usePaymentMode();

    const balance = useMemo(() => {
        if (loadingSiteDb || loadingCustDb) {
            return "...";
        }
        return formatMoney(customerRDB?.balance?.current_balance);
    }, [customerRDB, loadingSiteDb, loadingCustDb, formatMoney]);

    const lineOfCredit = useMemo(() => {
        if (loadingSiteDb || loadingCustDb) {
            return "...";
        }
        return customerRDB?.name;
    }, [customerRDB?.name, loadingSiteDb, loadingCustDb]);

    return <AzaSettingsBox
        title={{
            name: `${title}: `,
            data: balance,
            id: 'balance',
            className: ''
        }}
        button={{
            name:t('container.credits.balance.chargement'),
            action: () => {setOpenPayment(true)},
        }}
    >
        <Typography>{t('container.credits.balance.creditline')}: {lineOfCredit}</Typography>
    </AzaSettingsBox>
}

export default CreditsBalance;
