// A list of functions that return a date range, used to populate the menu
export const predefinedPeriods = {
    today: () => {
        const today = new Date();
        return [today, today];
    },
    yesterday: () => {

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        return [yesterday, yesterday];
    },
    last7Days: () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(last7Days.getDate() - 7);
        return [last7Days, today];
    },
    thisWeek: () => {
        const today = new Date();
        const thisWeek = new Date(today);
        thisWeek.setDate(thisWeek.getDate() - thisWeek.getDay() + 1);
        return [thisWeek, today];
    },
    lastWeek: () => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay() - 6);
        const lastWeekEnd = new Date(lastWeek);
        lastWeekEnd.setDate(lastWeekEnd.getDate() + 6);
        return [lastWeek, lastWeekEnd];
    },
    last30Days: () => {
        const today = new Date();
        const last30Days = new Date(today);
        last30Days.setDate(last30Days.getDate() - 30);
        return [last30Days, today];
    },
    thisMonth: () => {
        const today = new Date();
        const thisMonth = new Date(today);
        thisMonth.setDate(1);
        return [thisMonth, today];
    },
    lastMonth: () => {
        const today = new Date();
        const lastMonth = new Date(today);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        lastMonth.setDate(1);
        const lastMonthEnd = new Date(lastMonth);
        lastMonthEnd.setMonth(lastMonthEnd.getMonth() + 1);
        lastMonthEnd.setDate(0);
        return [lastMonth, lastMonthEnd];
    },
    monthBeforeLastMonth: () => {
        const today = new Date();
        const monthBeforeLastMonth = new Date(today);
        monthBeforeLastMonth.setMonth(monthBeforeLastMonth.getMonth() - 2);
        monthBeforeLastMonth.setDate(1);
        const monthBeforeLastMonthEnd = new Date(monthBeforeLastMonth);
        monthBeforeLastMonthEnd.setMonth(monthBeforeLastMonthEnd.getMonth() + 1);
        monthBeforeLastMonthEnd.setDate(0);
        return [monthBeforeLastMonth, monthBeforeLastMonthEnd];
    },
    thisYear: () => {
        const today = new Date();
        const thisYear = new Date(today);
        thisYear.setMonth(0);
        thisYear.setDate(1);
        return [thisYear, today];
    },
    lastYear: () => {
        const today = new Date();
        const lastYear = new Date(today);
        lastYear.setFullYear(lastYear.getFullYear() - 1);
        lastYear.setMonth(0);
        lastYear.setDate(1);
        const lastYearEnd = new Date(lastYear);
        lastYearEnd.setFullYear(lastYearEnd.getFullYear() + 1);
        lastYearEnd.setDate(0);
        return [lastYear, lastYearEnd];
    },
}

export const daysFromToday = (days) => {
    const today = new Date();
    const date = new Date(today);
    date.setDate(date.getDate() - days);
    return [date, today];
}

export const daysFromYesterday = (days) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const date = new Date(yesterday);
    date.setDate(date.getDate() - days);
    return [date, yesterday];
}

export const displayedPredefinedPeriods = [
    "today",
    "yesterday",
    "thisWeek",
    "lastWeek",
    "thisMonth",
    "lastMonth",
    "monthBeforeLastMonth",
    "thisYear",
    "lastYear",
]