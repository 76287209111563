import React from 'react';

import {styled} from "@mui/material";

import {AzaTextField} from "../mui/AzaTextField";

const Input = styled(AzaTextField)(({ theme }) => ({
    margin: '0',
    // code that remove spinner button from input type=number
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    },
}));

const AzaInputNumber = (props) => {
    return (
        <Input
            type="number"
            {...props}
        />
    );
};

export default AzaInputNumber;