import {AzaPaper} from "../../components/mui/AzaPaper";
import {AdminModeRequired} from "../../features/adminMode/adminModeComponents";
import {Competitors} from "./Competitors";
import {UserInterest} from "./UserInterest";
import React from "react";
import {ThemeCustomization} from "../../themes";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AdminSiteSettings} from "./AdminSiteSettings";

export const AdminOnlySettings = () => {

    return (
        <AdminModeRequired disableTheme={true}>

            <AzaPaper
                sx={{
                    p: 2,
                }}>
                <ThemeCustomization presetColor={"themeColorsAdmin"}>
                    <AzaTypography color="primary" variant={"h4"}>Admin only settings</AzaTypography>
                </ThemeCustomization>
                <AdminSiteSettings/>
                <Competitors/>
                <UserInterest/>
            </AzaPaper>

        </AdminModeRequired>
    )
}