import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectSiteId, selectSiteListLoaded, selectSiteLoaded, selectUserInfos, setUserInfos} from "./globalSlice";
import {useCallback, useEffect, useMemo, useState} from "react";
import {getAuth} from "firebase/auth";
import {firebaseApp} from "../firebaseConfig";
import {useAuthState} from "react-firebase-hooks/auth";
import {get, ref, set, update} from "firebase/database";
import {
    ACTION_RESET_STATE,
    PATH_LOGIN,
    PATH_REGISTER,
    RDB_ASYNC,
    RDB_CUSTOMER,
    RDB_SITE,
    RDB_USER
} from "../utils/constant";
import {db} from "../utils/firebase/firebaseHelper";
import {useTranslation} from "react-i18next";
import {useDbItem} from "../utils/rdbHooks";
import {getName, registerLocale} from "i18n-iso-countries";
import fr_countries from "i18n-iso-countries/langs/fr.json";
import en_countries from "i18n-iso-countries/langs/en.json";
import de_countries from "i18n-iso-countries/langs/de.json";
import es_countries from "i18n-iso-countries/langs/es.json";
// Solution if the same names of functions from diff packages
import {getName as lgGetName, registerLocale as lgRegisterLocale} from "@cospired/i18n-iso-languages";
import fr_languages from "@cospired/i18n-iso-languages/langs/fr.json";
import en_languages from "@cospired/i18n-iso-languages/langs/en.json";
import de_languages from "@cospired/i18n-iso-languages/langs/de.json";
import es_languages from "@cospired/i18n-iso-languages/langs/es.json";
import useMediaQuery from "@mui/material/useMediaQuery";
import {LogoAzameo, LogoFacebook, LogoGoogleAds, LogoMicrosoftAds} from "../components/logo/Logo";
import {methodType, siteBackendFetch} from "../utils/backendHelper";

export const useSiteId = () => {
    return useSelector(selectSiteId);
}

export const useSiteLoaded = () => {
    return useSelector(selectSiteLoaded);
}

export const useSiteListLoaded = () => {
    return useSelector(selectSiteListLoaded);
}

export const useSite = () => {
    const site_id = useSiteId();
    const site_loaded = useSiteLoaded();
    const site_list_loaded = useSiteListLoaded();

    const {item: site, loading: loadingSiteDb} = useDbItem(RDB_SITE, site_id);

    const loaded = useMemo(() => {
        return site_loaded && site_list_loaded && (!site_id || !loadingSiteDb);
    }, [site_loaded, site_list_loaded, site_id, loadingSiteDb])

    return {site_id, loaded, site}
}

export const useCustomerId = () => {
    const site_id = useSiteId();
    const {item: siteRDB, loading: loadingSiteDb} = useDbItem(RDB_SITE, site_id);

    if (loadingSiteDb) {
        return null;
    }
    return siteRDB?.customer_id
}

export const useScreenSize = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const isNotLargeScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    const isMediumScreen = useMemo(() => !isSmallScreen && isNotLargeScreen, [isSmallScreen, isNotLargeScreen]);

    const isLargeScreen = useMemo(() => !isSmallScreen && !isNotLargeScreen,
        [isSmallScreen, isNotLargeScreen]);

    return {isSmallScreen, isMediumScreen, isLargeScreen};
}

const createUser = (email) => {
    let now = new Date().getTime();
    return {
        firstConnexion: now,
        lastConnexion: now,
        lastPing: now,
        email,
        recent: [], // for IDE
        site_id: null, // for IDE
    };
};

export const useUser = () => {
    const auth = useMemo(() => getAuth(firebaseApp), []);
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const redirect = encodeURI(`?redirect=${location.pathname}${location.search}`);
    const userInfos = useSelector(selectUserInfos);
    const dispatch = useDispatch();
    const site_id = useSiteId();

    useEffect(() => {
        if (loading || user === null) {
            return;
        }
        const userRef = ref(db, RDB_USER + user.uid);
        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                // user exist, just update the last Connexion
                let now = new Date().getTime();
                update(userRef, {lastConnexion: now, lastPing: now, email: user.email});
            } else {
                //user doesn't exist, create it
                set(userRef, createUser(user.email));
            }
        }).catch(error => {
            console.log(error)
        });
    }, [user, loading]);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (user === null && ((site_id !== null && site_id !== "") || userInfos !== {})) {
            // Older data: Clear the store
            dispatch({type: ACTION_RESET_STATE, payload: null});
        }
        if (user === null && location.pathname !== PATH_LOGIN && location.pathname !== PATH_REGISTER) {
            navigate({pathname: PATH_LOGIN, search: redirect});
        }
    }, [navigate, user, loading, redirect, location, site_id, dispatch, userInfos]);

    useEffect(() => {

        if (user === null || loading) {
            return;
        }

        const userInfo = {
            name: user?.email,
            photoURL: user?.photoURL
        }

        user?.providerData.forEach((profile) => {
            if (profile.photoURL) {
                userInfo.photoURL = profile.photoURL;
            }
            if (profile.displayName) {
                userInfo.name = profile.displayName;
            }
        })
        dispatch(setUserInfos(userInfo));
    }, [user, loading, dispatch]);

    return {user, loading, userInfos}
}


export const useFormat = () => {
    const {t, i18n} = useTranslation();

    const site_id = useSiteId()
    const {item: siteRDB, loading: loadingSiteDb} = useDbItem(RDB_SITE, site_id);
    const {item: customerRDB, loading: loadingCustDb} = useDbItem(RDB_CUSTOMER, siteRDB?.customer_id);

    const lastInvoiceId = useMemo(() => {
        if (loadingSiteDb || loadingCustDb) {
            return null;
        }
        return customerRDB?.last_invoice_id ?? null;
    }, [loadingSiteDb, loadingCustDb, customerRDB])

    const currencyInit = useMemo(() => {
        if (loadingSiteDb || loadingCustDb) {
            return "EUR";
        }
        return customerRDB?.balance?.currency ?? customerRDB?.data?.currency ?? "";

    }, [loadingSiteDb, loadingCustDb, customerRDB])

    const formatMoney = useCallback((value, currency = null) => {
        return t("credit.value",
            {
                val: isNaN(value) ? 0 : value,
                formatParams: {
                    val: {currency: currency ?? currencyInit,}
                }
            });
    }, [t, currencyInit]);

    const moneySymbol = (currencycode) => {
        return Intl.NumberFormat(i18n.language, {
            style: "currency",
            currency: currencycode ?? currencyInit
        }).formatToParts(0).find(part => part.type === "currency").value;
    }

    useEffect(() => {
        switch (i18n.resolvedLanguage) {
            case "fr":
                registerLocale(fr_countries);
                lgRegisterLocale(fr_languages);
                break;
            case 'es':
                registerLocale(es_countries);
                lgRegisterLocale(es_languages);
                break;
            case "de":
                registerLocale(de_countries);
                lgRegisterLocale(de_languages);
                break;
            case "en":
            default:
                registerLocale(en_countries);
                lgRegisterLocale(en_languages);
                break;
        }

    }, [i18n.resolvedLanguage])

    const formatCountry = useCallback((countryCode) => {
        return getName(countryCode, i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);

    const formatLanguage = useCallback((languageCode) => {
        return lgGetName(languageCode, i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);

    const formatDate = useCallback((date, monthFormat = 'long') => {
        return t("date.format", {
            date: date,
            formatParams: {
                date: {year: "numeric", month: monthFormat, day: "numeric"}
            }
        });
    }, [t]);

    const formatDateTime = useCallback((date) => {
        return t("date.format", {
            date: date,
            formatParams: {
                date: {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }
            }
        });
    }, [t]);

    const formatNumber = useCallback((value) => {
        return t("numbers.integer",
            {
                val: value
            }
        )
    }, [t]);

    const formatFloat = useCallback((value) => {
        return t("numbers.float",
            {
                val: value,
                formatParams: {
                    val: {maximumFractionDigits: 2}
                }
            }
        )
    }, [t]);

    const formatPercent = useCallback((value, maxFraction = 2) => {
        return t("numbers.percent",
            {
                val: value,
                formatParams: {
                    val: {
                        maximumFractionDigits: maxFraction,
                        style: 'percent'
                    }
                }
            }
        )
    }, [t]);

    const formatNetworks = useCallback((networks) => {
        return (
            networks.map((network, index) => {
                switch (network) {
                    case "Facebook":
                        return <LogoFacebook key={index}/>;
                    case "GoogleAds":
                        return <LogoGoogleAds key={index}/>;
                    case "MicrosoftAds":
                        return <LogoMicrosoftAds key={index}/>;
                    case "AppNexus":
                    case "Azameo":
                    default:
                        return <LogoAzameo key={index}/>;
                }
            })
        )
    }, []);

    const formatDict = useMemo(() => {
        return {
            formatNumber: formatNumber,
            formatFloat: formatFloat,
            formatPercent: formatPercent,
            formatMoney: formatMoney,
            formatDateTime: formatDateTime,
            formatDate: formatDate,
            formatNetworks: formatNetworks,
            formatWild: (data) => data,
        }
    }, [formatNumber, formatFloat, formatPercent, formatMoney, formatDateTime, formatDate, formatNetworks]);

    const getFormatter = useCallback((format) => {
        return formatDict[format] ?? formatDict.formatWild
    }, [formatDict]);

    const formattedData = useCallback((data, format, params = []) => {

        const formatter = getFormatter(format);

        if ((format === 'formatNumber'
                || format === 'formatFloat'
                || format === 'formatPercent'
                || format === 'formatMoney')
            && !isFinite(data)
        ) {
            return "-";
        } else {
            return formatter(data, ...params);
        }

    }, [getFormatter]);

    return {
        formattedData,
        getFormatter,
        formatDict,
        formatMoney,
        moneySymbol,
        formatCountry,
        formatLanguage,
        formatDate,
        formatDateTime,
        formatNumber,
        formatFloat,
        formatPercent,
        lastInvoiceId
    }
}


export const useAsyncStatus = (taskId) => {
    const {item, loading, error} = useDbItem(RDB_ASYNC, taskId);
    const status = useMemo(() => {
        if (error) {
            console.log(taskId, loading, item, error);
        }

        // console.log("useAsyncTaskCall:status", taskId, loading, item, error)

        if (taskId === null) {
            return "success";
        }

        if (loading) {
            return "pending";
        }

        if (item) {
            return item?.status
        }
        return "pending";

    }, [error, item, loading, taskId]);

    return {status}
}
export const useAsyncTaskCall = () => {

    const [pendingRest, setPendingRest] = useState(false);
    const [response, setResponse] = useState(null);
    const [taskid, setTaskid] = useState(null);
    const {item, loading, error} = useDbItem(RDB_ASYNC, taskid);

    const {user} = useUser();
    const site_id = useSiteId();

    const status = useMemo(() => {
        if (error) {
            console.log(taskid, loading, item, error);
        }

        console.log("useAsyncTaskCall:status", taskid, loading, item, error, pendingRest)

        if (pendingRest) {
            return "pending";
        }

        if (taskid === null) {
            return "success";
        }

        if (loading) {
            return "pending";
        }

        if (item) {
            if (item?.status !== "pending") {
                setResponse(item?.response);
            }
            return item?.status
        }
        return "pending";

    }, [taskid, item, loading, error, setResponse, pendingRest]);

    const pending = useMemo(() => {
        return status === "pending";

    }, [status])

    const siteAsyncTask = useCallback(({path, queryParams, data, method}) => {

        if (!user) {
            console.log("entering siteAsyncTask", "no user");
            return
        }

        console.log("entering siteAsyncTask", user, site_id, path, queryParams, data, method);

        setPendingRest(true);
        setTaskid(null);
        siteBackendFetch({
            user: user,
            site_id: site_id,
            path: path,
            queryParams: queryParams,
            method: method ?? methodType.GET,
            data: data,
        })
            .then(res => {
                console.log(res);
                setTaskid(res.async?.id)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setPendingRest(false);
            });

    }, [setPendingRest, setTaskid, user, site_id]);

    return {siteAsyncTask, response, pending, status}
}
