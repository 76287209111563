import {Link} from "react-router-dom";

import {styled} from "@mui/material";

import {AzaDrawer} from "../../components/mui/AzaDrawer";
import DrawerList from './DrawerList';
import {AzaFabButton, AzaIconButton} from "../../components/mui/AzaButton";
import React from "react";
import {useTranslation} from "react-i18next";
import {AzaBox} from "../../components/mui/AzaBox";
import {AzaAdd} from "../../components/mui/AzaIcons";
import {useScreenSize} from "../../app/globalHooks";
import {LogoAzameo} from "../../components/logo/Logo";
import {PATH_CAMPAIGN_NEW, PATH_HOME} from "../../utils/constant";
import {SiteSelector} from "../../widgets/siteSelector/SiteSelector";

const DrawerHeader = styled('div', {
    shouldForwardProp: (prop) => prop !== 'open'
})(({theme, open}) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: open ? 'flex-start' : 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        // [theme.breakpoints.down('tablet')]: {
        [theme.breakpoints.down('sm')]: {
            minHeight: '25px',
        },
    }
});

const SideBar = ({open, handleDrawerHover, handleClose, overlapHelp, mobileMenuClose}) => {
    const {t} = useTranslation();
    const {isSmallScreen} = useScreenSize();

    return (
        <>
            <AzaDrawer
                variant={isSmallScreen ? `temporary` : `permanent`}
                open={open}
                onMouseOver={handleDrawerHover}
                onClose={isSmallScreen ? mobileMenuClose : handleClose}
                onMouseLeave={handleClose}
                sx={{
                    position: overlapHelp ? 'absolute' : 'relative',
                }}
            >
                <DrawerHeader open={open}>
                    <AzaIconButton sx={{width: 'auto'}}>
                        <Link style={{textDecoration: 'none', color: 'inherit'}} to={PATH_HOME}>
                            {open ? <AzaBox sx={{display: 'flex', alignItems: 'center'}}><LogoAzameo style={{height:"30px"}}/> AZAMEO</AzaBox> : <LogoAzameo style={{height:"30px"}}/>}
                        </Link>
                    </AzaIconButton>
                </DrawerHeader>
                {isSmallScreen && <SiteSelector isSmallScreen={isSmallScreen}/>}
                {!isSmallScreen &&
                    <AzaBox sx={{
                        display: "flex",
                        justifyContent: open ? "initial" : "center",
                    }}>
                        <Link style={{textDecoration: 'none', color: 'inherit'}} to={PATH_CAMPAIGN_NEW}>
                            <AzaFabButton
                                variant={'extended'}
                                sx={{
                                    mt: '15px',
                                    mb: '15px',
                                    ml: open ? '15px' : '0',
                                    padding: open ? '16px' : '0',
                                    minWidth: '48px'
                                }}
                            >
                                <AzaAdd/>
                                {open && t('sidebar.title.newcampaign')}
                            </AzaFabButton>
                        </Link>
                    </AzaBox>
                }
                <div onClick={isSmallScreen ? mobileMenuClose : null}>
                    <DrawerList open={open}/>
                </div>
            </AzaDrawer>
        </>
    )
}

export default SideBar;
