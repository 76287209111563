import {useUser} from "../../app/globalHooks";
import {useDbItem} from "../../utils/rdbHooks";
import {RDB_USER, RDB_USERRIGHTS} from "../../utils/constant";
import {useCallback, useMemo} from "react";
import {phrase_ice} from "../../app/i18n";

export const useTranslationMode = () => {
    const {user, loading: loadingUser} = useUser()
    const {item: userDB, loadingDb, updateField} = useDbItem(RDB_USER, user?.uid)
    const {item: userRights, loadingRights} = useDbItem(RDB_USERRIGHTS, user?.uid)

    const canTranslate = useMemo(() => {
        return !!(userRights?.data?.global?.["can_translate"])
    }, [userRights])

    const translating = useMemo(() => {
        if (loadingUser || loadingDb || loadingRights) return false;
        // force cast to bool
        if (!!(userDB?.translating) !== phrase_ice.phraseEnabled){
            phrase_ice.phraseEnabled = !!(userDB?.translating);
        }
        return !!(userDB?.translating)
    }, [loadingUser, loadingDb, loadingRights, userDB?.translating])

    const toggleTranslationMode = useCallback(() => {
        updateField("translating", !translating)
        phrase_ice.phraseEnabled = !translating;
    }, [translating, updateField])

    return {canTranslate, translating, toggleTranslationMode}
}

