import {useTranslation} from "react-i18next";
import {useSiteId, useUser} from "../../app/globalHooks";
import {useEffect, useState} from "react";
import {backendFetch} from "../../utils/backendHelper";
import {API_SITE_DATA} from "../../utils/constant";
import {AzaGrid} from "../../components/mui/AzaGrid";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaSwitch} from "../../components/mui/AzaSwitch";
import {AzaButton} from "../../components/mui/AzaButton";
import {AzaDialog, AzaDialogContent, AzaDialogTitle} from "../../components/mui/AzaDialog";

export const AdminSiteSettings = () => {
    const {t} = useTranslation();
    const site_id = useSiteId();
    const {user, loading: loadingUser} = useUser()
    const [siteData, setSiteData] = useState({})

    useEffect(() => {
        if (!user || loadingUser || !site_id) return;
        backendFetch({path: API_SITE_DATA, user, queryParams: {site_id}})
            .then(json_data => {
                // Store the api result in the local state
                setSiteData(json_data)
            })
    }, [loadingUser, site_id, user]);


    return <>
        Not implemented yet
        <SettingBanned
            setSiteData={() => {
            }}

            siteData={siteData}
        />
        {/*<pre>{JSON.stringify(siteData, null, 2)}</pre>*/}
    </>
}

const SettingSwitch = ({label, value, setValue}) => {

    return (
        <AzaGrid
            container
            direction={"row"}
            spacing={2}
            alignItems={"center"}
        >
            <AzaGrid item>
                <AzaTypography>{label}</AzaTypography>
            </AzaGrid>
            <AzaGrid item>
                <AzaSwitch defaultChecked={value}/>
            </AzaGrid>
        </AzaGrid>

    )
}

const SettingActionToggle = ({label, value, setValue}) => {

    return (
        <AzaGrid
            container
            direction={"row"}
            spacing={2}
            alignItems={"center"}
        >
            <AzaGrid item>
                <AzaTypography>{label}</AzaTypography>
            </AzaGrid>
            <AzaGrid item>
                <AzaSwitch defaultChecked={value}/>
            </AzaGrid>
        </AzaGrid>

    )
}

const SettingBanned = ({siteData, setSiteData}) => {
    const {isBanned, setIsBanned} = useState(!!(siteData?.["is_banned"]))

    return (
        <SettingSwitch
            label={"Banned"}
            value={isBanned}
            setValue={setIsBanned}
        />
    )
}
