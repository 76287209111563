import {useTheme} from "@mui/material";
import {AzaBox} from "../../components/mui/AzaBox";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import AzaSettingsBox from "../../components/azameo/AzaSettingsBox";
import {useThemeMode} from "../../app/useThemeMode";
import {AzaSwitch} from "../../components/mui/AzaSwitch";
import {AzaFormControlLabel} from "../../components/mui/AzaFormControlLabel";

const ColorSquare = ({ color }) => {
    return <AzaBox sx={{
        width: '30px',
        height: '100%',
        backgroundColor: color
    }} />;
};

const ColorLine = ({ colorClass, colorLevel  }) => {

    const theme = useTheme();
    const color = theme.palette[colorClass][colorLevel];

    return <AzaBox sx={{
        width: '100%',
        height: '100%',
    }}>
        <AzaGridContainer>
            <AzaGridItem>
                <ColorSquare color={color} />
            </AzaGridItem>
            <AzaGridItem>
                <AzaTypography variant={"h4"}>{`palette.${colorClass}.${colorLevel}`}</AzaTypography>
                <AzaTypography variant={"body"}>{color}</AzaTypography>
            </AzaGridItem>
        </AzaGridContainer>
    </AzaBox>;
}

const ColorsColumn = ({ colorClass }) => {
    const colorLevel = ["lighter", "light", "main", "dark", "darker", "contrastText"];

    return (
        <AzaGridContainer direction={"column"} spacing={0} sx={{
            width:"360px",
        }}>
            {colorLevel.map((level) => (
                <AzaGridItem key={level} sx={{
                    width: '100%',
                    // height: '100%',
                }}>
                    <ColorLine colorClass={colorClass} colorLevel={level}/>
                </AzaGridItem>
            )
            )}
        </AzaGridContainer>
    )
}

const ColorsRow = () => {
    const colorClass = ["primary", "secondary", "error", "warning", "info", "success"];

    return (
        <AzaGridContainer direction = {"row"}>
            {
                colorClass.map((color) => (
                    <AzaGridItem key={color}>
                        <ColorsColumn colorClass={color}/>
                    </AzaGridItem>
                ))
            }
        </AzaGridContainer>
    )
}

export const SampleColors = ({title}) => {
    const {themeMode, toggleThemeMode} = useThemeMode();
    return (
        <AzaSettingsBox
            title={{name:title}}
        >
                <AzaTypography variant={"h5"}>Sample of colors set for Azameo</AzaTypography>
                <p>All the color used in the application should be picked up here</p>
                <AzaFormControlLabel
                    control={
                        <AzaSwitch
                            checked={themeMode === "dark"}
                            onChange={toggleThemeMode}
                        />
                    }
                    label={`Mode is ${themeMode}`}
                />
                <ColorsRow/>
        </AzaSettingsBox>
    );
}




