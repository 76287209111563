import React from 'react';
import AzaLink from "../../../../components/mui/AzaLink";
import {PictureAsPdf} from "@mui/icons-material";
import {BlobProvider} from "@react-pdf/renderer";

const PdfToShow = (props) => {
    const {template, elementData} = props;
    return (
        <BlobProvider document={template(elementData)}>
            {({ blob, url, loading, error }) => {
                return (
                    <AzaLink href={url} target={'_blank'}>
                        <PictureAsPdf/>
                    </AzaLink>
                )
            }}
        </BlobProvider>
    );
};

export default PdfToShow;