import React from 'react';
import Box from "@mui/material/Box";
import {PDFDownloadLink} from "@react-pdf/renderer";

const PdfToDownload = (props) => {
    const {selectedInvoices, template} = props;

    return (
        selectedInvoices.length > 0 &&
        <Box sx={{display: 'none', opacity: 0, width: 0}}>
            {selectedInvoices.map((el) => {
                return (
                    <PDFDownloadLink
                        key={el.invoice}
                        className={`${el.invoice}`}
                        document={template(el)}
                        fileName={"Invoice"}
                    >
                        DOWNLOAD
                    </PDFDownloadLink>)
            })}
        </Box>
    )
};

export default PdfToDownload;