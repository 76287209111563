import {
    API_GET_SITE_RENEWAL_STOP,
    API_GET_SITE_SETTINGS,
    BACKEND_URL,
    API_GET_INVOICES_DATA,
    API_GET_CAMPAGN_NAMES, API_POST_CAMPAGN_CREATE
} from "./constant";

const addFirebaseAuthorization = async (headers, user) => {
    if (user) {
        // const idToken = await user.getIdToken();
        const idToken = user.accessToken; // works the same but no async call
        headers.Authorization = `firebase ${idToken}`
    }
}

const buildPath = (host, path, queryParams) => {
    const query = [];
    for (const queryParam in queryParams) {
        query.push(`${queryParam}=${queryParams[queryParam]}`)
    }
    return (query.length > 0) ? `${host}${path}?${query.join("&")}` : `${host}${path}`;
}

// string enum makes it easier to debug
export const dataType = {
    json: 'json',
    form: 'form'
}

export const formatType = {
    json: 'application/json',
}

export const methodType = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
    HEAD: 'HEAD',
}

export const noBodyMethodType = [
    methodType.HEAD,
    methodType.GET,
]

export const backendFetchPromise = async ({
                                              user,
                                              path,
                                              queryParams = {},
                                              data = {},
                                              method = methodType.GET,
                                              type = dataType.json,
                                              format = formatType.json,
                                              headers = {}
                                          }) => {

    const host = BACKEND_URL;

    let fetchHeaders = {
        'Accept': format,
    }

    fetchHeaders = {...fetchHeaders, ...headers}

    if (user) {
        await addFirebaseAuthorization(fetchHeaders, user)
    }

    const parameters = {
        method: method,
        headers: fetchHeaders,
    }

    // Only HEAD and GET can't have a body
    if (!noBodyMethodType.includes(method)) {
        switch (type) {
            case dataType.form:
                // The browser files up the Content Type for us
                // parameters.headers["Content-Type"] = 'multipart/form-data'
                let formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                parameters.body = formData
                break;
            case dataType.json:
            default:
                parameters.headers["Content-Type"] = 'application/json'
                parameters.body = JSON.stringify(data)
                break;
        }
    }


    const built_path = buildPath(host, path, queryParams)

    return fetch(built_path, parameters)
}

export const backendFetch = async ({
                                       user,
                                       path,
                                       queryParams = {},
                                       data = {},
                                       method = methodType.GET,
                                       type = dataType.json,
                                       format = formatType.json,
                                       headers = {}
                                   }) => {

    return await backendFetchPromise({
        user,
        path,
        queryParams,
        data,
        method,
        type,
        format,
        headers
    }).then(res => res.json())
}

export const backendGet = async (user = null, path, queryParams = {}) => {

    // Deprecated but here for sample use

    const params = {
        path: path,
        method: methodType.GET,
        format: formatType.json,
        user: user,
        queryParams: queryParams
    }

    return await backendFetch(params)
}


export const backendPost = async (user, path, queryParams, data, type = dataType.json) => {

    // Deprecated but here for sample use

    const params = {
        path: path,
        method: methodType.POST,
        format: formatType.json,
        user: user,
        queryParams: queryParams,
        type: type,
        data: data,
    }
    return await backendFetch(params)
}

export const azameo_log_event = (type, log) => {
    backendPost('/api/monitoring', {}, {type: type, log: JSON.stringify(log)})
}

export const siteBackendFetch = async ({
                                    user,
                                    site_id,
                                    path,
                                    queryParams = {},
                                    data = {},
                                    method = methodType.GET,
                                    type = dataType.json,
                                    format = formatType.json,
                                    headers = {}
                                }) => {

    return await backendFetch({
        user,
        path,
        queryParams:{site_id:site_id, ...queryParams},
        data,
        method,
        type,
        format,
        headers
    })
}

export const siteBackendFetchRaw = async ({
                                           user,
                                           site_id,
                                           path,
                                           queryParams = {},
                                           data = {},
                                           method = methodType.GET,
                                           type = dataType.json,
                                           format = formatType.json,
                                           headers = {}
                                       }) => {

    return await backendFetchPromise({
        user,
        path,
        queryParams:{site_id:site_id, ...queryParams},
        data,
        method,
        type,
        format,
        headers
    })
        .then(res => res.blob())
        .then((response)=> {
        return URL.createObjectURL(response);
    })
}


export const getSiteSettings = async (user, site_id, params) => {
    return siteBackendFetch({
        user: user,
        site_id: site_id,
        path: API_GET_SITE_SETTINGS,
        method: methodType.Get,
        queryParams: {
            ...params
        }
    })
}


export const stopCustomerRenewalPayment = async (user, site_id) => {
    return siteBackendFetch({
        user: user,
        site_id: site_id,
        path: API_GET_SITE_RENEWAL_STOP,
        method: methodType.Get,
    })
}

export const getInvoicesData = async (user, site_id) => {
    return siteBackendFetch({
        user: user,
        site_id: site_id,
        path: API_GET_INVOICES_DATA,
        method: methodType.GET,
    })
}

export const getCampaignNames = async (user, site_id) => {
    return siteBackendFetch({
        user: user,
        site_id: site_id,
        path: API_GET_CAMPAGN_NAMES,
        method: methodType.GET,
    }).then(json => json["campaign_names"])
}

export const createCampaign = async (user, site_id, data) => {

    /*
        data = {
            params: { json containing the campaign parameters},
            image_file_field: FILE,
        }
        For information the image_file_field is the name of the field in the form that contains the image file and
        is defined in fields  that points to image (such "image" or "logo") contained in the json parameters:
        {
             ...
            "image": "image_file_field",
            "logo": "another_image_file_field",
             ...
         }

         The return of the api is a json - if the campaign is created - containing the campaign info else
    */

    return siteBackendFetch({
        user: user,
        site_id: site_id,
        path: API_POST_CAMPAGN_CREATE,
        method: methodType.POST,
        data: data,
        type: dataType.form,
    }).then( resp => {
        console.log(resp)
        if (JSON.stringify(resp["code"]) === "200") {
            return resp["campaign"]
        } else {
            throw new Error(resp["params"])
        }
    })
}
