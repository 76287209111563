import React from 'react';
import LogIn from "./LogIn";
import Register from "./Register";

const LogInRegisterToggle = ({children,  mode}) => {
    return (
        (mode === "register") ? <Register>{children}</Register> : <LogIn>{children}</LogIn>
    );
};

export default LogInRegisterToggle;