import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import {PHRASE_ACCOUNT_ID, PHRASE_PROJECT_ID, SUPPORTED_LANGUAGES} from "../utils/constant";

import {registerLocale} from "i18n-iso-countries/index.js";
import fr_countries from "i18n-iso-countries/langs/fr.json";
import en_countries from "i18n-iso-countries/langs/en.json";
import es_countries from "i18n-iso-countries/langs/es.json";

registerLocale(fr_countries);
registerLocale(es_countries);
registerLocale(en_countries);

export const phrase_ice = new PhraseInContextEditorPostProcessor({
    phraseEnabled: false,
    fullReparse: true,
    projectId: PHRASE_PROJECT_ID,
    accountId: PHRASE_ACCOUNT_ID,
});


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(phrase_ice)
    .init({
        debug: false,
        fallbackLng: "en",
        supportedLngs: SUPPORTED_LANGUAGES,
        defaultNS: "translation",
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        interpolation: {
            escapeValue: false
        },
        postProcess: ['phraseInContextEditor']
    });

export default i18n;
