import React from 'react';
import {Route, Routes} from "react-router-dom";
import {AuthenticatedPages} from "./pages/authenticated/AuthenticatedPages";
import {OpenPages} from "./pages/open/OpenPages";
import {LocalizationProvider} from "@mui/lab";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {PATH_LOGIN, PATH_REGISTER} from "./utils/constant";
import {AzameoTag} from "./features/azameoTag/AzameoTag";
import {store} from "./app/store";

import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {ThemeCustomization} from "./themes";
import {FileStoreProvider} from "./features/fileStoreContext";

function App() {

    const {i18n} = useTranslation();

    return (
        <Provider store={store}>
            <FileStoreProvider>
                <BrowserRouter>
                    <ThemeCustomization>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
                            <AzameoTag/>
                            <Routes>
                                <Route path={`${PATH_LOGIN}/*`} element={<OpenPages mode={"login"}/>}/>
                                <Route path={`${PATH_REGISTER}/*`} element={<OpenPages mode={"register"}/>}/>
                                <Route path={`*`} element={<AuthenticatedPages/>}/>
                            </Routes>
                        </LocalizationProvider>
                    </ThemeCustomization>
                </BrowserRouter>
            </FileStoreProvider>
        </Provider>
    );
}

export default App;
