import React from 'react';

import {AzaDialog} from "../../components/mui/AzaDialog";
import Payment from "./Payment";

const PaymentPopUp = ({open, handleClose, renewal, status, reentry, code}) => {
    return (
        <AzaDialog open={open} onClose={handleClose} popupWidth={'lg'} >
            <Payment
                handleClose={handleClose}
                renewal={renewal}
                status={status}
                code={code}
                reentry={reentry}
            />
        </AzaDialog>
    );
};

export default PaymentPopUp;
