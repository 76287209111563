import {
    Autocomplete,
    Box,
    CircularProgress,
    FormControl,
    IconButton,
    Link,
    styled,
    TextField,
    Typography
} from "@mui/material";
import React, {Children, cloneElement, useCallback, useState} from "react";
import {OpenInNew, Visibility} from "@mui/icons-material";

const SelectorInsideBox = styled((props) => {
    const {children, ...other} = props;
    return <Box {...other}>{children}</Box>

})(({theme}) => ({
    marginTop: "12px"
}));

const addIconsToEndAdornment = (endAdornment, siteUrl, active, selectorFromHeader) => {
    const AutocompleteAdditionalIcons = [
        <Visibility sx={{
            color: active ? 'green' : 'inherit',
        }}/>,
        <Link href={siteUrl} color="inherit" underline="none" sx={{height: '24px'}} target="_blank">
            <OpenInNew/>
        </Link>
    ]
    const children = Children.toArray(endAdornment.props.children);
    if (!selectorFromHeader || !siteUrl) {
        return cloneElement(endAdornment, {}, children);
    }
    children.unshift(
        AutocompleteAdditionalIcons.map((el, index) => {
            return (<IconButton key={index} sx={{paddingTop: '2px', paddingBottom: '2px'}}>
                {el}
            </IconButton>);
        })
    );
    return cloneElement(endAdornment, {}, children);
}

export const AzaSelector = ({
                                proposedList,
                                concatenateId,
                                loading,
                                value,
                                disabled,
                                label,
                                onChange,
                                additionalContent,
                                displayAdditionalContent,
                                additionalContentInvalid,
                                additionalContentEmpty,
                                classNames,
                                selectorFromHeader = false
                            }) => {

    /**
     *
     * This is used to select from a list of object:
     *  each object must at least contain the following data:
     *   - id
     *   - valid (boolean): (if set to false the object will be marked as red)
     *   - label Name of the option
     *
     *  Special case for the object where the id === -1:
     *    - Separate the item in a different group from other objects
     *
     *  optional values:
     *   - error (list): combined with value to false) display below thew selector the reasons why the item is not valid the error is a key a will be selected from the additionalContentInvalid
     *
     *  Other global params (not in the object of the list):
     *
     *  - label: title inside when empty above when something
     *  - concatenateId: in choice display the id if true
     *  - value: the current selected object
     *  - loading: if the proposed list is loading asynchronously display a rotating loading
     *  - onChange: callback to handle when the user is choosing an item
     *  - disabled: to set in readonly
     *
     *  - additionalContent (node): optional content to display below the selector if no error
     *  - displayAdditionalContent (boolean): if set to true display the additionalContent
     *  - additionalContentInvalid is the object is not valid (the table of nodes to choose the error display)
     *  - additionalContentEmpty: what to display if the proposedList is empty
     *
     */
    const [siteUrl, setSiteUrl] = useState(''); // by default, we have to put url of the site owner (who was signed in)
    const [isClientActive, setIsClientActive] = useState('');
    const [selected, setSelected] = useState('');
    const buildData = useCallback(() => {
        if (!proposedList) {
            return []
        } else {
            let newList = proposedList.map((element) => {
                return {'label': element.name, ...element}
            })
            let has_creation = false;
            newList = newList.sort((a, b) => {
                if (a.id === -1) {
                    has_creation = true
                    return -1;
                }
                if (b.id === -1) {
                    has_creation = true;
                    return 1;
                }
                if (a.valid !== b.valid) {
                    if (a.valid === true) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            })
            if (has_creation) {
                newList = newList.map((element) => {
                    return {'group': (element.id === -1) ? 'Create' : 'Use existing', ...element}
                })
            }
            return newList
        }
    }, [proposedList]);

    const handleChange = (event, newValue) => {
        setSiteUrl(newValue ? newValue.siteUrl : '');
        setIsClientActive(newValue ? newValue.isActive : '');
        setSelected(newValue);
        // onChange(newValue);
    }

    const display_additional_content = useCallback(() => {

        if (additionalContentEmpty && !loading && (!proposedList || proposedList.length === 0)) {
            return <SelectorInsideBox>{additionalContentEmpty}</SelectorInsideBox>
        }
        if (value && !value.valid && value.error && additionalContentInvalid) {
            return <>{additionalContentInvalid
                .filter((element) => value.error.includes(element.name))
                .map((element) => <SelectorInsideBox key={`${element.key}`}>{element.react}</SelectorInsideBox>)}</>
        } else {
            if (!displayAdditionalContent) {
                return <></>
            } else {
                return <SelectorInsideBox>{additionalContent}</SelectorInsideBox>
            }
        }


    }, [value, proposedList, loading, displayAdditionalContent,
        additionalContent, additionalContentInvalid, additionalContentEmpty])

    const renderSelected = useCallback((props, option, state) => {
        let style = null;
        let text = option.label;
        if (!option.valid) {
            style = {color: "red"}
        }
        if (option.id === -1) {
            style = {fontWeight: "bold"}
        } else {
            if (concatenateId) {
                text += ' : ' + option.id
            }
        }
        // return <Typography {...props}  style={style} key={option.id} sx={{display:'flex', justifyContent: 'space-between'}}>{text}<Visibility/></Typography>
        return <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><Typography {...props}
                                                                                                              style={style}
                                                                                                              key={option.id}
                                                                                                              sx={{flexGrow: '2'}}>{text}</Typography><Visibility
            sx={{marginRight: '15px'}}/></Box>
    }, [concatenateId])

    const grouping = useCallback(() => {
            const computedList = buildData()
            if (computedList.reduce((a, b) => {
                return a || (b.group !== undefined)
            }, false)) {
                return ((option) => option.group)
            }
            return null;
        },
        [buildData]
    );

    return (
        <FormControl sx={{width: "100%"}} className={classNames}>
            <Autocomplete
                options={buildData()}
                loading={loading}
                loadingText={<CircularProgress/>}
                getOptionLabel={(option) => {
                    return (option ? option.label : "")
                }}
                renderOption={renderSelected}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value.id
                }}
                // value={(value?.id)? value: null}
                // value={(selected?.name) ? selected?.name : null}
                onChange={handleChange}
                groupBy={grouping()}
                disabled={disabled}
                // popupIcon={<ImportContacts />}
                renderInput={(params) => <TextField
                    {...params}
                    size={"small"}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: addIconsToEndAdornment(params.InputProps.endAdornment, siteUrl, isClientActive, selectorFromHeader)
                    }}
                />}
            />
            {display_additional_content()}
        </FormControl>
    )
}

