// Backend url,depends on the environment variable
export const DEBUG = process.env.NODE_ENV === "development";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? "https://api.azame.net";

export const ACTION_RESET_STATE = "global/reset"

export const SUPPORTED_LANGUAGES = ["en", "fr",];
export const SUPPORTED_CURRENCY = ["EUR", "USD", "GBP", "CAD",];
export const SUPPORTED_LANGUAGES_NAMES = {"en": "English", "fr": "Français",};
export const DEFAULT_LANGUAGE = "en";
export const PHRASE_ENABLED = true;
export const PHRASE_PROJECT_ID = "82ede7507b30f89bd7a96589b1f3e1ca";
export const PHRASE_ACCOUNT_ID = "6f20f364d2fb0390ac7196185c00f979";

export const RDB_USER = "users/";
export const RDB_ASYNC = "async/";
export const RDB_USERRIGHTS = "userrights/";
export const RDB_SITE = "sites/";
export const RDB_CUSTOMER = "customers/";
export const RDB_CUSTOMIZATION = "customization/";
export const RDB_CHAT = "chat/sites/";


// The default period length in the dashboard
export const DEFAULT_PERIOD = 30;
// The number of site to store the recent site list per user
export const RECENT_NUMBER = 5
// The number of seconds before a site is considered disconnected
export const PING_TIMEOUT = 60
export const PING_PERIOD = 15

// Query params keys:
export const QUERY_PARAM_SITE_ID = "site_id";
export const QUERY_PARAM_FORCE_ADMIN = "admin";

export const QUERY_PARAM_PAYMENT_REENTRY = "returning";
export const QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_INTENT = "payment_intent";
export const QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_CLIENT_SECRET = "payment_intent_client_secret";
export const QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_STATUS = "redirect_status";

// Paths
export const PATH_LOGIN = "/login";
export const PATH_REGISTER = "/register";

export const PATH_HOME = "/";
export const PATH_OVERVIEW = "/overview";
export const PATH_CAMPAIGNS = '/my-campaigns';
export const PATH_CAMPAIGN_NEW = '/new-campaign';
export const PATH_EDIT_CAMPAIGN = '/edit-campaign';
export const PATH_INTEGRATION = '/integration';
export const PATH_ADD_ACCOUNT = '/add-account';
export const PATH_CREDITS_SETTINGS = '/settings/credits';
export const PATH_ACCOUNT_SETTINGS = '/settings/account';
export const PATH_SITE_SETTINGS = '/settings/site';
export const PATH_CREDITS = '/credits';
export const PATH_SUBSCRIPTION = '/subscription';
export const PATH_ASSET_EDITION = '/assets-edition';

export const PATH_BACKEND_SETTINGS = '/backend-settings';
export const PATH_SAMPLE_MUI = '/sample/mui/elements';
export const PATH_SAMPLE_MUI_COLOR = '/sample/mui/colors';
export const PATH_OPENAI_GENERATION = '/admin/openai-generation';
export const PATH_CHAT = '/chat';

export const PATH_AI_CAMPAIGN = '/ai-campaign';
export const PATH_SMART5 = '/smart5';

/* ----------
// Backend API urls
---------------- */

// API to get the list of sites accessible for a connected user
export const API_GET_SITES = "/user/site_dict_access"
export const API_POST_ADD_SITE = "/user/site_add"

export const API_SITE_DATA = "/site/data"
// API to check validity or consume promocode
export const API_GET_CHECK_PROMOCODE = "/customer/promocode"
export const API_GET_BILLING_INFO = "/customer/billing_info"
export const API_GET_SITE_SETTINGS = "/site/settings"

// API to get or set the settings for a site by an admin
export const API_GET_SET_SITE_SETTINGS = "/admin/settings"
// API to validate VAT number
export const API_GET_VALIDATE_VAT = "/utils/checkvat"

export const API_POST_PAYPAL_CREATE = "/site/payment/paypal/create"
export const API_POST_PAYPAL_CAPTURE = "/site/payment/paypal/capture"
export const API_POST_PAYPAL_CANCEL = "/site/payment/paypal/cancel"
export const API_POST_STRIPE_ORDER_CREATE = "/site/payment/stripe/single/create"
export const API_POST_STRIPE_ORDER_CANCEL = "/site/payment/stripe/single/cancel"
export const API_GET_STRIPE_ORDER_RESULT = "/site/payment/stripe/single/get"
export const API_POST_STRIPE_ORDER_CAPTURE = "/site/payment/stripe/single/get"
export const API_GET_SITE_RENEWAL_INFO = "/site/payment/renewal/info"
export const API_GET_SITE_RENEWAL_STOP = "/site/payment/renewal/stop"
export const API_GET_INVOICES_DATA = "/customer/invoices"

export const API_GET_SINGLE_INVOICE_DATA = "/customer/invoices/data"
export const API_GET_BRANDROI_CALCULATE = "/site/brandroi/calculate"
// API to modify campaigns
export const API_POST_CAMPAGN_CREATE = "/site/campaign/create"
export const API_GET_CAMPAIGN_TO_EDIT = "/site/campaign/detail"
export const API_POST_MODIFY = "/site/campaign/modify"
export const API_POST_MODIFY_MULTI = "/site/campaign/modify_multi"
export const API_GET_CAMPAGN_NAMES = "/site/campaign/names"

export const API_POST_CHAT = '/chat/chat'

export const API_POST_MESSAGE = '/chat/message'

export const API_GET_MESSAGE_SINCE = '/chat/message/since'


export const API_GET_POST_SITE_LOGO = "/site/logo"

export const API_GOOGLE_ADS_CONNEXION = "/site/googleads/connexion"
export const API_GOOGLE_ADS_LINK = "/site/googleads/link"

// Payment keys
export const paypalInitialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "AbXaZQXp_V9iVHNSTxWRsr3hvNKEj6v4kUtFg5JUcglI5SWgLVzbk6Y8ZfsWxgmCsOcRYxItFrsH44Ij",
};
export const stripeInitialOptions = {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? 'pk_live_gVjRI5elBSlnzqJtyGzKHCLf',
}

export const API_OPENAI_PROMPT = "/ai/prompt"
export const API_OPENAI_FORMAT = "/ai/format"
export const API_OPENAI_RESULT = "/ai/result"
export const API_OPENAI_GENERATION = "/ai/generation"
export const API_OPENAI_COMPETITORS = "/ai/competitors"
export const API_OPENAI_USER_INTEREST = "/ai/user_interest"
// API to check validity or consume promocode
export const API_ASSETS_LIST = "/assets/list"
export const API_ASSETS_GENERATE = "/assets/generate"
export const API_ASSETS_DETAIL = "/assets/detail/<pk>"

export const API_SHORT_PRODUCTS = "/site/shortproducts"

export const API_AI_CAMPAIGN = "/ai/ai-campaign"
export const API_POST_SMART5_REPORT = "/smart5/update"
