import React from "react";

import {Accordion, AccordionDetails, AccordionSummary, styled} from "@mui/material";

export const AzaAccordion = (props) => {
    const {children, ...other} = props;
    return <Accordion {...other}>{children}</Accordion>
}

export const AzaAccordionSummary = styled((props) => {
    /* here better extract 'endDirection' element, if not extracted react will alert an error :
       "React does not recognize the `endDirection` prop on a DOM element..."
     */
    const {endDirection, ...other} = props;
    return (
        <AccordionSummary
            {...other}
        />
    )
})`flex-direction: ${(props) => props.endDirection ? 'row' : 'row-reverse'};
  padding-right: 0;
& .MuiAccordionSummary-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 0 0 8px;
    cursor: auto;
},
& .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 0 0 8px;
}`

export const AzaAccordionDetails = (props) => {
    return <AccordionDetails {...props}/>
}

