const ButtonGroup = () => {
  return {
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true
      }
    }
  };
}

export default ButtonGroup;
