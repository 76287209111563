import {createSlice} from "@reduxjs/toolkit";

const sliceName = "backendsettings"

const subtree = (sidenodes, item, nodeid, json) => {
    if (item.type === "dict") {
        dicttree(sidenodes, item.description, nodeid, json)
    } else if (item.type === "list") {
        listtree(sidenodes, item.description, nodeid, json)
    } else {
        valuetree(sidenodes, item, nodeid, json)
    }
}

const dicttree = (sidenodes, item, nodeid, json) => {
    sidenodes[nodeid] = {help: item.help, type: "dict", nodeid: nodeid}
    for (const [key, value] of Object.entries(item)) {
        subtree(sidenodes, value, nodeid + "." + key, json)
    }
}

const listtree = (sidenodes, item, nodeid, json) => {
    sidenodes[nodeid] = {help: item.help, type: "list", nodeid: nodeid}
    const currents = getJsonValueKey(nodeid, json)
    if (currents === null) {
        subtree(sidenodes, item.description, nodeid + ".0", json)
    } else {
        for (const elem in currents) {
            subtree(sidenodes, currents[elem], nodeid + "." + elem, json)
        }
    }
}

const valuetree = (sidenodes, item, nodeid, json) => {
    sidenodes[nodeid] = {...item, nodeid: nodeid}
}

export const getJsonValueKey = (key, json) => {
    let current = json;
    const keys = key.split(".").slice(1)

    if (!current) return null;
    for (const key of keys) {
        current = current[key]
        if (!current) {
            return null
        }
    }
    return current
}

const getJson = (level, state) => {
    switch (level) {
        case "site":
            return state.site_settings
        case "currency":
            return state.currency_settings
        default:
        case "global":
            return state.global_settings
    }
}

export const backendSettingSlice = createSlice({
    name: `${sliceName}`,
    initialState: {
        nodeids: {'root': {'help': 'root', 'type': 'dict'}},
        current_nodeid: 'root',
        definitions: {},
        global_settings: {},
        currency_settings: {},
        site_settings: {},
        changed: {
            global: {},
            currency: {},
            site: {},
        },
        witherror: {
            global: {},
            currency: {},
            site: {},
        },
        needrefresh: {
            global: false,
            currency: false,
            site: false,
        }
    },
    reducers: {
        addNode: (state, action) => {
            // state.nodeids[action.payload.nodeid] = action.payload.element;
        },
        setCurrentNode: (state, action) => {
            state.current_nodeid = action.payload;
        },
        setDefinitions: (state, action) => {
            dicttree(state.nodeids, action.payload, "root", {})
            state.definitions = action.payload;
        },
        setGlobalSettings: (state, action) => {
            state.global_settings = action.payload;
        },
        setCurrencySettings: (state, action) => {
            state.currency_settings = action.payload;
        },
        setSiteSettings: (state, action) => {
            state.site_settings = action.payload;
        },
        setError: (state, action) => {
            if (action.payload.value) {
                state.witherror[action.payload.level][action.payload.nodeid] = action.payload.value;
            } else {
                if (state.witherror[action.payload.level][action.payload.nodeid]) {
                    delete state.witherror[action.payload.level][action.payload.nodeid];
                }
            }
        },
        setChanged: (state, action) => {
            const json = getJson(action.payload.level, state)
            const currentValue = getJsonValueKey(action.payload.nodeid, json)
            if (currentValue !== action.payload.value) {
                state.changed[action.payload.level][action.payload.nodeid] = action.payload.value
            } else {
                delete state.changed[action.payload.level][action.payload.nodeid]
            }
        },
        clearChanged: (state, action) => {
            state.changed[action.payload] = {}
            state.witherror[action.payload] = {}
        },
        clearOneChanged: (state, action) => {
            if (state.changed[action.payload.level][action.payload.nodeid]) {
                delete state.changed[action.payload.level][action.payload.nodeid];
            }
            if (state.witherror[action.payload.level][action.payload.nodeid]) {
                delete state.witherror[action.payload.level][action.payload.nodeid];
            }
        },
        setNeedRefresh: (state, action) => {
            state.needrefresh[action.payload.level] = action.payload.value;
        }
    }
})

export const {
    addNode,
    setCurrentNode,
    setDefinitions,
    setGlobalSettings,
    setSiteSettings,
    setCurrencySettings,
    setChanged,
    setError,
    clearChanged,
    clearOneChanged,
    setNeedRefresh
} = backendSettingSlice.actions;

export const selectNodesInfo = (state) => {
    return state[`${sliceName}`].nodeids
}

export const selectCurrentNode = (state) => {
    return state[`${sliceName}`].current_nodeid
}

export const selectDefinitions = (state) => {
    return state[`${sliceName}`].definitions
}

export const selectGlobalSettings = (state) => {
    return state[`${sliceName}`].global_settings
}

export const selectCurrencySettings = (state) => {
    return state[`${sliceName}`].currency_settings
}

export const selectSiteSettings = (state) => {
    return state[`${sliceName}`].site_settings
}

export const selectExpandable = (state) => {
    return Object.entries(state[`${sliceName}`].nodeids)
        .filter(([key, value]) => (value.type === 'dict' || value.type === 'list'))
        .map(([key, value]) => key);
}

export const selectHasChanged = (state) => {
    return Object.entries(state[`${sliceName}`].changed).filter(([key, value]) => Object.keys(value).length > 0).length > 0;
}

export const selectHasError = (state) => {
    return Object.entries(state[`${sliceName}`].witherror).filter(([key, value]) => Object.keys(value).length > 0).length > 0;
}

export const selectChanged = (state) => {
    return state[`${sliceName}`].changed;
}

export const selectCurrentValueGlobal = (state) => {
    return getJsonValueKey(selectCurrentNode(state), selectGlobalSettings(state))
}

export const selectCurrentValueCurrency = (state) => {
    return getJsonValueKey(selectCurrentNode(state), selectCurrencySettings(state))
}

export const selectCurrentValueSite = (state) => {
    return getJsonValueKey(selectCurrentNode(state), selectSiteSettings(state))
}

export const selectModifiedValueGlobal = (state) => {
    return state[`${sliceName}`].changed.global[selectCurrentNode(state)] ?? getJsonValueKey(selectCurrentNode(state), selectGlobalSettings(state))
}

export const selectModifiedValueCurrency = (state) => {
    return state[`${sliceName}`].changed.currency[selectCurrentNode(state)] ?? getJsonValueKey(selectCurrentNode(state), selectCurrencySettings(state))
}

export const selectModifiedValueSite = (state) => {
    return state[`${sliceName}`].changed.site[selectCurrentNode(state)] ?? getJsonValueKey(selectCurrentNode(state), selectSiteSettings(state))
}

const updateSettings = (settings, changes) => {
    const newSettings = structuredClone(settings)
    for (const [key, value] of Object.entries(changes)) {
        const keys = key.split(".").slice(1)
        let current = newSettings;
        for (const key of keys.slice(0, -1)) {
            if (!current[key]) {
                current[key] = {}
            }
            current = current[key]
        }
        current[keys.slice(-1)] = value
    }
    return newSettings
}

export const selectUpdatedGlobalSettings = (state) => {
    const settings = state[`${sliceName}`].global_settings;
    const changes = state[`${sliceName}`].changed.global;
    if (Object.keys(changes).length === 0) {
        return settings;
    }
    return updateSettings(settings, changes);
}

export const selectUpdatedCurrencySettings = (state) => {
    const settings = state[`${sliceName}`].currency_settings;
    const changes = state[`${sliceName}`].changed.currency;
    if (Object.keys(changes).length === 0) {
        return settings;
    }
    return updateSettings(settings, changes);
}

export const selectUpdatedSiteSettings = (state) => {
    const settings = state[`${sliceName}`].site_settings;
    const changes = state[`${sliceName}`].changed.site;
    if (Object.keys(changes).length === 0) {
        return settings;
    }
    return updateSettings(settings, changes);
}

export const selectNeedRefreshGlobal = (state) => {
    return state[`${sliceName}`].needrefresh.global;
}

export const selectNeedRefreshCurrency = (state) => {
    return state[`${sliceName}`].needrefresh.currency;
}

export const selectNeedRefreshSite = (state) => {
    return state[`${sliceName}`].needrefresh.site;
}

export const selectWithError = (state) => {
    return state[`${sliceName}`].witherror;
}

