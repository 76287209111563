import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAttribution} from "../../../features/attribution/attributionHooks";
import {useCustomization} from "../../../features/customization/customizationHooks";
import {AdminModeBranch} from "../../../features/adminMode/adminModeComponents";
import {useWidgetDefinitions} from "./widgetDefinitions";
import {AzaGrid} from "../../../components/mui/AzaGrid";
import {WidgetSelector} from "./WidgetSelector";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import AzaSettingsBox from "../../../components/azameo/AzaSettingsBox";
import {useFormat} from "../../../app/globalHooks";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaStack} from "../../../components/mui/AzaStack";

import MantisMainCard from "../../../components/mantis/MantisMainCard";
import {AzaFallOutlined, AzaRiseOutlined} from "../../../components/mui/AzaIcons";
import {AzaChip} from "../../../components/mui/AzaChip";

const AttributionWidget = ({label, data, format}) => {
    const {formattedData} = useFormat();
    const growthResult = data ?  data.current - data.previous: undefined;
    const isGrowing = !(growthResult < 0);

    const formattedCurrentData = formattedData(data?.current, format);
    const formattedGrowthData = formattedData(growthResult, format);
    const formattedPercentageGrowth =  formattedData(( data?.previous &&  data?.previous!==0)?growthResult / data?.previous: 0.0, "formatPercent");
    const color = isGrowing ? (growthResult > 0 ? "success" : "warning") : "error";

    const {t} = useTranslation();

    const indicatorText = useMemo(() => {
        if (color === "success") {
            return t("attributionwidget.indicatorText.growing", {grows: formattedGrowthData})
        } else if (color === "warning") {
            return t("attributionwidget.indicatorText.stable")
        } else {
            return t("attributionwidget.indicatorText.degrowing", {grows: formattedGrowthData})
        }

    }, [color, formattedGrowthData, t]);

    return (
        <AzaBox sx={{ cursor: 'pointer', height: '100%' }}>
            <MantisMainCard sx={{  height: '100%' }}>
                <AzaBox sx={{ p: 2.25 }}>
                    <AzaStack spacing={0.5}>
                        <AzaTypography variant="h6" color="textSecondary">
                            {label}
                        </AzaTypography>
                        <AzaStack direction="row" alignItems="center">
                            <AzaTypography variant="h4" color="inherit">
                                {formattedCurrentData}
                            </AzaTypography>
                            {data?.previous !== undefined && (
                                <AzaChip
                                    variant="combined"
                                    color={color}
                                    icon={
                                        <>
                                            {isGrowing && <AzaRiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                            {!isGrowing && <AzaFallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                        </>
                                    }
                                    label={`${formattedPercentageGrowth}`}
                                    sx={{ ml: 1.25, pl: 1 }}
                                    size="small"
                                />
                            )}
                        </AzaStack>
                    </AzaStack>
                    <AzaBox sx={{ pt: 2.25 }}>
                        <AzaTypography variant="caption" color="textSecondary">
                            {indicatorText}
                        </AzaTypography>
                    </AzaBox>
                </AzaBox>
            </MantisMainCard>
        </AzaBox>
    )
}

export const AttributionWidgets = () => {
    const {widgetData, loaded} = useAttribution();
    const {loading, attributionWidgets} = useCustomization();
    const [attributionWidgetsChanges, setAttributionWidgetsChanges] = useState([]);
    const {t} = useTranslation();
    const {widgetDefinitions} = useWidgetDefinitions();

    useEffect(() => {
        setAttributionWidgetsChanges(Object.values(widgetDefinitions).filter((widget)=>
        {
            return attributionWidgets.includes(widget.field)
        }));
    }, [attributionWidgets, setAttributionWidgetsChanges, widgetDefinitions]);

    const statisticComponents = useMemo(() => {
        return (
            <AzaSettingsBox
            title={{
                name: t('attribution.widgets.title')
            }}
        >
            <AzaBox>
                <WidgetSelector/>
                <AzaGrid container spacing={2}>
                    {attributionWidgetsChanges.map((widget) => (
                        <AzaGrid item key={widget.field} xs={12} sm={6} md={4} lg={3}>
                            <AdminModeBranch needAdmin={widget?.admin}>
                                <AttributionWidget
                                    label={widget.label}
                                    data={widgetData[widget.field]}
                                    format={widget?.format}
                                />
                            </AdminModeBranch>
                        </AzaGrid>
                    ))}
                </AzaGrid>
            </AzaBox>
        </AzaSettingsBox>)
    }, [t, attributionWidgetsChanges, widgetData]);

    if ( loading || !loaded) return <></>

    return statisticComponents;

}
