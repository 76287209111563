export const imgValidation = (file, imgFiles, maxElAllowed, t) => {
    // const maxLength = 20;

    // get number of images from redux. Validation of img quantity do not work correctly
    if (imgFiles.length >= maxElAllowed){
        return {
            code: "files-too-much",
            message: t('images.error.maximum-uploaded')
        };
    }

    // if (file.name.length > maxLength) {
    //     return {
    //         code: "name-too-large",
    //         message: `Name is larger than ${maxLength} characters`
    //     };
    // }

    if(imgFiles.length > 0) {
        for (const element of imgFiles) {
            // is better .name? (not .path)
            if(element.path === file.path) {
                return {
                    code: "name-exist",
                    message: t('images.error.already-uploaded')
                }
            }
        }
    }

    return null;
}