export const badgeColor = (status, loading) => {
    if (loading) return "gray"
    switch (status) {
        case "paused":
            return "gray"
        case "running":
            return "green"
        case "warning":
            return "orange"
        case "error":
            return "red"
        case "banned":
            return "purple"
        default:
            return "gray"
    }
}
