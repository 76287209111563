import {useNavigate} from "react-router-dom";
import {Box, Divider, Tab, Tabs} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import sample_routes from "./SampleRoutes";

const SampleHeader = () => {

    const [value, setValue] = useState(null);
    const navigate = useNavigate()

    const links = useMemo(() => {
        return sample_routes.map((route) => {
            return (
                route.testpath ?? route.path
            )
        })
    }, []);

    useEffect(() => {
        let temp_value = value
        if (!temp_value) {
            // Need to find the value corresponding to the actual URL
            const currentPath = window.location.pathname;
            temp_value = sample_routes.findIndex((route) => {
                return (route.testpath??route.path).startsWith(currentPath)
            })
            setValue(temp_value)
        }
        navigate(links[temp_value]);
    }, [links, navigate, value])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(links[newValue]);
    }

    const buidlItems = (items) => {
        return items.map((item, index) => {
            return (
                <Tab value={index} key={index} label={item.name}/>
            )
        })
    }

    if (!value) return (<></>)
    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
            >
                {buidlItems(sample_routes)}
            </Tabs>
            <Divider/>
        </Box>
    );
}

export default SampleHeader;
