/*
*  doc:  https://azameo.atlassian.net/wiki/spaces/D/pages/2131984385/AzaAutoComplete
 */

import Autocomplete from "@mui/material/Autocomplete";
import {createFilterOptions, styled} from "@mui/material";


export const AzaAutocomplete = styled(Autocomplete)(() => ({}));

export const azaCreateFilterOptions = createFilterOptions
