import { alpha } from '@mui/material/styles';

const  Dialog = () => {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: alpha('#000', 0.7)
          }
        }
      }
    }
  };
}

export default Dialog;
