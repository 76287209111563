import {useDispatch, useSelector} from "react-redux";

import {AzaTextField} from "../../../../components/mui/AzaTextField";
import {AzaGridContainer, AzaGridItem} from "../../../../components/mui/AzaGrid";
import {AzaBox} from "../../../../components/mui/AzaBox";
import {actionSetAssetsBrandName, selectBrandName} from "../../reducers/createNewCampaignSlice";
import {AzaAlert} from "../../../../components/mui/AzaAlert";

const BrandName = (props) => {
    const {inputGroupName, inputPlaceholder} = props;
    const dispatch = useDispatch();
    const brandName = useSelector(selectBrandName);
    const handleValue = (e) => {
        dispatch(actionSetAssetsBrandName({data: e.target.value}));
    }

    return (
        <AzaBox sx={{mb: '30px'}}>
            <AzaBox sx={{mb: '20px', display: 'flex'}}>
                <AzaBox sx={{fontWeight: 'bold', mr: '5px'}}>{inputGroupName}</AzaBox>
            </AzaBox>
            <AzaGridContainer>
                <AzaGridItem xs={12} md={6}>
                    <AzaTextField
                        error={brandName?.error?.status}
                        helperText=''
                        onChange={handleValue}
                        value={brandName.name}
                        size="small"
                        placeholder={inputPlaceholder}
                    />
                    {brandName?.error?.status &&
                        <AzaAlert severity="error" sx={{marginTop: '15px'}}>
                            <AzaBox>
                                {brandName?.error?.message}
                            </AzaBox>
                        </AzaAlert>}
                </AzaGridItem>
            </AzaGridContainer>
        </AzaBox>
    );
};

export default BrandName;