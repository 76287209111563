import {Avatar, Box, Typography} from "@mui/material";

import {useCallback, useEffect} from "react";

import {LoadingButton} from "@mui/lab";
import {
    connectAzameo,
    getAdaccounts,
    getBusinesses,
    getCatalogs,
    getPages,
    getPixels,
    initFacebook,
    loginFacebook,
    logoutFacebook,
    selectAdaccounts,
    selectAdaccountsLoading,
    selectBusinesses,
    selectBusinessesLoading,
    selectBusinessesRequested,
    selectCatalogs,
    selectCatalogsLoading,
    selectChoosenAdaccount,
    selectChoosenBusiness,
    selectChoosenCatalog,
    selectChoosenPage,
    selectChoosenPixel,
    selectConnected,
    selectConnecting,
    selectFacebookPolicyChecked,
    selectLogged,
    selectLogoutLoading,
    selectPages,
    selectPagesLoading,
    selectPixels,
    selectPixelsLoading,
    selectSystemUser,
    selectUserinfo,
    selectWizardOpen,
    setAdaccount,
    setBusiness,
    setCatalog,
    setFacebookPolicyChecked,
    setPage,
    setPixel,
    setWizardOpen,
    userInfoFacebook
} from "./facebookSlice";
import {useDispatch, useSelector} from "react-redux";


import profileFacebook from "./basefacebookprofilejpg.jpg"

import {AzaDialog, AzaDialogActions, AzaDialogContent, AzaDialogTitle} from "../../components/mui/AzaDialog";
import {AzaAlert, AzaAlertTitle} from "../../components/mui/AzaAlert";
import {AzaStepVertical, AzaStepper} from "../../components/mui/AzaStepper";
import {AzaSelector} from "../../components/azameo/AzaSelector";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaButton} from "../../components/mui/AzaButton";
import {useSiteId, useUser} from "../../app/globalHooks";


const LoginFacebook = () => {
    const dispatch = useDispatch()
    const logged = useSelector(selectLogged)

    const login = useCallback(() => {
        if (!logged) {
            dispatch(loginFacebook())
        }
    }, [dispatch, logged])

    if (logged) {
        return (
            <></>
        )
    }

    return (
        <>
            <Box sx={{marginBottom: '20px'}}>
                <AzaAlert severity={"info"} display={'block'}>
                    <Typography variant={"body2"}>To use Facebook with Azameo you must authorize the platform to connect
                        to your business manager.
                        This will create an Azameo system user to which you'll assign asset for doing
                        advertising.</Typography>
                    <Typography variant={"body2"}>Seem's complicated? Let's you guided!</Typography>
                </AzaAlert>
            </Box>
            <Box display={'flex'} justifyContent={"center"} sx={{marginBottom: '20px', width: "100%"}}>
                <LoadingButton variant="contained"
                               onClick={login}

                >
                    Login with Facebook
                </LoadingButton>
            </Box>
        </>

    )
}

const LogoutFacebook = () => {
    const user = useSelector(selectUserinfo)
    const dispatch = useDispatch()
    const loading = useSelector(selectLogoutLoading)
    const logged = useSelector(selectLogged)


    useEffect(() => {
        console.log("dispatch(userInfoFacebook());")
        dispatch(userInfoFacebook());
    }, [logged, dispatch])

    const build_button = useCallback(() => {
        const logout = () => {
            dispatch(logoutFacebook())
        }

        if (!logged) {
            return (
                <></>
            )
        }

        return (
            <AzaGridContainer direction={"row"} justifyContent={"left"} alignContent={"left"}>
                <AzaGridItem>
                    <Avatar
                        src={(user.picture) ? user.picture.url : profileFacebook}
                        sx={{height: 46, width: 46}}
                    />
                </AzaGridItem>
                <AzaGridItem>
                    <AzaGridContainer direction={"column"} justifyContent={"center"} alignContent={"center"}>
                        <AzaGridItem>
                            <Typography>{user.name}</Typography>
                        </AzaGridItem>
                        <AzaGridItem item>
                            <LoadingButton
                                loading={loading}
                                disabled={loading}
                                onClick={logout}
                                sx={{height: "10px"}}
                                loadingPosition={"center"}
                            >
                                Log out
                            </LoadingButton>
                        </AzaGridItem>
                    </AzaGridContainer>
                </AzaGridItem>
            </AzaGridContainer>
        )

    }, [user, dispatch, loading, logged])

    return build_button()
}


const ConnectFacebook = ({disabled, handleSave}) => {
    const dispatch = useDispatch()

    const connecting = useSelector(selectConnecting);
    const connected = useSelector(selectConnected);
    const business = useSelector(selectChoosenBusiness)
    const system_user = useSelector(selectSystemUser)

    const additonnalText = useCallback((disabled) => {
        if (disabled && !connecting) {
            return <></>
        }
        if (!connected) {
            return <Box sx={{marginBottom: '20px'}}>
                <AzaAlert severity={'info'} sx={{marginRight: "10px"}}>
                    <Box display={'flex'}>
                        <Typography>You're almost done! The configuration will take effect when you click on bind
                            button</Typography>
                    </Box>
                </AzaAlert>
            </Box>
        }
        return <Box sx={{marginBottom: '20px'}}>
            <AzaAlert severity={'success'} sx={{marginRight: "10px"}}>
                <Box display={'flex'}>
                    <Typography>You are connected!</Typography>
                </Box>
            </AzaAlert>
        </Box>
    }, [connected, connecting])

    const click_on_fb_policy = useCallback(() => {
        dispatch(setFacebookPolicyChecked())
    }, [dispatch])

    const finalbutton = useCallback((disabled) => {
        if (!connected) {
            return <Box display={'flex'} justifyContent={"center"} sx={{marginBottom: '20px', width: "100%"}}>
                <LoadingButton
                    variant="contained"
                    loading={connecting}
                    disabled={disabled}
                    onClick={handleSave}>Bind</LoadingButton> </Box>
        } else {
            return <Box display={'flex'} justifyContent={"center"} sx={{marginBottom: '20px', width: "100%"}}><AzaButton
                variant={"outlined"}
                href={`https://business.facebook.com/settings/system-users/${system_user.id}?business_id=${business.id}`}
                target={"_blank"}
                onClick={click_on_fb_policy}
            >
                Please review and approve Facebook policies here.
            </AzaButton> </Box>
        }
    }, [connected, connecting, handleSave, system_user.id, business.id, click_on_fb_policy])

    return <>
        {additonnalText(disabled)}
        {finalbutton(disabled)}
    </>
}

export const FacebookWizard = () => {

    const dispatch = useDispatch()
    const logged = useSelector(selectLogged)

    const businesses = useSelector(selectBusinesses)
    const businesses_loading = useSelector(selectBusinessesLoading)
    const businesses_searched = useSelector(selectBusinessesRequested)
    const business = useSelector(selectChoosenBusiness)

    const adaccounts = useSelector(selectAdaccounts)
    const adaccounts_loading = useSelector(selectAdaccountsLoading)
    const adaccount = useSelector(selectChoosenAdaccount)

    const pixels = useSelector(selectPixels)
    const pixels_loading = useSelector(selectPixelsLoading)
    const pixel = useSelector(selectChoosenPixel)

    const pages = useSelector(selectPages)
    const pages_loading = useSelector(selectPagesLoading)
    const page = useSelector(selectChoosenPage)

    const catalogs = useSelector(selectCatalogs)
    const catalogs_loading = useSelector(selectCatalogsLoading)
    const catalog = useSelector(selectChoosenCatalog)

    const connected = useSelector(selectConnected)
    const facebook_policy_check = useSelector(selectFacebookPolicyChecked)

    const open = useSelector(selectWizardOpen)

    const site_id = useSiteId();
    const {user} = useUser();

    const handleClose = () => {
        dispatch(setWizardOpen(false))
    }

    useEffect(() => {
        dispatch(initFacebook())
    }, [dispatch])

    useEffect(() => {
        if (logged && !businesses_searched) {
            dispatch(getBusinesses({logged}))
        }
    }, [businesses_searched, dispatch, logged])

    useEffect(() => {
        if (logged && business.id) {
            dispatch(getAdaccounts({business: business}))
            dispatch(getPixels({business: business}))
            dispatch(getPages({business: business}))
            dispatch(getCatalogs({business: business}))
        }
    }, [logged, dispatch, business])


    const onBusinessChange = useCallback((business) => {
        dispatch(setBusiness(business))
    }, [dispatch])

    const onAdAccountChange = useCallback((adaccount) => {
        dispatch(setAdaccount(adaccount))
    }, [dispatch])

    const onPixelChange = useCallback((pixel) => {
        dispatch(setPixel(pixel))
    }, [dispatch])

    const onPageChange = useCallback((page) => {
        dispatch(setPage(page))
    }, [dispatch])

    const onCatalogChange = useCallback((catalog) => {
        dispatch(setCatalog(catalog))
    }, [dispatch])

    const handleSave = useCallback(() => {

        const params = {
            site_id: site_id,
            user: user,
            azameo_business: {id: '295838484616332'},
            business: business,
            addacount: adaccount,
            page: page,
            pixel: pixel,
            catalog: catalog
        }
        console.log(params)
        dispatch(connectAzameo(params))
    }, [dispatch, business, adaccount, page, pixel, catalog, site_id, user])


    const activeStep = useCallback(() => {
        if (!logged) {
            return 0
        }
        if (!business.valid) {
            return 1
        }
        if (!adaccount.valid) {
            return 2
        }
        if (!pixel.valid) {
            return 3
        }
        if (!page.valid) {
            return 4
        }
        if (!catalog.valid) {
            return 5
        }
        if (!connected) {
            return 6
        }
        if (!facebook_policy_check) {
            return 7
        }
        return 8
    }, [logged, business, adaccount, pixel, page, catalog, connected, facebook_policy_check])


    const getSteps = useCallback((currentStep) => {
        return [
            {
                label: 'Login into Facebook',
                content: <><LoginFacebook/><LogoutFacebook/></>,

            },
            {
                label: 'Choose a Facebook Business Manager',
                content: <AzaSelector
                    id={"business_selector"}
                    concatenatId={true}
                    label={"Business Manager"}
                    disabled={connected}
                    proposedList={businesses}
                    onChange={onBusinessChange}
                    loading={businesses_loading}
                    value={business}
                    displayAdditionnalContent={currentStep <= 1}
                    additionnalContent={
                        <AzaAlert key="business_selector_info" severity={"info"}>
                            <AzaAlertTitle>You cannot find your Business Manager?</AzaAlertTitle>
                            Make sure your personnal Facebook account has access to it in the Facebook Business Manager
                            settings
                        </AzaAlert>
                    }
                    additionnalContentInvalid={
                        [
                            {
                                name: 'admin',
                                key: "business_selector_error_1",
                                react: <AzaAlert severity={"error"}>You must be ADMIN of the Business Manager</AzaAlert>
                            }
                        ]
                    }
                    additionnalContentEmpty={<AzaAlert key="business_selector_error_2" severity={"error"}>You must use
                        business manager to do advertising with Facebook</AzaAlert>}
                />,
            },
            {
                label: (connected) ? "Ad account" : "Choose an Ad account",
                content: <AzaSelector
                    id={"account_selector"}
                    concatenatId={true}
                    label={"Ad Account"}
                    disabled={connected}
                    text={"Select the addacount"}
                    value={adaccount}
                    proposedList={adaccounts}
                    onChange={onAdAccountChange}
                    loading={adaccounts_loading}
                    displayAdditionnalContent={currentStep <= 2}
                    additionnalContent={
                        <AzaAlert key="account_selector_info" severity={"info"}>
                            <AzaAlertTitle>You cannot find the addacount?</AzaAlertTitle>
                            Make sure it is attached to the right the Facebook Business Manager
                        </AzaAlert>
                    }
                    additionnalContentInvalid={
                        [
                            {
                                name: 'paiement',
                                key: "account_selector_error_1",
                                react: <AzaAlert severity={"error"}>Your addacount has no paiement method</AzaAlert>
                            },
                            {
                                name: 'account_not_authorized',
                                key: "account_selector_error_2",
                                react: <AzaAlert severity={"error"}>Facebook does not allow your facebook adaccount to
                                    do advertising</AzaAlert>
                            }
                        ]
                    }
                    additionnalContentEmpty={<AzaAlert key="account_selector_error_3" severity={"error"}>No adaccount
                        has been found in this business manager</AzaAlert>}

                />,
            },
            {
                label: 'Choose a Pixel',
                content: <AzaSelector
                    id={"pixel_selector"}
                    concatenatId={true}
                    label={"Pixel"}
                    disabled={connected}
                    text={"Select the pixel"}
                    value={pixel}
                    proposedList={pixels}
                    onChange={onPixelChange}
                    loading={pixels_loading}
                    displayAdditionnalContent={currentStep <= 3}
                    additionnalContent={
                        <AzaAlert key="pixel_selector_info" severity={"info"}>
                            <AzaAlertTitle>You cannot find the pixel?</AzaAlertTitle>
                            Make sure it is attached to the right the Facebook Business Manager
                        </AzaAlert>
                    }
                    // additionnalContentInvalid={}
                    additionnalContentEmpty={<AzaAlert key="pixel_selector_error_3" severity={"error"}>No pixel has been
                        found in this business manager</AzaAlert>}

                />
            },
            {
                label: 'Choose a Page',
                content: <AzaSelector
                    id={"page_selector"}
                    concatenatId={true}
                    label={"Page"}
                    disabled={connected}
                    text={"Select the page"}
                    value={page}
                    proposedList={pages}
                    onChange={onPageChange}
                    loading={pages_loading}
                    displayAdditionnalContent={currentStep <= 4}
                    additionnalContent={
                        <AzaAlert key="page_selector_info" severity={"info"}>
                            <AzaAlertTitle>You cannot find the page?</AzaAlertTitle>
                            Make sure it is attached to the right the Facebook Business
                        </AzaAlert>
                    }
                    // additionnalContentInvalid={}
                    additionnalContentEmpty={<AzaAlert key="page_selector_error_3" severity={"error"}>No page has been
                        found in this business manager</AzaAlert>}

                />
            },
            {
                label: 'Choose a Catalog',
                content: <AzaSelector
                    id={"catalog_selector"}
                    concatenatId={true}
                    label={"Catalog"}
                    disabled={connected}
                    text={"Select the catalog"}
                    value={catalog}
                    proposedList={catalogs}
                    onChange={onCatalogChange}
                    loading={catalogs_loading}
                    displayAdditionnalContent={currentStep <= 4}
                    additionnalContent={
                        <AzaAlert key="page_selector_info" severity={"info"}>
                            <AzaAlertTitle>You cannot find the catalog?</AzaAlertTitle>
                            Make sure it is attached to the right the Facebook Business
                        </AzaAlert>
                    }
                    // additionnalContentInvalid={}
                    // additionnalContentEmpty={<AzaAlert key="page_selector_error_3" severity={"error"}>No catalog has been found in this business manager</AzaAlert>}

                />
            },
            {
                label: 'Connect',
                content: <ConnectFacebook handleSave={handleSave} disabled={activeStep() < 5}/>

            }
        ]
    }, [connected, businesses, onBusinessChange, businesses_loading, business, adaccount, adaccounts, onAdAccountChange, adaccounts_loading, pixel, pixels, onPixelChange, pixels_loading, page, pages, onPageChange, pages_loading, catalog, catalogs, onCatalogChange, catalogs_loading, handleSave, activeStep]);

    const current_step = activeStep();

    const errorStep = useCallback((step) => {
        switch (step) {
            case 1:
                return (business.valid === false)
            case 2:
                return (adaccount.valid === false)
            case 3:
                return (pixel.valid === false)
            case 4:
                return (page.valid === false)
            case 5:
                return (catalog.valid === false)
            default:
                return false;
        }
    }, [business.valid, adaccount.valid, pixel.valid, page.valid, catalog.valid])

    return (
        <AzaDialog open={open} onClose={handleClose}>
            <AzaDialogTitle onClose={handleClose}>Facebook Connection</AzaDialogTitle>
            <AzaDialogContent>
                <AzaStepper activeStep={current_step} orientation="vertical">
                    {getSteps(current_step).map((step, index) => {
                        return (
                            <AzaStepVertical key={step.label}
                                     index={index}
                                     current={current_step}
                                     expanded={(index <= current_step)}
                                     label={step.label}
                                     error={errorStep(index)}
                            >
                                {step.content}
                            </AzaStepVertical>
                        );
                    })}
                </AzaStepper>
            </AzaDialogContent>
            <AzaDialogActions>
                <AzaButton variant={"contained"} disabled={activeStep() < 7} onClick={handleClose}>Done</AzaButton>
            </AzaDialogActions>
        </AzaDialog>
    )
}