import {AzaButton, AzaIconButton} from "../../components/mui/AzaButton";
import {AzaNotifications} from "../../components/mui/AzaIcons";
import React, {useCallback, useMemo, useState} from "react";
import AzaBadge from "../../components/mui/AzaBadge";
import {useAdminMode} from "../../features/adminMode/adminModeContext";
import {AzaMenu, AzaMenuItem} from "../../components/mui/AzaMenu";
import {AzaDivider} from "../../components/mui/AzaDivider";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaBox} from "../../components/mui/AzaBox";
import {useDbItem} from "../../utils/rdbHooks";
import {useSiteId} from "../../app/globalHooks";
import {ref, update} from "firebase/database";

import {
    AzaDialog,
    AzaDialogActions,
    AzaDialogContent,
    AzaDialogContentText,
    AzaDialogTitle
} from "../../components/mui/AzaDialog";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {PATH_INTEGRATION} from "../../utils/constant";
import {useTranslation} from "react-i18next";
import LinkIcon from '@mui/icons-material/Link';
import {useNavigate} from "react-router-dom";

const useNotifications = () => {
    const {isAdmin} = useAdminMode()
    const site_id = useSiteId();
    const {
        item: siteNotifications, loading: loadingSite, error: errorSite, db
    } = useDbItem("notifications/sites/", site_id)
    const {
        item: siteAdminNotifications, loading: loadingAdmin, error: errorAdmin
    } = useDbItem("notifications/admin/sites/", isAdmin ? site_id : null)

    // If the user is an admin or not the field to read the notification is different
    const readField = useMemo(() => (isAdmin ? "read_admin" : "read"), [isAdmin])


    const listNotifications = useMemo(() => {
        let list = []
        // Merge both notification lists, Admin or not
        if (siteNotifications) {
            // siteNotifications is a dict, we need to convert it to a list, added the key as a new field : id
            let listSite = Object.entries(siteNotifications).map(([id, notification]) => {
                return formatNotification(id, notification, false, readField)
            })
            list = [...list, ...listSite]
        }

        if (siteAdminNotifications) {
            // siteNotifications is a dict, we need to convert it to a list, added the key as a new field : id
            let listAdminSite = Object.entries(siteAdminNotifications).map(([id, notification]) => {
                return formatNotification(id, notification, true, readField)
            })
            list = [...list, ...listAdminSite]
        }
        // sort by date
        list.sort((a, b) => {
            return new Date(b.date) - new Date(a.date)
        })
        return list


    }, [readField, siteAdminNotifications, siteNotifications])

    // Mark a notification as read
    const readNotification = useCallback((id, admin) => {
        if (!db) return;
        if (!site_id) return;
        let path = "notifications/sites/" + site_id + "/" + id
        // If it's an admin notification the path is different
        if (admin) {
            path = "notifications/admin/sites/" + site_id + "/" + id
        }
        const notificationRef = ref(db, path)

        return update(notificationRef, {[readField]: true})

    }, [db, readField, site_id])

    const readAll = useCallback(() => {
        listNotifications.map((notification) => {
            return readNotification(notification.id, notification.admin)
        })
    }, [listNotifications, readNotification])

    return {
        listNotifications,
        readNotification,
        readAll,
        loading: loadingSite || loadingAdmin,
        error: errorSite || errorAdmin
    }

}

export const NotificationWidget = () => {
    const [currentNotification, setCurrentNotification] = useState(null)
    const {listNotifications, readNotification, readAll} = useNotifications()


    // Open the selected notification and mark as read
    const openNotificationDialog = useCallback((id) => {
        const notification = listNotifications.find((notification) => notification.id === id)
        // If the notification can't be found no need to continue (should not happen, but with rdb it's better to check)
        if (!notification) return;
        // Open the notification dialog with the selected notification
        setCurrentNotification(notification)
        // Flag the notification as read
        if (!notification.current_read) readNotification(id, notification.admin);

    }, [listNotifications, readNotification, setCurrentNotification])

    return (<>
            <NotificationMenu
                listNotifications={listNotifications}
                readAll={readAll}
                openNotificationDialog={openNotificationDialog}
            />
            <NotificationDialog
                notification={currentNotification}
                setNotification={setCurrentNotification}
            />

        </>

    )

}


const timeSince = (isoDateTimeString) => {
    const givenDate = new Date(isoDateTimeString);
    const currentDate = new Date();

    // Calculate difference in milliseconds
    const diff = currentDate - givenDate;

    // Calculate difference in days
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days > 0) {
        return {
            "value": days, "unit": "days"
        };
    }

    // Calculate difference in hours
    const hours = Math.floor(diff / (1000 * 60 * 60));

    if (hours > 0) {
        return {
            "value": hours, "unit": "hours"
        };
    }

    // Calculate difference in minutes
    const minutes = Math.floor(diff / (1000 * 60));

    if (minutes > 0) {
        return {
            "value": minutes, "unit": "minutes"
        };
    }

    // Default case: If time is less than a minute, it will still show 1 minute.
    return {
        "value": 1, "unit": "minutes"
    };
}

const formatNotification = (id, notification, admin, readField) => {
    let description = notification?.["template_description"] ? formatString(notification["template_description"], notification["data"]) : notification["description"]
    let title = notification?.["template_title"] ? formatString(notification["template_title"], notification["data"]) : notification["title"]
    return {
        ...notification, description, title, admin, id, current_read: notification[readField],
    }
}

const formatString = (template, data) => {
    const parts = template.split(/([{}])/).filter(part => part !== '{' && part !== '}');

    return parts.map((part, index) => {
        if (data?.[part]) {
            return <strong key={index}>{data[part]}</strong>;
        } else {
            return part;
        }
    });
};

const NotificationItem = ({notification}) => {
    const {title, date, current_read: read} = notification
    const ago = useMemo(() => {
        const since = timeSince(date);
        return `${since.value}${since.unit[0]}`
    }, [date])

    return (
        <AzaBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            paddingRight={4}
            sx={{
                width: "100%", minWidth: 320
            }}
        >
            <AzaTypography
                variant="body1"
                sx={{
                    fontWeight: read ? 'normal' : 'bold',
                }}
            >
                {notification.admin ? <AdminPanelSettingsIcon/> : <></>} {title}
            </AzaTypography>
            <AzaTypography variant="caption" position="absolute" top={0} right={0}>
                {ago}
            </AzaTypography>
        </AzaBox>
    )
}


const NotificationAction = ({notification, closeDialog}) => {
    //  couple of shortcuts
    const data = useMemo(() => (notification?.data), [notification])
    const action = useMemo(() => (data?.action), [data])
    const {t} = useTranslation();
    const navigate = useNavigate();

    // No action, do nothing
    if (!data || !action) return <></>

    // If the action is to link a shop, we need to forward to the integration page
    if (action === "cms_app_install") {
        // We need a button to forward to the integration page
        return (
            <AzaButton
                onClick={() => {
                    navigate(PATH_INTEGRATION);
                    closeDialog()
                }}
                variant="contained"
                color="primary"
                startIcon={<LinkIcon/>}>
                {t("notification.connect-shop")}
            </AzaButton>
        )
    }
    return <></>
}
const NotificationDialog = ({notification, setNotification}) => {
    const closeDialog = useCallback(() => {
        setNotification(null)
    }, [setNotification])
    if (!notification) return <></>;
    console.log("notification", notification)
    return (<AzaDialog
        open={!!notification}
        onClose={closeDialog}
    >
        <AzaDialogTitle onClose={() => (setNotification(null))}>
            {notification["title"]}
        </AzaDialogTitle>
        <AzaDialogContent>
            <AzaDialogContentText>
                {notification.description}
            </AzaDialogContentText>

        </AzaDialogContent>
        <AzaDialogActions>
            <NotificationAction notification={notification} closeDialog={closeDialog}/>
            <AzaButton onClick={() => (setNotification(null))} autoFocus>
                Ok
            </AzaButton>
        </AzaDialogActions>
    </AzaDialog>)
}

const NotificationList = ({
                              listNotifications,
                              anchorEl,
                              handleClose,
                              readAll,
                              numberOfUnread,
                              openNotificationDialog,
                              open
                          }) => {
    return (<AzaMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
            minWidth: 500
        }}
    >
        <AzaMenuItem onClick={readAll}>
            Mark all as read ({numberOfUnread})
        </AzaMenuItem>

        <AzaDivider/>
        {listNotifications.map((notification) => (
            <AzaMenuItem key={notification.id} onClick={() => (openNotificationDialog(notification.id))}>
                <NotificationItem notification={notification}/>
            </AzaMenuItem>))}
    </AzaMenu>)
}

const NotificationIcon = ({numberOfUnread, onClick}) => {
    return (<AzaIconButton onClick={onClick}>
        <AzaBadge badgeContent={numberOfUnread} color="primary">
            <AzaNotifications
                color={numberOfUnread > 0 ? "primary" : "inherit"}
            />
        </AzaBadge>
    </AzaIconButton>)
}

const NotificationMenu = ({listNotifications, openNotificationDialog, readAll}) => {
    // Count the number of unread notifications
    const numberOfUnread = useMemo(() => {
        return listNotifications.filter((notification) => !notification.current_read).length
    }, [listNotifications])
    // Open/Close notification menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [])
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [])


    return (<>
        <NotificationIcon
            numberOfUnread={numberOfUnread}
            onClick={handleClick}
        />
        <NotificationList
            listNotifications={listNotifications}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            readAll={readAll}
            numberOfUnread={numberOfUnread}
            openNotificationDialog={openNotificationDialog}
        />
    </>)
}