/*
* doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2083127297/Admin+Mode
 */
import {useAdminModeProvider} from "./adminModeContext";
import {IconButton} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {ThemeCustomization} from "../../themes";

export const AdminModeToggleButton = () => {
    const {isAdmin, adminMode, toggleAdminMode} = useAdminModeProvider()
    if (!isAdmin) return <></>;
    return (
        <ThemeCustomization presetColor={"themeColorsAdmin"}>
            <IconButton
                color={adminMode ? "primary" : "default"}
                label="Toggle admin Mode"
                onClick={toggleAdminMode}
            >
                <AdminPanelSettingsIcon/>
            </IconButton>
        </ThemeCustomization>
    )
}

export const AdminModeRequired = ({children, disableTheme}) => {
    const {adminMode} = useAdminModeProvider()

    if (!adminMode) return <></>

    // If the admin mode is activated but the theming is disabled, return the children without special colors
    if (!!disableTheme) return <>{children}</>

    return (
        <ThemeCustomization presetColor={"themeColorsAdmin"}>
            {children}
        </ThemeCustomization>
    )
}


export const AdminModeBranch = ({needAdmin, children}) => {
    const {adminMode} = useAdminModeProvider()
    // If the admin mode is not needed, return the children normally
    if (!needAdmin) return <>{children}</>

    // From there the admin mode is required to display the children

    // If the admin mode is not activated, return an empty component
    if (!adminMode) return <></>

    // If the admin mode is activated, return the children with a special colors
    return (
        <ThemeCustomization presetColor={"themeColorsAdmin"}>
            {children}
        </ThemeCustomization>
    )
}
