import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useTheme} from '@mui/material';

import {usePayment} from "./paymentHook";
import {useFormat} from "../../../app/globalHooks";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    actionSetAmount,
    actionSetRecurring,
    actionSetSetUpPaymentIndex,
    selectAmount,
    selectPaymentSettingMinimumAmount,
    selectPaymentSettingPackageInfo,
    selectPromocode,
    selectReccuring,
    selectSUPIndex
} from "./paymentSlice";
import {selectSiteSettingsReady} from "../../../app/globalSlice";

import {PromocodeInput} from "./promocodeInput";
import {AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import AzaInputNumber from "../../../components/azameo/AzaInputNumber";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaButton} from "../../../components/mui/AzaButton";
import AzaLink from "../../../components/mui/AzaLink";
import {AzaSkeleton} from "../../../components/mui/AzaSkeleton";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {AzaSwitch} from "../../../components/mui/AzaSwitch";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AzaInputAdornment} from "../../../components/mui/AzaInputAdornment";
import {alpha} from "@mui/material/styles";
import {AzaSelect} from "../../../components/mui/AzaSelect";
import {AzaMenuItem} from "../../../components/mui/AzaMenu";
import {SUPPORTED_CURRENCY} from "../../../utils/constant";



const PackageItem = ({setAmount, price, name, index, selectedKey, setSelected}) => {

    const {formatMoney} = useFormat();
    const {currency} = usePayment();
    const {t} = useTranslation();
    const theme = useTheme();

    const handlePackageItemClick = () => {
        setAmount(price);
        setSelected(index);
    }

    useEffect(() => {
        if (index === selectedKey) {
            setAmount(price);
            setSelected(index);
        }
    }, [selectedKey, index, setAmount, setSelected, price]);

    return (
        <AzaGridItem key={index} item xs={12} sm={6} md={4}>
            <AzaPaper variant="outlined"
                   onClick={handlePackageItemClick}
                   sx={{
                       textAlign: "center",
                       padding: "7px",
                       border: selectedKey === index ? `1px solid ${theme.palette.primary.dark}` : `1px solid ${theme.palette.grey}`,
                       backgroundColor: theme.palette.background.default,
                       "&:hover": {
                           backgroundColor: alpha(
                               theme.palette.primary.light,
                               theme.palette.action.hoverOpacity),
                           borderColor: theme.palette.text.primary
                       },
                   }}
            >
                <AzaBox sx={{height: "43px"}}>
                    <AzaTypography sx={{fontSize: "12px", padding: "0 0 7px", }}>{t(name)}</AzaTypography>
                </AzaBox>
                <AzaTypography sx={{fontSize: "16px", padding: "7px"}}>{formatMoney(price, currency)}</AzaTypography>
                <AzaButton disabled={selectedKey === index}>{t("payment.package.item.apply")}</AzaButton>
            </AzaPaper>
        </AzaGridItem>
    )
}

const ToggleAutomaticRenewal = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const renewalSwitch = useSelector(selectReccuring);
    const handleRenewal = useCallback(() => {
        dispatch(actionSetRecurring(!renewalSwitch));
    }, [dispatch, renewalSwitch]);

    const theme = useTheme();

    return (
        <>
            <AzaBox
                component={"h4"}
                sx={{marginTop: '30px'}}
            >
                {t('payment.autorenew.automatic_renewal')}
            </AzaBox>
            <AzaBox sx={{
                display: "flex",
                alignItems: "center",
            }}>
                <AzaTypography
                    sx={{
                        color: renewalSwitch ? theme.palette.text.secondary : theme.palette.text.primary,
                    }}
                >
                    {t('payment.autorenew.pay_one_time')}
                </AzaTypography>
                <AzaSwitch
                    checked={renewalSwitch}
                    sx={{
                        margin: "0, 15px",
                        "& .MuiSwitch-colorPrimary.Mui-checked": {
                            color: theme.palette.text.primary
                        },
                        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                            backgroundColor: theme.palette.text.secondary
                        },
                        "& .MuiSwitch-colorPrimary": {
                            color: theme.palette.text.primary
                        },
                        "& .MuiSwitch-switchBase+.MuiSwitch-track": {
                            backgroundColor: theme.palette.text.secondary
                        },
                    }}
                    onClick={handleRenewal}

                />
                <AzaTypography
                    sx={{
                        color: renewalSwitch ? theme.palette.text.primary : theme.palette.text.secondary,
                    }}
                >
                    {t('payment.autorenew.pay_every_month')}
                </AzaTypography>
            </AzaBox>
        </>
    )
}

const SelectCurrency = () => {
    const {t} = useTranslation();
    const {setCurrency, defaultCurrency} = usePayment();

    const {moneySymbol} = useFormat();
    const [selectedCurrency, setSelectedCurrency] = useState("");

    if (defaultCurrency !== undefined) {
        return (<></>)
    }

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
        setCurrency(event.target.value);
    }

    return (<>
        <AzaBox
            component={"h4"}
            sx={{marginTop: '30px'}}
        >
            {t('payment.selectcurrency.title')}
        </AzaBox>
        <AzaBox>
            <AzaGridContainer direction="column" alignItems="center" justifyContent="center">
                <AzaGridItem item >
                    {t('payment.selectcurrency.text')}
                </AzaGridItem>
                <AzaGridItem item >
                    <AzaSelect
                        onChange={handleCurrencyChange}
                        value={selectedCurrency}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <AzaMenuItem value="" sx={{ color: 'text.secondary' }}>
                            {t('payment.selectcurrency.select')}
                        </AzaMenuItem>
                        {SUPPORTED_CURRENCY.map((item, index) => {
                            return (<AzaMenuItem value={item} key={index}>{item} ({moneySymbol(item)})</AzaMenuItem>)
                        })}
                    </AzaSelect>
                </AzaGridItem>
            </AzaGridContainer>
        </AzaBox>
    </>)
}


const SetUpAmount = ({setCanNext}) => {

    const [isAmountToSmall, setIsAmountToSmall] = useState(false);

    const {t} = useTranslation();

    const {currency} = usePayment();

    const dispatch = useDispatch();

    const selectedIndex = useSelector(selectSUPIndex);
    const setSelectedIndex = useCallback((index) => {
        dispatch(actionSetSetUpPaymentIndex(index));
    }, [dispatch]);

    const packageInfo = useSelector(selectPaymentSettingPackageInfo);

    const amount = useSelector(selectAmount);
    const setAmount = useCallback((value) => {
        dispatch(actionSetAmount(value));
    }, [dispatch]);

    const promoCode = useSelector(selectPromocode);
    const [showPromoCodeInput, setShowPromoCodeInput] = useState(promoCode.length > 0);
    const handleShowPromoCode = () => {
        setShowPromoCodeInput(!showPromoCodeInput);
    };

    const minimumAmount = useSelector(selectPaymentSettingMinimumAmount);

    const settingsReady = useSelector(selectSiteSettingsReady);

    const {moneySymbol} = useFormat();

    const [isCustom, setIsCustom] = useState(false);

    const handleInput = useCallback((event) => {
        setSelectedIndex(-1);
        if (event.target.value === "") {
            setIsCustom(false)
            setIsAmountToSmall(false);
            setAmount(0);
        } else {
            setIsCustom(true)
            setAmount(event.target.value);
        }
        if (currency === undefined) {
            setCanNext(false);
        }  else if (amount < minimumAmount) {
            setCanNext(false);
        } else {
            setCanNext(true);
        }
    }, [amount, setAmount, minimumAmount, setCanNext, setSelectedIndex, currency]);

    const handleFocusout = () => {
        if (selectedIndex === -1 && amount < minimumAmount) {
            setIsAmountToSmall(true);
        } else {
            setIsAmountToSmall(false);
        }
    }

    useEffect(() => {
        if (selectedIndex !== -1) {
            setIsCustom(false);
            setIsAmountToSmall(false);
        }
    }, [selectedIndex]);


    useEffect(() => {
        if (currency === undefined) {
            setCanNext(false);
        }  else if (amount < minimumAmount) {
            setCanNext(false);
        } else {
            setCanNext(true);
        }
    }, [amount, minimumAmount, setCanNext, currency]);

    useEffect(() => {
        if (!currency){
            setCanNext(false);
        } else if (amount < minimumAmount && isCustom) {
            setCanNext(false);
            if (selectedIndex === -1 ) {
                setIsAmountToSmall(true);
            }
        } else {
            setCanNext(true);
            setIsAmountToSmall(false);
        }
    }, [promoCode, amount, minimumAmount, setCanNext, selectedIndex, isCustom, currency]);

    const defaultAmountsChoice = useMemo(() => {
        if (!settingsReady) {
            return Object.entries({1:1, 2:2, 3:3}).map((key, index) => {
                return <AzaGridItem key={index} item xs={12} sm={6} md={4}><AzaSkeleton variant="rectangular" width={134} height={135}/></AzaGridItem>
            })
        } else {
            return Object.entries(packageInfo).map(([key, item], index) => {
                return <PackageItem
                    key={index}
                    setAmount={setAmount}
                    price={item.value}
                    name={item.tradkey}
                    index={index}
                    selectedKey={selectedIndex}
                    setSelected={setSelectedIndex}
                />
            })
        }
    }, [settingsReady, packageInfo, selectedIndex, setSelectedIndex, setAmount]);

    return (
        <div>
            <SelectCurrency/>
            {currency && <div>
            <div>
                <AzaBox
                    component={"h4"}
                    sx={{marginTop: '30px'}}
                >
                    {t('payment.amount.select.title')}
                </AzaBox>
                <AzaGridContainer spacing={2} justifyContent="center">
                    {
                        defaultAmountsChoice
                    }
                </AzaGridContainer>
            </div>
            <AzaTypography sx={{margin: "15px 0"}}>{t('common.or')}</AzaTypography>

            { settingsReady && <AzaBox component="form">
                <AzaInputNumber
                    error={isAmountToSmall}
                    placeholder={t('payment.amount.custom.placeholder')}
                    variant="outlined"
                    value={(selectedIndex === -1) ? (amount > 0) ? amount : "" : ""}
                    onChange={handleInput}
                    fullWidth
                    onBlur={handleFocusout}
                    InputProps={{
                        endAdornment: <AzaInputAdornment position="end">{(settingsReady)?moneySymbol(currency):""}</AzaInputAdornment>,
                    }}
                />
                {isAmountToSmall && <AzaAlert severity="error"
                                              sx={{marginTop: '10px'}}>{t('payment.amount.custom.error.minimum_value_to_pay', {
                    val: minimumAmount,
                    formatParams: {
                        val: {currency: currency ?? "",}
                    }
                })} </AzaAlert>}
            </AzaBox>}
            {!settingsReady && <AzaBox component="form">
                <AzaSkeleton variant="rectangular" width={"100%"}/>
            </AzaBox>}
            <ToggleAutomaticRenewal/>
            <AzaBox>
                <AzaLink
                    onClick={handleShowPromoCode}
                    sx={{
                        display: "inline-block",
                        margin: "15px 0",
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                >
                    {t('payment.promocode.toggle')}
                </AzaLink>
                {showPromoCodeInput && <PromocodeInput/>}
            </AzaBox>
            </div>}
        </div>
    );
};

export default SetUpAmount;
