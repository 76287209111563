/*
*  doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2102984738/AzaCountrySelector
 */

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {countries} from "../../utils/countries";
import {useFormat} from "../../app/globalHooks";
import {AzaTextFieldBasic} from "../mui/AzaTextField";
import {AzaBox} from "../mui/AzaBox";
import {AzaAutocomplete, azaCreateFilterOptions} from "../mui/AzaAutocomplete";

const filter = azaCreateFilterOptions();

export const AzaCountrySelector = ({
                                       country_code,
                                       disabled,
                                       loading,
                                       onChange,
                                       whitelist,
                                       blacklist,
                                       allchoice,
                                       required = true,
                                       multiple = false,
                                       min,
                                       max,
                                   }) => {
    const {t} = useTranslation();
    const {formatCountry} = useFormat();

    const country_required = t('component.azatextfield.required');
    const country_not_found = t('component.azacountryselector.countrynotfound');
    const not_enough_country = t('component.azacountryselector.notenoughcountry', {min: min});
    const too_much_country = t('component.azacountryselector.toomuchcountry', {max: max});

    const [errorEmpty, setErrorEmpty] = useState(false);
    const [errorUnderMin, setErrorUnderMin] = useState(false);
    const [errorOverMax, setErrorOverMax] = useState(false);
    const [errorNotFound, setErrorNotFound] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const handleInput = (event, value) => {
        if (required) {
            if (!multiple) {
                setErrorEmpty(!value || value.length === 0);
            }
        }
        setInputValue(value)
    };

    useEffect(() => {
        setInputValue("")
    }, [multiple])

    const handleChange = useCallback((event, value) => {
        if (multiple) {
            if (required) {
                setErrorEmpty(value.length === 0);
            }
            if (min && min > 0) {
                setErrorUnderMin(value.length < min);
            }
            if (max && max > 0) {
                setErrorOverMax(value.length > max);
            }
            if (value?.find(elem => elem.code === 'all')) {
                onChange(['all'])
            } else {
                onChange?.(value.map(elem => elem.code));
            }
        } else {
            if (required) {
                setErrorEmpty(!(value?.code && value.code !== ""));
            }
            onChange?.(value?.code);
        }
    }, [multiple, onChange, required, min, max]);


    const error = useMemo(() => {
        return errorEmpty || errorNotFound || errorUnderMin || errorOverMax;
    }, [errorEmpty, errorNotFound, errorUnderMin, errorOverMax]);

    const helperText = useMemo(() => {
        if (errorNotFound) {
            return country_not_found;
        }
        if (errorEmpty) {
            return country_required;
        }
        if (errorUnderMin) {
            return not_enough_country;
        }
        if (errorOverMax) {
            return too_much_country;
        }
        return "";
    }, [errorEmpty, errorNotFound, errorUnderMin, errorOverMax, country_not_found, country_required, not_enough_country, too_much_country]);

    const defaultCountries = useMemo(() => {
        if (allchoice) {
            return [{code: "all", label: t('component.azacountryselector.allcountries')}];
        } else {
            return [];
        }
    }, [allchoice, t]);

    const countries_list = useMemo(() => {
        return defaultCountries.concat(countries.filter(
            (country) => {
                return (!whitelist || whitelist.length === 0 || whitelist.includes(country.code))
            }
        ).filter(
            (country) => {
                return (!blacklist || blacklist.length === 0 || !blacklist.includes(country.code))
            }
        ).map((country) => {
            return {
                code: country.code,
                label: formatCountry(country.code),
            }
        }))
    }, [blacklist, whitelist, defaultCountries, formatCountry]);

    const countryValue = useMemo(() => {
        if (multiple) {
            return countries_list.filter((country) => {
                return country_code?.includes(country.code);
            });
        }
        return countries_list.find((country) => {
            return country.code === country_code;
        }) ?? {code: "", label: ""}
    }, [country_code, countries_list, multiple]);

    return (
        <AzaBox sx={{position: 'relative'}}>
            <AzaAutocomplete
                multiple={multiple}
                limitTags={3}
                disablePortal
                // freeSolo
                options={countries_list}
                value={countryValue}
                inputValue={inputValue}
                disabled={disabled}
                onChange={handleChange}
                onInputChange={handleInput}
                loading={loading}
                autoHighlight
                isOptionEqualToValue={(option, value) => option.code === value?.code}
                getOptionLabel={(option) => option.label ?? ""}
                renderOption={(props, option) => (
                    <AzaBox component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        {option.label}
                    </AzaBox>
                )}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (filtered.length === 0) {
                        setErrorNotFound(true);
                    } else {
                        if (errorNotFound) {
                            setErrorNotFound(false);
                        }
                    }
                    return filtered;
                }}
                renderInput={(params) => (
                    <AzaTextFieldBasic
                        {...params}
                        required={required}
                        error={error}
                        helperText={error ? helperText : ''}
                        label={t('payment.billing.selectcountry')}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </AzaBox>
    )
}
