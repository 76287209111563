import React from 'react';

import {Box} from "@mui/material";

import GIcon from "../../assets/images/g_icone.svg";

const CustomGoogleIcon = () => {
    return (
        <Box component={'span'} sx={{
            display: 'inline-block',
            minWidth: '20px',
            minHeight: '20px',
            background: `center no-repeat url(${GIcon})`,
        }}/>
    );
};

export default CustomGoogleIcon;