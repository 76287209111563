export const assetType = {
    HEADLINE: "Headline",
    LONG_HEADLINE: 'Long Headline',
    DESCRIPTION: 'Description',
    CALLOUT: 'Callout',
    KEYWORD: 'Keyword',
}

export const assetOrigin = {
    SITE: 'Site',
    CUSTOMER: 'Customer',
    GENERATED: 'Generated',
}
export const assetScope = {
    SITE: 'Site',
    PRODUCT: 'Product',
}



export const assetTypeSize = {
    [assetType.HEADLINE]: 30,
    [assetType.LONG_HEADLINE]: 90,
    [assetType.DESCRIPTION]: 90,
    [assetType.CALLOUT]: 25,
}

export const assetTypeOrder = [
    assetType.HEADLINE,
    assetType.LONG_HEADLINE,
    assetType.DESCRIPTION,
    assetType.CALLOUT,
    assetType.KEYWORD,
]


