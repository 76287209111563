export const ToolBar= (theme) => {
    return {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.paper,
                }
            }
        }
    }
}
