/*
 * doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2102820881/AzaTextField
 */

import {TextField, CircularProgress} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";


export const AzaTextField = (props) => {

    const {required, error, helperText, value, loading, onChange, type, inputProps, ...other} = props;
    const {t} = useTranslation()

    const [errorState, setErrorState] = useState(error);

    const handleInnerChange = useCallback((event) => {
        if (!(required??true)){
            setErrorState(false);
        } else {
            setErrorState(event.target.value === "");
        }
        onChange?.(event);
    }, [onChange, required, setErrorState]);

    useEffect(() => {
        if (!(required??false)){
            setErrorState(false);
        } else {
            setErrorState(value === "");
        }
    }, [value, required, setErrorState]);


    const errorText = useMemo(() => {
        if (errorState){
            return t('component.azatextfield.required');
        }
        if (error){
            return helperText??t('component.azatextfield.genericerror');
        }
        return "";
    }, [errorState, error, helperText, t]);

    return (
        <TextField
            type={type??"text"}
            error={errorState || error}
            helperText={errorText}
            value={value ?? ""}
            fullWidth
            onChange={handleInnerChange}
            InputProps={inputProps || {
                startAdornment: loading && <CircularProgress
                    size={40}
                    sx={{
                        color: 'rgba(0, 0, 0, 0.38)',
                        position: 'absolute',
                        top: 7,
                        left: 'calc(50% - 20px)',
                        transform: 'translateX(-50%)',
                        zIndex: 1,
                    }}
                />,
            }}
            {...other}
        />)
};


export const AzaTextFieldBasic = (props) => {return (<TextField {...props}/>)}