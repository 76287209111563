import React from 'react';
import {Typography} from "@mui/material";
import {
    SampleAzaInputEmail,
    SampleAzaTextField,
    SampleCard,
    SampleDialog,
    SampleInputPhone,
    SampleSelectCountry,
    SampleZohoForm
} from "./components/mui";
import {AzaPage, AzaPageItem} from "../components/azameo/AzaPage";
import {AzaAlert, AzaAlertTitle} from "../components/mui/AzaAlert";

const page_items = [
    <AzaPageItem maptitle={'Alert'} anchor_id={'AzaAlert'}><AzaAlert>AzaAlert without title</AzaAlert></AzaPageItem>,
    <AzaPageItem maptitle={'Alert -Error'} anchor_id={'AzaAlert-error'}>
        <AzaAlert severity={'error'}>
            <AzaAlertTitle>AzaAlertTitle - severity error</AzaAlertTitle>
            AzaAlert with title
        </AzaAlert>
    </AzaPageItem>,
    <AzaPageItem maptitle={'Dialog'} anchor_id={'SampleDialog'}><SampleDialog/></AzaPageItem>,
    <AzaPageItem maptitle={'Card'} anchor_id={'SampleCard'}><SampleCard/></AzaPageItem>,
    <AzaPageItem maptitle={'SelectCountry'} anchor_id={'SampleSelectCountry'}><SampleSelectCountry/></AzaPageItem>,
    <AzaPageItem maptitle={'InputPhone'} anchor_id={'SampleInputPhone'}><SampleInputPhone/></AzaPageItem>,
    <AzaPageItem maptitle={'InputEmail'} anchor_id={'SampleAzaInputEmail'}><SampleAzaInputEmail/></AzaPageItem>,
    <AzaPageItem maptitle={'AzaTextField'} anchor_id={'SampleAzaTextField'}><SampleAzaTextField/></AzaPageItem>,
    <AzaPageItem maptitle={'ZohoForm'} anchor_id={'SampleAzaTextField'}><SampleZohoForm/></AzaPageItem>,
];

const SampleElements = () => {
    return (
        <>
            <Typography variant={"h5"}>Sample of subset of MUI components ready for Azameo</Typography>
            <p>Each component should be prefixed with Aza</p>
            <p>This page is by the way an example of AzaPage</p>
            <AzaPage pageItems={page_items} sorted={true}/>
        </>
    );
};

export default SampleElements;
