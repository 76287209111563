import {createSlice} from "@reduxjs/toolkit";

import {MIN_BUDGET_AMOUNT, MIN_TARGET_ROI} from "../../createNewCampaign/createNewCampaignUtile/createNewCamaignConst";

const editCampaignSliceName = 'editCampaignData';

const initialState = {
    on_loading: false,
    is_loaded: false,
    campaign_id: '',
    campaign_name: '',
    budget: {
        daily_budget_data: {
            daily_budget: MIN_BUDGET_AMOUNT,
            error: {status: false, massage: ''}
        },
        roi_checked: false,
        exceed_daily_budget_data: {
            exceed_daily_budget: MIN_TARGET_ROI,
            error: {status: false, message: ''}
        }
    },
    campaign_params: {}
}

export const editCampaignSlice = createSlice({
    name: `${editCampaignSliceName}`,
    initialState,
    reducers: {
        // HANDLE 'ON LOADING'
        actionSetOnLoading(state, action) {
            state.on_loading = action.payload;
        },

        // IS DATA ALREADY LOADED
        actionSetIsLoaded(state, action) {
            state.is_loaded = action.payload;
        },

        actionSetCampaignId(state, action) {
            state.campaign_id = action.payload;
        },

        actionSetCampaignName(state, action) {
            state.campaign_name = action.payload;
        },

        actionSetCampaignParams(state, action) {
            state.campaign_params = action.payload;
        }
    }
});

const {actions} = editCampaignSlice;
export const {
    actionSetOnLoading,
    actionSetIsLoaded,
    actionSetCampaignId,
    actionSetCampaignName,
    actionSetCampaignParams,
} = actions;

export const selectOnLoading = (state) => {
    return state[`${editCampaignSliceName}`].on_loading;
}

export const selectIsLoaded = (state) => {
    return state[`${editCampaignSliceName}`].is_loaded;
}

export const selectCampaignId = (state) => {
    return state[`${editCampaignSliceName}`].campaign_id;
}

export const selectCampaignName = (state) => {
    return state[`${editCampaignSliceName}`].campaign_name;
}

export const selectCampaignParams = (state) => {
    return state[`${editCampaignSliceName}`].campaign_params;
}