import React, {useMemo, useState} from 'react';

import {styled, useTheme} from "@mui/material";

import {AzaButton} from "../mui/AzaButton";
import {AzaAccordion, AzaAccordionDetails, AzaAccordionSummary} from "../mui/AzaAccordion";
import {useScreenSize} from "../../app/globalHooks";
import {AzaBox} from "../mui/AzaBox";
import {AzaKeyboardArrowDown} from "../mui/AzaIcons";

const AzaSettingsBox = styled((props) => {
    const {children, title, button, childsx} = props;
    const {isSmallScreen, isMediumScreen} = useScreenSize();
    const [expand, setExpand] = useState(!isMediumScreen);
    const theme = useTheme();

    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    }

    const detailSX = useMemo(() => {
        return {
            padding: isSmallScreen ? '8px 8px 16px' : '8px 16px 16px',
            ...childsx
        }
    }, [isSmallScreen, childsx])

    return (
        <AzaAccordion
            expanded={expand}
            sx={{
                boxShadow: 'none',
                border: '1px solid',
                borderColor: theme.palette.divider,
                borderRadius: '4px',
                mb: '5px',
            }}
        >
            <AzaAccordionSummary
                expandIcon={<AzaKeyboardArrowDown
                    onClick={toggleAccordion}
                />}
            >
                {title && <AzaBox component={'h2'} sx={{marginTop: '10px', marginBottom: '10px',}}>
                    {title.name}
                    {title?.data}
                </AzaBox>}
                {button &&
                    <AzaBox sx={{
                        width: isSmallScreen ? '100%' : 'auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '10px',
                    }}>
                        <AzaButton
                            variant={button.variant || "contained"}
                            onClick={button.action}
                            disabled={button.disabled}
                        >
                            {button.name}
                        </AzaButton>
                    </AzaBox>
                }
            </AzaAccordionSummary>
            <AzaAccordionDetails
                sx={detailSX}
            >
                {children}
                {/*{expand ? children : null}*/}
            </AzaAccordionDetails>
        </AzaAccordion>
    );
})(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '16px 24px',
}));

export default AzaSettingsBox;
