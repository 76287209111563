import React, {useCallback, useMemo} from 'react';
import {GoogleAdsOAuth} from "../../../widgets/GoogleOAuth/GoogleOAuth";
import {AzaButton} from "../../../components/mui/AzaButton";
import {useSite} from "../../../app/globalHooks";
import {AzaTypography} from "../../../components/mui/AzaTypography";

const Integration = () => {
    const {site, loaded} = useSite()

    const cms = useMemo(() => {
        if (!site) return "unknown"
        return site?.["cms"] ? site?.["cms"] : "unknown"
    }, [site])

    if (!loaded) {
        return <></>
    }

    return (
        <div>
            <h2>Integration</h2>
            <h3>Connect your shop</h3>
            <AzaTypography>
                Detected CMS: {cms}
            </AzaTypography>
            {cms === "Shopify" && <WidgetShopifyInstallation/>}
            <h3>Google Ads</h3>
            <AzaTypography>
                Connect your Google Ads account:
            </AzaTypography>
            <GoogleAdsOAuth/>

        </div>
    );
};

const WidgetShopifyInstallation = () => {

    const InstallShopifyApp = useCallback(() => {
        window.open("https://apps.shopify.com/app71742", '_blank');
    }, [])

    return (
        <AzaButton variant="contained" color="primary" onClick={InstallShopifyApp}>
            Install Shopify App
        </AzaButton>
    )
}

export default Integration;