/*
 *  doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2102951981/AzaInputPhone
 */

import {AzaTextField} from "../mui/AzaTextField";
import {AzaGridContainer, AzaGridItem} from "../mui/AzaGrid";
import {useEffect, useMemo, useState} from "react";
import {
    isValidPhoneNumber, parsePhoneNumber
} from 'libphonenumber-js'
import {AzaCountrySelector} from "./AzaCountrySelector";
import {useTranslation} from "react-i18next";

import useMediaQuery from "@mui/material/useMediaQuery";

export const AzaInputPhone = ({ value, defcountry, onChange, required }) => {

    const [country, setCountry] = useState(defcountry??"FR");
    const [phone, setPhone] = useState((value)?value:"");

    const [errorInvalid, setErrorInvalid] = useState(false);
    const {t} = useTranslation();
    const isMediumScreen =  useMediaQuery(theme => theme.breakpoints.down("md"));

    const error = useMemo(() => {
        return errorInvalid;
    }, [errorInvalid]);

    const helperText = useMemo(() => {
        if (errorInvalid){
            return t('component.azainputphone.invalid');
        }
        return "";
    }, [errorInvalid, t]);

    const phoneChange = (event) => {
        setPhone(event.target.value)
    };

    const phoneFocusedOut = () => {
        validatePhone(country, phone);
    };

    const countryChange = (event) => {
        setCountry(event);
        validatePhone(event, phone);
    }

    useEffect(() => {setPhone(value)}, [value]);

    const validatePhone = (country, phonenumber) => {
        const valid = isValidPhoneNumber(phonenumber, country)
        setErrorInvalid(!valid);
        if (valid){
            const phoneNumber = parsePhoneNumber(phonenumber, country);
            setPhone(phoneNumber.formatInternational())
            onChange?.(country, phoneNumber.formatInternational(), valid);

        } else {
            onChange?.(country, phonenumber, valid);
        }

    }

    return (
        <AzaGridContainer direction={isMediumScreen?"column":"row"}>
            <AzaGridItem xs={6}>
                <AzaCountrySelector
                    sx={{width: "100%"}}
                    country_code={country}
                    onChange={countryChange}
                    required={false}
                    fullWidth
                />
            </AzaGridItem>
            <AzaGridItem xs={6}>
                <AzaTextField
                    required={required}
                    type={"tel"}
                    value={phone}
                    onChange={phoneChange}
                    onBlur={phoneFocusedOut}
                    error={error}
                    helperText={helperText}
                    label={t('component.azainputphone.phonenumber')}
                    fullWidth
                />
            </AzaGridItem>
        </AzaGridContainer>
    );
}

