import {AzaTypography} from "../../components/mui/AzaTypography";
import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {useTheme} from "@mui/material";

import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaButton} from "../../components/mui/AzaButton";
import {useUser} from "../../app/globalHooks";
import {PATH_CAMPAIGN_NEW} from "../../utils/constant";
import {AzaDivider} from "../../components/mui/AzaDivider";
import {AzaStack} from "../../components/mui/AzaStack";
import {InlineEditSelect, Phrase} from "../Phrase";
import {useCreateNewCampaign} from "../../containers/createNewCampaign/createNewCampaignUtile/createNewCampaignHook";


const campaignTypes = [{
    label: "Performance Max", value: "PerfMax",
}, {
    label: "Search", value: "Search", disabled: true,
}, {
    label: "Display", value: "Display", disabled: true,
}, {
    label: "Shopping", value: "Shopping", disabled: true,
},]

const campaignSubTypes = [{
    label: "aicampaign.subtypes.products", value: "Product", disabled: true,
}, {
    label: "aicampaign.subtypes.brand", value: "Brand",
}]

const campaignLanguages = [{
    label: "aicampaign.languages.french", value: "fr",
}, {
    label: "aicampaign.languages.english", value: "en",
},]

const campaignBudgets = [{
    label: "5€", value: 5,
}, {
    label: "10€", value: 10,
}, {
    label: "20€", value: 20,
}, {
    label: "50€", value: 50,
}, {
    label: "100€", value: 100,
}]

const campaignNetworks = [{
    label: "Google Ads", value: "GoogleAds",
}, {
    label: "Azameo RTB", value: "AzameoRTB", disabled: true,
}, {
    label: "Microsoft Ads", value: "MicrosoftAds", disabled: true,
}, {
    label: "All networks", value: "AllNetworks", disabled: true,
}]

const campaignSegments = [{
    label: "aicampaign.segments.newscustomers", value: "NewCustomers", disabled: true,
}, {
    label: "aicampaign.segments.customers", value: "ExistingCustomers", disabled: true,
}, {
    label: "aicampaign.segments.all", value: "BothNewAndExistingCustomers",
}]

const campaignCountries = [{
    label: "aicampaign.countries.fr", value: "FR",
}, {
    label: "aicampaign.countries.uk", value: "GB",
}, {
    label: "aicampaign.countries.be", value: "BE",
}, {
    label: "aicampaign.countries.all", value: "all",
}]


export const AiCampaign = ({disabled, onDone}) => {

    const {user, loading: loadingUser} = useUser()
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const {resetData, setData} = useCreateNewCampaign();

    const [campaignType, setCampaignType] = useState(campaignTypes[0])
    const [campaignSubType, setCampaignSubType] = useState(campaignSubTypes[1])
    const [campaignLanguage, setCampaignLanguage] = useState(campaignLanguages[0])
    const [campaignBudget, setCampaignBudget] = useState(campaignBudgets[0])
    const [campaignNetwork, setCampaignNetwork] = useState(campaignNetworks[0])
    const [campaignSegment, setCampaignSegment] = useState(campaignSegments[2])
    const [campaignCountry, setCampaignCountry] = useState(campaignCountries[0])

    const createCampaign = useCallback(() => {
        if (loadingUser) return;
        if (!user) return;

        resetData();
        setData({
            dailyBudget: campaignBudget,
            campaignLanguage: campaignLanguage,
            campaignCountry: [campaignCountry,],
            campaignScope: campaignSubType,
            campaignType: campaignType,
            campaignNetwork: [campaignNetwork,]
        });

        if(onDone){
            onDone();
        }

        navigate({pathname: PATH_CAMPAIGN_NEW});
    }, [loadingUser, campaignType, campaignSubType, campaignLanguage, campaignBudget, campaignNetwork, campaignCountry, user, navigate, onDone, resetData, setData])

    return (<>
        <AzaStack alignItems={"flex-start"}>
            <AzaGridItem>
                <AzaTypography variant={"body"}>{t("aicampaign.description")} </AzaTypography>
            </AzaGridItem>
            <AzaGridItem>
                <AzaTypography variant={"body"}>{t("aicampaign.info")}</AzaTypography>
            </AzaGridItem>
        </AzaStack>
        <AzaDivider sx={{borderColor: theme.palette.secondary.main}}/>
        <AzaStack alignItems={"flex-end"}>
            <AzaGridItem>
                <Phrase
                    items={[t("aicampaign.create"), <InlineEditSelect
                        options={campaignTypes}
                        defaultValue={campaignType}
                        onChange={setCampaignType}
                    />, t("aicampaign.promote"), <InlineEditSelect
                        options={campaignSubTypes}
                        defaultValue={campaignSubType}
                        onChange={setCampaignSubType}
                    />, t("aicampaign.on"), <InlineEditSelect
                        options={campaignNetworks}
                        defaultValue={campaignNetwork}
                        onChange={setCampaignNetwork}
                    />,]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[t("aicampaign.target"), <InlineEditSelect
                        options={campaignSegments}
                        defaultValue={campaignSegment}
                        onChange={setCampaignSegment}
                    />, t("aicampaign.in"), <InlineEditSelect
                        options={campaignCountries}
                        defaultValue={campaignCountry}
                        onChange={setCampaignCountry}
                    />, t("aicampaign.to"), <InlineEditSelect
                        options={campaignLanguages}
                        defaultValue={campaignLanguage}
                        onChange={setCampaignLanguage}
                    />]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[t("aicampaign.spend"), <InlineEditSelect
                        options={campaignBudgets}
                        defaultValue={campaignBudget}
                        onChange={setCampaignBudget}
                    />, t("aicampaign.perday")]}
                />
            </AzaGridItem>
        </AzaStack>
        <br/>
        <AzaGridContainer direction={"row"}>
            <AzaGridItem xs>
            </AzaGridItem>
            <AzaGridItem>
                <AzaButton
                    onClick={createCampaign}
                    variant={"contained"}
                    disabled={disabled}
                >{t("aicampaign.button")}
                </AzaButton>
            </AzaGridItem>
        </AzaGridContainer>
    </>)
}
