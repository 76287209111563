import React from 'react';
import {FacebookWidget} from "../widgets/facebook/FacebookWidget";

const SampleFacebook = () => {
    return (
        <>
            <FacebookWidget/>
        </>
    );
};

export default SampleFacebook;