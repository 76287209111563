import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
    actionSetLongHeadlines,
    actionUpdateAssets,
    selectLongHeadlines,
    selectProductAssetSelected,
} from "../../reducers/createNewCampaignSlice";
import InputCreation from "./addAssetsUtility/InputCreation";
import {AzaBox} from "../../../../components/mui/AzaBox";
import ToolTips from "../../createNewCampaignUtile/ToolTips";
import {allGrey} from "../../../../utils/colors";
import {AzaGridContainer, AzaGridItem} from "../../../../components/mui/AzaGrid";
import AddNewInputBtn from "../../createNewCampaignUtile/AddNewInputBtn";

const LongHeadlines = (props) => {
    const {inputGroupName, toolTipsText, configData, addNewInputBtnName, minMaxIndication, currentSiteId,
        assetsScope, productId, assetsDataProduct} = props;
    const dispatch = useDispatch();
    const assetsDataSite = useSelector(selectLongHeadlines);
    const minQuantity = configData.minInputs;
    const maxQuantity = configData.maxInputs;
    const maxLength = configData.maxLength;

    // Hardcoded data. 'Site' scope VS 'Product' scope, to see how to manage this
    const isMultiline = true;
    const componentClass = 'long-headline';
    const language = 'fr';
    const inputName = 'long-headline';
    const textType = 'Long Headline';
    const siteId = currentSiteId;
    const assetsData = useMemo(() => {
        return (assetsScope === 'Site') ? assetsDataSite : (assetsDataProduct[textType] ? assetsDataProduct[textType] : []);
    }, [assetsScope, assetsDataSite, assetsDataProduct]);

    const generateNewInputObj = useCallback((assetId) => {
        return {
            cleaned_text: "",
            enabled: true,
            id: assetId,
            language: language,
            origin: "Customer",
            product_id: (assetsScope === 'Site') ? null : productId,
            scope: assetsScope,
            site_id: siteId,
            text: "",
            text_type: textType,
        }
    }, [productId, assetsScope, siteId]);

    const handleAddNewInput = useCallback(() => {
        let controlNum = assetsData.length;
        if(controlNum < maxQuantity) {
            const keyHolder = Date.now() + '-' + Math.floor(Math.random() * 1000);
            const newObj = generateNewInputObj(keyHolder);
            const inputData = {data: [...assetsData, newObj], scope: assetsScope, productId: productId}
            dispatch(actionSetLongHeadlines(inputData));
        } else {
            // create alert of error
            console.log(`You can add ${maxQuantity} long headlines maximum`);
        }
    }, [assetsData, maxQuantity, generateNewInputObj, dispatch, assetsScope, productId]);

    const handleRemoveInput = useCallback((index) => {
        const newAssets = assetsData.filter((el) => el.id !== index);
        dispatch(actionUpdateAssets(newAssets));
    }, [assetsData, dispatch]);

    const inputs = useMemo(() => {
        // in future version possible situation : assetsData.length > maxQuantity, need to manage it
        return  assetsData.map((el) => {
            return (
                <AzaGridItem key={el.id} xs={12} md={6}>
                    <InputCreation
                        inputName={inputName}
                        maxLength={maxLength}
                        handleRemoveInput={() => handleRemoveInput(el.id)}
                        minQuantity={minQuantity}
                        nameOfClass={componentClass}
                        isMultiline={isMultiline}
                        assetValue={el.cleaned_text}
                        assetData={el}
                        selectAssetsFunc={assetsScope === 'Site' ? selectLongHeadlines : selectProductAssetSelected}
                    />
                </AzaGridItem>)
        });
    }, [assetsData, maxLength, handleRemoveInput, minQuantity, isMultiline, assetsScope]);

    return (
        <AzaBox sx={{mb: '30px'}}>
            <AzaBox sx={{mb: '20px', display: 'flex'}}>
                <AzaBox sx={{fontWeight: 'bold', mr: '5px'}}>{inputGroupName}</AzaBox>
                <ToolTips toolTipsText={toolTipsText}/>
            </AzaBox>
            <AzaBox sx={{mb: '20px', color: allGrey[500]}}>
                {minMaxIndication} ({assetsData.length})
            </AzaBox>
            <AzaGridContainer container spacing={2}>
                {inputs}
                <AddNewInputBtn
                    assetsData={assetsData}
                    maxQuantity={maxQuantity}
                    handleAddNewInput={handleAddNewInput}
                >
                    {addNewInputBtnName}
                </AddNewInputBtn>
            </AzaGridContainer>
        </AzaBox>
    );
};

export default LongHeadlines;