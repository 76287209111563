import { RightOutlined } from '@ant-design/icons';

const AccordionSummary = (theme) => {
  const { palette, spacing } = theme;

  return {
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <RightOutlined style={{ fontSize: '0.75rem' }} />
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.background.paper,
          flexDirection: 'row-reverse',
          minHeight: 46
        },
        expandIconWrapper: {
          transform: 'rotate(-90deg)',
          '&.Mui-expanded': {
            transform: 'rotate(0deg)'
          }
        },
        content: {
          marginTop: spacing(1.25),
          marginBottom: spacing(1.25),
          marginLeft: spacing(1)
        }
      }
    }
  };
}

export default AccordionSummary;
