import {useState} from "react";
import {useTranslation} from "react-i18next";

import {AzaStack} from "../mui/AzaStack";
import {AzaGridItem} from "../mui/AzaGrid";
import {InlineEditSelect, Phrase} from "../../widgets/Phrase";

const listOptimLimits = [10, 20, 50 ,100]
const lostOptimConstraints = [250, 500, 1000]
const listDailyVariations = [5, 10, 20, 50]
const listOptimWindows = [7]

const OptimisationBudgetRoas = () => {
    const {t} = useTranslation();

    const optimLimits = listOptimLimits.map((value) => ({label: t("optimroas.limit", {value}), value}));
    const optimConstraints = lostOptimConstraints.map((value) => ({label: t("optimroas.percentage", {value}), value}));
    const dailyVariations = listDailyVariations.map((value) => ({label: t("optimroas.percentage", {value}), value}));
    const optimWindows = listOptimWindows.map((value) => ({label: t("optimroas.windowspan", {value}), value}));

    const [optimLimit, setOptimLimit] = useState(optimLimits[0]);
    const [optimConstraint, setOtimConstraint] = useState(optimConstraints[0]);
    const [dailyVariationLow, setDailyVariationLow] = useState(dailyVariations[1]);
    const [dailyVariationHigh, setDailyVariationHigh] = useState(dailyVariations[2]);
    const [window, setWindows] = useState(optimWindows[0]);

    return (
        <AzaStack>
            <AzaGridItem>
                <Phrase
                    items={[
                        t("optimroas.increase"),
                        <InlineEditSelect
                            options={optimLimits}
                            defaultValue={optimLimit}
                            onChange={setOptimLimit}
                        />,
                        t("optimroas.roas_target"),
                        <InlineEditSelect
                            options={optimConstraints}
                            defaultValue={optimConstraint}
                            onChange={setOtimConstraint}
                        />
                    ]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[
                        t("optimroas.daily_pace_up"),
                        <InlineEditSelect
                            options={dailyVariations}
                            defaultValue={dailyVariationHigh}
                            onChange={setDailyVariationHigh}
                        />,
                        t("optimroas.daily_pace_down"),
                        <InlineEditSelect
                            options={dailyVariations}
                            defaultValue={dailyVariationLow}
                            onChange={setDailyVariationLow}
                        />
                    ]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[
                        t("optimroas.window"),
                        <InlineEditSelect
                            options={optimWindows}
                            defaultValue={window}
                            onChange={setWindows}
                        />,
                    ]}
                />
            </AzaGridItem>
        </AzaStack>
    )
}

export default OptimisationBudgetRoas;