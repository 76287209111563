import {Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {useDispatch, useSelector} from "react-redux";
import {
    actionResetStripePaymentIntent,
    actionSetCode,
    actionSetReentry,
    actionSetStatus,
    getStripePaymentIntent,
    selectStripePaymentIntent,
    selectTotals,
} from "./paymentSlice";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ButtonBase, CircularProgress, Paper, Typography} from "@mui/material";
import {PaymentOutlined} from "@mui/icons-material";
import {AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";
import {loadStripe} from "@stripe/stripe-js";
import {
    API_POST_STRIPE_ORDER_CANCEL,
    stripeInitialOptions
} from "../../../utils/constant";
import {usePayment} from "./paymentHook";
import {useFormat, useSiteId, useUser} from "../../../app/globalHooks";
import {useTranslation} from "react-i18next";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaModalStepperButtons} from "../AzaModalStepperButtons";
import {methodType, siteBackendFetch} from "../../../utils/backendHelper";
import {AzaSkeleton} from "../../../components/mui/AzaSkeleton";
import {useTheme} from "@mui/material";
import {alpha} from "@mui/material/styles";

const stripePromise = loadStripe(stripeInitialOptions.publicKey);

const CheckoutForm = ({paimentDone, paimentCanceled}) => {

    const {user} = useUser();
    const site_id = useSiteId();
    const elements = useElements();

    const [canPay, setCanPay] = useState(false);
    const [canPayStripe, setCanPayStripe] = useState(false);
    const [canPayStripeEmail, setCanPayStripeEmail] = useState(false);
    const [pendingConfirmation, setPendingConfirmation] = useState(false);

    const stripe = useStripe();
    const dispatch = useDispatch();

    const paymentIntent = useSelector(selectStripePaymentIntent);

    const {t} = useTranslation();

    const setReentry = useCallback((new_reentry) => {
        dispatch(actionSetReentry(new_reentry));
    }, [dispatch]);

    const setCode = useCallback((code) => {
        dispatch(actionSetCode(code));
    }, [dispatch]);

    const setStatus = useCallback((status) => {
        dispatch(actionSetStatus(status));
    }, [dispatch]);

    useEffect(() => {
        const paymentElement = elements?.getElement("payment")

        if (paymentElement) {
            paymentElement.on("change", (event) => {
                if (event.complete) {
                    setCanPayStripe(true);
                } else {
                    setCanPayStripe(false);
                }
            });
        }
    }, [elements,  setCanPayStripe]);

    useEffect(() => {
        const linkAuthenticationElement = elements?.getElement("linkAuthentication")

        if (linkAuthenticationElement) {
            linkAuthenticationElement.on("change", (event) => {
                if (event.complete) {
                    setCanPayStripeEmail(true);
                } else {
                    setCanPayStripeEmail(false);
                }
            });
        }
    }, [elements, setCanPayStripeEmail]);

    useEffect(() => {
        setCanPay(canPayStripeEmail && canPayStripe);
    }, [ canPayStripe, canPayStripeEmail]);

    const confirmStripe = useCallback( async () => {
        if (!stripe && !elements) {
            return
        }

        setPendingConfirmation(true)
        stripe.confirmPayment(
            {
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}${window.location.pathname}?returning=stripe`
                },
                redirect: "if_required"
            }
        ).then((result) => {
            if (result.error) {

            } else {
                setReentry("stripe");
                setStatus("succeeded");
                setCode(result.paymentIntent.id);
                setPendingConfirmation(false);
                if (paimentDone) {
                    paimentDone();
                }
            }
        }).finally(
            () => {
                setPendingConfirmation(false);
            }
        )
    }, [stripe, elements, setReentry, setStatus, setCode, setPendingConfirmation, paimentDone]);

    const cancelStripe = useCallback(() => {
        if (!stripe && !elements) {
            return;
        }

        if (paymentIntent.client_secret) {
            siteBackendFetch(
                {
                    user: user,
                    site_id: site_id,
                    path: API_POST_STRIPE_ORDER_CANCEL,
                    method: methodType.POST,
                    data: {payment_secret: paymentIntent.client_secret}
                }
            ).finally(() => {
                dispatch(actionResetStripePaymentIntent());
                setPendingConfirmation(false);

                if (paimentCanceled) {
                    paimentCanceled();
                }
            });
        } else {
            setPendingConfirmation(false);
            if (paimentCanceled) {
                paimentCanceled();
            }
        }

    }, [dispatch, paimentCanceled, stripe, elements, user, site_id, paymentIntent]);

    const appearance = useMemo(() => ({
        theme: 'flat'
    }), []);


    useEffect(() => {
        elements?.update({
            appearance: appearance
        });
    }, [appearance, elements]);

    if (paymentIntent.client_secret || pendingConfirmation) {
        return (
            <AzaBox>
                <LinkAuthenticationElement />
                <br/>
                <PaymentElement />
                <br/>
                <AzaModalStepperButtons
                    handleNext={confirmStripe}
                    handleBack={cancelStripe}
                    canNext={canPay}
                    nextButtonText={t('common.paiement')}
                />
            </AzaBox>
        )
    }

    return (
        <>
            <CircularProgress/>
        </>
    )

};

const StripeHandler = ({paimentDone, paimentCanceled}) => {

    return (
        <CheckoutForm
            paimentDone={paimentDone}
            paimentCanceled={paimentCanceled}
        />
    )
};

export const StripeBox = ({paimentDone, paimentCanceled}) => {
    const paymentIntent = useSelector(selectStripePaymentIntent);
    const {t} = useTranslation();
    const themeMode = useTheme().palette.mode ;
    const stripeTheme = (themeMode === 'dark')?'night':'stripe';

    const stripe_options = useMemo(() => {
        if (paymentIntent.client_secret) {
            return {
                clientSecret: paymentIntent.client_secret,
                appearance: {
                    theme: stripeTheme.toString()
                }
            }
        }
        return {
            appearance: {
                theme: stripeTheme.toString()
            }
        }
    }, [paymentIntent, stripeTheme]);

    if (!paymentIntent.client_secret) {
        return (
            <AzaBox>
                <AzaGridContainer direction={"column"}>
                    <AzaGridItem>
                        <AzaSkeleton variant={"rectangular"} width={"100%"} height={"40px"}/>
                        <br/>
                    </AzaGridItem>
                    <AzaGridItem>
                        <AzaSkeleton variant={"rectangular"} width={"100%"} height={"40px"}/>
                    </AzaGridItem>
                    <AzaGridItem >
                        <AzaBox sx={{display: "flex"}} >
                            <AzaSkeleton variant={"rectangular"} width={"40%"} height={"40px"}/>
                            <AzaBox sx={{width: "20px"}}/>
                            <AzaSkeleton variant={"rectangular"} width={"40%"} height={"40px"}/>
                        </AzaBox>
                    </AzaGridItem>
                    <AzaGridItem>
                        <AzaSkeleton variant={"rectangular"} width={"100%"} height={"40px"}/>
                    </AzaGridItem>
                </AzaGridContainer>
                <br/>
                <AzaModalStepperButtons
                    handdleBack={paimentCanceled}
                    canNext={false}
                    nextButtonText={t('common.paiement')}
                />
            </AzaBox>
        )
    }

    return (
        <>
            <Elements stripe={stripePromise} options={stripe_options}>
                <StripeHandler
                    paimentDone={paimentDone}
                    paimentCanceled={paimentCanceled}
                />
                <br/>
            </Elements>
        </>
    )

}

const AzaStripeButton = (props) => {

    const totals = useSelector(selectTotals);
    const dispatch = useDispatch();
    const {currency} = usePayment();
    const {formatMoney} = useFormat();
    const {user} = useUser();
    const site_id = useSiteId();
    const {t} = useTranslation();
    const theme = useTheme();

    const requestPending = useSelector(selectStripePaymentIntent).status === "pending";

    const {onClick, sx, onDone, ...other} = props;

    const baseSx = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 253,
        height: 128,
        fontSize: '20px',
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.default,
        "&:hover": {
            backgroundColor: alpha(
                theme.palette.primary.light,
                theme.palette.action.hoverOpacity),
        },
        ...sx
    };

    const handleSinglePayment = useCallback(() => {
        const paimentData = {
            description: formatMoney(totals.ttc, currency),
            value: totals.ht,
            promocode: totals.promocode.code,
            billing_info: totals.billing_info,
            reccuring: totals.reccuring
        }
        dispatch(getStripePaymentIntent({user: user, site_id: site_id, paiement_data: paimentData}));
        if (onDone) {
            onDone();
        }
    }, [formatMoney,
        totals.ttc, totals.ht, totals.promocode.code, totals.billing_info, totals.reccuring,
        currency, dispatch, user, site_id, onDone]);

    const handleClick = useCallback( () => {
        handleSinglePayment();
    }, [handleSinglePayment])

    return (
        <>
            <ButtonBase onClick={handleClick} {...other} disabled={requestPending}>
                <Paper variant="outlined" sx={baseSx} >
                    <AzaGridContainer direction="column">
                        <AzaGridItem><PaymentOutlined color={"primary"} sx={{fontSize: '45px'}}/></AzaGridItem>
                        <AzaGridItem>
                            {(requestPending && <CircularProgress/>)}
                            {(!requestPending &&
                                <Typography sx={{fontSize: '20px',}}>{t("payment.method.stripe.title")}</Typography>)}
                        </AzaGridItem>
                    </AzaGridContainer>
                </Paper>
            </ButtonBase>
        </>
    )
};

export default AzaStripeButton;
