import {AzaPage, AzaPageItem} from "../components/azameo/AzaPage";
import {SampleColors} from "./components/colors";
import {SampleTypography} from "./components/typography";
import {SampleAzaIcons, SampleAzaLogos} from "./components/icons";

export const SampleColorsPage = () => {
    const colortitle = "Colors";
    const typotitle = "Typographies";
    const icontitle = "Icons";
    const logotitle = "Logos";

    const page_items = [
        <AzaPageItem maptitle={colortitle} anchor_id={colortitle}><SampleColors title={colortitle}/></AzaPageItem>,
        <AzaPageItem maptitle={typotitle} anchor_id={typotitle}><SampleTypography title={typotitle}/></AzaPageItem>,
        <AzaPageItem maptitle={icontitle} anchor_id={icontitle}><SampleAzaIcons title={icontitle}/></AzaPageItem>,
        <AzaPageItem maptitle={logotitle} anchor_id={logotitle}><SampleAzaLogos title={logotitle}/></AzaPageItem>,
    ];

    return (
        <>
            <AzaPage pageItems={page_items} sorted={true}/>
        </>
    );
};
