import React from 'react';

const Credits = () => {
    return (
        <div>
            Credits
        </div>
    );
};

export default Credits;