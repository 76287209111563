import {createContext, useCallback, useContext, useMemo, useState} from "react";
import {useChatBackend} from "../../features/chat/chatHooks";

const ChatContext = createContext(null);
ChatContext.displayName = 'chatContext';

const useChat = () => {

    const {suggestions, placeholder, lastMessage, postMessage, sending, messages} = useChatBackend();

    const promptDisabled = useMemo(() => {
        return lastMessage?.disable_prompt ?? false;
    }, [lastMessage]);

    const onSubmitPrompt = useCallback(async (question) => {
        postMessage(question);
    }, [postMessage]);

    return {
        messages,
        lastMessage,
        placeholder,
        promptDisabled,
        suggestions,
        onSubmitPrompt,
        sending,
    }
}

export const ChatProvider = ({children}) => {
    const value = useChat();
    return (<ChatContext.Provider value={value}>
        {children}
    </ChatContext.Provider>)
}

export const useChatProvider = () => useContext(ChatContext);
