export const isADate = (date) => {
    return date instanceof Date;
}

function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

export const todayDateFormat = () => {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('en', {month: 'long'});
    const day = date.getDate();
    const month = formatter.format(date);
    const year = date.getFullYear();
    // const todayDateFormat = [day, month, year].join(' ');
    return [day, month, year].join(' ');
}
