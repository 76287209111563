import {AzaPage, AzaPageItem} from "../../components/azameo/AzaPage";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import AzaSettingsBox from "../../components/azameo/AzaSettingsBox";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {FormGroup, Typography} from "@mui/material";
import {useUser} from "../../app/globalHooks";
import ResetPassword from "../../containers/resetPassword/ResetPassword";
import {useTranslation} from "react-i18next";
import {AzaTextField} from "../../components/mui/AzaTextField";
import {AzaInputPhone} from "../../components/azameo/AzaInputPhone";
import {useDbItem} from "../../utils/rdbHooks";
import {RDB_USER} from "../../utils/constant";
import {AzaAvatar} from "../../components/mui/AzaAvatar";
import {VerifyEmail} from "../../containers/resetPassword/VerifyEmail";
import {AzaButton} from "../../components/mui/AzaButton";

const ConnectionInfo = () => {
    const {user, loading, userInfos} = useUser()
    const [openReset, setOpenReset] = useState(false);
    const [openVerification, setOpenVerification] = useState(false);

    const needEmailVerification = useMemo(() => {
        return user?.emailVerified === false;
    }, [user]);

    const {t} = useTranslation();

    const handleResetPasseClose = () => {
        setOpenReset(false);
    }

    const handleVerificationClose = () => {
        setOpenVerification(false);
    }

    // noinspection JSCheckFunctionSignatures
    return (
        <AzaSettingsBox
            title={{name: t('container.accountsetting.title'), data: ""}}
            button={{
                name: t('container.resetpassword.resetbutton'), action: () => {
                    setOpenReset(true)
                }
            }}
        >
            <AzaGridContainer direction={"row"}>
                <AzaGridItem>
                    <AzaAvatar
                        src={userInfos.photoURL}
                        alt={userInfos.name}
                    />
                </AzaGridItem>
                <AzaGridItem>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem>
                            <Typography
                                variant={"body2"}>{t('container.accountsetting.user', {name: userInfos.name})}</Typography>
                            <Typography
                                variant={"body2"}>{t('container.accountsetting.email', {email: user.email})}</Typography>
                        </AzaGridItem>
                        <AzaGridItem>
                            {needEmailVerification && <AzaButton color={"error"} loading={loading} onClick={() => {
                                setOpenVerification(true)
                            }}>{t('container.verifyemail.button')}</AzaButton>}
                        </AzaGridItem>
                    </AzaGridContainer>
                </AzaGridItem>
            </AzaGridContainer>
            <ResetPassword
                openReset={openReset}
                handleResetPasseClose={handleResetPasseClose}
                defaultemail={user.email}
            />
            {needEmailVerification && (
                <VerifyEmail
                    open={openVerification}
                    handleClose={handleVerificationClose}
                />
            )}
        </AzaSettingsBox>
    )
};

const ProfileInfo = () => {
    const {t} = useTranslation();
    const {user, loading: loadingUser} = useUser()
    const {item: userDB, loadingDb, updateField} = useDbItem(RDB_USER, user?.uid)

    const firstName = useMemo(() => {
        return userDB?.firstname
    }, [userDB]);
    const lastName = useMemo(() => {
        return userDB?.lastname
    }, [userDB]);

    const [phone, setPhone] = useState('');
    const country = useMemo(() => {
        return userDB?.country
    }, [userDB]);

    useEffect(() => {
        setPhone(userDB?.phone);
    }, [userDB]);

    const handleFistNameChange = useCallback((e) => {
        if (!loadingDb && !loadingUser && user) {
            updateField("firstname", e.target.value);
        }
    }, [loadingDb, loadingUser, user, updateField]);

    const handleLastNameChange = useCallback((e) => {
        if (!loadingDb && !loadingUser && user) {
            updateField("lastname", e.target.value);
        }
    }, [loadingDb, loadingUser, user, updateField]);

    const handlePhoneChange = useCallback((newcountry, phone, valid) => {
        if (!loadingDb && !loadingUser && user && country !== newcountry) {
            updateField("country", newcountry)
        }
        if (!loadingDb && !loadingUser && user && valid) {
            updateField("phone", phone);
        }
    }, [loadingDb, loadingUser, user, country, updateField]);

    return (
        <AzaSettingsBox
            title={{name: t('container.accountsetting.profileinfo.title'), data: ""}}
        >
            <Typography variant={"body2"}>{t('container.accountsetting.profileinfo.text')}</Typography>
            <FormGroup>
                <AzaTextField required={false} label={t('container.accountsetting.profileinfo.firstname')}
                              value={firstName} onChange={handleFistNameChange}/>
                <AzaTextField required={false} label={t('container.accountsetting.profileinfo.lastname')}
                              value={lastName} onChange={handleLastNameChange}/>
                <AzaInputPhone required={false} label={t('container.accountsetting.profileinfo.phonenumber')}
                               value={phone} defcountry={country} onChange={handlePhoneChange}/>
            </FormGroup>
        </AzaSettingsBox>
    )
}

const AccountSettings = () => {

    const {t} = useTranslation();
    const page_items = [
        <AzaPageItem key={1} maptitle={t('container.accountsetting.title')}
                     anchor_id={'connection-info'}><ConnectionInfo/></AzaPageItem>,
        <AzaPageItem key={1} maptitle={t('container.accountsetting.profileinfo.title')}
                     anchor_id={'profile-info'}><ProfileInfo/></AzaPageItem>,
    ];

    return (
        <AzaPage pageItems={page_items}/>
    )
}

export default AccountSettings
