import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {AzaBox} from "../mui/AzaBox";
import {
    MIN_BUDGET_AMOUNT,
    MIN_TARGET_ROI
} from "../../containers/createNewCampaign/createNewCampaignUtile/createNewCamaignConst";
import AzaInputNumber from "../azameo/AzaInputNumber";
import {AzaInputAdornment} from "../mui/AzaInputAdornment";
import {AzaAlert} from "../mui/AzaAlert";
import {AzaFormGroup} from "../mui/AzaFormGroup";
import {AzaFormControlLabel} from "../mui/AzaFormControlLabel";
import {AzaCheckbox} from "../mui/AzaCheckbox";
import {usePayment} from "../../containers/payment/paymentGroup/paymentHook";
import {selectSiteSettingsReady} from "../../app/globalSlice";

const CampaignBudget = (
    {handleBudgetInput, handleExceedInput, handleCheckboxChange, dailyBudgetData, isExceedChecked, exceedDailyBudgetData, edit = false}
) => {
    const {t} = useTranslation();
    const settingsReady = useSelector(selectSiteSettingsReady);
    const {currency} = usePayment();
    const currencySymbol = (settingsReady)?t(`currency.${currency}`):"";

    return (
        <>
            <AzaBox sx={{mb: '30px'}}>
                <AzaBox sx={{mb: '15px', display: 'flex'}}>
                    <AzaBox sx={{mr: '5px'}}>
                        {t('campaigns_data.min-average-daily-budget', {amount: MIN_BUDGET_AMOUNT, currencySymbol: currencySymbol})}
                    </AzaBox>
                </AzaBox>
                <AzaBox>
                    <AzaInputNumber
                        sx={{maxWidth: '100px'}}
                        required
                        error={dailyBudgetData?.error?.status}
                        placeholder={t('payment.amount.custom.placeholder')}
                        value={dailyBudgetData.daily_budget > 0 && dailyBudgetData.daily_budget}
                        onChange={handleBudgetInput}
                        size="small"
                        inputProps={{"data-minval": MIN_BUDGET_AMOUNT}}
                        helperText={''}
                        InputProps={{
                            endAdornment: <AzaInputAdornment position="end">{currencySymbol}</AzaInputAdornment>,
                        }}
                    />
                </AzaBox>
                {dailyBudgetData?.error?.status &&
                    <AzaAlert severity="error" sx={{marginTop: '15px'}}>
                        <AzaBox>
                            {
                                dailyBudgetData?.error?.message && <AzaBox>{dailyBudgetData.error.message}</AzaBox>
                            }
                        </AzaBox>
                    </AzaAlert>}
            </AzaBox>

            {!edit && <AzaBox>
                <AzaBox sx={{mb: '15px', display: 'flex', alignItems: 'center'}}>
                    <AzaFormGroup>
                        <AzaFormControlLabel
                            control={
                                <AzaCheckbox
                                    checked={isExceedChecked}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label={t('campaigns_data.exceed-roi-checkbox')}
                        />
                    </AzaFormGroup>
                    {isExceedChecked && <AzaInputNumber
                        sx={{maxWidth: '100px'}}
                        required
                        error={exceedDailyBudgetData?.error?.status}
                        value={exceedDailyBudgetData.exceed_daily_budget}
                        onChange={handleExceedInput}
                        size="small"
                        inputProps={{"data-minval": MIN_TARGET_ROI}}
                        helperText={''}
                        InputProps={{
                            endAdornment: <AzaInputAdornment position="end">{'%'}</AzaInputAdornment>,
                        }}
                    />}
                </AzaBox>
                {exceedDailyBudgetData?.error?.status &&
                    <AzaAlert severity="error" sx={{marginTop: '15px'}}>
                        <AzaBox>
                            {
                                exceedDailyBudgetData?.error?.message &&
                                <AzaBox>{exceedDailyBudgetData.error.message}</AzaBox>
                            }
                        </AzaBox>
                    </AzaAlert>}
            </AzaBox>}
        </>
    );
};

export default CampaignBudget;