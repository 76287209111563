import {useAsyncTaskCall} from "../../app/globalHooks";
import {useCallback} from "react";
import {useSelector} from "react-redux";
import {selectCurrentPeriod} from "../../app/globalSlice";
import {useTranslation} from "react-i18next";
import {methodType} from "../../utils/backendHelper";
import {API_GET_BRANDROI_CALCULATE} from "../../utils/constant";
import {AdminModeBranch} from "../../features/adminMode/adminModeComponents";
import {AzaContainedButton} from "../../components/mui/AzaButton";
import {AzaSync} from "../../components/mui/AzaIcons";

export const RecalculateBrandroiButton = () => {

    const currentPeriod = useSelector(selectCurrentPeriod)
    const {t} = useTranslation()
    const {pending, siteAsyncTask} = useAsyncTaskCall();

    const onClick = useCallback(() => {
        siteAsyncTask(
            {
                path:API_GET_BRANDROI_CALCULATE,
                queryParams: {
                    dstart: currentPeriod["start"],
                    dend: currentPeriod["end"]
                },
                method: methodType.GET,
            }
        )

    }, [siteAsyncTask, currentPeriod]);

    return (
        <AdminModeBranch needAdmin={true}>
            <AzaContainedButton
                loading={pending}
                loadingPosition="start"
                startIcon={<AzaSync/>}
                onClick={onClick}
                disabled={pending}
            >
                {t("attribution.calculate")}
            </AzaContainedButton>
        </AdminModeBranch>
    )
}
