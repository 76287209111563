import React from 'react';
import {AzaList, AzaListItem, AzaListItemButton, AzaListItemIcon, AzaListItemText} from "../mui/AzaList";
import {Link} from "react-router-dom";
import {AdminModeBranch} from "../../features/adminMode/adminModeComponents";

const DataDrawerList = ({iconsData, open, isHeader = false}) => {
    return (
        <AzaList sx={{display: isHeader ? 'flex' : 'block'}}>
            {iconsData.map((obj, index) => (
                <AdminModeBranch key={index} needAdmin={!!(obj.admin)}>

                    <AzaListItem key={index + obj.name} disablePadding sx={{display: 'block'}}>
                        {obj.link &&
                            <Link style={{textDecoration: 'none', color: 'inherit'}} to={obj.link}>
                                <AzaListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                    }}
                                >
                                    {isHeader ? <AzaListItemText primary={obj.name}/> : null}
                                    <AzaListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {obj.icon}
                                    </AzaListItemIcon>
                                    {isHeader ? null : <AzaListItemText primary={obj.name} sx={{opacity: open ? 1 : 0}}/>}
                                </AzaListItemButton>
                            </Link>}
                        {obj.action &&
                            <AzaListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                            }}
                            onClick={obj.action}
                            >
                                {isHeader ? <AzaListItemText primary={obj.name}/> : null}

                                <AzaListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {obj.icon}
                                </AzaListItemIcon>
                                {isHeader ? null : <AzaListItemText primary={obj.name} sx={{opacity: open ? 1 : 0}}/>}
                            </AzaListItemButton>
                            }
                    </AzaListItem>
                </AdminModeBranch>
            ))}
        </AzaList>
    );
};

export default DataDrawerList;
