import React, {forwardRef, useMemo} from 'react';

import {Box, Divider, Fade, Typography} from "@mui/material";

import {useFormat} from "../../../app/globalHooks";
import {useSelector} from "react-redux";
import {selectCurrency, selectReccuring, selectRenewalActive, selectTotals, VAT_STATUS} from "./paymentSlice";
import {useTranslation} from "react-i18next";

const styledData = {
    detailText: {
        display: "flex",
        justifyContent: "space-between",
        margin: "30px 0",
    }
}

const PromocodeItem = ({promocodeinfo}) => {
    const {formatMoney} = useFormat();
    const {t} = useTranslation();

    const promoCode = promocodeinfo.code;
    const promoAmount = promocodeinfo.amount
    const promoRemoveCredit = promocodeinfo.remove;
    const promoAddCredit = promocodeinfo.add;

    if (promoAddCredit) {
        return (
            <Box style={styledData.detailText}>
                <span>{t('payment.promocode.detail.creditsofferts', {promoCode: promoCode})}</span>
                <span>{formatMoney(promoAmount)}</span>
            </Box>
        )
    }
    if (promoRemoveCredit) {
        return (
            <Box style={styledData.detailText}>
                <span>{promoCode}</span>
                <span>{formatMoney(-1 * promoAmount)}</span>
            </Box>
        )
    }
    return <></>

}

const DetailRenewal = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const hasRenewal = useSelector(selectRenewalActive)
    return (
        <div ref={ref}>
            <Typography sx={{marginBottom: "18px"}}>{t('payment.detail.renewalactive')}</Typography>
            {hasRenewal && <Typography sx={{marginBottom: "18px"}}>{t('payment.detail.renewalchanged')}</Typography>}
        </div>

    )
});

const DetailPayment = () => {
    const {
        vat_status,
        promocode,
        ht,
        ttc
    } = useSelector(selectTotals);

    const {t} = useTranslation();

    const tax = useMemo(() => ttc - ht, [ttc, ht]);

    const {formatMoney} = useFormat();
    const renewalSwitch = useSelector(selectReccuring);
    const currency = useSelector(selectCurrency);

    return (
        <div>
            <Box component={'h3'} sx={{marginTop: '6px'}}>
                {t('payment.detail.title')}
            </Box>
            {renewalSwitch &&
                <Fade in={renewalSwitch}>
                    <DetailRenewal/>
                </Fade>}
            <Divider/>
            <Box style={styledData.detailText}>
                <span>{t('payment.detail.subtotal')}</span>
                <span>{formatMoney(ht, currency)}</span>
            </Box>
            <PromocodeItem
                promocodeinfo={promocode}
            />
            <Box style={styledData.detailText}>
                <span>{t('payment.detail.tax')}</span>
                <span>{(vat_status === VAT_STATUS.IDLE) && t('payment.detail.calculatednext')}{(vat_status !== VAT_STATUS.IDLE) && formatMoney(tax, currency)}</span>
            </Box>
            <Divider/>
            <Box style={styledData.detailText}>
                <span>{t('payment.detail.total')}</span>
                <span>{(vat_status === VAT_STATUS.IDLE) && '--'}{(vat_status !== VAT_STATUS.IDLE) && formatMoney(ttc, currency)}</span>
            </Box>
        </div>
    );
};

export default DetailPayment;
