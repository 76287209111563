import {AzaButton} from "../../../components/mui/AzaButton";
import {AzaAdd} from "../../../components/mui/AzaIcons";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {AzaBox} from "../../../components/mui/AzaBox";

const AddNewInputBtn = ({assetsData, maxQuantity, handleAddNewInput, children}) => {

    return (
        <AzaBox sx={{width: '100%', textAlign: 'right', mt: '15px'}}>
            <AzaButton
                sx={{p: '10px 16px 10px 12px'}}
                variant="outlined"
                onClick={handleAddNewInput}
                disabled={assetsData.length >= maxQuantity}
            >
                <AzaAdd sx={{fontSize: '17px', mr: '3px'}}/>
                <AzaTypography sx={{fontSize: '14px', lineHeight: '1.1', marginTop: '1.5px'}}>
                    {children}
                </AzaTypography>
            </AzaButton>
        </AzaBox>
    );
};

export default AddNewInputBtn;