import {AzaBox} from "../../../components/mui/AzaBox";
// import Audiences from "./audienceTargetinGroup/Audiences";
import Keywords from "./audienceTargetinGroup/Keywords";
import Geo from "./audienceTargetinGroup/Geo";

const Audience = () => {
    return (
        <AzaBox>
            {/*<Audiences/>*/}
            <Geo/>
            <Keywords/>
        </AzaBox>
    );
};

export default Audience;