import React from "react";
import {useTranslation} from "react-i18next";

import {AzaButton} from "../../components/mui/AzaButton";
import {AzaBox} from "../../components/mui/AzaBox";

export const AzaModalStepperButtons = ({
                                           handleNext, canNext, noNext = false, nextButtonText,
                                           handleBack, noBack = false
                                       }) => {
    const {t} = useTranslation();
    return (
        <AzaBox sx={{
            display: {sm: 'inline', md: 'flex'},
            flexDirection: {md: 'row'},
            pt: {sm: 2, md: 0},
            justifyContent: 'flex-end'
        }}>
            {!noBack && <AzaButton
                variant="text"
                onClick={handleBack}
                sx={{mr: 1, mt: {sm: 1, md: 0}}}
            >
                {t('common.back')}
            </AzaButton>}
            {!noNext && <AzaButton
                onClick={handleNext}
                variant="contained"
                disabled={!canNext}
                sx={{mr: {sm: 1, md: 0}, mt: {sm: 1, md: 0}}}
            >
                {nextButtonText}
            </AzaButton>}
        </AzaBox>
    )
}
