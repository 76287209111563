import {SampleRdbRead} from "./SampleRdbRead";
import {SampleRdbWrite} from "./SampleRdbWrite";
import {Grid} from "@mui/material";
import {AzaGrid} from "../../components/mui/AzaGrid";

export const SampleRdb = ()=>{

    return (
        <>
            <AzaGrid container direction={"row"} spacing={2} >
                <Grid item>
                    <SampleRdbRead/>
                </Grid>
                <Grid item>
                    <SampleRdbWrite/>
                </Grid>
            </AzaGrid>
        </>
    )
}