import * as React from "react";
import {useMemo} from "react";
// import {useDbItem} from "../../utils/rdbHooks";
// import {RDB_SITE} from "../../utils/constant";
import {AzaGrid} from "../../components/mui/AzaGrid";
import {AzaSelect} from "../../components/mui/AzaSelect";
// import {badgeColor} from "./utils";
import {AzaMenuItem} from "../../components/mui/AzaMenu";
//icons
// import CircleIcon from "@mui/icons-material/Circle";
// import {AzaTooltip} from "../../components/mui/AzaTooltip";
import {useTranslation} from "react-i18next";

const SPECIAL_NO_SITE_ID = -1;

const UserSiteMenuItem = ({id, name}) => {
    // Here we should get the status of the site to display a colored badge
    // For now simply get it from the rdb, one connexion per site to avoid reading 10000 sites in admin mode
    // const {item: status, loading} = useDbItem(RDB_SITE, `${id}/status`);
    // const {t} = useTranslation();
    // Get the color of the badge from both the loading state and the site status
    // const color = useMemo(() => {
    //
    //         if (id === SPECIAL_NO_SITE_ID){
    //             return "red";
    //         }
    //         return badgeColor(status, loading)
    //     }, [loading, status, id]
    // )

    return (

        <AzaGrid
            container
            direction={"row"}
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
        >
            <AzaGrid item>
                {/*{!loading &&*/}
                {/*    <AzaTooltip title={t(`site_selector.${color}`)}>*/}
                {/*        <CircleIcon fontSize="small" style={{color: color}}/>*/}
                {/*    </AzaTooltip>*/}
                {/*}*/}
            </AzaGrid>
            <AzaGrid item>
                {name}
            </AzaGrid>
        </AzaGrid>
    )
}

export const UserSiteSelector = ({siteID, changeSite, siteList}) => {

    const {t} = useTranslation();

    // We can order the site, for example by name, for customers it should stay simple
    const sites = useMemo(() => {
        // Limiting to MAX_SITES, in case of too many sites the dashboard will be unusable
        const MAX_SITES = 50
        const No_all_sites = {site_id:SPECIAL_NO_SITE_ID, name: t('site_selector.needseemoresite')};

        const output = []

        for (const site of Object.values(siteList)) {
            output.push(site)
        }
        if (output.length > MAX_SITES) {
            output.splice(MAX_SITES, output.length - MAX_SITES);
            output.push(No_all_sites)
        }

        return output.sort((a, b) => {
            if (a.site_id === SPECIAL_NO_SITE_ID) return -1;
            if (b.site_id === SPECIAL_NO_SITE_ID) return 1;
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
            return 0;
        })
    }, [siteList, t])

    const selectedSiteId = useMemo(() => {
        if (sites.filter((site) => site.id === siteID) === []) {
            return -1;
        } else {
            return siteID;
        }
    }, [siteID, sites])

    return (
        <AzaSelect
            value={selectedSiteId ?? null}
            // label="Change site"
            onChange={(e) => {
                changeSite(e.target.value)
            }}
            options={sites}
            size={"small"}
        >
            {sites.map(({site_id, name}) => (
                <AzaMenuItem
                    key={site_id}
                    value={site_id}
                >
                    <UserSiteMenuItem id={site_id} name={name}/>
                </AzaMenuItem>
            ))}
        </AzaSelect>
    )
}
