import {backendFetch} from "../../../utils/backendHelper";
import {API_GET_CAMPAIGN_TO_EDIT} from "../../../utils/constant";

export const getCampaignDetails = (user, site_id, campaign_id, callback_success) => {
    backendFetch({path: API_GET_CAMPAIGN_TO_EDIT, user, queryParams: {site_id, campaign_id}})
        .then(json_data => {
            callback_success({
                campaign_params: JSON.parse(json_data.campaign.campaign_params),
                campaign_name: json_data.campaign.name,
                posts: json_data.posts
            });
        }).catch(error => {
            console.log('ERROR:: ');
            console.log(error);
    });
}