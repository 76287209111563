import {useEffect} from "react";
import {PING_PERIOD} from "../../utils/constant";
import {useUserRDB} from "../../utils/rdbHooks";


export const Heartbeat = () => {

    const {user, ping} = useUserRDB()

    // an effect to create a ping interval, lets the system knows who is still connected
    useEffect(() => {
        if (user) {
            const interval = setInterval(() => {
                ping()
            }, PING_PERIOD * 1000);
            return () => clearInterval(interval);
        }
    }, [user, ping]);

    return <></>
}