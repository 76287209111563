import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import AzaCardTemplate from "../../../../components/azameo/AzaCardTemplate";
import {AzaBox} from "../../../../components/mui/AzaBox";
import CampaignBudget from "../../../../components/campaignComponents/CampaignBudget";
import {
    actionSetDailyBudget,
    actionSetCheckedExceedBudget,
    actionSetExceedDailyBudget,
    selectDailyBudget,
    selectCheckedExceedBudget,
    selectExceedDailyBudget
} from "../../reducers/createNewCampaignSlice";

const BudgetSettings = () => {
    const {t} = useTranslation();
    const dailyBudgetData = useSelector(selectDailyBudget);
    const isExceedChecked = useSelector(selectCheckedExceedBudget);
    const exceedDailyBudgetData = useSelector(selectExceedDailyBudget);
    const dispatch = useDispatch();

    const handleBudgetInput = (e) => {
        // if amount to big...
        dispatch(actionSetDailyBudget({daily_budget: Number(e.target.value)}));
    }

    const handleCheckboxChange = (e) => {
        dispatch(actionSetCheckedExceedBudget(e.target.checked));
    }

    const handleExceedInput = (e) => {
        // if amount to big?..
        dispatch(actionSetExceedDailyBudget({exceed_daily_budget: e.target.value}));
    }

    return (
        <AzaBox sx={{mb: '30px'}}>
            <AzaCardTemplate title={t('settings.budget-settings')}>
                <CampaignBudget
                    handleBudgetInput={handleBudgetInput}
                    handleCheckboxChange={handleCheckboxChange}
                    handleExceedInput={handleExceedInput}
                    dailyBudgetData={dailyBudgetData}
                    isExceedChecked={isExceedChecked}
                    exceedDailyBudgetData={exceedDailyBudgetData}
                />
            </AzaCardTemplate>
        </AzaBox>
    );
};

export default BudgetSettings;