// noinspection AllyPlainJsInspection,AllyJsxHardcodedStringInspection // This is not facing end user only for admin

/*
* doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2103246853/Settings
 */

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {API_GET_SET_SITE_SETTINGS} from "../../../utils/constant";

import {AzaGridContainer, AzaGridDivider, AzaGridItem} from "../../../components/mui/AzaGrid";
import {AzaTextField} from "../../../components/mui/AzaTextField";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaButton, AzaIconButton} from "../../../components/mui/AzaButton";
import AzaLink from "../../../components/mui/AzaLink";
import {AzaList, AzaListItem} from "../../../components/mui/AzaList";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {AzaTreeItem, AzaTreeView} from "../../../components/mui/AzaTree";

import {backendFetch} from "../../../utils/backendHelper";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {usePayment} from "../../../containers/payment/paymentGroup/paymentHook";

import {
    clearChanged,
    clearOneChanged,
    getJsonValueKey,
    selectChanged,
    selectCurrentNode,
    selectDefinitions,
    selectExpandable,
    selectGlobalSettings,
    selectHasChanged,
    selectHasError,
    selectModifiedValueCurrency,
    selectModifiedValueGlobal,
    selectModifiedValueSite,
    selectNeedRefreshCurrency,
    selectNeedRefreshGlobal,
    selectNeedRefreshSite,
    selectNodesInfo,
    selectUpdatedCurrencySettings,
    selectUpdatedGlobalSettings,
    selectUpdatedSiteSettings,
    selectWithError,
    setChanged,
    setCurrencySettings,
    setCurrentNode,
    setDefinitions,
    setError,
    setGlobalSettings,
    setNeedRefresh,
    setSiteSettings
} from "./BackendSettingsSlice";
import {AzaAddCircle, AzaDataArray, AzaDataObject, AzaDelete, AzaErrorIcon} from "../../../components/mui/AzaIcons";




// Out of the component because we don't want to recreate the function on every render
const getBackendSettings = (user, key, callback) => {
    backendFetch({path: API_GET_SET_SITE_SETTINGS, queryParams: key, user})
        .then(json_data => {
            callback(json_data)
        }).catch(error => {
        console.log(error)
    });
}

const saveBackendSettings = (user, key, settings, callback) => {
    backendFetch({path: API_GET_SET_SITE_SETTINGS, queryParams: key, user, method: "POST", data: settings})
        .then(() => {
            //make sure to get the data from the backend at the end, just in case the backend add a sanity check or a cleanup of some sort
            callback()
        })
}

const isInRange = (value, range) => {
    // Make sure it's a number first
    if (isNaN(value)) {
        return "Not a number"
    }
    // Check for the range
    if (range.min !== undefined && range.min !== null && value < range.min) {
        return "Too small"
    }
    if (range.max !== undefined && range.max !== null && value > range.max) {
        return "Too big"
    }
    return true
}

const strictParseInt = (value) => {
    let converted = parseInt(value)
    if (converted.toString() !== value.toString()) {
        return NaN
    }
    return converted
}

const strictParseFloat = (value) => {
    let converted = parseFloat(value)
    if (converted.toString() !== value.toString()) {
        return NaN
    }
    return converted
}

const SubTree = ({label, value, nodeid, addSideNode, valueSettings}) => {
    if (value.type === "dict") {
        return <DictTree
            label={label}
            item={value.description}
            nodeid={nodeid}
            addSideNode={addSideNode}
            valueSettings={valueSettings}
        />
    } else if (value.type === "list") {
        return <ListTree
            label={label}
            item={value.description}
            nodeid={nodeid}
            addSideNode={addSideNode}
            valueSettings={valueSettings}
        />
    } else {
        return <ValueTree
            label={label}
            nodeid={nodeid}
            addSideNode={addSideNode}
        />
    }
}

const ValueTree = ({label, nodeid}) => {
    const dispatch = useDispatch();

    const setSideNode = useCallback((nodeid) => {
        dispatch(setCurrentNode(nodeid))
    }, [dispatch]);

    return <AzaTreeItem
        icon={<AzaAddCircle/>}
        nodeId={nodeid}
        label={label}
        key={nodeid}
        onClick={() => setSideNode(nodeid)}
    />
}

const ListTree = ({label, item, nodeid}) => {
    const dispatch = useDispatch();

    const valueSettings = useSelector(selectGlobalSettings);

    const setSideNode = useCallback((nodeid) => {
        dispatch(setCurrentNode(nodeid))
    }, [dispatch]);

    const dicttree = [];
    const current_node_id = nodeid ?? label;
    const currents = getJsonValueKey(current_node_id, valueSettings)
    if (currents === null) {
        dicttree.push(
            <SubTree
                value={"item sample"}
                label={current_node_id + "." + -1}
                key={current_node_id + "." + -1}
                valueSettings={valueSettings}
            />
        );
    } else {
        for (const elem in currents) {
            dicttree.push(
                <SubTree
                    key={"item " + elem}
                    label={"item " + elem}
                    value={item}
                    nodeid={current_node_id + "." + elem}
                    valueSettings={valueSettings}
                />
            );
        }
    }

    return <AzaTreeItem
        icon={<AzaDataArray/>}
        label={label}
        nodeId={current_node_id}
        key={current_node_id}
        onClick={() => setSideNode(nodeid)}>
        {dicttree}
    </AzaTreeItem>;
}

const DictTree = ({label, item, nodeid}) => {
    const dispatch = useDispatch();
    const dicttree = [];
    const current_node_id = nodeid ?? label;

    const valueSettings = useSelector(selectGlobalSettings);

    const setSideNode = useCallback((nodeid) => {
        dispatch(setCurrentNode(nodeid))
    }, [dispatch]);

    for (const [key, value] of Object.entries(item)) {
        dicttree.push(
            <SubTree
                key={key}
                label={key}
                value={value}
                nodeid={`${current_node_id}.${key}`}
                valueSettings={valueSettings}
            />);
    }
    return (
        <AzaTreeItem icon={<AzaDataObject/>}
                  label={label}
                  nodeId={current_node_id}
                  key={current_node_id}
                  onClick={() => setSideNode(nodeid)}>
            {dicttree}
        </AzaTreeItem>
    );
}


const ChangedList = ({label, level, items}) => {
    const dispatch = useDispatch();
    const errors = useSelector(selectWithError);


    if (Object.keys(items).length === 0) {
        return <></>;
    }

    return (
        <AzaBox>
            <AzaTypography variant="h6" gutterBottom component="div">{label}</AzaTypography>
            <AzaList>
                {Object.entries(items).map(([node, value]) => {
                    return (
                        <AzaListItem key={node}>
                            <AzaGridContainer direction="row" alignItems="center">
                                <AzaGridItem xs={1}>
                                    <AzaIconButton onClick={() => {
                                        dispatch(clearOneChanged({nodeid: node, level: level}))
                                    }}><AzaDelete/></AzaIconButton>
                                </AzaGridItem>
                                <AzaGridItem xs={7}>
                                    <AzaButton variant="text"
                                            color={"info"}
                                            size={"small"}
                                            onClick={() => {
                                                dispatch(setCurrentNode(node))
                                            }}
                                               sx={{width: "100%", maxWidth: "100%"}}
                                    >
                                        <AzaTypography variant="body1" gutterBottom component="div">{node}</AzaTypography>
                                    </AzaButton>
                                </AzaGridItem>
                                <AzaGridItem xs={1}>
                                    <AzaTypography variant="body1" gutterBottom component="div">{JSON.stringify(value)}</AzaTypography>
                                </AzaGridItem>
                                {errors[level][node] ?
                                    <>
                                        <AzaGridItem xs={1}>
                                            <AzaErrorIcon color={"error"}/>
                                        </AzaGridItem>
                                        <AzaGridItem xs={2}>
                                            <AzaTypography variant="body1" gutterBottom
                                                        component="div">{errors[level][node]}</AzaTypography>
                                        </AzaGridItem>
                                    </> : null
                                }
                            </AzaGridContainer>
                        </AzaListItem>
                    )
                })}
            </AzaList>
        </AzaBox>
    )
}

const Modification = () => {
    const {user} = useUser();

    const {currency} = usePayment();
    const site_id = useSiteId();
    const haschanged = useSelector(selectHasChanged);
    const changed = useSelector(selectChanged);
    const dispatch = useDispatch();

    const updatedGlobal = useSelector(selectUpdatedGlobalSettings);
    const updatedSite = useSelector(selectUpdatedSiteSettings);
    const updatedCurrency = useSelector(selectUpdatedCurrencySettings);

    const hasError = useSelector(selectHasError);

    const globalchanged = useMemo(() => {
        const globalchange = changed.global;
        return <ChangedList label="Global" items={globalchange} level={'global'}/>
    }, [changed]);

    const currencychanged = useMemo(() => {
        const currencychange = changed.currency;
        return <ChangedList label={"Currency - " + currency} items={currencychange} level={'currency'}/>
    }, [changed, currency]);

    const sitechanged = useMemo(() => {
        const sitechange = changed.site;
        return <ChangedList label={"Site - " + site_id} items={sitechange} level={'site'}/>
    }, [changed, site_id]);

    const saveChanges = useCallback(() => {
        const changeDic = {
            global: updatedGlobal,
            site: updatedSite,
            currency: updatedCurrency
        }
        const changeKey = {
            global: {"scope": "global"},
            currency: {"scope": "currency", "currency": currency},
            site: {"scope": "site", "site_id": site_id}
        }
        console.log(changed);
        for (const [level, changes] of Object.entries(changed)) {
            console.log(level, changes);
            if (Object.keys(changes).length > 0) {
                saveBackendSettings(user, changeKey[level], changeDic[level], () => {
                    dispatch(setNeedRefresh({level: level, value: true}))
                    dispatch(clearChanged(level));
                });
            }
        }

    }, [updatedGlobal, updatedSite, updatedCurrency, currency, site_id, changed, user, dispatch]);

    const cancelChanges = useCallback(
        () => {
            dispatch(clearChanged("global"));
            dispatch(clearChanged("site"));
            dispatch(clearChanged("currency"));
        }, [dispatch]);


    if (!haschanged) {
        return (
            <></>
        )
    }

    return (
        <>
            <AzaGridItem>
                <AzaTypography variant="h5" gutterBottom component="div">Current Not Saved Modifications</AzaTypography>
                {globalchanged}
                {currencychanged}
                {sitechanged}
                <AzaGridContainer direction={"row"}>
                    <AzaGridItem>
                        <AzaButton variant="outlined" color="secondary" onClick={cancelChanges}>Cancel</AzaButton>
                    </AzaGridItem>
                    <AzaGridItem>
                        <AzaButton variant="contained" color="primary" onClick={saveChanges}
                                disabled={hasError}>Save</AzaButton>
                    </AzaGridItem>
                    {hasError ? <AzaGridItem>
                        <AzaTypography variant="body1" gutterBottom component="div">There are errors in the settings, fix
                            them before saving</AzaTypography>
                    </AzaGridItem> : null}
                </AzaGridContainer>
            </AzaGridItem>
        </>
    )
}

const checkValue = (value, fieldInfo) => {
    let converted;
    let valid;
    switch (fieldInfo.type) {
        case "int":
            converted = strictParseInt(value)
            // Check for the range
            valid = isInRange(converted, fieldInfo)
            // only return the converted value if it's valid
            return {value: valid === true ? converted : value, valid}
        case "float":
            converted = strictParseFloat(value)
            // Check for the range
            valid = isInRange(converted, fieldInfo)
            // only return the converted value if it's valid
            return {value: valid === true ? converted : value, valid}
        case "bool":
            valid = (value === "true" || value === "false")
            if (valid) {
                return {value: value === "true", valid}
            }
            return {value, valid: "Not a boolean"}
        case "str":
            return {value, valid: (typeof value === 'string')}
        default:
            return {value, valid: false}
    }
}

const SideNode = () => {
    const {currency} = usePayment();
    const site_id = useSiteId();
    const dispatch = useDispatch();

    const currentSideNode = useSelector(selectCurrentNode);
    const sidenodes = useSelector(selectNodesInfo);
    const currentNode = sidenodes[currentSideNode];

    const currentGlobalValue = useSelector(selectModifiedValueGlobal);
    const currentCurrencyValue = useSelector(selectModifiedValueCurrency);
    const currentSiteValue = useSelector(selectModifiedValueSite);

    const errors = useSelector(selectWithError);

    const globalerror = useMemo(() => !!errors.global[currentSideNode], [errors, currentSideNode]);
    const siteerror = useMemo(() => !!errors.site[currentSideNode], [errors, currentSideNode]);
    const currencyerror = useMemo(() => !!errors.currency[currentSideNode], [errors, currentSideNode]);

    const handleInputChange = useCallback((event, level) => {
        const nodeinfo = sidenodes[currentSideNode];
        const {value, valid} = checkValue(event.target.value, nodeinfo);
        console.log("handleInputChange", value, valid, level, currentSideNode)
        dispatch(setChanged({level: level, value: value, nodeid: currentSideNode}))
        dispatch(setError({level: level, value: !(valid === true) ? valid : false, nodeid: currentSideNode}))
    }, [sidenodes, currentSideNode, dispatch]);

    const handleInputGlobalChange = (event) => {
        handleInputChange(event, "global")
    };

    const handleInputCurrencyChange = (event) => {
        handleInputChange(event, "currency")
    };

    const handleInputSiteChange = (event) => {
        handleInputChange(event, "site")
    };

    if (currentNode === undefined) {
        return (<></>)
    }

    return (
        <AzaGridContainer direction={"column"} spacing={2}>
            <AzaGridItem>
                <AzaTypography variant={"h5"}>JSON key: {currentSideNode}</AzaTypography>
            </AzaGridItem>
            <AzaGridItem>
                <AzaTypography>{currentNode.help}</AzaTypography>
            </AzaGridItem>
            {(currentNode.type !== "list" && currentNode.type !== "dict") &&
                <AzaGridItem>
                    <AzaTypography>The type of the setting: {currentNode.type}</AzaTypography>
                    <br/>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem xs={3}>
                            <AzaTypography>Global</AzaTypography>
                        </AzaGridItem>
                        <AzaGridItem xs={9}>
                            <AzaTextField
                                required={false}
                                value={currentGlobalValue}
                                error={globalerror}
                                onChange={handleInputGlobalChange}/>
                        </AzaGridItem>
                    </AzaGridContainer>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem xs={3}>
                            <AzaTypography>Currency ({currency})</AzaTypography>
                        </AzaGridItem>
                        <AzaGridItem xs={9}>
                            <AzaTextField
                                required={false}
                                value={currentCurrencyValue}
                                error={currencyerror}
                                onChange={handleInputCurrencyChange}/>
                        </AzaGridItem>
                    </AzaGridContainer>
                    <AzaGridContainer direction={"row"}>
                        <AzaGridItem xs={3}>
                            <AzaTypography>Site ({site_id})</AzaTypography>
                        </AzaGridItem>
                        <AzaGridItem xs={9}>
                            <AzaTextField
                                required={false}
                                value={currentSiteValue}
                                error={siteerror}
                                onChange={handleInputSiteChange}/>
                        </AzaGridItem>
                    </AzaGridContainer>
                </AzaGridItem>
            }
            <Modification/>
        </AzaGridContainer>
    )

}

export const BackendSettings = () => {

    const {user} = useUser()
    const {currency} = usePayment();
    const site_id = useSiteId();
    const dispatch = useDispatch()

    const definition = useSelector(selectDefinitions)
    const expandable = useSelector(selectExpandable);
    const [expanded, setExpanded] = useState(['root'])

    const needRefreshGlobal = useSelector(selectNeedRefreshGlobal);
    const needRefreshCurrency = useSelector(selectNeedRefreshCurrency);
    const needRefreshSite = useSelector(selectNeedRefreshSite);

    const currentSideNode = useSelector(selectCurrentNode);
    const sidenodes = useSelector(selectNodesInfo);

    const getDefinitionSettings = useCallback(() => {
        const key = {"scope": "definition"}
        if (user) {
            getBackendSettings(user, key, (data) => {
                dispatch(setDefinitions(data))
            })
        }
    }, [dispatch, user])

    const getGlobalSettings = useCallback(() => {
        const key = {"scope": "global"}
        if (user) {
            getBackendSettings(user, key, (data) => {
                dispatch(setGlobalSettings(data))
            })
        }
    }, [dispatch, user])

    const getCurrencySettings = useCallback(() => {
        const key = {"scope": "currency", "currency": currency}
        if (user && currency) {
            getBackendSettings(user, key, (data) => {
                dispatch(setCurrencySettings(data))
            })
        }
    }, [currency, user, dispatch])

    const getSiteSettings = useCallback(() => {
        const key = {"scope": "site", "site_id": site_id}
        if (user && site_id) {
            getBackendSettings(user, key, (data) => {
                dispatch(setSiteSettings(data))
            })
        }
    }, [site_id, user, dispatch])

    useEffect(() => {
        getDefinitionSettings();
        getGlobalSettings();
    }, [getDefinitionSettings, getGlobalSettings])

    useEffect(() => {
        getSiteSettings();
        dispatch(clearChanged("site"));
    }, [site_id, dispatch, getSiteSettings])

    useEffect(() => {
        getCurrencySettings();
        dispatch(clearChanged("currency"));
    }, [currency, dispatch, getCurrencySettings])

    useEffect(() => {
        if (needRefreshGlobal) {
            getGlobalSettings();
            dispatch(setNeedRefresh({level: "global", value: false}))
        }
    }, [dispatch, getGlobalSettings, needRefreshGlobal])

    useEffect(() => {
        if (needRefreshCurrency) {
            getCurrencySettings();
            dispatch(setNeedRefresh({level: "currency", value: false}))
        }
    }, [dispatch, getCurrencySettings, needRefreshCurrency])

    useEffect(() => {
        if (needRefreshSite) {
            getSiteSettings();
            dispatch(setNeedRefresh({level: "site", value: false}))
        }
    }, [dispatch, getSiteSettings, needRefreshSite])

    useEffect(() => {
        if (!currentSideNode) {
            return
        }
        const current_expandable = (sidenodes[currentSideNode].type === "list" || sidenodes[currentSideNode].type === "dict") ? currentSideNode : currentSideNode.split(".").slice(0, -1).join(".");
        if (!expanded.includes(current_expandable)) {
            setExpanded([...expanded, current_expandable])
        }
    }, [expanded, currentSideNode, sidenodes])

    const collapsed_length = useMemo(() => {
        return Object.keys(sidenodes).filter((key) => key.split('.').length === 2).length
    }, [sidenodes]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) =>
            oldExpanded.length <= collapsed_length ? expandable : ['root'],
        );
    }, [expandable, collapsed_length]);

    return (
        <>
            <h1>Site Settings Editor</h1>
            <p><AzaLink
                href={"https://azameo.atlassian.net/wiki/spaces/D/pages/2103246853/Settings"}
                target="_blank"
                rel={"noopener noreferrer"}
            >Documentation on jira</AzaLink></p>
            <AzaAlert severity={"warning"}>All changes will take effect on client side only after a reload or delog/log
                or by changing the active site</AzaAlert>
            <p>The settings are computed the following way</p>
            <ul>
                <li>All starts from a common json</li>
                <li>Then a specific entry is defined for the currency</li>
                <li>Then a specific entry is defined for the site_id</li>
            </ul>
            <p>The merge of the settings is the following, if the settings exists in the secondary choice, it will
                replace the old one, the other are simply let unchanged</p>
            <p>SumUp priority: site_id then currency then global</p>
            <p>In case of array, the whole array is replaced</p>
            <AzaGridContainer direction={"row"}>
                <AzaGridItem xs={3}>
                    <AzaBox sx={{mb: 1}}>
                        <AzaButton onClick={handleExpandClick}>
                            {expanded.length <= collapsed_length ? 'Expand all' : 'Collapse all'}
                        </AzaButton>
                    </AzaBox>
                    <AzaTreeView
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        multiSelect={false}
                        selected={currentSideNode}
                    >
                        <DictTree
                            label="Settings"
                            item={definition}
                            nodeid='root'
                        />
                    </AzaTreeView>
                </AzaGridItem>
                <AzaGridDivider/>
                <AzaGridItem xs={8} >
                    <AzaBox sx={{position:"sticky", top:"75px"}}>
                        <SideNode/>
                    </AzaBox>
                </AzaGridItem>
            </AzaGridContainer>
        </>
    )


}
