import React, {useMemo} from 'react';

import LogData from "./LogData";
import {PATH_LOGIN} from "../../../utils/constant";
import {useTranslation} from "react-i18next";



const Register = ({children}) => {

    const {t} = useTranslation();

    const registerData = useMemo(() => { return {
        general: {
            title: t('container.open.register.title'),
            check_trigger_text: t('container.open.register.toggle'),
            check_trigger_link: t('container.open.register.togglebutton'),
            link: PATH_LOGIN,
            btn_aza: t('container.open.register.createbutton'),
            btn_google: t('container.open.register.signupgoogle'),
            id: 'toLogin',
        },

        form_fields: [
            {
                label: t('login.email'),
                type: "email",
                name: "email",
            },
            {
                label: t('login.password'),
                type: "password",
                name: "password",
            },
            {
                label: t('login.confirmpassword'),
                type: "password",
                name: "confirmPassword",
            },
        ]
    }} ,[t])

    return (
        <LogData logData={registerData} register={true} >{children}</LogData>
    );
};

export default Register;
