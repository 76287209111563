export const headlineHandler = {
    minInputs: 3,
    maxInputs: 15,
    maxLength: 30,
    className: 'headline',
}

export const longHeadlineHandler = {
    minInputs: 1,
    maxInputs: 2,
    maxLength: 90,
    className: 'long-headline',
}

export const descriptionHandler = {
    minInputs: 1,
    maxInputs: 5,
    maxLength: 90,
    className: 'description',
}

export const calloutHandler = {
    minInputs: 3,
    maxInputs: 5,
    maxLength: 25,
    className: 'callout',
}

export const keywordHandler = {
    minInputs: 3,
    maxInputs: 15,
    maxLength: 90,
}

export const imgHandler = {
    minInputs: 1,
    maxInputs: 5,
}

export const logoHandler = {
    minInputs: 1,
    maxInputs: 1,
}