import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {selectCampaign} from "./campaignDataSlice";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaContainedButton} from "../../../components/mui/AzaButton";
import {ConfirmCampaignActionPopUp} from "./utils/ConfirmCampaignActionPopUp";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AdminModeBranch} from "../../../features/adminMode/adminModeComponents";
import {
    AzaContentCopy,
    AzaDelete,
    AzaEdit,
    AzaPauseCircleOutline,
    AzaPlayCircleOutline
} from "../../../components/mui/AzaIcons";
import {RecalculateBrandroiButton} from "../RecalculateBrandroiButton";
import {PeriodPicker} from "../../PeriodPicker/PeriodPicker";
import {ColumnSelector} from "./ColumnSelector";
import {AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";


const FunctionalButtonsSection = () => {
    const chosenCampaigns = useSelector(selectCampaign);
    const numberChosenCampaigns = chosenCampaigns.length;
    const chooseOne = numberChosenCampaigns > 0 && numberChosenCampaigns < 2;
    const campaignsNotChosen = numberChosenCampaigns === 0;
    const {t} = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [clickedBtn, setClickedBtn] = useState('');
    let isDeleted = false;

    if (chosenCampaigns.length > 0) {
        isDeleted = chosenCampaigns[0].campaign_status === 'deleted';
    }

    const handleClick = (e) => {
        let action = e.target.value;
        switch (action) {
            case 'start':
            case 'stop':
            case 'delete':
                setDialogOpen(true);
                setClickedBtn(action);
                break;
            case 'duplicate':
                // do something...
                alert('Soon we will duplicate campaign');
                break;
            case 'edit':
                alert('Edit page not ready yet. Our apologies for any inconvenience caused.');
                /*
                just a link to edit 'page'
                 */
                break;
            case 'by_day':

                alert('Not ready yet, probably need to use redux');
                /*
                Possible solution:
                create redux slice
                send data (id of selected campaigns (or 'by_day' clicked = true) to redux
                get this data in each campaign component
                check if array of id from redux contain current component id
                if 'yes' open=!open for daily data
                check if big impact site ('page') productivity
                 */
                break;
            default:
                alert('Something went wrong');
                break;
        }
    }

    const handleClose = () => {
        setDialogOpen(false);
    }

    return (
        <AzaPaper>
            <AzaBox  sx={{
                                padding: '10px 15px',
                                margin: '10px 0 10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                // marginRight: '24px',
                            }}>
            <AzaGridContainer direction={"row"}>
                <AzaGridItem sm={12} md={6}>
                    <AzaContainedButton
                        disabled={isDeleted ? true : campaignsNotChosen}
                        onClick={handleClick}
                        startIcon={<AzaPlayCircleOutline/>}
                        value={'start'}
                    >
                        {t('common.buttons.start')}
                    </AzaContainedButton>
                    <AzaContainedButton
                        disabled={isDeleted ? true : campaignsNotChosen}
                        onClick={handleClick}
                        startIcon={<AzaPauseCircleOutline/>}
                        value={'stop'}
                    >
                        {t('common.buttons.stop')}
                    </AzaContainedButton>
                    <AdminModeBranch needAdmin={true}>
                        <AzaContainedButton
                            disabled={isDeleted ? true : !chooseOne}
                            onClick={handleClick}
                            startIcon={<AzaEdit/>}
                            value={'edit'}
                        >
                            {t('common.buttons.edit')}
                        </AzaContainedButton>
                    </AdminModeBranch>
                    <AdminModeBranch needAdmin={true}>
                        <AzaContainedButton
                            disabled={!chooseOne}
                            onClick={handleClick}
                            startIcon={<AzaContentCopy/>}
                            value={'duplicate'}
                        >
                            {t('common.buttons.duplicate')}
                        </AzaContainedButton>

                    </AdminModeBranch>
                    <AzaContainedButton
                        disabled={isDeleted ? true : campaignsNotChosen}
                        onClick={handleClick}
                        startIcon={<AzaDelete/>}
                        value={'delete'}
                    >
                        {t('translation:common.buttons.delete')}
                    </AzaContainedButton>
                </AzaGridItem>
                <AzaGridItem sm={12} md={6} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <ColumnSelector/>
                    <RecalculateBrandroiButton/>
                    <PeriodPicker/>
                </AzaGridItem>

            </AzaGridContainer>
            <ConfirmCampaignActionPopUp
                dialogOpen={dialogOpen}
                action={clickedBtn}
                handleClose={handleClose}
                chosenCampaigns={chosenCampaigns}
                t={t}
            />
            </AzaBox>
        </AzaPaper>
    );

    // return (
    //     <AzaPaper>
    //         <AzaBox
    //             sx={{
    //                 padding: '10px 15px',
    //                 margin: '10px 0 10px',
    //                 display: 'flex',
    //                 justifyContent: 'space-between',
    //                 // marginRight: '24px',
    //             }}
    //         >
    //             <AzaBox>
    //                 <AzaContainedButton
    //                     disabled={isDeleted ? true : campaignsNotChosen}
    //                     onClick={handleClick}
    //                     startIcon={<AzaPlayCircleOutline/>}
    //                     value={'start'}
    //                 >
    //                     {t('common.buttons.start')}
    //                 </AzaContainedButton>
    //                 <AzaContainedButton
    //                     disabled={isDeleted ? true : campaignsNotChosen}
    //                     onClick={handleClick}
    //                     startIcon={<AzaPauseCircleOutline/>}
    //                     value={'stop'}
    //                 >
    //                     {t('common.buttons.stop')}
    //                 </AzaContainedButton>
    //                 <AdminModeBranch needAdmin={true}>
    //                     <AzaContainedButton
    //                     disabled={isDeleted ? true : !chooseOne}
    //                     onClick={handleClick}
    //                     startIcon={<AzaEdit/>}
    //                     value={'edit'}
    //                     >
    //                         {t('common.buttons.edit')}
    //                     </AzaContainedButton>
    //                 </AdminModeBranch>
    //                 <AdminModeBranch needAdmin={true}>
    //                     <AzaContainedButton
    //                         disabled={!chooseOne}
    //                         onClick={handleClick}
    //                         startIcon={<AzaContentCopy/>}
    //                         value={'duplicate'}
    //                     >
    //                         {t('common.buttons.duplicate')}
    //                     </AzaContainedButton>
    //
    //                 </AdminModeBranch>
    //                 <AzaContainedButton
    //                     disabled={isDeleted ? true : campaignsNotChosen}
    //                     onClick={handleClick}
    //                     startIcon={<AzaDelete/>}
    //                     value={'delete'}
    //                 >
    //                     {t('translation:common.buttons.delete')}
    //                 </AzaContainedButton>
    //             </AzaBox>
    //             <AzaBox
    //                 sx={{
    //                     justifyContent: 'right',
    //                 }}
    //             >
    //                 <ColumnSelector/>
    //                 <RecalculateBrandroiButton/>
    //                 <PeriodPicker/>
    //             </AzaBox>
    //             <ConfirmCampaignActionPopUp
    //                 dialogOpen={dialogOpen}
    //                 action={clickedBtn}
    //                 handleClose={handleClose}
    //                 chosenCampaigns={chosenCampaigns}
    //                 t={t}
    //             />
    //         </AzaBox>
    //     </AzaPaper>
    // );
};

export default FunctionalButtonsSection;
