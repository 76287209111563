import {useSearchParams} from 'react-router-dom'

import {useAuthState} from "react-firebase-hooks/auth";

import {Box} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import {auth} from "../../utils/firebase/firebaseHelper";
import LogInRegisterToggle from "../open/authenticationGroup/LogInRegisterToggle";
import AuthFooterMenu from "../../components/authFooterMenu/AuthFooterMenu";
import Footer from "../../components/footer/Footer";
import bgImage from "../../assets/images/bg_img.svg";
import {useTranslation} from "react-i18next";

export const Auth = (props) => {
    const {t} = useTranslation();
    const [userAuth] = useAuthState(auth);
    const [searchParams] = useSearchParams();
    const redirect = decodeURI(searchParams.get('redirect'));
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const styleAuthForm = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: isMediumScreen ? "100%" : "100vh",
        backgroundImage: isMediumScreen ? null : `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }

    const copyright_year = new Date().getFullYear();

    return (
        <>
            <Box style={userAuth ? null : styleAuthForm}>
                <Box>
                    {userAuth ? null : <LogInRegisterToggle mode={props.mode}/>}
                </Box>
                {userAuth ? null : <AuthFooterMenu/>}
            </Box>
            {userAuth ? null : (isMediumScreen ? null : <Footer
                sx={{
                    width: "100%",
                    padding: "20px 0",
                    textAlign: "center",
                    position: "absolute",
                    bottom: "0",
                    color: "#ababab",
                }}
            >
                {t('copyright_azameo', {copyright_year: copyright_year, copyright_symbol: '©'})}
            </Footer>)}
        </>
    )
};
