import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {
    QUERY_PARAM_PAYMENT_REENTRY,
    QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_CLIENT_SECRET, QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_INTENT,
    QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_STATUS
} from "../../utils/constant";
import {useSearchParams} from "react-router-dom";
import PaymentPopUp from "./PaymentPopUp";
import {
    actionClearCustomerInfo,
    actionGetInvoicesInfo,
    actionGetRenewalInfo,
    actionReset,
    actionResetStripePaymentIntent, actionSetCode,
    actionSetOpenPayment, actionSetReentry, actionSetStatus,
    actionSetWasOpenPayment,
    selectCode,
    selectOpenPayment,
    selectReentry, selectStatus,
    selectWasOpenPayment
} from "./paymentGroup/paymentSlice";
import {useSiteId, useUser} from "../../app/globalHooks";
import {useDispatch, useSelector} from "react-redux";

export const PaymentModeContext = createContext(null);
PaymentModeContext.displayName = 'PaymentModeContext';

export const usePaymentMode = () => {

    const {user} = useUser();
    const site_id = useSiteId();

    const dispatch = useDispatch();

    const openPayment = useSelector(selectOpenPayment);
    const wasOpenPayment = useSelector(selectWasOpenPayment);
    const setOpenPayment = useCallback((open) => {
        dispatch(actionSetOpenPayment(open));
    }, [dispatch]);

    const [searchParams, setSearchParams] = useSearchParams();


    const reentry = useSelector(selectReentry);
    const code = useSelector(selectCode);
    const status = useSelector(selectStatus);

    const setReentry = useCallback((new_reentry) => {
        dispatch(actionSetReentry(new_reentry));
    }, [dispatch]);

    const setCode = useCallback((code) => {
        dispatch(actionSetCode(code));
    }, [dispatch]);

    const setStatus = useCallback((status) => {
        dispatch(actionSetStatus(status));
    }, [dispatch]);

    useEffect(() => {
        const new_reentry = searchParams.get(QUERY_PARAM_PAYMENT_REENTRY) ?? null
        if (new_reentry != null && new_reentry !== reentry) {
            setReentry(new_reentry)
            setOpenPayment(true);
        }
        switch (new_reentry) {
            case "stripe":
                const new_code = searchParams.get(QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_CLIENT_SECRET) ?? null
                if (new_code !== code) {
                    setCode(new_code)
                }
                const new_status = searchParams.get(QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_STATUS) ?? null
                if (new_status !== status) {
                    setStatus(new_status)
                }
                break;
            case "paypal":
                break;
            default:
                break;
        }

        // Clear the reentry query params
        if (new_reentry != null && reentry != null) {
            searchParams.delete(QUERY_PARAM_PAYMENT_REENTRY)
            switch (new_reentry) {
                case "stripe":
                    searchParams.delete(QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_STATUS)
                    searchParams.delete(QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_CLIENT_SECRET)
                    searchParams.delete(QUERY_PARAM_PAYMENT_STRIPE_PAYMENT_INTENT)
                    break;
                case "paypal":
                    break;
                default:
                    break;
            }
            setSearchParams(searchParams);
        }

    }, [searchParams, reentry, code, status, setCode, setReentry, setStatus, setOpenPayment, setSearchParams])

    useEffect(() => {
        dispatch(actionClearCustomerInfo());
        if (user && site_id) {
            dispatch(actionGetRenewalInfo({user, site_id}));
            dispatch(actionGetInvoicesInfo({user:user, site_id:site_id}));
        }
    }, [user, site_id, dispatch]);

    useEffect(() => {
        if (wasOpenPayment && !openPayment) {
            dispatch(actionReset());
            dispatch(actionResetStripePaymentIntent());
        }
        if (openPayment !== wasOpenPayment){
            dispatch(actionSetWasOpenPayment(openPayment));
            if (!openPayment) {
                setReentry(null);
                setCode(null);
                setStatus(null);
            }
        }
    }, [openPayment, wasOpenPayment, dispatch]);

    return {openPayment, setOpenPayment, reentry, code, status,}
}

export const PaymentModeProvider = ({children}) => {
    const value = usePaymentMode()
    const {openPayment, setOpenPayment, reentry, code, status} = value;

    const handleClosePayment = () => {
        setOpenPayment(false);
    }

    return (
        <PaymentModeContext.Provider value={value}>
            {children}
            <PaymentPopUp
                open={openPayment}
                renewal={false}
                reentry={reentry}
                status={status}
                code={code}
                handleClose={handleClosePayment}
            />
        </PaymentModeContext.Provider>
    )
}

export const usePaymentModeProvider = () => useContext(PaymentModeContext);
