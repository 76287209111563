import {AzaButton} from "../../components/mui/AzaButton";
import React from "react";
import {Link} from "react-router-dom";
import {PATH_SUBSCRIPTION, RDB_CUSTOMER, RDB_SITE, PATH_CREDITS_SETTINGS, PATH_HOME} from "../../utils/constant";
import {useFormat, useSiteId} from "../../app/globalHooks";
import {useDbItem} from "../../utils/rdbHooks";
import {useAdminModeProvider} from "../../features/adminMode/adminModeContext";
import {AzaBox} from "../../components/mui/AzaBox";
import {useTheme} from "@mui/material";
import {AzaCreditCardIcon, AzaCrownOutlined} from "../../components/mui/AzaIcons";
import {useTranslation} from "react-i18next";


export const AzaTopBarChip = ({name, link, icon}) => {

    const theme = useTheme();

    return (
        <AzaBox sx={{ flexShrink: 0, ml: 0.75 }}>
            <Link style={{textDecoration: 'none', color: 'inherit'}} to={link}>
                <AzaButton
                    variant="light"
                    color="secondary"
                    sx={{
                        color: 'text.primary',
                        bgcolor: theme.palette.secondary.lighter,
                        ":hover": {
                            bgcolor: theme.palette.secondary.light,
                        }
                    }}
                    aria-label="open localization"
                    aria-haspopup="true"
                    startIcon={icon}>
                    {name}
                </AzaButton>
            </Link>
        </AzaBox>
    );
}

export const CreditChip = () => {

    const {formatMoney} = useFormat();
    const site_id = useSiteId()
    const {item: siteRDB, loading: loadingSiteDb} = useDbItem(RDB_SITE, site_id);
    const {item: customerRDB, loading: loadingCustDb} = useDbItem(RDB_CUSTOMER, siteRDB?.customer_id);
    const {isAdmin} = useAdminModeProvider();

    if (loadingSiteDb || loadingCustDb) {
        return  <AzaTopBarChip
            name={" ... "}
            link={isAdmin?PATH_CREDITS_SETTINGS:PATH_HOME}
            icon={<AzaCreditCardIcon/>}
        />
    }

    if (site_id === ""){
        return <></>
    }

    return (
        <>
            <AzaTopBarChip
                name={formatMoney(customerRDB?.balance?.current_balance)}
                link={isAdmin?PATH_CREDITS_SETTINGS:PATH_HOME}
                icon={<AzaCreditCardIcon/>}
            />
        </>
    )
}

export const PlanChip = () => {
    const {t} = useTranslation();
    return (
        <>
            <AzaTopBarChip
                name={t("subscription.subscribe")}
                link={PATH_SUBSCRIPTION}
                icon={<AzaCrownOutlined/>}
            />
        </>
    )
}
