import {backendFetch} from "../../../utils/backendHelper";
import {API_ASSETS_LIST} from "../../../utils/constant";
import {filteringData} from "./editGetAssetsData";

export const getSiteAssets = (user, site_id, callback_success, callback_error) => {
    backendFetch({path: API_ASSETS_LIST, user, queryParams: {site_id}})
        .then(json_data => {
            callback_success(filteringData(json_data, site_id));
        }).catch(error => {
            console.log(error);
            if (callback_error) {
                callback_error(error);
            }
    });
}