import { useMemo } from 'react';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {useThemeMode} from "../app/useThemeMode";

import Palette from './palette';
import Typography, {fontFamily} from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import adminOverrides from "./adminOverrides";
import merge from "validator/es/lib/util/merge";


const getExtraOverrides = (presetColor, theme) => {
    switch (presetColor) {
        default:
            return {};
        case "themeColorsAdmin":
            return adminOverrides(theme);
    }
}

export const  ThemeCustomization = ({children, presetColor='themeColorsMain'}) => {
    const {themeMode:mode} = useThemeMode();
    const themeDirection = 'ltr';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const theme = useMemo(() => Palette(mode, presetColor), [mode, presetColor]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = useMemo(() => Typography(fontFamily), [fontFamily]);
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1440
                }
            },
            direction: themeDirection,
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography
        }),
        [themeDirection, theme, themeTypography, themeCustomShadows]
    );

    const themes = createTheme(themeOptions);

    themes.components = merge(componentsOverride(themes), getExtraOverrides(presetColor, themes).components);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


