import React, {useMemo, useState} from 'react';
import {AppBar, Box, Toolbar, IconButton, Menu, Container, MenuItem, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {AzaDialog, AzaDialogContent, AzaDialogTitle} from "../mui/AzaDialog";
import {AzaButton} from "../mui/AzaButton";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "../../containers/topbar/LanguageSelector";


const AuthFooterMenu = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const {t} = useTranslation();

    const menuData = useMemo(() => {return [
        {
            name: t('footer.help.title'),
            href: 'https://help.azameo.com/portal/fr/kb/success',
            link: true,
            title: t('footer.help.title'),
            content: '',

        },
        {
            name: t('footer.about.title'),
            href: 'https://www.azameo.com/en/welcome/',
            link: true,
            title: t('footer.about.title'),
            content: '',
        },
        {
            name: t('footer.privacy.title'),
            href: 'http://www.azameo.fr/politique-de-confidentialite/',
            link: true,
            title: t('footer.privacy.title'),
            content: '',
        },
        {
            name: t('footer.terms.title'),
            href: '#',
            link: false,
            title: t('footer.terms.title'),
            content: 'Hello, here you can read our \'Terms\'',
        },
    ];}, [t])


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenModal = (title, content) => {
        setOpen(true);
        setTitle(title);
        setContent(content);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }


    return (
        <>
            <AppBar
                sx={{
                    backgroundColor: 'transparent',
                    color: 'black',
                    boxShadow: 'none',
                    position: {
                        xs: 'absolute',
                        md: 'relative',
                    },
                    top: {
                        xs: '0',
                    }
                }}
                position="static">
                <Container sx={{padding: '0'}}>
                    <Toolbar
                        sx={{
                            maxWidth: '430px',
                            width: '100%',
                            margin: '0 auto',
                        }}
                        disableGutters>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <LanguageSelector
                                    large={true}
                                    handleCloseNavMenu={handleCloseNavMenu}
                                    sx={{
                                        padding: '6px 16px',
                                    }}
                                />
                                {menuData.map((data) => (
                                    <MenuItem key={data.name} onClick={handleCloseNavMenu}>
                                        <AzaButton
                                            key={data.name}
                                            sx={{ my: 2, color: '#4a4a4a', display: 'flex', textTransform: 'none', margin: '0', justifyContent: 'start', padding: '0' }}
                                            href={data.link ? data.href : null}
                                            target={data.link ? '_blank' : null}
                                            onClick={data.link ? null : (() => {handleOpenModal(data.title, data.content)})}
                                            name={data.name}
                                        >
                                            {data.name}
                                        </AzaButton>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between' }}  className={"polite"}>
                            <LanguageSelector
                                sx={{
                                    margin: '16px 0',
                                    padding: '6px 8px',
                                }}
                            />
                            <Box sx={{
                                display: 'flex',
                            }}>
                                {menuData.map((data) => (
                                    <AzaButton
                                        key={data.name}
                                        sx={{ my: 2, color: '#4a4a4a', display: 'flex', textTransform: 'none', }}
                                        href={data.link ? data.href : null}
                                        target={data.link ? '_blank' : null}
                                        onClick={data.link ? null : (() => {handleOpenModal(data.title, data.content)})}
                                        name={data.name}
                                    >
                                        <Typography variant={"body2"} sx={{fontSize: "smaller"}}>{data.name}</Typography>
                                    </AzaButton>
                                ))}
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <AzaDialog open={open} onClose={handleDialogClose}>
                <AzaDialogTitle onClose={handleDialogClose}>
                    {title}
                </AzaDialogTitle>
                <AzaDialogContent>
                    {content}
                </AzaDialogContent>
            </AzaDialog>
        </>
    );
};
export default AuthFooterMenu;
