import React from "react";

import {Box} from "@mui/material";

import {AzaPaper} from "../mui/AzaPaper";
import AzaLink from "../mui/AzaLink";
import useMediaQuery from "@mui/material/useMediaQuery";

export const HelperForKey = ({children}) => {
    return children;
}

export const AzaPage = ({pageItems, sorted=false}) => {
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    let items = pageItems;
    if (sorted) {
        items = pageItems.sort((a, b) => a.props.order - b.props.order);
    }
    return (
        <Box sx={{display: 'flex', alignItems: 'flex-start'}} id={"azapage"}>
            {!isMediumScreen ?
                <>
                    <Box sx={{width: 147, p: '16px 8px', mt: '15px', mr: '24px'}}></Box>
                    <AzaPaper sx={{width: 147, p: '16px 8px', mt: '15px', mr: '24px', position: 'fixed'}}>
                        {items.map((item) => {
                            return (
                                <Box key={item.props.maptitle} sx={{p: '3px 2px'}}>
                                    <AzaLink href={"#" + item.props.anchor_id}>
                                        {item.props.maptitle}
                                    </AzaLink>
                                </Box>
                            );
                        })}
                    </AzaPaper>
                </>: null}
            <Box sx={{flexGrow: '1', maxWidth: isMediumScreen ? '100%' : '900px', marginTop: isMediumScreen ? '0' : '-16px'}}>
                {items.map((el) => {
                    return <HelperForKey key={el.props.maptitle} children={el}/>;
                })}
            </Box>
        </Box>
    );
}
export const AzaPageItem = ({anchor_id, children}) => {
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    return (!isMediumScreen ? <Box sx={{m: {md: '16px 0 0 10px', xs: "5px 0"}}}>
        <Box id={anchor_id}
             sx={{
                 '&::before': {
                     display: 'block',
                     content: '" "',
                     mt: '-86px',
                     height: '86px',
                     visibility: 'hidden',
                     pointerEvents: 'none'
                 }
             }}
        ></Box>
        {children}
    </Box> : children);
};
