export const MIN_BUDGET_AMOUNT = 5;

export const MIN_TARGET_ROI = 200;

export const REGEX_TEXT_CHECKER = {
    keywords_checker: {
        no_emoticon: {
            regex: /([\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F1E0}-\u{1F1FF}]|[\u{2702}-\u{27B0}])/u,
            message: 'keyword.emoticon',
        },
        no_punctuation: {
            regex: /[!?.,><`@#%*()]/,
            message: 'keyword.punctuation',
        },
        no_long_word: {
            regex: /.{90}/,
            message: 'keyword.long-word',
        },
        limit_numb_words: {
            regex: /^\W*(?:\w+\b\W*){11,1000}$/,
            message: 'keyword.limit-numb-words',
        },
    },

    google_checker: {
        no_emoticon: {
            regex: /([\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F1E0}-\u{1F1FF}]|[\u{2702}-\u{27B0}])/u,
            message: 'announce.emoticon',
        },
        no_double_sign: {
            regex: /[,.?!@$%&*]{2,}/,
            message: 'announce.double-sign',
        },
        no_all_caps: {
            regex: /[A-Z]{3,}/,
            message: 'announce.all-caps',
        },
        no_exclamation: {
            regex: /[!|<>()]/,
            message: 'announce.exclamation',
        },
        space_after_coma: {
            regex: /[,.?][^ ]/,
            message: 'announce.space-after-coma',
        },
        space_maj_after_dot: {
            regex: /[.!?] [^A-ZÀ-Ý1-9\p{P}+¡¿-]/u,
            // regex: /[.!?] [^A-Z\u00C0-\u00DC1-9\p{P}+¡¿-]/gu,
            message: 'announce.space-maj-after-dot',
        }
    },

    my_checker: {
        message: 'Hello, world!'
    }
}