import React from 'react';

import {NativeSelect, Select} from "@mui/material";

export const AzaNativeSelect = (props) => {
    const {children, ...others} = props;
    return (
        <NativeSelect {...others}>{children}</NativeSelect>
    );
}

export const AzaSelect = (props) => {
    const {children, ...others} = props;
    return (
        <Select {...others}>{children}</Select>
    );
}