import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    actionExposedUpdateAssetScope,
    actionResetCreateNewCampaignState,
    actionSetAllCampaignNames,
    actionSetAssetsBrandName,
    actionSetAssetsLoaded,
    actionSetAssetsWasLoaded,
    actionSetCallouts,
    actionSetCampaignName,
    actionSetCheckedExceedBudget,
    actionSetCheckerSiteId,
    actionSetCountries,
    actionSetDailyBudget,
    actionSetDescriptions,
    actionSetExceedDailyBudget,
    actionSetHeadlines,
    actionSetKeywords,
    actionSetLanguages, actionSetListProducts,
    actionSetLongHeadlines,
    actionSetOnLoadingState, actionSetProductAssets,
    actionUpdateAssetScope, actionUpdateNetworks
} from "../reducers/createNewCampaignSlice";
import {selectSiteList, setSiteLogo} from "../../../app/globalSlice";
import {getSiteAssets} from "./getAssets";
import {getSiteProducts} from "../../../pages/authenticated/assetsEdition/utils";
import {MIN_BUDGET_AMOUNT, MIN_TARGET_ROI} from "./createNewCamaignConst";
import {getCampaignNames, methodType, siteBackendFetchRaw} from "../../../utils/backendHelper";
import {assetType} from "../../../pages/authenticated/assetsEdition/constants";
import {useFormat, useSiteId, useUser} from "../../../app/globalHooks";
import {useFileStoreProvider} from "../../../features/fileStoreContext";
import {API_GET_POST_SITE_LOGO} from "../../../utils/constant";

export const useCreateNewCampaign = () => {
    const site_id = useSiteId();
    const {user} = useUser();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const siteList = useSelector(selectSiteList);
    const {formatDate} = useFormat();
    const {addFile, clearAllFiles} = useFileStoreProvider();

    const getLogo = useCallback(async() => {
        const siteLogo = await siteBackendFetchRaw({
            user: user,
            site_id: site_id,
            path: API_GET_POST_SITE_LOGO,
            method: methodType.GET,
            queryParams: {data:true},
        });

        return {
            error: {
                status: false,
                message: '',
            },
            is_updated: false,
            files: [{
                preview: siteLogo,
                name: 'Logo',
                path: 'site_logo',
                size: ''
            }],
        };
    }, [site_id, user]);

    const handleUpdateAssets = useCallback((filtered_data) => {
        dispatch(actionSetHeadlines({data: filtered_data.Site[assetType.HEADLINE]}));
        dispatch(actionSetLongHeadlines({data: filtered_data.Site[assetType.LONG_HEADLINE]}));
        dispatch(actionSetDescriptions({data: filtered_data.Site[assetType.DESCRIPTION]}));
        dispatch(actionSetCallouts({data: filtered_data.Site[assetType.CALLOUT]}));
        dispatch(actionSetKeywords({data: filtered_data.Site[assetType.KEYWORD]}));

        if(Object.keys(filtered_data.Product).length > 0) {
            dispatch(actionSetProductAssets(filtered_data.Product));
        } else {
            dispatch(actionSetProductAssets({}));
        }
        dispatch(actionSetAssetsWasLoaded(true));
        dispatch(actionSetOnLoadingState(false));
    }, [dispatch]);

    const handleListProducts = useCallback((products) => {
        // limited to 500 products
        const restrictedList = products.slice(0, 500);
        dispatch(actionSetListProducts(restrictedList));
    }, [dispatch]);

    const handleError = useCallback((error) => {
        console.log('Some error occurred: ' + error);
        dispatch(actionSetAssetsLoaded(true));
    }, [dispatch]);

    const createDefaultCampaignName = useCallback((allNames) => {
        let found = false;
        let todayDate = formatDate(Date.now(), 'numeric').replaceAll('/', '');
        let suffix = '';
        let iterator = 0;
        let defaultName = t("campaigns_data.default-campaign-name") + '_' + todayDate;

        while (true) {
            for (const el of allNames) {
                if (el.toLowerCase() === defaultName.toLowerCase() + suffix) {
                    iterator += 1;
                    suffix = "_" + iterator;
                    found = true;
                    break;
                }
            }
            if (found === false) {
                break;
            } else {
                found = false;
            }
        }

        defaultName += suffix;
        dispatch(actionSetCampaignName({new_name: defaultName}));
    }, [formatDate, t, dispatch]);

    const getAllCampaignsNames = useCallback(async() => {
        return await getCampaignNames(user, site_id);
    }, [user, site_id]);

    const resetData = useCallback(() => {
        dispatch(actionResetCreateNewCampaignState());
        clearAllFiles('logo');
        clearAllFiles('images');

        getLogo().then((res) => {
            addFile('logo', res.files[0].name, res.files[0]);
            dispatch(setSiteLogo(res));
        }).catch((err) => {
            console.log(err);
        });

        getAllCampaignsNames().then((res) => {
            dispatch(actionSetAllCampaignNames(res));
            createDefaultCampaignName(res);
        }).catch((err) => {
            console.log(err);
        });

        getSiteAssets(user, site_id, handleUpdateAssets, handleError);
        getSiteProducts(user, site_id, handleListProducts);
        dispatch(actionSetOnLoadingState(true));
        dispatch(actionSetCheckerSiteId(site_id));
        dispatch(actionSetAssetsLoaded(true));
        dispatch(actionSetCheckedExceedBudget(false));
        dispatch(actionSetExceedDailyBudget({exceed_daily_budget: MIN_TARGET_ROI}));
        dispatch(actionUpdateAssetScope({activeTab: 0, scopeName: 'Site'}));
        dispatch(actionSetAssetsBrandName({data: siteList[site_id]?.name}));
        dispatch(actionSetDailyBudget({daily_budget: MIN_BUDGET_AMOUNT}));
        dispatch(actionSetCountries({data: siteList[site_id]?.countries.length ? siteList[site_id].countries : 'FR'}));
        dispatch(actionSetLanguages({data: siteList[site_id].language ? siteList[site_id].language : 'fr'}));
    }, [
        addFile,
        clearAllFiles,
        createDefaultCampaignName,
        dispatch,
        getAllCampaignsNames,
        getLogo,
        handleError,
        handleListProducts,
        handleUpdateAssets,
        siteList,
        site_id,
        user]);

    const setData = useCallback(({dailyBudget, campaignLanguage, campaignCountry, campaignScope, campaignType, campaignNetwork}) => {
        if (dailyBudget) {
            dispatch(actionSetDailyBudget({daily_budget: dailyBudget.value}));
        }
        if (campaignLanguage) {
            dispatch(actionSetLanguages({data: campaignLanguage.value.toLowerCase()}));
        }
        if (campaignCountry) {
            const countriesList = campaignCountry.map((el) => el.value.toUpperCase());
            dispatch(actionSetCountries({data: countriesList}));
        }
        if (campaignScope) {
            dispatch(actionExposedUpdateAssetScope(campaignScope.value));
        }
        if (campaignType && campaignNetwork) {
            const networksList = campaignNetwork.map((el) => el.value);
            dispatch(actionUpdateNetworks({name: campaignType.value, network_types: networksList}));
        }
    }, [dispatch]);

    return {resetData, setData}
}