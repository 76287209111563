import {useDispatch, useSelector} from "react-redux";

import {AzaIconClose} from "../../../../../components/mui/AzaIcons";
import {AzaButton} from "../../../../../components/mui/AzaButton";
import {
    actionSetProductAssetSelected,
    selectProductAssetSelected,
    actionResetBrandAssets, selectLanguages,
    selectProductAssets,
    actionSetProductAssets,
} from "../../../reducers/createNewCampaignSlice";
import {creatNewAssetsGroup} from "./generateNewInputObj";
import {useSiteId} from "../../../../../app/globalHooks";

const ClearAssets = ({activeScope}) => {
    const dispatch = useDispatch();
    const productAssets = useSelector(selectProductAssetSelected);
    const siteLanguageObj = useSelector(selectLanguages);
    const language = siteLanguageObj.data.length ? siteLanguageObj.data : 'fr';
    const site_id = useSiteId();
    const allProductsWithAssets = useSelector(selectProductAssets);

    const cleaBrandAssets = () => {
        const data = {
            scope: activeScope,
            siteId: site_id,
            productId: null,
            language: language,
        }

        const newAssetsGroup = creatNewAssetsGroup(data);
        dispatch(actionResetBrandAssets(newAssetsGroup));
    }

    const clearProductAssets = () => {
        let data = {};
        let notEmpty = false;
        let productId;

        for(let asset in productAssets) {
            if(productAssets[asset].length > 0) {
                notEmpty = true;
                productId = productAssets[asset][0].product_id;
                data = {
                    scope: productAssets[asset][0].scope,
                    siteId: productAssets[asset][0].site_id,
                    productId: productAssets[asset][0].product_id,
                    language: language,
                }
                break;
            }
        }
        // 'notEmpty' will almost always be 'true', because not checked if inputs (or 'cleaned_text') are empty
        // except if not input generated
        if(notEmpty) {
            const newProductAssetsGroup = creatNewAssetsGroup(data);
            dispatch(actionSetProductAssetSelected(newProductAssetsGroup));
            if(productId){
                const updatedProductsWithAssets = {...allProductsWithAssets, [productId]: newProductAssetsGroup}
                dispatch(actionSetProductAssets(updatedProductsWithAssets));
            }
        } else {
            console.log('ASSETS already empty');
        }
    }

    const handleClearingAssets = () => {
        if(activeScope === 'Site') {
            cleaBrandAssets();
        } else {
            clearProductAssets();
        }
    }

    return (
        <AzaButton
            variant="outlined"
            startIcon={<AzaIconClose sx={{fontSize: '17px!important'}}/>}
            onClick={handleClearingAssets}
        >
            Remove all assets
        </AzaButton>
    );
};

export default ClearAssets;