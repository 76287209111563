import React from 'react';
import {Page, Text, Image, Document, StyleSheet, View, Font} from "@react-pdf/renderer";

import logo from '../../../../assets/images/azameo_banner_2.png';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
    ]
});
const style = StyleSheet.create({
    image: {
        width: 350,
        height: 'auto'
    },
    section: {
        margin: '10px 10px 10px 20px',
        padding: '0 0 10px 0',
        flexDirection: 'row',
        // flexGrow: 1,
        justifyContent: 'space-between',
        fontSize: 9,
        borderBottom: '1.5px solid grey',
        alignContent: 'space-between',
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 20
    },
    headCell: {
        fontWeight: 700,
        marginBottom: 4,
    },
    sectionNextImg: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    textNextToImg: {
        color: 'grey',
        fontSize: 20,
        fontWeight: 'bold',
        padding: '10px 0',
    },
    sectionAmount: {
        textAlign: 'center',
    },
    buttonSpace: {
        marginBottom: 10
    }
});
const PdfInvoice = (elementData) => {
    return (
        <Document>
            <Page size="A4" style={{fontFamily: 'Open Sans'}}>
                <View style={[style.section, {border: 'none'}]}>
                    <View>
                        <Image style={style.image} src={logo}/>
                    </View>
                    <View style={style.sectionNextImg}>
                        <Text style={style.textNextToImg}>
                            Invoice
                        </Text>
                        <Text style={[style.textNextToImg, {color: 'green'}]}>
                            Paid
                        </Text>
                    </View>
                </View>
                <View style={[style.section]}>
                    <View>
                       <Text style={style.title}>Azameo</Text>
                       <Text>S.A.S au capital de 74.700,53 Euros</Text>
                       <Text>635, Route des Lucioles</Text>
                       <Text>06560 Valbonne Sophia Antipolis</Text>
                       <Text style={{marginBottom: 20}}>France</Text>
                       <Text>Tax ID: FR88800706475</Text>
                       <Text>billing@azameo.com</Text>
                       <Text style={{color: '#3586d4'}}>http://www.azameo.com</Text>
                       <Text>RCS Grasse 800706475</Text>
                    </View>
                    <View style={{alignItems: 'center', justifyContent: 'center', width: 215}}>
                        <Text style={{marginBottom: 20}}>Invoice #: {elementData?.invoice}</Text>
                        <Text style={{marginBottom: 10}}>Invoice Date: (date) November 6, 2022</Text>
                        <div style={{border:'1px solid black', padding: '12px 5px', alignItems: 'center'}}>
                            <Text style={{marginBottom: 25}}>Amount due:</Text>
                            <Text style={{fontWeight: 700, fontSize: 10}}>0.00 EUR</Text>
                        </div>
                    </View>
                </View>
                <View style={style.section}>
                    <View>
                        <Text style={{marginBottom: 20}}>Bill to:</Text>
                        {/*address, how many line? probably show with 'map'*/}
                        <Text>hypeboost</Text>
                        <Text>N°client: 11795</Text>
                        <Text>duncan@hypeboost.com</Text>
                        <Text>N°TVA: NL862852225B01</Text>
                    </View>
                    <View style={{ width: 140}}>
                        <Text style={{marginBottom: 20}}>Billed to:</Text>
                        {/*address, how many line? probably show with 'map'*/}
                        <Text>HYPEBOOST B.V</Text>
                        <Text>Weidehek 68 D</Text>
                        <Text>4824 AS Breda</Text>
                        <Text>Netherlands</Text>
                    </View>

                </View>
                <View style={style.section}>
                    <View>
                        <Text style={style.headCell}>Description</Text>
                        <Text>{elementData?.price} Credits Campagne Web Marketing Azameo (TVA 0%)</Text>
                    </View>
                    <View style={{alignItems: 'flex-end'}}>
                        <Text style={style.headCell}>Quantity</Text>
                        <Text>1</Text>
                    </View>
                    <View style={{alignItems: 'flex-end'}}>
                        <Text style={style.headCell}>Price</Text>
                        <Text style={style.buttonSpace}>{elementData?.price} EUR</Text>
                        <Text style={style.buttonSpace}>Subtotal</Text>
                        <Text style={style.headCell}>Total</Text>
                    </View>
                    <View style={{alignItems: 'flex-end'}}>
                        <Text style={style.headCell}>Amount</Text>
                        {/*<Text style={style.buttonSpace}>300.00 EUR</Text>
                            change with translation function
                        */}
                        <Text style={style.buttonSpace}>{elementData?.price} EUR</Text>
                        <Text style={style.buttonSpace}>{elementData?.price} EUR</Text>
                        <Text style={style.headCell}>{elementData?.price} EUR</Text>
                    </View>
                </View>
                <View style={[style.section, {border: 'none'}]}>
                    <View>
                        <Text style={{fontWeight: 700, fontSize: 10, marginBottom: 10}}>Terms and conditions</Text>
                        <Text style={{marginBottom: 10}}>Early payment discount: None</Text>
                        <Text>TVA: 0%</Text>
                        <Text>"Exoneration of VAT, article 44 directive 2006/112" (article 259 B of French CGI)</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PdfInvoice;