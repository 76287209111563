import React from 'react';

const Subscription = () => {
    return (
        <div>
          Subscription
        </div>
    );
};

export default Subscription;