import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {AzaAlert} from "../../../components/mui/AzaAlert";
import {AzaCircularProgress} from "../../../components/mui/AzaIcons";
import AzaSettingsBox from "../../../components/azameo/AzaSettingsBox";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {AzaDialog, AzaDialogActions, AzaDialogContent, AzaDialogTitle} from "../../../components/mui/AzaDialog";
import {AzaButton} from "../../../components/mui/AzaButton";

import {
    actionGetRenewalInfo, actionResetRenewalInfo,
    selectRenewalInfo,
    stopCustomerRenewalPayment
} from "../../payment/paymentGroup/paymentSlice";
import {useFormat, useSiteId, useUser} from "../../../app/globalHooks";

const ConfirmStopDialog = ({open, onClose, title}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {user, loading} = useUser();
    const site_id = useSiteId();
    const {data, loading:loadingRenewal} = useSelector(selectRenewalInfo);

    const [pending, setPending] = useState(false);

    const onConfirm = useCallback(() => {

        if (loading || !user || loadingRenewal) {
            return;
        }

        if (!data.source) {
            onClose();
            return;
        }

        setPending(true);
        stopCustomerRenewalPayment(user, site_id)
            .then( () => {
                dispatch(actionResetRenewalInfo());
                dispatch(actionGetRenewalInfo({user, site_id}));
            })
            .finally(() => {
                setPending(false);
                onClose();
            })

    }, [user, loading, site_id, dispatch, onClose, loadingRenewal, data.source, setPending]);

    return (
        <AzaDialog open={open} onClose={onClose}>
            <AzaDialogTitle onClose={onClose}>{title}</AzaDialogTitle>
            <AzaDialogContent>
                {!pending ?? <AzaAlert severity={"info"}>{t('container.credits.renewal.askconfirmstop')}</AzaAlert>}
                {pending ?? <AzaAlert severity={"info"}><AzaCircularProgress/>{t('container.credits.renewal.stopping')}</AzaAlert>}
            </AzaDialogContent>
            <AzaDialogActions>
                <AzaButton onClick={onClose}>{t('container.credits.renewal.cancelstop')}</AzaButton>
                <AzaButton variant={"contained"} onClick={onConfirm}>{t('container.credits.renewal.confirmstop')} </AzaButton>
            </AzaDialogActions>
        </AzaDialog>
    )
}

const CreditsRenewal = ({title}) => {
    const {data, loading} = useSelector(selectRenewalInfo);
    const [openConfirmStop, setOpenConfirmStop] = useState(false) ;

    const closeConfirmStop = () => {
        setOpenConfirmStop(false);
    }

    const askForConfirmStop = () => {
        setOpenConfirmStop(true);
    }

    const {formatMoney, formatDate} = useFormat();
    const {t} = useTranslation();

    const buttonName = useMemo(() => {
        if (loading) {
            return "..."
        }
        if (data.source) {
            return t('container.credits.renewal.stoprenewal')
        }
        return t('container.credits.renewal.startrenewal')
    }, [data.source, loading, t])

    const buttonAction = useCallback( () => {
        if (!loading && data.source) {
            askForConfirmStop();
        }
    }, [loading, data.source])

    const source = useMemo(() => {
        if (data.source === "Stripe") {
            return t('container.credits.renewal.creditcard')
        }
        return t('container.credits.renewal.paypal')
    }, [data.source, t])

    return <AzaSettingsBox
        title={{
            name: `${title}: `,
            data:  loading?"...":(data?.amount>0.0)?formatMoney(data?.amount):"",
            id: 'renewal',
        }}
        button={!loading &&data.source && {
            name: buttonName,
            action: buttonAction,
        }}
    >
        {loading && <AzaCircularProgress/>}
        {!loading && !data.source && <AzaAlert severity={"info"}>{t('container.credits.renewal.info')}</AzaAlert>}
        {!loading && data.source && <>
            <AzaTypography>{t('container.credits.renewal.nextdate')} :  {formatDate(Date.parse(data.next_date))}</AzaTypography>
            <AzaTypography>{t('container.credits.renewal.source')} :  {source}</AzaTypography>
        </>}
        <ConfirmStopDialog
            open={openConfirmStop}
            onClose={closeConfirmStop}
            title={title}
        />
    </AzaSettingsBox>
};

export default CreditsRenewal;
