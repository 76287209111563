import {Badge} from "@mui/material";

const AzaBadge = ({ children, ...props }) => {
    return (
        <Badge color={"primary"} {...props}>
            {children}
        </Badge>
    );
}
export default AzaBadge;
