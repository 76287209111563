import {useMemo} from "react";
import {Grid, List, ListItem, ListItemText} from "@mui/material";
import {useDbItem} from "../../utils/rdbHooks";
import {
    selectCurrent,
    selectLastUpdate,
    selectLoaded,
    selectNow,
    selectPrevious,
    selectUpdating,
} from "../../features/attribution/attributionSlice";
import {useSelector} from "react-redux";
import {selectSiteId} from "../../app/globalSlice";
import {AttributionUpdater} from "../../features/attribution/AttributionUpdater";

export const SampleBrandRoi = () => {
    // redux
    const siteID = useSelector(selectSiteId)

    const nowAttribution = useSelector(selectNow)
    const currentAttribution = useSelector(selectCurrent)
    const previousAttribution = useSelector(selectPrevious)
    const loaded = useSelector(selectLoaded)
    const updating = useSelector(selectUpdating)


    const reduxLastUpdate = useSelector(selectLastUpdate)

    const {item: siteData} = useDbItem("sites/", siteID)

    const rdbLastUpdate = useMemo(() => {
        if (siteData?.["updated"]) {
            // Store an int for easier comparison
            return (Math.floor(siteData?.["updated"]));
        }
        return 0;
    }, [siteData])

    return (
        <>
            <AttributionUpdater/>
            <Grid container direction={"column"}>
                <Grid>
                    reduxLastUpdate: {reduxLastUpdate}
                </Grid>
                <Grid>
                    rdbLastUpdate: {rdbLastUpdate}
                </Grid>
                <Grid container item direction={"row"}>
                    <Grid item>
                        <SampleSingleBrandRoi
                            loaded={loaded}
                            updating={updating}
                            attribution={nowAttribution}
                        />
                    </Grid>
                    <Grid item>
                        <SampleSingleBrandRoi
                            loaded={loaded}
                            updating={updating}
                            attribution={currentAttribution}
                        />
                    </Grid>
                    <Grid item>
                        <SampleSingleBrandRoi
                            loaded={loaded}
                            updating={updating}
                            attribution={previousAttribution}
                        />
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}
const SampleSingleBrandRoi = ({attribution, loaded, updating}) => {

    return <>

        <List dense={true}>
            <ListItem>
                <ListItemText
                    primary={"loaded: " + loaded}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={"updating: " + updating}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={"start_date: " + attribution["start_date"]}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={"end_date: " + attribution["end_date"]}
                />
            </ListItem>
            {/*<pre>{JSON.stringify(currentBrandRoi, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(previousBrandRoi, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(todayBrandRoi, null, 2)}</pre>*/}
        </List>
    </>
}