import {createContext, useContext, useState} from "react";

export const FileStoreContext = createContext(null);
FileStoreContext.displayName = 'FileStoreContext';


const useFileStore = () => {
    const [files, setFiles] = useState({});

    const addFile = (substore, key, file) => {
        const newFiles = files;
        newFiles[substore] = newFiles[substore] || {};
        newFiles[substore][key] = file;
        setFiles(newFiles)
    }

    const getFile = (substore, key) => {
        return files[substore][key];
    }

    const allFiles = (substore) => {
        return Object.values(files[substore] || {});
    }

    const removeFile = (substore, key) => {
        const newFiles = files;
        delete(newFiles[substore][key]);
        setFiles(newFiles);
    }

    const clearAllFiles = (substore) => {
        const newFiles = files;
        delete(newFiles[substore]);
        setFiles(newFiles);
    }

    return {addFile, getFile, removeFile, allFiles, clearAllFiles}
}


export const FileStoreProvider = ({children}) => {
    const value = useFileStore()
    return (
        <FileStoreContext.Provider value={value}>
            {children}
        </FileStoreContext.Provider>
    )
}

export const useFileStoreProvider = () => useContext(FileStoreContext);
