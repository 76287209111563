import {LocalizationProvider, StaticDateRangePicker} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {AzaMenu, AzaMenuItem} from "../../components/mui/AzaMenu";
import {AzaGrid} from "../../components/mui/AzaGrid";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {changeCurrentPeriod, changePreviousPeriod, selectCurrentPeriod} from "../../app/globalSlice";
import {AzaDivider} from "../../components/mui/AzaDivider";
import {AzaTextField} from "../../components/mui/AzaTextField";
import {AzaButton} from "../../components/mui/AzaButton";
import {daysFromToday, daysFromYesterday, displayedPredefinedPeriods, predefinedPeriods} from "./predefinedPeriods";
import {useTranslation} from "react-i18next";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {useCustomization} from "../../features/customization/customizationHooks";
import {useFormat, useScreenSize} from "../../app/globalHooks";
import {AzaBox} from "../../components/mui/AzaBox";
// import {useTheme} from "@mui/material";



// The text component of the predefined period menu
const PredefinedPeriodLink = ({children}) => {
    return (
        <AzaTypography variant={"body2"}> {children} </AzaTypography>
    )
}

// The period picket input always visible on the page, open the menu when clicked
const PeriodPickerInput = ({value, onClick}) => {
    // const theme = useTheme();
    const {formatDate} = useFormat()
    // The text inside the input
    const text = useMemo(() => {
        if (!value || !value["start"] || !value["end"]) {
            return ""
        }
        const start = new Date(value["start"])
        const end = new Date(value["end"])

        return `${formatDate(start)} - ${formatDate(end)}`
    }, [value, formatDate])

    return (
        <AzaButton
            variant={"outlined"}
            onClick={onClick}
            sx={{maxWidth:"350px"}}
        >
            {text}
        < /AzaButton>
    )
}

// The period picker predefined period items
const PredefinedPeriodsItem = ({period, onSelect}) => {
    const {t} = useTranslation();

    return (
        <AzaMenuItem onClick={() => onSelect(predefinedPeriods[period]())}>
            <PredefinedPeriodLink>{t(`periodpicker.${period.toLowerCase()}`)}</PredefinedPeriodLink>
        < /AzaMenuItem>
    )
}

// The period picker predefined period menu
const PredefinedPeriodsMenu = ({onSelect}) => {
    const {t} = useTranslation();
    // Local state for cache and avoid network lag
    const [upToToday, setUpToToday] = useState(30)
    const [upToYesterday, setUpToYesterday] = useState(30)
    // Both upToToday and upToYesterday are also loaded and stored in the customization rdb
    const {customization, updateCustomization, loading} = useCustomization()

    // The function called on the menu item click
    const handleUpToTodayClick = useCallback(() => {
        onSelect(daysFromToday(upToToday))
    }, [onSelect, upToToday])

    // The function called on the text field change, should also contain some sort of sanity check
    const handleUpToTodayChange = useCallback((value) => {
        setUpToToday(value)
        updateCustomization("periodPicker", {upToToday: value, upToYesterday})
        // TODO: Add some sort of sanity check
    }, [upToYesterday, updateCustomization])

    // The function called on the menu item click
    const handleUpToYesterdayClick = useCallback(() => {
        onSelect(daysFromYesterday(upToYesterday))
    }, [onSelect, upToYesterday])

    // The function called on the text field change, should also contain some sort of sanity check
    const handleUpToYesterdayChange = useCallback((value) => {
        setUpToYesterday(value)
        updateCustomization("periodPicker", {upToToday, upToYesterday: value})
        // TODO: Add some sort of sanity check
    }, [upToToday, updateCustomization])

    // Load the customization from the rdb
    useEffect(() => {
        if (loading) return;
        setUpToToday(customization?.["periodPicker"]?.["upToToday"] || 30)
        setUpToYesterday(customization?.["periodPicker"]?.["upToYesterday"] || 30)
    }, [loading, customization])

    return (
        <>
            {displayedPredefinedPeriods.map((period) => (
                <PredefinedPeriodsItem key={period} period={period} onSelect={onSelect}/>
            ))}
            <AzaDivider/>
            <AzaMenuItem
                onClick={handleUpToTodayClick}
            >
                <AzaGrid
                    container
                    spacing={2}
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <AzaGrid item>
                        <AzaTextField
                            size={"small"}
                            sx={{width: "45px"}}
                            value={upToToday}
                            onChange={(e) => handleUpToTodayChange(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </AzaGrid>
                    <AzaGrid item>
                        <PredefinedPeriodLink> {t("periodpicker.daysuptotoday")} </PredefinedPeriodLink>
                    </AzaGrid>
                </AzaGrid>
            < /AzaMenuItem>
            <AzaMenuItem
                onClick={handleUpToYesterdayClick}
            >
                <AzaGrid
                    container
                    spacing={2}
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <AzaGrid item>
                        <AzaTextField
                            size={"small"}
                            sx={{width: "45px"}}
                            value={upToYesterday}
                            onChange={(e) => handleUpToYesterdayChange(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </AzaGrid>
                    <AzaGrid item>
                        <PredefinedPeriodLink> {t("periodpicker.daysuptoyesterday")} </PredefinedPeriodLink>
                    </AzaGrid>
                </AzaGrid>
            < /AzaMenuItem>
        </>
    )
}

// The calendar part of the period picker
const PeriodPickerCalendars = ({value, changeValue}) => {
    const {i18n} = useTranslation();
    const {isSmallScreen} = useScreenSize();

    return (
        <LocalizationProvider
            adapterLocale={i18n.language}
            dateAdapter={AdapterDayjs}
        >
            <StaticDateRangePicker
                displayStaticWrapperAs={isSmallScreen?"mobile":"desktop"}
                value={value}
                onChange={changeValue}
                calendars={2}
                disableFuture={true}
                renderInput={(startProps, endProps) => (
                    <>
                        <AzaTextField {...startProps} />
                            <AzaBox sx={{mx: 2}}> - </AzaBox>
                        <AzaTextField {...endProps} />
                    </>
                )}
            />
        </LocalizationProvider>
    )
}

// The period picker as a whole, contains the input and the menu
export const PeriodPicker = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch()
    const currentPeriod = useSelector(selectCurrentPeriod)

    const {isSmallScreen} = useScreenSize();

    const [value, setValue] = useState([null, null]);

    // Use the current selected period as the initial value
    useEffect(() => {
        if (value[0] == null && value[1] == null) {
            setValue([currentPeriod.start, currentPeriod.end])
        }
    }, [value, currentPeriod])

    // The anchor element for the menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    // The open state of the menu, true if the menu is open
    const open = Boolean(anchorEl);
    // The close menu function will be called by the menu
    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);
    // The toggle menu function will be called by the button
    const toggleMenu = useCallback((event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [anchorEl]);

    // The function called when the OK button is clicked, will dispatch the new period
    const validatePeriod = useCallback(() => {
        // If both the start and end date are set in the local state send it to the redux store
        if (value[0] != null && value[1] != null) {
            // Number of days between the start and end date
            const days = Math.round((value[1] - value[0]) / (1000 * 60 * 60 * 24)) + 1;
            // create a previous period with the same duration as the current period
            const previousStart = new Date(value[0].format('YYYY-MM-DD')); // moment to string to remove the timezone issue
            previousStart.setDate(previousStart.getDate() - days);
            const previousEnd = new Date(value[0].format('YYYY-MM-DD'));// moment to string to remove the timezone issue
            previousEnd.setDate(previousEnd.getDate() - 1);
            // Send the new current and previous periods to the redux store
            dispatch(changeCurrentPeriod({
                start: value[0].format('YYYY-MM-DD'),// convert moment to iso format string for redux serialisation
                end: value[1].format('YYYY-MM-DD'),// convert moment to iso format string for redux serialisation
            }))
            dispatch(changePreviousPeriod({
                start: previousStart.toISOStringDateOnly(),// convert date to iso format string for redux serialisation
                end: previousEnd.toISOStringDateOnly(),// convert date to iso format string for redux serialisation
            }))
        }
        closeMenu()

    }, [closeMenu, dispatch, value])

    // Function called when the Cancel button is clicked
    const cancelPeriod = useCallback(() => {
        // Reset the local state to the current period
        setValue([currentPeriod.start, currentPeriod.end])
        closeMenu()
    }, [closeMenu, currentPeriod?.end, currentPeriod?.start])

    const changeValue = useCallback((newValue) => {
        setValue(newValue);
    }, [])

    const clickPredefinedPeriod = useCallback(([start, end]) => {
        start = moment(start);
        end = moment(end);
        changeValue([start, end])
    }, [changeValue])

    return <>
        <PeriodPickerInput value={currentPeriod} onClick={toggleMenu}/>
        <AzaMenu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <AzaGrid
                container
                direction={"column"}
                sx={{p: 1}}
            >
                <AzaGrid item>
                    <AzaGrid container direction={isSmallScreen?"column":"row"}>
                        <AzaGrid item>
                            <PeriodPickerCalendars value={value} changeValue={changeValue}/>
                        </AzaGrid>
                        <AzaGrid item>
                            <AzaDivider orientation={isSmallScreen?"horizontal":"vertical"} variant="middle"/>
                        </AzaGrid>
                        <AzaGrid item>
                            <PredefinedPeriodsMenu onSelect={clickPredefinedPeriod}/>
                        </AzaGrid>
                    </AzaGrid>
                </AzaGrid>
                <AzaGrid item xs={12}>
                    <AzaGrid container direction={"row"} spacing={1}>
                        <AzaGrid item flex={1}></AzaGrid>
                        <AzaGrid item>
                            <AzaButton variant={"text"} onClick={cancelPeriod}>{t('periodpicker.cancel')}</AzaButton>
                        </AzaGrid>
                        <AzaGrid item>
                            <AzaButton variant={"contained"} onClick={validatePeriod}>{t('periodpicker.ok')}</AzaButton>
                        </AzaGrid>
                    </AzaGrid>
                </AzaGrid>
            </AzaGrid>
        </AzaMenu>
    </>
}

