import {useEffect} from 'react';


import './paymentMethods.css';
import AzaPaypalButton from "./AzaPaypalButton";
import {AzaButton} from "../../../components/mui/AzaButton";
import {AzaBox} from "../../../components/mui/AzaBox";
import {RequestOtherPaymentZohoForm} from "../../../components/azameo/ZohoForm";
import {useSelector} from "react-redux";
import {selectTotals} from "./paymentSlice";
import AzaStripeButton from "./AzaStripeButton";
import {useTranslation} from "react-i18next";
import {Divider} from "@mui/material";


const PaymentMethods = ({setCanNext, isSmallScreen, incrementStep}) => {

    const {ttc} = useSelector(selectTotals);
    const {t} = useTranslation();

    useEffect(() => {
        setCanNext(true)
    }, [setCanNext]);

    return (
        <AzaBox>
            <AzaBox
                component={"h4"}
                sx={{marginTop: '30px'}}
            >
                {t('payment.method.title')}
            </AzaBox>
            <p>{t('payment.method.text')}</p>
            <AzaBox
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    '& > :not(style)': {
                        m: 1,
                        width: isSmallScreen ? 215 : 253,
                        height: 128,
                    },
                }}
            >
                <AzaButton>Paypal is comming soon</AzaButton>
                <AzaStripeButton onDone={incrementStep} />
                {/*<AzaPaypalButton onDone={incrementStep}/>*/}
                <br/>
            </AzaBox>
            <Divider/>
            <AzaBox
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    }}
            >
                <RequestOtherPaymentZohoForm amount={ttc}/>
            </AzaBox>
            <br/>
        </AzaBox>
    );
};

export default PaymentMethods;
