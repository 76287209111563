const  DialogTitle = () => {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500
        }
      }
    }
  };
}

export default  DialogTitle;
