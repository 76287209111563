import {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {FormControlLabel, useTheme} from "@mui/material";

import {useCustomization} from "../../../features/customization/customizationHooks";
import {useCampaignColumns} from "./utils/campaignDataHooks";
import {AzaButton} from "../../../components/mui/AzaButton";
import {AzaMenu, AzaMenuItem} from "../../../components/mui/AzaMenu";
import {AzaFilter, AzaViewColumn} from "../../../components/mui/AzaIcons";
import {AdminModeBranch} from "../../../features/adminMode/adminModeComponents";
import {AzaCheckbox} from "../../../components/mui/AzaCheckbox";

export const ColumnSelector = () => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 90;
    const {loading, attributionColumns, updateAttributionColumns} = useCustomization();
    const {requiredData, campaignColumns} = useCampaignColumns();
    const theme = useTheme();

    const handleClickOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFiltersOpen = useCallback(() => {
        alert('Filters are not ready yet');
    }, []);

    const handleClick = useCallback((event) => {
        const elValue = event.target.value;
        const selectedIndex = attributionColumns.indexOf(elValue);
        let newSelected = [];

        if(selectedIndex === -1) {
            newSelected = newSelected.concat(attributionColumns, elValue);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(attributionColumns.slice(1));
        } else if (selectedIndex === attributionColumns.length - 1) {
            newSelected = newSelected.concat(attributionColumns.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                attributionColumns.slice(0, selectedIndex),
                attributionColumns.slice(selectedIndex + 1)
            );
        }

        updateAttributionColumns(newSelected);
    }, [attributionColumns, updateAttributionColumns]);

    const selectColumnsComponent = useMemo(() => {
        return <>
            <
                //sx={{
                //    display: 'flex',
                //    justifyContent: 'flex-end',
                //}}
            >
                <AzaButton
                    onClick={handleClickOpen}
                    sx={{
                        textTransform: 'none',
                        color: theme.palette.text.secondary,
                        marginRight: '7px',
                    }}
                >
                    {t('attribution.columnselector')}&nbsp;
                    <AzaViewColumn/>
                </AzaButton>
                <AdminModeBranch needAdmin={true}>
                    <AzaButton
                        onClick={handleFiltersOpen}
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.text.secondary,
                        }}
                    >
                        {t('attribution.filter')}&nbsp;
                        <AzaFilter/>
                    </AzaButton>
                </AdminModeBranch>
            </>

            <AzaMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                {Object.entries(campaignColumns).filter((el) => requiredData.indexOf(el[0]) === - 1).map((widget) => (
                    <AdminModeBranch key={widget[0]} needAdmin={widget[0] === 'campaign_code'}>
                        <AzaMenuItem
                            value={widget[0]}
                        >
                            <FormControlLabel
                                sx={{width: '100%', mr: '11px'}}
                                label={t(widget[1].name)}
                                control={
                                    <AzaCheckbox
                                        value={widget[0]}
                                        checked={attributionColumns.indexOf(widget[0]) > -1}
                                        onClick={handleClick}
                                    />
                                }
                            />
                        </AzaMenuItem>
                    </AdminModeBranch>
                ))}
            </AzaMenu>
        </>
    }, [open, requiredData, attributionColumns, anchorEl, t, campaignColumns,handleClick, handleFiltersOpen, theme.palette.text.secondary]);

    if (loading) return <></>

    return selectColumnsComponent;
}
