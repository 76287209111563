import {DrawerHeader} from "../../App.style";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import {AdminModeProvider} from "../../features/adminMode/adminModeContext";
import {PaymentModeProvider} from "../../containers/payment/paymentContext";
import MainContentWrapper from "../../containers/mainContent/MainContentWrapper";

export const AuthenticatedPages = () => {

    return (
        <AdminModeProvider>
            <PaymentModeProvider>
                <MainContentWrapper>
                    <DrawerHeader/>
                    <AuthenticatedRoutes/>
                </MainContentWrapper>
            </PaymentModeProvider>
        </AdminModeProvider>

    )
}
