import {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useDbItem} from "../../utils/rdbHooks";
import {RDB_USER} from "../../utils/constant";
import {getAuth} from "firebase/auth";
import {firebaseApp} from "../../firebaseConfig";
import {useAuthState} from "react-firebase-hooks/auth";

export const useLanguage = () => {
    const {i18n} = useTranslation();
    /* using useUser() here slow down component (when click avatar icon, appearance of dialog is quite slow),
    because it forces to rerender all component on the page
    */
    // const {user, loading: loadingUser} = useUser()
    const auth = useMemo(() => getAuth(firebaseApp), []);
    const [user, {loading: loadingUser}] = useAuthState(auth);
    const {item: userDB, loading: loadingDb, updateField} = useDbItem(RDB_USER, user?.uid)

    const changeLanguage = useCallback((lng) => {
        if (user) {
            updateField("lng", lng)
        }
        i18n.changeLanguage(lng).then(() => {
        });
    }, [i18n, updateField, user]);

    useEffect(() => {
        if (loadingUser || loadingDb || user === null) return;
        if (userDB?.lng === undefined) {
            changeLanguage(i18n.resolvedLanguage);
        } else {
            if (userDB?.lng !== i18n.resolvedLanguage) {
                i18n.changeLanguage(userDB?.lng).then(() => {
                });
            }
        }
    }, [changeLanguage, i18n, loadingDb, loadingUser, user, userDB?.lng])

    const language = useMemo(() => {
        if (user && !loadingDb && userDB) {
            return userDB.lng;
        }
        return i18n.resolvedLanguage
    }, [user, loadingDb, i18n, userDB]);

    return {language, changeLanguage};
}