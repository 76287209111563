import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {AzaButton} from "../../../components/mui/AzaButton";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {dataType, methodType, siteBackendFetch} from "../../../utils/backendHelper";
import {API_POST_SMART5_REPORT} from "../../../utils/constant";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import {AzaContainer} from "../../../components/mui/AzaContainer";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AzaStack} from "../../../components/mui/AzaStack";
import {AzaGridItem} from "../../../components/mui/AzaGrid";
import {useTheme} from "@mui/material";
import {AzaList, AzaListItem} from "../../../components/mui/AzaList";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaMoveToInbox} from "../../../components/mui/AzaIcons";

export const Smart5 = () => {

    const [uploading, setUploading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [firstLines, setFirstLines] = useState([]);

    const {user} = useUser();
    const site_id = useSiteId();
    const theme = useTheme();

    const {
        getRootProps, getInputProps, isDragActive
    } = useDropzone({
        // accept: {"txt": [".csv"]},
        maxFiles: 1, onDrop: acceptedFiles => {
            setSelectedFile(acceptedFiles[0]);
        }
    });

    useEffect(() => {
        if (!selectedFile) return;
        const reader = new FileReader();
        reader.onload = () => {
            setFirstLines(reader.result.split("\n").slice(0, 5))
        }
        reader.readAsText(selectedFile);

    }, [selectedFile, setFirstLines]);

    const sendToBack = useCallback(() => {
        setUploading(true);

        siteBackendFetch({
            user: user,
            site_id: site_id,
            path: API_POST_SMART5_REPORT,
            method: methodType.POST,
            data: {csv_file: selectedFile},
            type: dataType.form
        }).then(resp => {
            if (resp.code === 200) {
                setSuccess(true);
                setError(false);
                setSelectedFile(null);
            } else {
                setSuccess(false);
                setError(true);
                setErrorMessage(resp.message);
            }
        }).finally(() => {
            setUploading(false)
        });
    }, [user, site_id, selectedFile]);

    return (

        <AzaContainer>
            <AzaPaper sx={{padding: 10}}>
                <AzaTypography variant="h1" component="div" gutterBottom>Smart5 fast interface</AzaTypography>
                <br/>
                <AzaAlert severity={"info"}>
                    <AzaTypography variant="body1" gutterBottom>
                        Upload the report csv file obtained from GoogleAds: the rapport must be a true csv (not an excel
                        csv
                        or whatever)
                    </AzaTypography>
                    <AzaTypography variant="body1" gutterBottom>
                        The columns it must have:
                    </AzaTypography>
                    <AzaList>
                        <AzaListItem>customer.id, [SPLIT]</AzaListItem>
                        <AzaListItem>account.name,</AzaListItem>
                        <AzaListItem>campaign.id, [SPLIT]</AzaListItem>
                        <AzaListItem>date of the day, [SPLIT]</AzaListItem>
                        <AzaListItem>campaign.name,</AzaListItem>
                        <AzaListItem>campaign.status,</AzaListItem>
                        <AzaListItem>metrics.impressions,</AzaListItem>
                        <AzaListItem>metrics.clicks,</AzaListItem>
                        <AzaListItem>metrics.average_cpc,</AzaListItem>
                        <AzaListItem>metrics.cost_micros,</AzaListItem>
                        <AzaListItem>metrics.all_conversions,</AzaListItem>
                        <AzaListItem>metrics.conversions,</AzaListItem>
                        <AzaListItem>metrics.all_conversions_value,</AzaListItem>
                        <AzaListItem>metrics.conversions_value,</AzaListItem>
                        <AzaListItem>metrics.interactions,</AzaListItem>
                        <AzaListItem>metrics.engagements</AzaListItem>
                    </AzaList>
                </AzaAlert>
                <br/>
                <AzaStack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <AzaGridItem>
                        <AzaBox
                            sx={{
                                padding: '10px',
                                textAlign: 'center',
                                background: theme.palette.secondary.lighter,
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <AzaBox>
                                <AzaMoveToInbox sx={{color: theme.palette.primary.main, fontSize: '33px'}}/>
                            </AzaBox>
                            {isDragActive ? (
                                <p style={{color: 'green', margin: '5px 0 3px'}}>drag-and-drop the report 2</p>) : (
                                <p style={{margin: '5px 0 3px'}}>drag-and-drop the report</p>)}
                            <em>(csv)</em>
                        </AzaBox>
                    </AzaGridItem>
                    <AzaGridItem sx={{maxWidth: 1}}>
                        {selectedFile &&
                            <AzaTypography variant="h4" gutterBottom>{selectedFile.name}</AzaTypography>}
                        {selectedFile && <AzaStack justifyContent="center"
                                                   alignItems="stretch"
                                                   sx={{overflow: 'auto'}}
                        >
                            {firstLines.map((e, idx) => {
                                return <AzaGridItem key={idx} w={1}>
                                    <AzaTypography variant="body1" noWrap={true}>{e}</AzaTypography>
                                </AzaGridItem>
                            })}
                            <AzaGridItem w={1}>...</AzaGridItem>
                        </AzaStack>}
                    </AzaGridItem>
                    <AzaGridItem>
                        <AzaButton variant={"contained"} onClick={() => {
                            sendToBack()
                        }} disabled={uploading || !selectedFile}> Send file to back </AzaButton>
                    </AzaGridItem>
                    <AzaGridItem>
                        {success && <AzaAlert severity={"success"}>Data successfully sent to back</AzaAlert>}
                        {error && <AzaAlert severity={"error"}>{errorMessage}</AzaAlert>}
                    </AzaGridItem>
                </AzaStack>

            </AzaPaper>
        </AzaContainer>

    );
};

export default Smart5;

