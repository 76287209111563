import {useCallback, useEffect, useMemo, useState} from "react";
import {backendFetch} from "../../utils/backendHelper";
import {useUser} from "../../app/globalHooks";
import {Alert, Box, Button, CircularProgress, Grid, Paper, styled, Switch, TextField} from "@mui/material";
import {green} from '@mui/material/colors';
import {shallowEqual} from "react-redux";


const SampleRowTextField = ({label, value, onChange}) => {
    return (
        <Grid
            container
            item
            alignItems="center"
            spacing={2}
        >
            <Grid
                item
                xs={4}
            >
                {label}
            </Grid>
            <Grid
                item
                xs={8}
            >
                <TextField
                    size={"small"}
                    value={value ? value : ""}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    )
}

const SampleRowSwitch = ({label, value, onChange}) => {
    return (
        <Grid
            container
            item
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={4}>
                {label}
            </Grid>
            <Grid item xs={8}>
                <Switch
                    size={"small"}
                    checked={value}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    )
}

const backendAPI = `/site/1307/data`


const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const SampleBack = () => {

    const [siteData, setSiteData] = useState({})
    const [currentSettings, setCurrentSettings] = useState({})
    const [loading, setLoading] = useState(true)
    const {user} = useUser()

    const refreshData = useCallback(() => {
        if (user) {
            backendFetch({path: backendAPI, user})
                .then(json_data => {
                    setSiteData(json_data)
                })
        }
    }, [user])

    useEffect(() => {
        if (!user) return
        refreshData()
    }, [refreshData, user])

    const originalSettings = useMemo(() => {
        const output = {}
        if (!siteData || Object.keys(siteData).length === 0) return

        output["currency"] = siteData.currency ? siteData.currency : "EUR"
        output["url"] = siteData.url
        output["language"] = siteData.language ? siteData.language : "fr"
        output["use_availability"] = !!siteData.use_availability

        return output
    }, [siteData])

    const reconstructData = useCallback(() => {
        // right now it's easy because there are no nested value
        // it may be more complex later if we edit business info for ex
        return {...siteData, ...currentSettings}
    }, [siteData, currentSettings])

    useEffect(() => {
        setCurrentSettings(originalSettings)
        setLoading(false)

    }, [originalSettings, setCurrentSettings])

    const saveData = useCallback(() => {
        const data = reconstructData()
        setLoading(true)
        backendFetch({user, path: backendAPI, data, method: 'PUT'}).then(() => {
            refreshData()
        })
    }, [reconstructData, refreshData, user])


    const isChanged = useMemo(() => {
        if (!currentSettings || !originalSettings) return false
        return !shallowEqual(currentSettings, originalSettings)
    }, [currentSettings, originalSettings])

    const handleChange = useCallback((field, value) => {
        setCurrentSettings({...currentSettings, [field]: value})
    }, [currentSettings, setCurrentSettings])


    if (!currentSettings || Object.keys(currentSettings).length === 0) return <>Loading ...</>

    return (
        <Box
            sx={{maxWidth: 'md'}}
        >
            <Grid
                container
                spacing={1}
            >
                <SampleRowTextField
                    label={"Currency"}
                    value={currentSettings.currency}
                    onChange={(e) => {
                        handleChange("currency", e.target.value,)
                    }}
                />
                <SampleRowTextField
                    label={"URL"}
                    value={currentSettings.url}
                    onChange={(e) => {
                        handleChange("url", e.target.value)
                    }}
                />
                <SampleRowTextField
                    label={"Language"}
                    value={currentSettings.language}
                    onChange={(e) => {
                        handleChange("language", e.target.value)
                    }}
                />
                <SampleRowSwitch
                    label={"use_availability"}
                    value={!!(currentSettings?.use_availability)}
                    onChange={(e) => {
                        handleChange("use_availability", e.target.checked)
                    }}
                />
                <Grid item>
                    <Box sx={{display: 'flex', position: 'relative'}}>
                        <Button
                            variant="contained"
                            disabled={!isChanged || loading}
                            onClick={saveData}
                        >
                            Save
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Grid>
                {!!isChanged &&
                    <Grid item>
                        <Alert severity="warning">Data changed, need to save!</Alert>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}