/*
Detail and doc at: https://azameo.atlassian.net/wiki/spaces/D/pages/2081619969/Attribution+reducer+updater+and+hook
 */
import {createSlice} from "@reduxjs/toolkit";

const attributionSliceName = "attribution"

const initialState = {
    current: {},
    previous: {},
    now: {},
    lastUpdate: 0,
    loaded: false, // attribution data are not available as long as this is false
    updating: false, // attribution data are being updated as long as this is true, but they are still accessible
    currentPeriod: null,
    previousPeriod: null,
    siteID: null,
}

export const attributionSlice = createSlice({
    name: `${attributionSliceName}`,
    initialState,
    reducers: {
        changeCurrent: (state, action) => {
            state.current = action.payload
        },
        changePrevious: (state, action) => {
            state.previous = action.payload
        },
        changeNow: (state, action) => {
            state.now = action.payload
        },
        changeLastUpdate: (state, action) => {
            state.lastUpdate = action.payload
        },
        changeLoaded: (state, action) => {
            state.loaded = action.payload
        },
        changeUpdating: (state, action) => {
            state.updating = action.payload
        },
        resetAttribution: (state, action) => {
            // not sure how to do better
            // with something closer to
            // state = initialState
            state.current = {}
            state.previous = {}
            state.now = {}
            state.lastUpdate = 0
            state.loaded = false // attribution data are not available as long as this is false
            state.updating = false // attribution data are being updated as long as this is true, but they are still accessible
            state.currentPeriod = null
            state.previousPeriod = null
            state.siteID = null
        },
        changeAttributionCurrentPeriod: (state, action) => {
            state.currentPeriod = action.payload
        },
        changeAttributionPreviousPeriod: (state, action) => {
            state.previousPeriod = action.payload
        },
        changeAttributionSiteID: (state, action) => {
            state.siteID = action.payload
        },
    },
})

export const {
    changeCurrent,
    changePrevious,
    changeNow,
    changeLastUpdate,
    changeLoaded,
    changeUpdating,
    resetAttribution,
    changeAttributionCurrentPeriod,
    changeAttributionPreviousPeriod,
    changeAttributionSiteID,
} = attributionSlice.actions;

export const selectCurrent = (state) => {
    return state[`${attributionSliceName}`].current
}
export const selectPrevious = (state) => {
    return state[`${attributionSliceName}`].previous
}
export const selectNow = (state) => {
    return state[`${attributionSliceName}`].now
}
export const selectLastUpdate = (state) => {
    return state[`${attributionSliceName}`].lastUpdate
}
export const selectLoaded = (state) => {
    return state[`${attributionSliceName}`].loaded
}
export const selectUpdating = (state) => {
    return state[`${attributionSliceName}`].updating
}
export const selectAttributionCurrentPeriod = (state) => {
    return state[`${attributionSliceName}`].currentPeriod
}
export const selectAttributionPreviousPeriod = (state) => {
    return state[`${attributionSliceName}`].previousPeriod
}
export const selectAttributionSiteID = (state) => {
    return state[`${attributionSliceName}`].siteID
}

