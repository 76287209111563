import {useCallback, useEffect} from "react";

import {FacebookWizard} from "./FacebookWizard";
import {Avatar, CircularProgress, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    backendFacebookState,
    getAzameoInfo,
    selectBackinfo,
    selectConnected,
    selectConnectionState,
    selectErrorSumUpState,
    setWizardOpen
} from "./facebookSlice";

import faceboookIcon from "./f_logo_RGB-Blue_58.png"
import {AzaAlert} from "../../components/mui/AzaAlert";
import {AzaButton, AzaIconSettingsButton} from "../../components/mui/AzaButton";
import {AzaGrid} from "../../components/mui/AzaGrid";
import {AzaAlertActionCard, AzaCard} from "../../components/mui/AzaCard";
import {useSiteId, useUser} from "../../app/globalHooks";


const FacebookAsset = ({asset}) => {

    return <AzaGrid container direction={"row"}>
        <AzaGrid item sx={{marginRight: "8px"}}>
            <Typography variant={"body2"}>{asset.type}:</Typography>
        </AzaGrid>
        <AzaGrid item sx={{marginRight: "8px"}}>
            <Typography variant={"body2"}> {asset.name} </Typography>
        </AzaGrid>
        <AzaGrid item sx={{marginRight: "8px"}}>
            <Typography variant={"body2"}>{asset.id}</Typography>
        </AzaGrid>
    </AzaGrid>
}


export const FacebookWidget = () => {

    const dispatch = useDispatch()
    const backInfo = useSelector(selectBackinfo)
    const backInfoState = useSelector(selectConnectionState)
    const hasError = useSelector(selectErrorSumUpState)
    const connected = useSelector(selectConnected)
    const siteId = useSiteId();
    const {user} = useUser();

    const handleOpenWizard = useCallback(() => {
        dispatch(setWizardOpen(true))
    }, [dispatch])

    const assets = backInfo.map(
        (element, index) => {
            return <AzaGrid item key={index}><FacebookAsset asset={element}/></AzaGrid>
        }
    );

    useEffect(() => {
        dispatch(getAzameoInfo({site_id: siteId, user: user}));
    }, [dispatch, connected, siteId, user])

    const actions = useCallback(() => {
        switch (backInfoState) {
            case backendFacebookState.notReady:
                return <></>
            case backendFacebookState.NotConnected:
                return <AzaButton onClick={handleOpenWizard} variant={"text"}>Connect</AzaButton>
            case backendFacebookState.ConnectedOK:
            default:
                if (hasError.has_error) {
                    return <AzaAlertActionCard
                        severity={"error"}
                        text={hasError.msg}
                        actions={<AzaIconSettingsButton onClick={handleOpenWizard}
                                                        aria-label="disconnect"></AzaIconSettingsButton>}
                    ></AzaAlertActionCard>

                } else {
                    return <AzaAlertActionCard
                        severity={"success"}
                        text={"Active"}
                        actions={<AzaIconSettingsButton onClick={handleOpenWizard} aria-label="disconnect"/>}
                    ></AzaAlertActionCard>
                }
        }
    }, [backInfoState, handleOpenWizard, hasError.has_error, hasError.msg]);

    const buildFacebookWidget = useCallback(() => {
        switch (backInfoState) {
            case backendFacebookState.notReady:
                return <AzaAlert severity={"info"} action={<CircularProgress/>}>
                    Loading information
                </AzaAlert>
            case backendFacebookState.NotConnected:
                return <Typography variant={"body2"}>
                    Connect your Facebook Ads account and create Ads in minutes
                </Typography>
            case backendFacebookState.ConnectedOK:
            default:
                return <div>
                    <AzaGrid container direction={"column"}>
                        {assets}
                    </AzaGrid>
                </div>
        }
    }, [backInfoState, assets]);

    return (
        <AzaCard
            icon={<Avatar alt="Facebook" src={faceboookIcon}/>}
            title={'Facebook Ads'}
            actions={actions()}
        >
            {buildFacebookWidget()}
            <FacebookWizard/>
        </AzaCard>
    )
}