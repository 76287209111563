import React, {useCallback, useMemo, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import useMediaQuery from "@mui/material/useMediaQuery";

import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {getDatabase} from "firebase/database";

import {LogoAzameo} from "../../../components/logo/Logo";
import AzaLink from "../../../components/mui/AzaLink";
import {AzaButton} from "../../../components/mui/AzaButton";
import {backendFetch} from "../../../utils/backendHelper";
import {AzaGrid, AzaGridItem} from "../../../components/mui/AzaGrid";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import {AzaDialog, AzaDialogContent, AzaDialogTitle} from "../../../components/mui/AzaDialog";
import {auth} from "../../../utils/firebase/firebaseHelper";
import ResetPassword from "../../../containers/resetPassword/ResetPassword";
import {selectLanguage} from "../../../app/globalSlice";
import {firebaseApp} from "../../../firebaseConfig";
import CustomGoogleIcon from "../../../components/customIcons/CustomGoogleIcon";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AzaTextField} from "../../../components/mui/AzaTextField";
import {AzaTypography} from "../../../components/mui/AzaTypography";
import {AzaCheckbox} from "../../../components/mui/AzaCheckbox";
import {AzaFormGroup} from "../../../components/mui/AzaFormGroup";
import {AzaFormControlLabel} from "../../../components/mui/AzaFormControlLabel";
import AzaInputEmail from "../../../components/azameo/AzaInputEmail";


const AuthGoogle = ({auth, userAuth, redirect}) => {
    const provider = useMemo(() => new GoogleAuthProvider(), []);
    const {t} = useTranslation();

    const logIn = useCallback(() => {
        signInWithPopup(auth, provider)
            .catch((error) => {
                console.log(error);
            })
    }, [auth, provider]);

    // if not authenticated, display a login button
    if (!userAuth) {
        return (
            <AzaGridItem>
                <AzaButton
                    type={"button"}
                    variant={"outlined"}
                    onClick={logIn}
                    sx={{width: '224px'}}
                    startIcon={<CustomGoogleIcon/>}
                >
                    {(redirect === '/register') ? t('container.open.register.signupgoogle') : t('container.open.login.logingoogle')}
                </AzaButton>
            </AzaGridItem>
        );
    } else {
        return (
            <></>
        );
    }
}

const defaultFormField = {
    email: '',
    password: '',
    confirmPassword: '',
}

const LogData = ({logData, logIn = false, register = false}) => {
    const {t} = useTranslation();
    const {general, form_fields} = logData;
    const [formField, setFormField] = useState(defaultFormField);
    const {email, password, confirmPassword} = formField;
    const [errorAuth, setErrorAuth] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [openResetPass, setOpenResetPass] = useState(false);
    const [severityValue, setSeverityValue] = useState('error');
    const [userAuth] = useAuthState(auth);
    const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const language = useSelector(selectLanguage);
    const [searchParams] = useSearchParams();
    const redirect = decodeURI(searchParams.get('redirect') ?? '');
    const [emailValid, setEmailValid] = useState(false);

    const db = useMemo(() => {
        return getDatabase(firebaseApp);
    }, []);

    const styleData = {
        paperStyle: {
            padding: '35px 20px',
            maxWidth: 390,
            margin: '5px auto',
        },
        logoStyle: {
            height: '75px',
        }
    }

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        if (email.length < 1 || password.length < 1 || (register && confirmPassword.length < 1)) {
            setErrorAuth(true);
            setAlertMsg(t("login.error.allfieldrequired"));
            return false;
        }
        if (!emailValid) {
            setErrorAuth(true);
            setAlertMsg(t("login.error.emailinvalid"));
            return false;
        }
        if (register && password !== confirmPassword) {
            setErrorAuth(true);
            setAlertMsg(t("login.error.missmatch"));
            return false;
        }
        if (register && password.length < 6) {
            setErrorAuth(true);
            setAlertMsg(t("login.error.weakpassword"));
            return false;
        }
        auth.languageCode = String(language);

        signInWithEmailAndPassword(auth, email, password)
            .catch((error) => {
                if (error.code === 'auth/wrong-password') {
                    setErrorAuth(true);
                    register ? setAlertMsg(t("login.error.alreadyexist")) :
                        setAlertMsg(t("login.error.notcorrect"));
                } else if (error.code === 'auth/user-not-found') {
                    backendFetch({
                        user: null,
                        path: "/auth/fos/login",
                        method: "POST",
                        data: {
                            login: email,
                            password: password
                        }
                    }).then((data) => {
                        if (data.token) {
                            // 200 : login
                            signInWithCustomToken(auth, data.token)
                                .catch((error) => {
                                    console.log(error.code);
                                });
                        } else if (logIn) {
                            // try to log in with wrong email or password
                            setErrorAuth(true);
                            setAlertMsg(t("login.error.notcorrect"));
                        } else if (data.error === 401) {
                            // email already exist
                            setErrorAuth(true);
                            setAlertMsg(t("login.error.alreadyexist"));
                        } else if (data.error === 404) {
                            // create account
                            createUserWithEmailAndPassword(auth, email, password)
                                .catch((error) => {
                                    console.log(error.code);
                                });
                            }
                    }).catch(error => {
                        console.log(error.code);
                    })
                } else if (error.code === 'auth/too-many-requests') {
                    setErrorAuth(true);
                    setAlertMsg(t("login.error.toomanyattempts"));
                } else {
                    console.log(error)
                    setErrorAuth(true);
                    setAlertMsg(t("login.error.notcorrect"));
                }
            })
    }, [confirmPassword, email, language, logIn, password, register, t, emailValid]);

    const handleChange = useCallback((event) => {
        const {name, value} = event.target;
        setFormField({...formField, [name]: value})
        if (errorAuth) {
            setErrorAuth(false);
        }
    }, [formField, setFormField, errorAuth, setErrorAuth])

    const handleDialogOpen = () => {
        setOpen(true);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    const openResetPassword = () => {
        setOpenResetPass(true);
    }

    const handleResetPasseClose = (resetSuccessful = false) => {
        if (resetSuccessful === true) {
            setErrorAuth(true);
            setSeverityValue('success');
            setAlertMsg(t("login.instructions"));
        }
        setOpenResetPass(false);
    }



    return (
        <>
            <AzaPaper elevation={isMediumScreen ? 0 : 8} style={styleData.paperStyle}>
                <AzaBox sx={{marginBottom: '20px', textAlign: 'center'}}>
                    <LogoAzameo style={styleData.logoStyle}/>
                    <AzaBox component={"h2"} sx={{marginBottom: register ? '5px' : '20px'}}>{general.title}</AzaBox>
                    <AzaTypography>{general.check_trigger_text} <Link id={general.link}
                                                                   to={general.link + ((redirect !== '') ? "?redirect=" + encodeURI(redirect) : "")}>{general.check_trigger_link}</Link></AzaTypography>

                </AzaBox>
                {register ?
                    <AzaTypography sx={{marginBottom: '5px'}}>{t('container.open.register.subtitle')}</AzaTypography> : null}
                <AzaBox component="form">
                    {form_fields.map((obj, index) => {
                        if (obj.type === "email"){
                            return (
                                <AzaInputEmail key={index} setValid={setEmailValid} label={obj.label} type={obj.type} name={obj.name}
                                           value={formField[obj.name]} onChange={handleChange} variant="outlined" fullWidth
                                           required sx={{marginBottom: '15px'}}/>
                            )
                        }
                        return (
                            <AzaTextField key={index} label={obj.label} type={obj.type} name={obj.name}
                                       value={formField[obj.name]} onChange={handleChange} variant="outlined" fullWidth
                                       required sx={{marginBottom: '15px'}}/>
                        )
                    })}

                    {errorAuth && <AzaGridItem xs={12} sx={{marginBottom: '15px'}}><AzaAlert
                        severity={severityValue}>{alertMsg}</AzaAlert></AzaGridItem>}
                    {register ? <AzaTypography>
                            {t('container.open.register.agreeing')} <AzaLink href="#"
                                                                             onClick={handleDialogOpen}>{t('footer.terms.title')}</AzaLink>
                        </AzaTypography> :
                        <AzaBox>
                            <AzaFormGroup sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <AzaFormControlLabel control={<AzaCheckbox/>} label={t('login.remember')}
                                                  sx={{marginRight: '10px'}}/>
                                <AzaLink href="#" onClick={openResetPassword}>{t('login.forgot_password')}</AzaLink>
                            </AzaFormGroup>
                        </AzaBox>}
                    <AzaGrid align='center' sx={{marginTop: '30px'}}>
                        <AzaButton type='submit' variant="contained" sx={{width: '224px'}}
                                   onClick={handleSubmit}>{general.btn_aza}</AzaButton>
                        <AzaTypography sx={{padding: '4px 0'}}>{t('common.or')}</AzaTypography>
                        <AuthGoogle auth={auth} userAuth={userAuth} redirect={redirect} db={db}/>
                    </AzaGrid>
                </AzaBox>
            </AzaPaper>

            <ResetPassword openReset={openResetPass} handleResetPasseClose={handleResetPasseClose}/>
            <AzaDialog open={open} onClose={handleDialogClose}>
                <AzaDialogTitle onClose={handleDialogClose}>
                    {t('footer.terms.title')}
                </AzaDialogTitle>
                <AzaDialogContent>
                    Hello, here you can read our 'Terms'
                </AzaDialogContent>
            </AzaDialog>
        </>
    );
};

export default LogData;
