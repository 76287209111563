import {backendFetch, methodType} from "../../../utils/backendHelper";
import {API_ASSETS_DETAIL, API_ASSETS_GENERATE, API_ASSETS_LIST, API_SHORT_PRODUCTS} from "../../../utils/constant";
import {assetOrigin, assetScope} from "./constants";


export const getSiteProducts = (user, site_id, callback) => {
    backendFetch({path: API_SHORT_PRODUCTS, user, queryParams: {site_id}})
        .then(json_data => {
            callback(json_data)
        }).catch(error => {
        console.log(error)
    });
}

export const getSiteAssets = (user, site_id, callback) => {
    backendFetch({path: API_ASSETS_LIST, user, queryParams: {site_id}})
        .then(json_data => {
            // Order by id
            json_data.sort((a, b) => a.id - b.id)
            // remove asset with enabled=false
            json_data = json_data.filter(asset => asset.enabled)
            // for now only keep asset with asset.scope = Site
            // json_data = json_data.filter(asset => asset.scope === assetScope.SITE)

            // console.log(json_data)
            callback(json_data)
        }).catch(error => {
        console.log(error)
    });
}

export const saveSiteAssets = (user, asset, callback) => {
    // If it's an existing asset, update it
    if (asset.id > 0) {
        const path = API_ASSETS_DETAIL.replace("<pk>", asset.id)
        // Send the new asset to the backend
        backendFetch({path, user, method: methodType.PUT, data: asset})
            .then(json_data => {
                callback(json_data)
            }).catch(error => {
            console.log(error)
        });
    } else {
        // if it's a new asset, create it
        backendFetch({path: API_ASSETS_LIST, user, method: methodType.POST, data: asset})
            .then(json_data => {
                callback(json_data)
            }).catch(error => {
            console.log(error)
        });
    }
}

export const generateSiteAssets = (user, site_id, product_id, callback) => {
    const data = {site_id}
    if (product_id) {
        data["product_id"] = product_id
    }
    backendFetch({path: API_ASSETS_GENERATE, user, method: methodType.POST, data, queryParams: {site_id}})
        .then(json_data => {
            const assets = json_data["assets"] ? json_data["assets"] : []
            callback(assets)
        }).catch(error => {
        console.log(error)
    });
}


export const formatAssets = (assets, autoClean) => {
    if (autoClean) {
        // set the value of asset.text to asset.cleaned_text
        assets.forEach(asset => {
            asset["text"] = asset["cleaned_text"]
        })
    }
    const dict = {}
    assets.forEach(asset => {
        if (!dict[asset["text_type"]]) {
            dict[asset["text_type"]] = []
        }
        dict[asset["text_type"]].push(asset)
    })
    return dict
}

export const createAsset = (site_id, text_type, scope, product_id) => {
    return {
        "id": null,
        "site_id": site_id,
        "text_type": text_type,
        "origin": assetOrigin.CUSTOMER,
        "scope": scope,
        "language": "fr",
        "text": "",
        "cleaned_text": "",
        "enabled": true,
        "product_id": product_id,
    }
}