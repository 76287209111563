import {useChatBackend} from "../../features/chat/chatHooks";
import {AzaGrid} from "../../components/mui/AzaGrid";
import {useState} from "react";
import {AzaTextField} from "../../components/mui/AzaTextField";
import {AzaButton} from "../../components/mui/AzaButton";
import {AzaPaper} from "../../components/mui/AzaPaper";

export const SampleChat = () => {
    // The hook handle most of the chat as a rdb object
    const {
        lastMessageTime, // The time from the last message, used internally to know when to fetch new messages
        messages, // the list of all user and assistants messages (will hide other types of messages)
        postMessage, // The callback to send a new message to the backend, only 2 params : text and language
        placeholder, // The current placeholder, from the lastMessage or the lastContext
        lastMessage, // The last message
        suggestions, // The current suggestions, from the lastMessage or the lastContext
        lastContext, // The last context
    } = useChatBackend()


    // Only for the demo, the text typed by the user
    const [text, setText] = useState("")

    return (
        <AzaGrid container direction={"column"} spacing={2}>

            <AzaGrid item>
                <AzaGrid container direction={"row"}>
                    <AzaGrid item>
                        <AzaTextField
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </AzaGrid>
                    <AzaGrid item>
                        <AzaButton
                            onClick={() => {
                                postMessage(text)
                            }}
                        >
                            Send
                        </AzaButton>
                    </AzaGrid>
                </AzaGrid>
            </AzaGrid>
            <AzaGrid item>
                lastMessageTime: {lastMessageTime}
            </AzaGrid>
            <AzaGrid item>
                lastMessage: {JSON.stringify(lastMessage, null, 2)}
            </AzaGrid>
            <AzaGrid item>
                lastContext: {JSON.stringify(lastContext, null, 2)}
            </AzaGrid>
            <AzaGrid item>
                placeholder: {placeholder}
            </AzaGrid>
            <AzaGrid item>
                suggestions: {JSON.stringify(suggestions, null, 2)}
            </AzaGrid>
            <AzaGrid item>
                Messages:
            </AzaGrid>

            {messages.map((message) => (
                <AzaGrid item key={message.id}>
                    <AzaPaper sx={{
                        p: 2,
                        backgroundColor: message.role === "user" ? "primary.main" : "secondary.main",
                    }}>
                        {message.text}
                    </AzaPaper>
                </AzaGrid>
            ))}
        </AzaGrid>
    )
}