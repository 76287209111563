import {useUser} from "../../app/globalHooks";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {auth} from "../../utils/firebase/firebaseHelper";
import {sendEmailVerification} from "firebase/auth";
import {AzaDialog, AzaDialogContent, AzaDialogTitle} from "../../components/mui/AzaDialog";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaAlert} from "../../components/mui/AzaAlert";
import {AzaButton} from "../../components/mui/AzaButton";


export const VerifyEmail = ({open, handleClose}) => {

    const {user} = useUser();
    const [errorVerifyEmail, setErrorVerifyEmail] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const {t, i18n} = useTranslation();

    const triggerEmailVerification = () => {
        auth.languageCode = String(i18n.resolvedLanguage);
        sendEmailVerification(auth.currentUser)
            .then(() => {
                handleClose(true);
            })
            .catch((error) => {
                setErrorVerifyEmail(true);
                switch (error.code) {
                    case 'auth/user-not-found':
                        setErrorMsg(t('container.resetpassword.error.notfound'));
                        break;
                    case 'auth/invalid-email':
                        setErrorMsg(t('container.resetpassword.error.emailinvalid'));
                        break;
                    case 'auth/missing-email':
                        setErrorMsg(t('container.resetpassword.error.missingemail'));
                        break;
                    case 'auth/too-many-requests':
                        setErrorMsg(t('container.resetpassword.error.toomanyrequests'));
                        break;
                    default:
                        setErrorMsg(t('container.resetpassword.error.genericerror'));
                }
            });
    }

    return (
        <div className="verifyEmail-main">
            <AzaDialog open={open} onClose={handleClose}>
                <AzaDialogTitle onClose={handleClose}
                                sx={{
                                    marginLeft: ['0', '24px', '24px'],
                                    marginRight: ['0', '24px', '24px'],
                                    fontSize: ['1.3rem', '1.5rem', '1.5rem'],
                                }}
                >
                    {t('container.verifyemail.text')}
                </AzaDialogTitle>
                <AzaDialogContent
                    sx={{
                        marginLeft: ['0', '24px', '24px'],
                        marginRight: ['0', '24px', '24px'],
                    }}
                >
                    {errorVerifyEmail && <AzaGridItem xs={12}
                                                      sx={{marginBottom: '15px'}}>
                        <AzaAlert severity="error">{errorMsg}</AzaAlert>
                    </AzaGridItem>}
                    {user.emailVerified && <AzaGridItem xs={12} sx={{marginBottom: '15px'}}>
                        <AzaAlert severity="success">{t('container.verifyemail.success')}</AzaAlert>
                    </AzaGridItem>}
                    <AzaGridContainer
                          sx={{
                              justifyContent: ['center', "flex-end", null]
                          }}
                    >
                        <AzaGridItem>
                            <AzaButton variant="contained" type="button" onClick={triggerEmailVerification}
                                       disabled={user?.emailVerified}>{t('container.verifyemail.askforcode')}</AzaButton>
                        </AzaGridItem>
                    </AzaGridContainer>
                </AzaDialogContent>
            </AzaDialog>
        </div>)
}
