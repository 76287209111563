import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_PERIOD} from "../utils/constant";

const globalSliceName = "global"

// only used locally in the initial state
const today = new Date();


export const globalSlice = createSlice({
    name: `${globalSliceName}`,
    initialState: {
        site_id: "",
        site_loaded: false,
        site_logo: {
            error: {
                status: false,
                message: '',
            },
            is_updated: false,
            files: [],
        },
        siteList: {},
        site_list_loaded: false,
        site_settings: {},
        userinfos: {},
        language: 'fr',
        currentPeriod: {
            // By default, start 30 days before today
            start: today.addDays(-DEFAULT_PERIOD).toISOStringDateOnly(),
            // By default, end today
            end: today.toISOStringDateOnly(),
        },
        previousPeriod: {
            // By default, start 61 days before today, so that we have enough data to compare with the current period
            start: today.addDays(-DEFAULT_PERIOD * 2 - 1).toISOStringDateOnly(),
            // By default, end 31 days before today
            end: today.addDays(-DEFAULT_PERIOD - 1).toISOStringDateOnly(),
        }
    },
    reducers: {
        setSiteId: {
            reducer: (state, action) => {
                state.site_id = action.payload
                state.site_loaded = true
            },
            prepare: (site_id) => {
                return {payload: site_id}
            }
        },
        setSiteLoaded: (state, action) => {
            state.site_loaded = action.payload;
        },
        setSiteLogo: (state, action) => {
            state.site_logo = action.payload;
        },
        setSiteList: (state, action) => {
            state.siteList = action.payload
            state.site_list_loaded = true;
        },
        setSiteSettings: (state, action) => {
            state.site_settings = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        changeCurrentPeriod: (state, action) => {
            state.currentPeriod = action.payload;
        },
        changePreviousPeriod: (state, action) => {
            state.previousPeriod = action.payload;
        },
        setUserInfos: (state, action) => {
            state.userinfos = action.payload;
        }
    }
})

export const {
    setSiteId,
    setSiteLoaded,
    setSiteLogo,
    setSiteList,
    setSiteSettings,
    setLanguage,
    changeCurrentPeriod,
    changePreviousPeriod,
    setUserInfos
} = globalSlice.actions;

export const selectSiteId = (state) => {
    return state[`${globalSliceName}`].site_id
}

export const selectSiteLoaded = (state) => {
    return state[`${globalSliceName}`].site_loaded
}

export const selectSiteLogo = (state) => {
    return state[`${globalSliceName}`].site_logo;
}
export const selectSiteList = (state) => {
    return state[`${globalSliceName}`].siteList;
}

export const selectSiteListLoaded = (state) => {
    return state[`${globalSliceName}`].site_list_loaded;
}

export const selectLanguage = (state) => {
    return state[`${globalSliceName}`].language
}
export const selectCurrentPeriod = (state) => {
    return state[`${globalSliceName}`].currentPeriod
}
export const selectPreviousPeriod = (state) => {
    return state[`${globalSliceName}`].previousPeriod
}
export const selectUserInfos = (state) => {
    return state[`${globalSliceName}`].userinfos
}
export const selectZohoForms = (state) => {
    return state[`${globalSliceName}`].site_settings?.zoho?.forms??{};
}

export const selectDefaultCampaignDisplay = (state) => {
    return state[`${globalSliceName}`].site_settings?.campaign?.views??{};
}

export const selectSiteSettingsReady = (state) => {
    if (state[`${globalSliceName}`].site_id === "") {
        return false;
    }
    else if (state[`${globalSliceName}`].site_settings?.site_id === undefined) {
        return false;
    }
    else if (state[`${globalSliceName}`].site_settings?.site_id === state[`${globalSliceName}`].site_id) {
        return true;
    }
    return false;
}
