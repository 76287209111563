import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import AzaCardTemplate from "../../../../components/azameo/AzaCardTemplate";
import {AzaBox} from "../../../../components/mui/AzaBox";
import {
    actionSetCountries,
    selectCountries,
    actionSetLanguages,
    selectLanguages,
} from "../../reducers/createNewCampaignSlice";
import {AzaCountrySelector} from "../../../../components/azameo/AzaCountrySelector";
import {AzaLanguageSelector} from "../../../../components/azameo/AzaLanguageSelector";
import {AzaAlert} from "../../../../components/mui/AzaAlert";

const Geo = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const selectedCountries = useSelector(selectCountries);
    const selectedLanguages = useSelector(selectLanguages);

    return (
        <AzaBox sx={{marginBottom: '30px'}}>
            {/*translation*/}
            <AzaCardTemplate title={t('audience.geo-targeting')}>
                <AzaBox sx={{mb: '50px'}}>
                    <AzaBox sx={{mb: '15px'}}>
                        {t('audience.targeted-countries')}
                    </AzaBox>
                    <AzaBox sx={{mb: '15px'}}>
                        <AzaCountrySelector
                            country_code={selectedCountries.data}
                            disabled={false}
                            loading={false}
                            onChange={(countryCode) => {
                                dispatch(actionSetCountries({data: countryCode}));
                            }}
                            whitelist={[]}
                            blacklist={[]}
                            allchoice={false}
                            multiple={true}
                            required={true}
                            min={1}
                            errorFromSlice={selectedCountries.error?.status}
                        />
                    </AzaBox>
                    {selectedCountries?.error?.status &&
                        <AzaAlert severity="error">
                            <AzaBox>
                                {
                                    selectedCountries?.error?.message && <AzaBox>{selectedCountries.error.message}</AzaBox>
                                }
                            </AzaBox>
                        </AzaAlert>
                    }
                </AzaBox>

                <AzaBox sx={{mb: '50px'}}>
                    <AzaBox sx={{mb: '15px'}}>
                        {t('audience.ad-language')}
                    </AzaBox>
                    <AzaBox sx={{mb: '15px'}}>
                        <AzaLanguageSelector
                            language_code={selectedLanguages.data}
                            disabled={false}
                            loading={false}
                            onChange={(languageCode) => {
                                dispatch(actionSetLanguages({data: languageCode ? languageCode : ''}));
                            }}
                            whitelist={[]}
                            blacklist={[]}
                            allchoice={true}
                            multiple={false}
                            required={true}
                            min={1}
                            errorFromSlice={selectedLanguages.error?.status}
                        />
                    </AzaBox>
                    {selectedLanguages?.error?.status &&
                        <AzaAlert severity="error">
                            <AzaBox>
                                {
                                    selectedLanguages?.error?.message && <AzaBox>{selectedLanguages.error.message}</AzaBox>
                                }
                            </AzaBox>
                        </AzaAlert>
                    }
                </AzaBox>
            </AzaCardTemplate>
        </AzaBox>
    );
};

export default Geo;