import {useTranslation} from "react-i18next";

export const useCampaignColumns = () => {

    const {t} = useTranslation()

    const requiredData = [
        "network",
        "status",
        "campaign_name",
        "notification",
    ];


    const campaignColumns = {
        status: {
            name: t('campaign.column.status'),
            format: "",
        },
        campaign_name: {
            name: t('campaign.column.name'),
            format: "formatText",
        },
        //just for admin
        // translation (?)
        campaign_code: {
            name: 'Campaign Code',
            format: "formatText",
        },
        network: {
            name: t('campaign.column.networks'),
            format: "formatNetworks",
        },
        notification: {
            name: t('campaign.column.notification'),
            format: "formatText",
        },
        budget: {
            key: "daily",
            name: t('campaign.column.daily-budget'),
            format: "formatMoney",
        },
        impressions: {
            name: t('campaign.column.impressions'),
            format: "formatNumber",
        },
        reach: {
            name: t('campaign.column.reach'),
            format: "formatNumber",
        },
        clicks: {
            name: t('campaign.column.clicks'),
            format: "formatNumber",
        },
        click_through_rate: {
            name: t('campaign.column.ctr'),
            format: "formatPercent",
            params: [3],
        },
        campaign_type: {
            name: t('campaign.column.attribution-model'),
            format: "formatText",
        },
        attributed_nb_conversions: { // vs clicks_nb_conversions (same name)
            name: t('campaign.column.conversion'),
            format: "formatNumber",
        },
        // clicks_nb_conversions: { // vs clicks_nb_conversions (same name)
        //     name: 'Conversion',
        //     format: "formatNumber",
        // },
        // network_conversions
        lever_attributed_nb_conversions: {
            name: t('campaign.column.network-conversions'),
            format: "formatNumber",
        },
        attributed_conversions: { // vs clicks_conversions (same name)
            name: t('campaign.column.sales'),
            format: "formatMoney",
        },
        lever_attributed_conversions: {
            name: t('campaign.column.network-sales'),
            format: "formatMoney",
        },
        cost: {
            name: t('campaign.column.cost'),
            format: "formatMoney",
        },
        roi: {
            name: t('campaign.column.roi'),
            format: "formatFloat",
        },
        network_roi: {
            name: t('campaign.column.network-roi'),
            format: "formatFloat",
        },
        postimp_nb_conversions: {
            name: t('campaign.column.post-view-conversions'),
            format: "formatNumber",
        },
        postimp_conversions: {
            name: t('campaign.column.post-view-sales'),
            format: "formatMoney",
        },
        cost_per_click: {
            name: t('campaign.column.cpc'),
            format: "formatFloat",
        },
        cost_per_thousand_impressions: {
            name: t('campaign.column.cpm'),
            format: "formatFloat",
        },
        cost_per_action: {
            name: t('campaign.column.cpa'),
            format: "formatFloat",
        },
    }

    const orderColumnModel = [
        "network",
        "status",
        "campaign_name",
        "campaign_code", //just for admin
        "notification",
        "budget",
        "impressions",
        "reach",
        "clicks",
        "click_through_rate",
        "campaign_type",
        "attributed_nb_conversions",
        "lever_attributed_nb_conversions",
        "attributed_conversions",
        "lever_attributed_conversions",
        "cost",
        "roi",
        "network_roi",
        "postimp_nb_conversions",
        "postimp_conversions",
        "cost_per_click",
        "cost_per_thousand_impressions",
        "cost_per_action"
    ];

    return { requiredData, campaignColumns, orderColumnModel };
}
