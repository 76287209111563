/*
Detail and doc at: https://azameo.atlassian.net/wiki/spaces/D/pages/2081619969/Attribution+reducer+updater+and+hook
 */
import {selectCurrent, selectLoaded, selectPrevious, selectUpdating} from "./attributionSlice";
import {useSelector} from "react-redux";
import {useMemo} from "react";

const marketingDataCalculation = (campaign) => {
    const output = {};

    if (campaign?.["impressions"] > 0) {
        output["click_through_rate"] = (campaign?.["clicks"] / campaign?.["impressions"]);
        output["cost_per_thousand_impressions"] = ((campaign?.["cost"] / campaign?.["impressions"]) * 1000);
    } else {
        output["click_through_rate"] = "-";
        output["cost_per_thousand_impressions"] = "-";
    }

    if (campaign?.["clicks"] > 0) {
        output["cost_per_click"] = (campaign?.["cost"] / campaign?.["clicks"]);
    } else {
        output["cost_per_click"] = "-";
    }

    if (campaign?.["attributed_nb_conversions"] > 0) {
        output["cost_per_action"] = (campaign?.["cost"] / campaign?.["attributed_nb_conversions"]);
    } else {
        output["cost_per_action"] = "-";
    }

    if (campaign?.["cost"] > 0) {
        output["network_roi"] = campaign?.["lever_attributed_conversions"] / campaign?.["cost"];
    } else {
        output["network_roi"] = 0;
    }

    return output;
}

const computeSpecificData = (campaign) => {
    const output = {};
    let updatedDailyData = [];

    if (campaign.list_date.length > 0) {
        updatedDailyData = campaign.list_date.map((dayData) => {
            const additionalData = marketingDataCalculation(dayData);
            return {...dayData, ...additionalData}
        })
    }

    output['campaignData'] = marketingDataCalculation(campaign);

    output['campaignData']['list_date'] = updatedDailyData;

    return output['campaignData'];
}
const formatCampaignData = (attribution) => {
    const output = {}
    if (!attribution.list_campaigns) return output;

    for (let i = 0; i < attribution["list_campaigns"].length; i++) {
        let campaign = attribution["list_campaigns"][i];
        // group by group_name
        if (!output[campaign["group_name"]]) {
            output[campaign["group_name"]] = {
                list_campaigns: [],
                total: null,
                name: campaign["group_name"],
            }
        }
        // Campaign with a campaign code greater than 0 is normal campaign
        const computedSpecificData = computeSpecificData(campaign);
        let campaignWithSpecificData = {...campaign, ...computedSpecificData};
        if (parseInt(campaign["campaign_code"]) > 0) {
            output[campaign["group_name"]].list_campaigns.push(campaignWithSpecificData);
        } else {
            // Lower than 0, it means it's a total
            output[campaign["group_name"]].total = campaignWithSpecificData;
        }
    }
    return output;
}

const extracted_campaign_total_data = [
    "impressions",
    "attributed_conversions",
    "attributed_nb_conversions",
    "roi",
    "clicks",
    "buyer",
    "multiple_buyer",
]

const extracted_site_data = [
    "visits",
    "visitors",
    "viewers",
    "turnover",
]

const sortingCampaignGroups = (unsortedCampaignGroup) => {
    const topData = {};
    const bottomData = {}; // should we add order?
    const checkerArr = ['managed', 'deleted','Total'];
    const middleData = Object.keys(unsortedCampaignGroup).filter((key) => {
        if(key === 'managed') {
            topData[key] = unsortedCampaignGroup[key];
        } else if (key === 'Total' || key === 'deleted') {
            bottomData[key] = unsortedCampaignGroup[key];
        }
        return !checkerArr.includes(key) && unsortedCampaignGroup[key].list_campaigns.length > 0;
    }
    ).reduce((obj, key) => {
        return {
            ...obj,
            [key]: unsortedCampaignGroup[key]
        }
    }, {});
    return {...topData, ...middleData, ...bottomData};
}

export const useAttribution = () => {

    const currentAttribution = useSelector(selectCurrent)
    const previousAttribution = useSelector(selectPrevious)
    const loaded = useSelector(selectLoaded)
    const updating = useSelector(selectUpdating)

    // Format the attribution data to be easier to navigate, group by group name and extract the totals
    const currentCampaignData = useMemo(() => {
        // If there are no data no need to continue
        if (!loaded) return {}
        if (!currentAttribution) return {}
        const unsortedCampaignGroup = formatCampaignData(currentAttribution);

        return sortingCampaignGroups(unsortedCampaignGroup);
    }, [loaded, currentAttribution])

    const previousCampaignData = useMemo(() => {
        // If there are no data no need to continue
        if (!loaded) return {}
        if (!previousAttribution) return {}

        return formatCampaignData(previousAttribution)
    }, [loaded, previousAttribution])

    const currentSiteData = useMemo(() => {
        if (!loaded) return {}
        if (!currentAttribution) return {}

        return currentAttribution["site_period_info"]
    }, [currentAttribution, loaded])

    const previousSiteData = useMemo(() => {
        if (!loaded) return {}
        if (!previousAttribution) return {}
        return previousAttribution["site_period_info"]
    }, [previousAttribution, loaded])

    //Extract the grand total so it's faster to access
    const currentTotalData = useMemo(() => {
        if (!loaded) return {}
        if (!currentCampaignData) return {}
        return currentCampaignData["Total"]?.["total"] ? currentCampaignData["Total"]["total"] : {}
    }, [currentCampaignData, loaded])

    const previousTotalData = useMemo(() => {
        if (!loaded) return {}
        if (!previousCampaignData) return {}
        return previousCampaignData["Total"]?.["total"] ? previousCampaignData["Total"]["total"] : {}
    }, [previousCampaignData, loaded])


    // Some data extracted for different widgets
    const widgetData = useMemo(() => {
        if (!loaded) return {}
        if (Object.keys(currentCampaignData).length === 0) return {}

        const output = {}
        // Extract some data from the campaigns
        for (let i = 0; i < extracted_campaign_total_data.length; i++) {
            const key = extracted_campaign_total_data[i]
            output[key] = {
                current: currentTotalData[key],
                previous: previousTotalData?.[key],
            }
        }
        // Extract some site data
        for (let i = 0; i < extracted_site_data.length; i++) {
            const key = extracted_site_data[i]
            output[key] = {
                current: currentSiteData?.[key],
                previous: previousSiteData?.[key],
            }
        }
        // Compute some specific data
        output["ctr"] = {
            current: currentTotalData?.["clicks"] / currentTotalData?.["impressions"],
            previous: previousTotalData?.["clicks"] / previousTotalData?.["impressions"],
        }
        output["awareness_contribution"] = {
            current: currentTotalData?.["awarness"] / currentSiteData?.["awarness_visit"],
            previous: previousTotalData?.["awarness"] / previousSiteData?.["awarness_visit"],
        }
        output["sales_contribution"] = {
            current: currentTotalData?.["attributed_conversions"] / currentSiteData?.["turnover"],
            previous: previousTotalData?.["attributed_conversions"] / previousSiteData?.["turnover"],
        }
        output["site_conversion_rate"] = {
            current: currentSiteData?.["buyer"] / currentSiteData?.["visitors"],
            previous: previousSiteData?.["buyer"] / previousSiteData?.["visitors"],
        }

        output["campaign_conversion_rate"] = {
            current: currentTotalData?.["attributed_conversions"] / currentTotalData?.["clicks"],
            previous: previousTotalData?.["attributed_conversions"] / previousTotalData?.["clicks"],
        }
        output["cpm"] = {
            current: currentTotalData?.["cost"] / currentTotalData?.["clicks"],
            previous: previousTotalData?.["cost"] / previousTotalData?.["clicks"],
        }
        // Profit should be a protected data, either it is not sent by the server or reset to 0 if not an admin
        output["profit"] = {
            current: currentTotalData?.["profit"],
            previous: previousTotalData?.["profit"],
        }
        output["marge"] = {
            current: currentTotalData?.["profit"] / currentTotalData?.["balance_cost"],
            previous: previousTotalData?.["profit"] / previousTotalData?.["balance_cost"],
        }


        return output
    }, [loaded, currentCampaignData, currentTotalData, previousTotalData, currentSiteData, previousSiteData])


    return {
        loaded,
        updating,
        currentCampaignData,
        previousCampaignData,
        currentSiteData,
        previousSiteData,
        currentTotalData,
        previousTotalData,
        widgetData
    }
}