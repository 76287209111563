import React, {useEffect} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useMemo} from "react";
import {getAuth} from "firebase/auth";
import {firebaseApp} from "../../firebaseConfig";
import {useAuthState} from "react-firebase-hooks/auth";
import {LoadingPage} from "./LoadingPage";
import {Auth} from "../auth/Auth";

export const OpenPages = (props) => {
    const navigate = useNavigate()
    const auth = useMemo(() => getAuth(firebaseApp), []);
    const [user, loading] = useAuthState(auth);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const redirect = decodeURI(searchParams.get('redirect') ?? '');

    useEffect(()=> {
        if (user !== null){
            if (redirect !== '') {
                navigate(redirect);
            } else {
                navigate('/');
            }
        }
    }, [navigate, redirect, user])

    if (loading) {
        return <LoadingPage redirect={location.pathname} search={location.search} loading={loading}/>
    }

    return (
        <><Auth mode={props.mode}/></>
    )
}