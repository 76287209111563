import {useUser} from "../../app/globalHooks";
import AzaBadge from "../../components/mui/AzaBadge";
import {AzaAvatar} from "../../components/mui/AzaAvatar";
import React from "react";
import logoAzameo from '../../assets/images/avatarzameo.png';

export function UserBadge({badgeContent, ...props}) {
    const {userInfos} = useUser()

    return <AzaBadge badgeContent={badgeContent} color="primary">
        <AzaAvatar
            src={userInfos?.photoURL}
            name={userInfos?.name}
            {...props}
        />
    </AzaBadge>;
}

export function AzameoBadge({badgeContent, ...props}) {

    return <AzaBadge badgeContent={badgeContent} color="primary">
        <AzaAvatar
            src={logoAzameo}
            name={"Azameo"}
            {...props}
        />
    </AzaBadge>;
}
