import {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    actionSetAssetsBrandName, actionSetCampaignName, actionSetCountries, actionSetDailyBudget,
    actionSetErrorCounter, actionSetExceedDailyBudget, actionSetIsNetworkSelected, actionSetLanguages,
    actionUpdateAssets, selectAllCampaignsNames,
    selectAssetScope,
    selectBrandName,
    selectCallouts, selectCampaignName, selectCheckedExceedBudget,
    selectCountries, selectDailyBudget,
    selectDescriptions, selectExceedDailyBudget,
    selectHeadlines,
    selectImages,
    selectIsMultipleProducts,
    selectKeywords, selectLanguages,
    selectLongHeadlines, selectNetworkAzameo, selectNetworkGoogle, selectNetworkMicrosoft,
    selectProductAssetSelected
} from "../createNewCampaign/reducers/createNewCampaignSlice";
import {selectSiteLogo} from "../../app/globalSlice";
import {
    MIN_BUDGET_AMOUNT,
    MIN_TARGET_ROI,
    REGEX_TEXT_CHECKER
} from "../createNewCampaign/createNewCampaignUtile/createNewCamaignConst";
import {
    calloutHandler,
    descriptionHandler,
    headlineHandler, keywordHandler,
    longHeadlineHandler
} from "../createNewCampaign/createNewCampaignGroup/addAssetsGroup/addAssetsUtility/inputConfigData";
import {usePayment} from "../payment/paymentGroup/paymentHook";

export const useCampaignValidation = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const assetsScopeObj = useSelector(selectAssetScope);
    const brandName = useSelector(selectBrandName);
    const headlinesToValidate = useSelector(selectHeadlines);
    const longHeadlinesToValidate = useSelector(selectLongHeadlines);
    const descriptionsToValidate = useSelector(selectDescriptions);
    const calloutsToValidate = useSelector(selectCallouts);
    const logoToValidate = useSelector(selectSiteLogo);
    const imgToValidate = useSelector(selectImages);
    const keywordsToValidate = useSelector(selectKeywords);
    const countriesToValidate = useSelector(selectCountries);
    const languageToValidate = useSelector(selectLanguages);
    const productAssets = useSelector(selectProductAssetSelected);
    const multipleProducts = useSelector(selectIsMultipleProducts);
    const isMultipleProducts = multipleProducts.is_multiple_product;
    const networkAzameo = useSelector(selectNetworkAzameo);
    const networkGoogle = useSelector(selectNetworkGoogle);
    const networkMicrosoft = useSelector(selectNetworkMicrosoft);
    const campaignNameData = useSelector(selectCampaignName);
    const allCampaignsNames = useSelector(selectAllCampaignsNames);
    const dailyBudgetData = useSelector(selectDailyBudget);
// usePayment provoke an error/waring 'Warning: Cannot update a component...'
    const {currency} = usePayment();
    const exceedBudgetChecked = useSelector(selectCheckedExceedBudget);
    const exceedBudgetData = useSelector(selectExceedDailyBudget);

    const maxLength = useMemo(() => ({
        'Headline': headlineHandler.maxLength,
        'Long Headline': longHeadlineHandler.maxLength,
        'Description': descriptionHandler.maxLength,
        'Callout': calloutHandler.maxLength,
        'Keyword': keywordHandler.maxLength,
    }), []);

    const checkIfAllEmpty = useCallback((assets) => {
        let elementsEmpty = true;
        let updatedAssets = [];

        for(let asset of assets) {
            if(asset.cleaned_text.length > 0) {
                elementsEmpty = false;
                break;
            } else {
                updatedAssets = [...updatedAssets, {...asset, error: {}}];
            }
        }

        if(elementsEmpty) {
            dispatch(actionUpdateAssets(updatedAssets));
        }

        return elementsEmpty;
    }, [dispatch]);

    const assetsValidationHelper = useCallback((dataToValidate, regExValidation) => {
        let errorFound = false;
        let countErrors = 0;
        for(let assets of dataToValidate) {
            let uniqueElements = new Set();
            let shouldBeUpdated = false;
            const elementsToUpdate = [];
            for(let asset of assets) {
                let inputsWithError = 0;
                let textToValidate = asset.cleaned_text.trim();
                if(textToValidate.length < 1) {
                    countErrors++;
                    shouldBeUpdated = true;
                    errorFound = true;
                    asset = {...asset, error: {status: true, message: [t('component.azatextfield.required')]}}
                    elementsToUpdate.push(asset);
                    continue;
                }

                if(asset.error) {
                    // remove 'error' key from object, in case of recheck (re-init)
                    shouldBeUpdated = true;
                    const {error, ...rest} = asset;
                    asset = rest;
                }

                if(uniqueElements.has(textToValidate.toLowerCase())) {
                    inputsWithError = 1;
                    shouldBeUpdated = true;
                    errorFound = true;
                    const {error, text_type} = asset;
                    const formattedType = text_type.toLowerCase().replace(' ', '-');
                    if(error?.status) {
                        error.message.push(t(`input.error.already-exists.${formattedType}`));
                    } else {
                        asset = {...asset, error: {status: true, message: [t(`input.error.already-exists.${formattedType}`)]}};
                    }
                } else {
                    uniqueElements.add(textToValidate.toLowerCase());
                }

                if(textToValidate.length > maxLength[asset.text_type]) {
                    inputsWithError = 1;
                    shouldBeUpdated = true;
                    errorFound = true;
                    const {error} = asset;
                    if(error?.status) {
                        error.message.push(t('input.error.too-long'));
                    } else {
                        asset = {...asset, error: {status: true, message: [t('input.error.too-long')]}}
                    }
                }

                for(let checker in regExValidation) {
                    if(regExValidation[checker].regex.test(asset.cleaned_text)) {
                        shouldBeUpdated = true;
                        errorFound = true;
                        inputsWithError = 1;
                        const {error} = asset;
                        if(error?.status) {
                            error.message.push(t('google-validation.error.' + regExValidation[checker].message));
                        } else {
                            asset = {...asset, error: {status: true, message: [t('google-validation.error.' + regExValidation[checker].message)]}}
                        }
                    }
                }
                countErrors += inputsWithError;
                elementsToUpdate.push(asset);
            }

            if(shouldBeUpdated) {
                dispatch(actionUpdateAssets(elementsToUpdate));
            }
        }
        return {error: errorFound, counter: countErrors}
    }, [dispatch, maxLength, t]);

    const assetsValidation = useCallback((dataToValidate, activeStep) => {
        let errorFound = false;
        let countErrors = 0;
        const regExValidation = REGEX_TEXT_CHECKER.google_checker;
        for(let el in dataToValidate) {
            switch(el) {
                case 'assets':
                    const {error, counter} = assetsValidationHelper(dataToValidate[el], regExValidation);
                    errorFound = error;
                    countErrors += counter;
                    break;
                case 'brandName':
                    if(dataToValidate[el].length < 1) {
                        errorFound = true;
                        countErrors++;
                        dispatch(actionSetAssetsBrandName({
                            data: '',
                            error: {status: true, message: t('component.azatextfield.required')}
                        }));
                    }
                    break;
                case 'logo':
                    // if(dataToValidate[el].files.length < 1) {
                    //     errorFound = true;
                    //     countErrors++;
                    //     dispatch(actionSetLogo({data: [], error: {status: true, message: t('images.empty')}}));
                    // }
                    break;
                case 'img':
                    // if(dataToValidate[el].files.length < 1) {
                    //     errorFound = true;
                    //     countErrors++;
                    //     dispatch(actionSetImages({data: [], error: {status: true, message: t('images.empty')}}));
                    // }
                    break;
                default:
                    console.log('Something went wrong');
            }
        }
        // this construction is correct just for step "0", when we have two tabs : "Brand" and "Product"
        dispatch(actionSetErrorCounter({[activeStep]: {[assetsScopeObj.scopeName.toLowerCase()]: countErrors}}));
        return errorFound;
    }, [assetsScopeObj.scopeName, assetsValidationHelper, dispatch, t]);

    const audienceValidation = useCallback((dataToValidate, activeStep) => {
        let errorFound = false;
        let countErrors = 0;
        const regExValidation = REGEX_TEXT_CHECKER.keywords_checker;
        for(let el in dataToValidate) {
            switch (el) {
                case 'assets':
                    const {error, counter} = assetsValidationHelper(dataToValidate[el], regExValidation);
                    errorFound = error;
                    countErrors += counter;
                    break;
                case 'country':
                    if(dataToValidate[el].data.length < 1) {
                        countErrors++;
                        errorFound = true;
                        dispatch(actionSetCountries({data: [], error: {status: true, message: t('component.azatextfield.required')}}))
                    }
                    break;
                case 'language':
                    if(dataToValidate[el].data.length < 1) {
                        countErrors++;
                        errorFound = true;
                        dispatch(actionSetLanguages({data: [], error: {status: true, message: t('component.azatextfield.required')}}))
                    }
                    break;
                default:
                    console.log('Something went wrong');
            }
        }

        dispatch(actionSetErrorCounter({[activeStep]: countErrors}));
        return errorFound;
    }, [assetsValidationHelper, dispatch, t]);

    const networksValidation = useCallback((activeStep) => {
        let countErrors = 0;

        const networksSelected = [networkAzameo.is_selected, networkGoogle.is_selected, networkMicrosoft.is_selected];
        let errorFound = !networksSelected.includes(true);
        if(errorFound) {
            countErrors++;
            dispatch(actionSetIsNetworkSelected(false));
        }
        dispatch(actionSetErrorCounter({[activeStep]: countErrors}));
        return errorFound;
    }, [dispatch, networkAzameo.is_selected, networkGoogle.is_selected, networkMicrosoft.is_selected]);

    const campaignSettingsValidation = useCallback((activeStep) => {
        let errorFound = false;
        let countErrors = 0;
        if(campaignNameData.new_name.length < 1) {
            errorFound = true;
            countErrors++;
            dispatch(actionSetCampaignName({new_name: '', error: {status: true, message: t('component.azatextfield.required')}}))
        } else {
            for(let el of allCampaignsNames) {
                if(campaignNameData.new_name.toLowerCase() === el.toLowerCase()) {
                    errorFound = true;
                    countErrors++;
                    dispatch(actionSetCampaignName({new_name: campaignNameData.new_name, error: {status: true, message: t('campaigns_data.existing-name')}}))
                    break;
                }
            }
        }

        if(dailyBudgetData.daily_budget < MIN_BUDGET_AMOUNT) {
            errorFound = true;
            countErrors++;
            dispatch(actionSetDailyBudget({daily_budget: dailyBudgetData.daily_budget,
                error: {status: true, message: t('payment.amount.custom.error.minimum_value_to_pay', {
                        val: MIN_BUDGET_AMOUNT,
                        formatParams: {
                            val: {currency: currency ?? "eur",}
                        }
                    })}}))
        }

        if(exceedBudgetChecked) {
            if(exceedBudgetData.exceed_daily_budget < MIN_TARGET_ROI) {
                errorFound = true;
                countErrors++;
                dispatch(actionSetExceedDailyBudget({
                    exceed_daily_budget: exceedBudgetData.exceed_daily_budget,
                    error: {status: true, message: t('min-amount') + MIN_TARGET_ROI + '%'}
                }));
            }
        } else {
            dispatch(actionSetExceedDailyBudget({
                exceed_daily_budget: exceedBudgetData.exceed_daily_budget,
                error: {status: false, message: ''}
            }));
        }
        dispatch(actionSetErrorCounter({[activeStep]: countErrors}));
        return errorFound;
    }, [
        allCampaignsNames,
        campaignNameData.new_name,
        currency,
        dailyBudgetData.daily_budget,
        dispatch,
        exceedBudgetChecked,
        exceedBudgetData.exceed_daily_budget,
        t]);

    const validateActiveStep = useCallback((stepName, step) => {
        let isError = false;

        switch (stepName) {
            case 'ad_assets':
                let addAssetsToValidate;
                let isProductAssetsEmpty = true;
                if(assetsScopeObj.scopeName === 'Site' || isMultipleProducts) {
                    addAssetsToValidate = {
                        assets: [headlinesToValidate, longHeadlinesToValidate, descriptionsToValidate, calloutsToValidate],
                        brandName: brandName.name,
                        logo: logoToValidate,
                        img: imgToValidate,
                    }
                } else {
                    let productAssetsArray = [];
                    for(let el in productAssets) {
                        if(el !== "Keyword") {
                            if(isProductAssetsEmpty) {
                                isProductAssetsEmpty = checkIfAllEmpty(productAssets[el]);
                            }
                            productAssetsArray.push(productAssets[el])
                        }
                    }

                    addAssetsToValidate = {
                        assets: productAssetsArray,
                        logo: logoToValidate,
                    }
                }
                if(isProductAssetsEmpty && assetsScopeObj.scopeName === 'Product') {
                    isError = false;
                    dispatch(actionSetErrorCounter({[step]: 0}));
                } else {
                    isError = assetsValidation(addAssetsToValidate, step);
                }
                break;
            case 'audience':
                let keywordsProductToValidate = [];
                let isProductKeywordsEmpty = true;
                for(let el in productAssets) {
                    if(el === "Keyword") {
                        if(isProductKeywordsEmpty) {
                            isProductKeywordsEmpty = checkIfAllEmpty(productAssets[el]);
                        }
                        keywordsProductToValidate.push(productAssets[el])
                    }
                }
                const audienceDataToValidate = {
                    assets: (assetsScopeObj.scopeName === 'Site' || isMultipleProducts) ? [keywordsToValidate] : keywordsProductToValidate,
                    country: countriesToValidate,
                    language: languageToValidate,
                }
                if(isProductKeywordsEmpty && assetsScopeObj.scopeName === 'Product') {
                    isError = false;
                    dispatch(actionSetErrorCounter({[step]: 0}));
                } else {
                    isError = audienceValidation(audienceDataToValidate, step);
                }
                break;
            case 'networks':
                isError = networksValidation(step);
                break;
            case 'settings':
                isError = campaignSettingsValidation(step);
                break;
            default:
                console.log(step + ' step passed');
        }

        return !isError;
    }, [
        dispatch,
        checkIfAllEmpty,
        isMultipleProducts,
        assetsScopeObj.scopeName,
        assetsValidation,
        audienceValidation,
        brandName.name,
        calloutsToValidate,
        campaignSettingsValidation,
        countriesToValidate,
        descriptionsToValidate,
        headlinesToValidate,
        imgToValidate,
        keywordsToValidate,
        languageToValidate,
        logoToValidate,
        longHeadlinesToValidate,
        networksValidation,
        productAssets]);

    /*
    return to use for edition(?):
    assetsValidation
    audienceValidation
    networksValidation
    campaignSettingsValidation
     */

    return {validateActiveStep}
}