import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAttribution} from "../../../features/attribution/attributionHooks";
import {useCustomization} from "../../../features/customization/customizationHooks";
import {AzaGrid} from "../../../components/mui/AzaGrid";
import {CampaignGroup} from "./CampaignGroup";
import {AzaTypography} from "../../../components/mui/AzaTypography";

export const CampaignData = () => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('campaign_name');
    const {currentCampaignData, loaded} = useAttribution();
    const {attributionColumns, loading} = useCustomization();
    const {t} = useTranslation();


    const handleRequestSort = useCallback((event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    if (!currentCampaignData || loading || !loaded) return <AzaTypography>{t('common.no_data')}</AzaTypography>

    return (
        <AzaGrid container direction={"column"} spacing={2}>
            {

                Object.values(currentCampaignData).map((groupData) => (
                    <AzaGrid
                        item
                        key={groupData.name}
                        sx={{
                            backgroundColor: 'transparent',
                            width: '100%'
                        }}
                    >
                        <CampaignGroup
                            groupData={groupData}
                            order={order}
                            orderBy={orderBy}
                            requestSort={handleRequestSort}
                            attributionColumns={attributionColumns}
                        />
                    </AzaGrid>)
                )
            }
        </AzaGrid>
    )
}
