/**
 * Documentation can be found at:
 * https://azameo.atlassian.net/wiki/spaces/D/pages/2080931841/RDB+Hooks+rdbHooks.js
 */

import {useCallback, useEffect, useMemo, useState} from "react";
import {getDatabase, onValue, ref, update} from "firebase/database";
import {firebaseApp} from "../firebaseConfig";
import {useList} from "react-firebase-hooks/database";
import {getAuth} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {PING_TIMEOUT, RDB_USER, RECENT_NUMBER} from "./constant";


/**
 * Form for user login
 * @param {string} path The path of the object in the database, should be a constant (ex: in constant.js)
 * @param {string} itemID The id of the item in the database
 * @returns {object, bool, function, string, object, object, function} The item, loading (false when loaded), and error from the database
 */
export const useDbItem = (path, itemID) => {
    const [item, setItem] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    // realtime db part
    const db = useMemo(() => {
        return getDatabase(firebaseApp);
    }, [])

    const itemRef = useMemo(() => {
        // We can't allow itemID to be null, because it would fetch the whole list
        if (!itemID) return null;
        return ref(db, path + itemID)
    }, [db, itemID, path])

    useEffect(() => {
        if (!itemRef) return;
        return onValue(itemRef, (snapshot) => {
                const data = snapshot.val();
                setItem(data);
                setLoading(false)
            }, (error) => {
                setError(error)
            }
        )
    }, [itemRef])

    const updateField = useCallback((field, value) => {
        if (!itemRef) return;
        return update(itemRef, {[field]: value})
    }, [itemRef])

    const updateItem = useCallback((updates) => {
        if (!itemRef) return;
        return update(itemRef, updates)
    }, [itemRef])

    return {item, loading, updateField, error, itemRef, db, updateItem}

}


/**
 * Form for user login
 * @param {string} path The path of the objects in the database, should be a constant (in constant.js)
 * @param {function} filter A function to filter the items, optional
 * @returns {object, bool, string, object, object} The item, loading (false when loaded), and error from the database
 */
export const useDbItems = (path, filter) => {

    // realtime db part
    const db = useMemo(() => {
        return getDatabase(firebaseApp);
    }, [])

    const itemsRef = useMemo(() => {
        return ref(db, path)
    }, [db, path])

    // We don't need the loading that seems to cause issues with re-rendering, but _ is still a valid variable name...
    // eslint-disable-next-line no-unused-vars
    const [snapshots, _, error] = useList(itemsRef);

    const loading = useMemo(() => {
        return snapshots === null && error === null;
    }, [snapshots, error]);


    const items = useMemo(() => {
        let output = {}
        if (loading) return output
        if (snapshots) {
            for (const snapshot of snapshots) {
                let item = snapshot.val()
                if (!filter || filter(item)) {
                    output[snapshot.key] = item
                }
            }
        }
        return output
    }, [filter, loading, snapshots])


    return {items, loading, error, db, itemsRef}
}


export const useUserRDB = () => {

    const auth = useMemo(() => getAuth(firebaseApp), []);
    const [userAuth] = useAuthState(auth);
    const {item: user, updateItem: updateUser, loading} = useDbItem(RDB_USER, userAuth?.uid);

    const ping = useCallback(() => {
        updateUser({lastPing: new Date().getTime()});
    }, [updateUser])

    const recent = useMemo(() => {
        if (!user) return []
        return user.recent || []
    }, [user])


    const favorites = useMemo(() => {
        if (!user) return []
        return user.favorites || []
    }, [user])


    const changeSite = useCallback((site_id) => {
        if (site_id) {
            const index = recent.indexOf(site_id);
            if (index >= 0) {
                recent.splice(index, 1)
            }
            recent.unshift(site_id)
        } else {
            site_id = null // we need to make sure it's not undefined
        }
        updateUser({recent: recent.slice(0, RECENT_NUMBER), site_id})
    }, [recent, updateUser])

    const toggleFavorite = useCallback((site_id) => {
        console.log("toggleFavorite", site_id)
        site_id = site_id.toString()
        const index = favorites.indexOf(site_id);
        if (index >= 0) {
            favorites.splice(index, 1)
        } else {
            favorites.unshift(site_id)
        }
        updateUser({favorites})
    }, [favorites, updateUser])

    return {user, ping, recent, changeSite, loading, favorites, toggleFavorite, updateUser}

}

export const useConnected = () => {
    // Get the list of connected sites
    const {items: users, loading} = useDbItems(RDB_USER, null)

    const connectedUsers = useMemo(() => {
        if (!users) return {}

        let output = {}
        let now = new Date().getTime()
        for (const [uid, user] of Object.entries(users)) {
            // Don't consider admin users at all for this list
            if (user.admin) continue;

            let delta = (now - user.lastPing) / 1000
            if (delta < PING_TIMEOUT) {
                output[uid] = user
            }
        }
        return output
    }, [users])

    // Since the list of connected sites is small we can get it once, it's not as heavy as the site status
    const connectedSites = useMemo(() => {
        if (!users) return []

        let output = {}
        let now = new Date().getTime()

        for (const user of Object.values(users)) {
            // Don't consider admin users at all for this list
            if (user.admin) continue;

            let delta = (now - user.lastPing) / 1000
            if (delta < PING_TIMEOUT) {
                if (user.site_id) {
                    output[user.site_id] = 1
                }
            }
        }
        return Object.keys(output)
    }, [users])

    return {connectedUsers, connectedSites, loading}
}