import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';

import {AzaCardBase, AzaCardContent, AzaCardHeader} from "../mui/AzaCard";
import {AzaDivider} from "../mui/AzaDivider";
import {AzaTypography} from "../mui/AzaTypography";

const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};


const MantisMainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            subheader,
            content = true,
            contentSX = {},
            darkTitle,
            divider = true,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            codeHighlight = false,
            codeString,
            modal = false,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

        return (
            <AzaCardBase
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    position: 'relative',
                    border: border ? '1px solid' : 'none',
                    borderRadius: 1,
                    borderColor: theme.palette.secondary.light,
                    boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
                    ':hover': {
                        boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
                    },
                    ...(codeHighlight && {
                        '& pre': {
                            m: 0,
                            p: '12px !important',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '0.75rem'
                        }
                    }),
                    ...(modal && {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
                        '& .MuiCardContent-root': {
                            overflowY: 'auto',
                            minHeight: 'auto',
                            maxHeight: `calc(100vh - 200px)`
                        }
                    }),
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <AzaCardHeader
                        sx={headerSX}
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        title={title}
                        action={secondary}
                        subheader={subheader}
                    />
                )}
                {darkTitle && title && <AzaCardHeader sx={headerSX} title={<AzaTypography variant="h4">{title}</AzaTypography>} action={secondary} />}

                {title && divider && <AzaDivider />}

                {/* card content */}
                {content && <AzaCardContent sx={contentSX}>{children}</AzaCardContent>}
                {!content && children}

                {codeString && (
                    <>
                        <AzaDivider sx={{ borderStyle: 'dashed' }} />
                    </>
                )}
            </AzaCardBase>
        );
    }
);

export default MantisMainCard;
