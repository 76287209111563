import {TreeItem, TreeView} from "@mui/lab";

export const AzaTreeItem = (props) => {
    return (
        <TreeItem {...props} />
    )
}

export const AzaTreeView = (props) => {
    return (
        <TreeView {...props} />
    )
}
