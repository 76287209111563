import {useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {AzaBox} from "../mui/AzaBox";
import {AzaButton} from "../mui/AzaButton";
import {AzaCheckbox} from "../mui/AzaCheckbox";
import {AzaDivider} from "../mui/AzaDivider";
import {AzaFormControlLabel} from "../mui/AzaFormControlLabel";
import {AzaFormGroup} from "../mui/AzaFormGroup";
import {AzaMenu} from "../mui/AzaMenu";
import {AzaRadio} from "../mui/AzaRadio";
import {AzaRadioGroup} from "../mui/AzaRadioGroup";
import {useTheme} from "@mui/material";


export const AzaChoice = (
    {choice, list, handlechoiceChange, multiple = false, translationSource, name, icon, iconOnly= false}
) => {

    const theme = useTheme();
    const [color,setColor] = useState(theme.palette.secondary.main);

    const [internal, setInternal] = useState(choice);
    const [anchorEl, setAnchorEl] = useState(null);
    const {t} = useTranslation();

    const getMultipleChoiceChange = (name) => {
        return (event) => {
            const tmpChoice = {...choice};
            tmpChoice.disabled = false;
            tmpChoice[name] = event.target.checked;
            let test =false;
            for (const key of list) {
                if(key!=="disabled"){
                    test= test||tmpChoice[key];
                }
            }
            if(!test){
                clearMultiple();
            }else{
                setColor(theme.palette.primary.main)
                setInternal(tmpChoice);
            }
        }
    };

    const getSingleChoiceChange = (name) => {
        return () => {
            setInternal(name);
        }
    };

    const clearMultiple = () => {
        const tmpChoice = {...choice};
        for (const it of list) {
            tmpChoice[it] = false;
        }
        tmpChoice["disabled"]=true;
        setColor(theme.palette.secondary.main);
        setInternal(tmpChoice);
    }

    const openChoice = useCallback((event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [anchorEl]);

    const closeChoice = () => {
        setAnchorEl(null);
    };

    const getChoice = useCallback((name) => {
        return internal[name]
    }, [internal]);

    useEffect(() => {
        handlechoiceChange(internal);
    }, [internal, handlechoiceChange]);

    const genMultiple = () => {
        return (
            <AzaFormGroup>
                {
                    list.map((name, key) => {
                        return <AzaFormControlLabel
                            control={
                                <AzaCheckbox checked={getChoice(name)}
                                             onChange={getMultipleChoiceChange(name)}/>
                            }
                            label={t(translationSource + name)}
                            key={key}
                        />
                    })
                }
                <AzaButton
                    sx={{
                        textTransform: 'none',
                        color: theme.palette.text.primary,
                        ml: -2
                    }}
                    onClick={clearMultiple}
                    key={"test"}
                >
                    {t(translationSource + "clear")}
                </AzaButton>
            </AzaFormGroup>
        )
    }

    const genSingle = () => {
        return (
            <AzaRadioGroup
                defaultValue={internal}
            >
                {
                    list.map((name, key) => {
                        return <AzaFormControlLabel
                            value={name}
                            control={<AzaRadio/>}
                            label={t(translationSource + name)}
                            onClick={getSingleChoiceChange(name)}
                            key={key}
                        />
                    })
                }
            </AzaRadioGroup>
        )
    }

    return (
        <>
            <AzaButton
                onClick={openChoice}
                sx={{
                    textTransform: 'none',
                    color: color,
                }}
            >
                {icon}{!iconOnly && t(translationSource + name)}
            </AzaButton>
            <AzaMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeChoice}
            >
                <AzaButton
                    sx={{
                        textTransform: 'none',
                        color: color,
                        ml: 3,
                    }}
                    onClick={closeChoice}
                >
                    {icon}{t(translationSource + name)}
                </AzaButton>
                <AzaDivider/>
                <AzaBox
                    sx={{ml: 2}}
                >
                    {multiple ? genMultiple() : genSingle()}
                </AzaBox>
            </AzaMenu>
        </>
    )

}
