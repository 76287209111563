import React from 'react';
import {Box} from "@mui/material";

function Footer(props) {
    const {children, ...others} = props;
    return (
        <Box component={'footer'} {...others}><Box>{children}</Box></Box>
    );
}

export default Footer;