/*
*  doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2128347137/AzaInputEmail
 */

import {useCallback, useEffect, useState} from 'react';

import {AzaTextField} from "../mui/AzaTextField";
import validator from "validator";
import {useTranslation} from "react-i18next";

const AzaInputEmail = (props) => {

    const {value, onChange, onBlur, error, setValid,  helperText, ...other} = props;
    const [emailError, setEmailError] = useState(false);
    const [emailhelperText, setHelperText] = useState(null);
    const {t} = useTranslation();

    const internalOnChange = useCallback((event) => {

        setValid?.(validator.isEmail(event.target.value));

        if (emailError) {
            setEmailError(false);
            setHelperText(null);
        }

        onChange?.(event);
    }, [value, onChange, emailError, setEmailError, setHelperText]);

    const internalOnBlur = useCallback((event) => {

        if (!validator.isEmail(event.target.value)){
            console.log(event.target.value, "isinvalid")
            setEmailError(true);
            setHelperText(t("component.azainputemail.invalid"));
        } else {
            console.log(event.target.value, "isvalid")
            setEmailError(false);
            setHelperText(null);
        }

        onBlur?.(event);
    }, [value, onBlur]);


    return (
        <AzaTextField
            type="email"
            label={t("component.azainputemail.label")}
            onChange={internalOnChange}
            onBlur={internalOnBlur}
            error={emailError || error}
            helperText={emailhelperText || helperText}
            value={value}
            {...other}
        />
    );
};

export default AzaInputEmail;
