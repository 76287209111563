import {ImageList, ImageListItem, ImageListItemBar, IconButton} from "@mui/material";
import {Cancel} from "@mui/icons-material";

const ImagesList = ({imgFiles, removeAction}) => {
    const handleDelete = (arg) => {
        removeAction(arg);
    }

    return (
        <>
            <ImageList
                rowHeight={150}
                sx={{
                    '&.MuiImageList-root': {
                        gridTemplateColumns:
                            'repeat(auto-fill, minmax(150px, 1fr))!important',
                    },
                }}
            >
                {imgFiles.map((file, index) => (
                    <ImageListItem key={index} cols={1} rows={1}>
                        <img
                            alt={file?.name}
                            style={{ height: '100%' }}
                            src={file.preview}
                        />
                        <ImageListItemBar
                            position="top"
                            sx={{
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)',
                            }}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'white' }}
                                    onClick={() => handleDelete(file)}
                                >
                                    <Cancel />
                                </IconButton>
                            }
                        ></ImageListItemBar>
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    );
};

export default ImagesList;

