import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import { alpha } from '@mui/material/styles';


import { visuallyHidden } from '@mui/utils';

import {AzaButton} from "../../../components/mui/AzaButton";
import {useFormat, useScreenSize} from "../../../app/globalHooks";
import PdfInvoice from "../../../widgets/Attribution/CampaignData/utils/PdfInvoice";
import PdfToDownload from "../../../widgets/Attribution/CampaignData/utils/PdfToDownload";
import PdfToShow from "../../../widgets/Attribution/CampaignData/utils/PdfToShow";
import {descendingComparator} from "../../../utils/sortingFunctionalities";
import {AzaCheckbox} from "../../../components/mui/AzaCheckbox";
import {AzaToolBar} from "../../../components/mui/AzaToolBar";
import {
    AzaTable,
    AzaTableBody,
    AzaTableCell, AzaTableContainer,
    AzaTableHead,
    AzaTablePagination,
    AzaTableRow, AzaTableRowHead, AzaTableSortLabel
} from "../../../components/mui/AzaTable";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaTypography} from "../../../components/mui/AzaTypography";

// noinspection AllyPlainJsInspection
const styleData = {
    styleCheckbox: {
        padding: '0 5px',
    },
    styleCell: {
        padding: '5px'
    },
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function BillTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const {isSmallScreen} = useScreenSize();
    const {t} = useTranslation();
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const headCells = [
        {
            id: 'time',
            numeric: false,
            disablePadding: true,
            label: t('credit.bills_table.header.date'),
        },
        {
            id: 'price',
            numeric: true,
            disablePadding: false,
            label: t('credit.bills_table.header.amount'),
        },
        {
            id: 'invoice',
            numeric: true,
            disablePadding: false,
            label: t('credit.bills_table.header.open'),
        },

    ];

    return (
        <AzaTableHead>
            <AzaTableRowHead>
                <AzaTableCell padding="checkbox" style={isSmallScreen ? styleData.styleCheckbox : null}>
                    <AzaCheckbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all',
                        }}
                    />
                </AzaTableCell>
                {headCells.map((headCell) => (
                    <AzaTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={isSmallScreen ? styleData.styleCell : null}
                    >
                        {headCell.id !== 'invoice' ? (
                            <AzaTableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <AzaBox component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </AzaBox>
                            ) : null}
                        </AzaTableSortLabel>
                        ) : headCell.label}
                    </AzaTableCell>
                ))}
            </AzaTableRowHead>
        </AzaTableHead>
    );
}

BillTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function BillTableToolbar(props) {
    const {numSelected, selectedEl, invoicesData} = props;
    const [downloadPDFBtn, setDownloadPDFBtn] = useState([]);
    const [downloadClicked, setDownloadClicked] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        // potential risk of "downloadClicked", to see comments in "handlePdfDownload"?
        if(downloadPDFBtn.length > 0 && downloadClicked) {
            let delay = 1000;
            for(let el of downloadPDFBtn) {
                let helper = document.getElementsByClassName(el.invoice);
                setTimeout(function () {
                    helper[0].click();
                }, delay);
                delay += 1000;
            }
        }
        setDownloadClicked(false);
    }, [downloadPDFBtn, downloadClicked]);

    const handlePdfDownload = (selectedEl) => {
        // is there a risk, that "setDownloadPDFBtn" will be done before "setDownloadClicked" ?
        setDownloadClicked(true);
        const selectedInvoicesData = invoicesData.filter((el) => selectedEl.indexOf(el.invoice) > - 1);
        setDownloadPDFBtn(selectedInvoicesData);
    }

    return (
        <>
            <PdfToDownload
                selectedInvoices={downloadPDFBtn}
                template={PdfInvoice}
            />
            <AzaToolBar
                sx={{
                    pl: { xs: 1, sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                    justifyContent: 'space-between',
                }}
            >
                <AzaButton variant="contained" disabled={!numSelected > 0} onClick={() => handlePdfDownload(selectedEl)}>
                    {t('common.download.pdf')}
                </AzaButton>
                {numSelected >= 0 ? (
                    <AzaTypography
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t('common.download.selected', {count: numSelected})}
                    </AzaTypography>
                ) : null}
            </AzaToolBar>
        </>
    );
}

const AzaBillsTable = ({invoicesData}) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('time');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const {formatMoney, formatDateTime} = useFormat();
    const {isSmallScreen} = useScreenSize();
    const dataRows = useMemo(() => {
        function createData(time, price, invoice, currency) {
            return {
                time,
                price,
                invoice,
                currency,
            };
        }

        const rows = [];
        for (const el of invoicesData) {
            rows.push(createData(el.transaction_time, el.price_ht, el.invoice_id, el.currency))
        }
        return rows;
    }, [invoicesData]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = dataRows.map((n) => n.invoice);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    // checkbox functionality
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => {
       return selected.indexOf(name) !== -1;
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataRows.length) : 0;

    return (
        <>
            <AzaBox sx={{ width: '100%', mb: 2 }}>
                <BillTableToolbar numSelected={selected.length} selectedEl={selected} invoicesData={dataRows}/>
                <AzaTableContainer>
                    <AzaTable
                        sx={{ minWidth: {md: 560, xs: 285} }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <BillTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={dataRows.length}
                        />
                        <AzaTableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 dataRows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(dataRows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.invoice);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <AzaTableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.invoice)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.invoice}
                                            selected={isItemSelected}
                                        >
                                            <AzaTableCell
                                                align="center"
                                                padding="checkbox"
                                                style={isSmallScreen ? styleData.styleCheckbox : null}
                                            >
                                                <AzaCheckbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </AzaTableCell>
                                            <AzaTableCell id={labelId} style={isSmallScreen ? styleData.styleCell : null}>
                                                {formatDateTime(Date.parse(row.time))}
                                            </AzaTableCell>
                                            <AzaTableCell align="right" style={isSmallScreen ? styleData.styleCell : null}>
                                                {formatMoney(row.price)}
                                            </AzaTableCell>
                                            <AzaTableCell
                                                align="right"
                                                onClick={(event) => event.stopPropagation()}
                                                style={isSmallScreen ? styleData.styleCell : null}
                                            >
                                                <PdfToShow template={PdfInvoice} elementData={row}/>
                                            </AzaTableCell>
                                        </AzaTableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <AzaTableRow
                                    style={{
                                        height: 33 * emptyRows,
                                    }}
                                >
                                    <AzaTableCell colSpan={6} />
                                </AzaTableRow>
                            )}
                        </AzaTableBody>
                    </AzaTable>
                </AzaTableContainer>
                <AzaTablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </AzaBox>
        </>
    );
}

export default AzaBillsTable;
