import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

/**
 * AzameoTag react hook:
 * Should be used with the component AzameoTag mounted at the root of the application.
 * This gives access to the following API:
 *
 * - navigationEvent: to send a navigation event to AzameoTag - this event is auto called on each navigation by the AzameoTag component - you probably don't need to call it yourself
 * - conversionEvent: to send a conversion event to AzameoTag each time a conversion is made
 * - leadEvent: to send a lead event to AzameoTag each time a lead is made
 * - profileEvent: to send some customer data to AzameoTag systems, this is an advanced feature to enrich azameo visitor profiles or match it to customer profiles in your CRM or ERP
 * - setUserConsent: this helps manage the customer consent to comply with the GDPR and other privacy laws
 *
 * Parameters of each of these apis:
 *
 * - navigationEvent: url (optional) - the full url to send to AzameoTag, if not provided, the current url is used
 *
 * - conversionEvent: name, ref, price, tax, shipping, type, sequence, cart
 *     - name: free text for the name of the conversion
 *     - ref: a unique identifier for the conversion
 *     - price: the cart/order total price excluding tax and shipping costs
 *     - tax: the tax of the conversion event (Ex: VAT)
 *     - shipping: the shipping cost all tax included
 *     - sequence: in case of a multi-steps conversion, the sequence of the conversion (Ex: "validation" for the last step of a checkout)
 *     - cart: an array of items in the cart, each item is an array (not object) with the following values in this order: name, price, quantity
 *         - name: the name of the item
 *         - price: the price of the item
 *         - quantity: the quantity of the item
 *
 * - leadEvent: name, ref, category
 *     - name: free text for the name of the lead
 *     - ref: a unique identifier for the lead
 *     - category: the category of the lead (Ex: "contact", "quotation", "register", "download", "newsletter", "twitter", "facebook", "linkedin", ...)
 *
 * - profileEvent: data
 *     - data: a free object that is JSONnifiable
 *
 * - setUserConsent: consent
 *    - consent: a boolean to set the user consent to true or false (False will stop sending events to AzameoTag)
 *
 */

export const useAzameoTag = () => {

    const navigationEvent = useCallback((url = "") => {
        if (window.azameoSilent === 1) {
            return;
        }
        if (window.azameoTag) {
            window.azameoTag.Navigation((!!url) ? url : window.location.href);
        }
    }, [])

    const conversionEvent = useCallback((name, ref, price, tax, shipping, sequence = "validation", cart = []) => {
        if (window.azameoSilent === 1) {
            return;
        }
        window.azameoTagEvent = {
            "name": name,
            "ref": ref,
            "price": price,
            "tax": tax,
            "shipping": shipping,
            "type": "cart",
            "sequence": sequence
        }
        window.azameoCart = window.azameoCart || [];
        window.azameoCart.push(cart);

        if (window.azameoTag) {
            window.azameoTag.Conversion();
        }
    }, [])

    const leadEvent = useCallback((name, ref, category,) => {
        if (window.azameoSilent === 1) {
            return;
        }
        window.azameoTagEvent = {
            "name": name,
            "ref": ref,
            "category": category,
            "type": "lead",
        }

        window.azameoCart = window.azameoCart || [];

        if (window.azameoTag) {
            window.azameoTag.Conversion();
        }
    }, [])

    const profileEvent = useCallback((data) => {
        if (window.azameoSilent === 1) {
            return;
        }
        window.azameoData = {"customer_id": "1234"}
        if (window.azameoTag) {
            window.azameoTag.SendCustomData();
        }
    }, [])

    const setUserConsent = useCallback((consent) => {
        if (consent) {
            window.azameoSilent = 0;
            navigationEvent();
        } else {
            if (window.azameoSilent !== 1) {
                window.azameoSilent = 1;
            }
        }
    }, [navigationEvent])

    return {navigationEvent, conversionEvent, leadEvent, profileEvent, setUserConsent}
}

export const AzameoTag = ({AzameoTrackerName}) => {

    const location = useLocation()
    const azameoSite = AzameoTrackerName ?? undefined;

    const [lastLocationFired, setlastLocationFired] = useState(null);

    const {navigationEvent} = useAzameoTag();

    useEffect(() => {
        window.azameoSite = azameoSite;
        const script = document.createElement('script');
        setlastLocationFired(location.pathname);
        script.src = `//tag.azame.net/tag/script.js`;
        script.async = true;
        script.onload = () => {
        };
        document.body.appendChild(script);
    }, [azameoSite, location.pathname])

    useEffect(() => {
        if (window.azameoTag) {
            if (location.pathname !== lastLocationFired) {
                setlastLocationFired(location.pathname);
                navigationEvent();
            }
        }
    }, [location, lastLocationFired, setlastLocationFired, navigationEvent])

    return (<></>)
}


