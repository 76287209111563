import {AzaBox} from "../../components/mui/AzaBox";
import {AzaGridContainer, AzaGridDivider, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaTypography} from "../../components/mui/AzaTypography";
import AzaSettingsBox from "../../components/azameo/AzaSettingsBox";

import Typography, {fontFamily} from "../../themes/typography";

const typoKeys = [
    "h1", "h2", "h3", "h4", "h5", "h6", "caption", "body1", "body2", "subtitle1", "subtitle2", "overline", "button"
];

const TypographyLine = ({ typoKey }) => {

    const typo = Typography(fontFamily);
    return (
        <AzaBox sx={{
            width: '100%',
            height: '100%',
        }}>
            <AzaGridContainer>
                <AzaGridItem sx={{width:"70px"}}>
                    <AzaTypography variant={typoKey}>{typoKey}</AzaTypography>
                </AzaGridItem>
                <AzaGridItem>
                    {typo[typoKey].fontWeight && <AzaTypography variant={"body1"}>fontWeight:{typo[typoKey].fontWeight}</AzaTypography>}
                    {typo[typoKey].fontSize && <AzaTypography variant={"body1"}>fontSize:{typo[typoKey].fontSize}</AzaTypography>}
                    {typo[typoKey].lineHeight && <AzaTypography variant={"body1"}>lineHeight:{typo[typoKey].lineHeight}</AzaTypography>}
                    {typo[typoKey].textTransform && <AzaTypography variant={"body1"}>textTransform:{typo[typoKey].textTransform}</AzaTypography>}
                </AzaGridItem>
            </AzaGridContainer>
        </AzaBox>
    )
}

const TypographyColumns = () => {
    return (
        <AzaGridContainer direction={"row"} spacing={0} sx={{
            width:"100%",
        }}>
            {typoKeys.map((key) => (
                <AzaGridItem key={key} sx={{
                    width: '100%',
                    height: '100%',
                }}>
                    <TypographyLine typoKey={key}/>
                    <AzaGridDivider/>
                </AzaGridItem>
            )
            )}
        </AzaGridContainer>
    )
}

export const SampleTypography = ({title}) => {
    return (
        <AzaSettingsBox
            title={{name:title}}
        >
            <AzaTypography variant={"h5"}>Sample of typography set for Azameo</AzaTypography>
            <br/>
            <TypographyColumns/>
        </AzaSettingsBox>
    )
}
