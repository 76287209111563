import React from 'react';
import {useNavigate} from "react-router-dom";

import {Typography} from "@mui/material";

import AzaSettingsBox from "../../../components/azameo/AzaSettingsBox";
import {todayDateFormat} from "../../../utils/utils";
import {PATH_ACCOUNT_SETTINGS} from "../../../utils/constant";

const PAYMENT_DATE = todayDateFormat();
const SERVICE_PLAN = 'Google Managed';
const SERVICE_START_DATE = PAYMENT_DATE;
const SERVICE_NEXT_PAYMENT_DATE = PAYMENT_DATE;

const CreditsServicePlan = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(PATH_ACCOUNT_SETTINGS);
    }
    return <AzaSettingsBox
        title={{
            name: 'Service plan : ',
            data: SERVICE_PLAN,
            id: 'service-plan',
        }}
        button={{
            name:'Manage your plan',
            variant: 'text',
            action: handleClick,
        }}
    >
        <Typography>Start date : {SERVICE_START_DATE}</Typography>
        <Typography>Next payment date: {SERVICE_NEXT_PAYMENT_DATE}</Typography><Typography>Start date : {SERVICE_START_DATE}</Typography>
        <Typography>Next payment date: {SERVICE_NEXT_PAYMENT_DATE}</Typography><Typography>Start date : {SERVICE_START_DATE}</Typography>
        <Typography>Next payment date: {SERVICE_NEXT_PAYMENT_DATE}</Typography><Typography>Start date : {SERVICE_START_DATE}</Typography>
        <Typography>Next payment date: {SERVICE_NEXT_PAYMENT_DATE}</Typography><Typography>Start date : {SERVICE_START_DATE}</Typography>
        <Typography>Next payment date: {SERVICE_NEXT_PAYMENT_DATE}</Typography><Typography>Start date : {SERVICE_START_DATE}</Typography>
        <Typography>Next payment date: {SERVICE_NEXT_PAYMENT_DATE}</Typography><Typography>Start date : {SERVICE_START_DATE}</Typography>
    </AzaSettingsBox>
};

export default CreditsServicePlan;