import {AzaStack} from "../../components/mui/AzaStack";
import {AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";
import {AzaTypography} from "../../components/mui/AzaTypography";
import {AzaDivider} from "../../components/mui/AzaDivider";
import {AzaAlert} from "../../components/mui/AzaAlert";
import {AzaButton} from "../../components/mui/AzaButton";
import React, {useCallback, useMemo, useState} from "react";
import {useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {InlineEditSelect, Phrase} from "../Phrase";
import {AzaSelect} from "../../components/mui/AzaSelect";
import {AzaMenuItem} from "../../components/mui/AzaMenu";
import {AzaFormControl} from "../../components/mui/AzaFormControl";
import {AzaInputLabel} from "../../components/mui/AzaInputLabel";

const listOptimLimits = [10, 20, 50 ,100]
const lostOptimConstraints = [250, 500, 1000]
const listDailyVariations = [5, 10, 20, 50]
const listOptimWindows = [7]



export const OptimBudgetRoas = ({disabled, onDone}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const optimLimits = listOptimLimits.map((value) => ({label: t("optimroas.limit", {value}), value}));
    const optimConstraints = lostOptimConstraints.map((value) => ({label: t("optimroas.percentage", {value}), value}));
    const dailyVariations = listDailyVariations.map((value) => ({label: t("optimroas.percentage", {value}), value}));
    const optimWindows = listOptimWindows.map((value) => ({label: t("optimroas.windowspan", {value}), value}));

    const campaigns = useMemo(() => {
        return [
            {label: "Campaign 1", value: "1024",},
            {label: "Campaign 2", value: "1025",},
        ]
    }, [])

    const [optimLimit, setOptimLimit] = useState(optimLimits[0]);
    const [optimConstraint, setOtimConstraint] = useState(optimConstraints[0]);
    const [dailyVariationLow, setDailyVariationLow] = useState(dailyVariations[1]);
    const [dailyVariationHigh, setDailyVariationHigh] = useState(dailyVariations[2]);
    const [window, setWindows] = useState(optimWindows[0]);
    const [campaignCode, setCampaignCode] = useState(null);


    const onChangeCampaign = useCallback((event) => {
        setCampaignCode(event.target.value);
    }, [setCampaignCode]);

    const createOptim = useCallback(() => {
        setErrorMessage("This feature is not yet implemented");
        setHasError(true);
        if (onDone) onDone();
    }, [onDone]);

    return (<>
        <AzaStack alignItems={"flex-start"}>
            <AzaGridItem>
                <AzaTypography variant={"body"}>{t("optimroas.description")} </AzaTypography>
            </AzaGridItem>
            <AzaGridItem>
                <AzaTypography variant={"body"}>{t("optimroas.info")}</AzaTypography>
            </AzaGridItem>
        </AzaStack>
        <AzaDivider sx={{borderColor: theme.palette.secondary.main}}/>
        <br/>
        <AzaStack alignItems={"flex-end"} >
            <AzaGridItem sx={{width:"300px"}}>
                <AzaFormControl size={"small"}>
                    <AzaInputLabel id="campaign-select-label">{t("optimroas.selectcampaign")}</AzaInputLabel>
                    <AzaSelect
                        value={campaignCode}
                        onChange={onChangeCampaign}
                        disabled={disabled}
                    >
                        {campaigns.map((choice, idx) => <AzaMenuItem value={choice.value} key={idx}>{choice.label}</AzaMenuItem>)}
                        <AzaMenuItem value={"import"}>{t("optimroas.importcampaign")}</AzaMenuItem>
                    </AzaSelect>
                </AzaFormControl>
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[t("optimroas.increase"), <InlineEditSelect
                        options={optimLimits}
                        defaultValue={optimLimit}
                        onChange={setOptimLimit}
                    />, t("optimroas.roas_target"), <InlineEditSelect
                        options={optimConstraints}
                        defaultValue={optimConstraint}
                        onChange={setOtimConstraint}
                    />]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[t("optimroas.daily_pace_up"), <InlineEditSelect
                        options={dailyVariations}
                        defaultValue={dailyVariationHigh}
                        onChange={setDailyVariationHigh}
                    />, t("optimroas.daily_pace_down"), <InlineEditSelect
                        options={dailyVariations}
                        defaultValue={dailyVariationLow}
                        onChange={setDailyVariationLow}
                    />]}
                />
            </AzaGridItem>
            <AzaGridItem>
                <Phrase
                    items={[t("optimroas.window"), <InlineEditSelect
                        options={optimWindows}
                        defaultValue={window}
                        onChange={setWindows}
                    />,]}
                />
            </AzaGridItem>
            <AzaGridItem  >
                {hasError && <AzaAlert severity="error">
                    <AzaTypography>{errorMessage}</AzaTypography>
                </AzaAlert>}
                {isSuccess && <AzaAlert severity="success">
                    <AzaTypography>{t("optimroas.success")}</AzaTypography>
                </AzaAlert>}
            </AzaGridItem>
        </AzaStack>
        <br/>
        <AzaGridContainer direction={"row"}>
            <AzaGridItem xs>
            </AzaGridItem>
            <AzaGridItem>
                <AzaButton
                    onClick={createOptim}
                    variant={"contained"}
                    disabled={disabled}
                >{t("optimroas.button")}
                </AzaButton>
            </AzaGridItem>
        </AzaGridContainer>
    </>)
}
