// noinspection AllyPlainJsInspection

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    backendFetch,
    getInvoicesData,
    getSiteSettings,
    methodType,
    siteBackendFetch
} from "../../../utils/backendHelper";
import {
    API_GET_BILLING_INFO,
    API_GET_CHECK_PROMOCODE,
    API_GET_INVOICES_DATA,
    API_GET_SITE_RENEWAL_INFO,
    API_GET_SITE_RENEWAL_STOP, API_GET_STRIPE_ORDER_RESULT,
    API_GET_VALIDATE_VAT,
    API_POST_STRIPE_ORDER_CREATE,
} from "../../../utils/constant";

const paymentSliceName = "payment"

export const MIN_VALUE = 2;

const europeanCountriesAllowed = [
    "AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "EL", "ES",
    "FI", "FR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT",
    "NL", "PL", "PT", "RO", "SE", "SI", "SK", "XI", "FR"
];

export const SETTING_STATUS = {
    PENDING: "pending",
    IDLE: "idle",
    SUCCESS: "success",
    ERROR: "error"
};

export const PROMOCODE_STATUS = {
    PENDING: "pending",
    IDLE: "idle",
    SUCCESS: "success",
    ERROR: "error"
};

export const PROMOCODE_TYPE = {
    FREE_CREDIT: "Free Credits",
    DISCOUNT_FLAT: "Discount Flat",
    DISCOUNT_PERCENT: "Discount Percent",
    BONUS_FLAT: "Bonus Flat",
    BONUS_PERCENT: "Bonus Percent"
};

export const VAT_STATUS = {
    PENDING: "pending",
    IDLE: "idle",
    VALID: "valid",
    INVALID: "invalid"
};

const getPaymentSettings = async (user, site_id) => {
    return getSiteSettings(user, site_id, {payment: ""});
}

const getBillingInfo = async (user, site_id) => {
    return backendFetch({
        user: user,
        path: API_GET_BILLING_INFO,
        method: methodType.GET,
        queryParams: {
            site_id: site_id,
        }
    })
}

const sendPromocode = async (user, site_id, promocode, consume) => {
    return backendFetch({
        user: user,
        path: API_GET_CHECK_PROMOCODE,
        method: methodType.GET,
        queryParams: {
            site_id: site_id,
            code: promocode,
            consume: consume
        }
    })
}

const validateVATNumber = async (user, countrycode, vatnumber) => {
    return backendFetch({
        user: user,
        path: API_GET_VALIDATE_VAT,
        method: methodType.GET,
        queryParams: {
            countrycode: countrycode,
            vatnumber: vatnumber
        }
    })
}

const getStripePaymentIntent = createAsyncThunk(`${paymentSliceName}/stripe/pi/get`, async ({
                                                                                                site_id,
                                                                                                user,
                                                                                                paiement_data
                                                                                            }, thunkAPI) => {
    const response = await siteBackendFetch(
        {
            user: user,
            site_id: site_id,
            path: API_POST_STRIPE_ORDER_CREATE,
            method: methodType.POST,
            data: paiement_data,
        }
    ).catch((error) => console.log(`failed to fetch info : ${error}`));
    return response;
})


const checkStripePaymentIntent = createAsyncThunk(`${paymentSliceName}/stripe/pi/check`, async ({
                                                                                                site_id,
                                                                                                user,
                                                                                                paiment_intent_id
                                                                                            }, thunkAPI) => {
    const response = await siteBackendFetch(
        {
            user: user,
            site_id: site_id,
            path: API_GET_STRIPE_ORDER_RESULT,
            queryParams:{payment_intent_id: paiment_intent_id,},
            method: methodType.GET,
        }
    ).catch((error) => console.log(`failed to fetch info : ${error}`));
    return response;
})

const doStripePayment = createAsyncThunk(`${paymentSliceName}/stripe/pi/capture`, async ({
                                                                                             stripe,
                                                                                             elements
                                                                                         }, thunkAPI) => {
    const {error, paymentMethod} = stripe.confirmPayment({
        elements: elements,
        confirmParams: {
            return_url: 'http://localhost:3000/',
        }
    });

    if (!error) {
        console.log('[paymentMethod]', paymentMethod);
    }

})


const IDLE_SITE_SETTINGS = {
    recommended: []
}

const actionGetBillingInfo = createAsyncThunk(`${paymentSliceName}${API_GET_BILLING_INFO}`, async ({
                                                                                                       user,
                                                                                                       site_id
                                                                                                   }, thunkAPI) => {
    const response = await getBillingInfo(user, site_id);
    return response;
})

const actionGetInvoicesInfo = createAsyncThunk(`${API_GET_INVOICES_DATA}`, async ({
                                                                                      user,
                                                                                      site_id
                                                                                  }, thunkAPI) => {
    const response = await getInvoicesData(user, site_id);
    return response;
})

const actionValidatePromocode = createAsyncThunk(`${paymentSliceName}${API_GET_CHECK_PROMOCODE}`, async ({
                                                                                                             site_id,
                                                                                                             user,
                                                                                                             promocode
                                                                                                         }, thunkAPI) => {

    let response = await sendPromocode(user, site_id, promocode, false);
    if (!response.valid) {
        return {
            promocode: promocode,
            is_valid: response.valid,
            amount: response.promocode?.value,
            error_code: response.error_code,
            startdate: response.startdate,
            enddate: response.enddate,
        }
    }

    if (response.promocode?.type === PROMOCODE_TYPE.FREE_CREDIT) {
        response = await sendPromocode(user, site_id, promocode, true);
    }

    return {
        promocode: promocode,
        is_valid: response.valid,
        amount: response.promocode?.value,
        error_code: response.error_code,
        type: response.promocode?.type,
        startdate: response.startdate,
        enddate: response.enddate,
    }
})

const actionValidateVATNumber = createAsyncThunk(`${paymentSliceName}${API_GET_VALIDATE_VAT}`, async ({
                                                                                                          user,
                                                                                                          countrycode,
                                                                                                          vatnumber
                                                                                                      }, thunkAPI) => {
    const countryCodeExist = europeanCountriesAllowed.indexOf(countrycode.toUpperCase());
    if (!countryCodeExist) {
        // Not UE Member
        return {
            payvat: false,
            countrycode: countrycode,
            vatnumber: vatnumber
        };
    } else if (vatnumber.length > 0) {
        const response = await validateVATNumber(user, countrycode, vatnumber);
        const payload = {...response, vatnumber: vatnumber, countrycode: countrycode, payvat: true}
        if (response.isvalid && countrycode.toUpperCase() !== "FR") payload.payvat = false; // UE country and valid VAT number and not FR
        return payload;
    } else {
        return {
            payvat: true,
            countrycode: countrycode,
            vatnumber: vatnumber
        };
    }
})

const getCustomerRenewalPaymentInfo = async (user, site_id) => {
    return siteBackendFetch({
        user: user,
        site_id: site_id,
        path: API_GET_SITE_RENEWAL_INFO,
        method: methodType.Get,
    })
}
export const stopCustomerRenewalPayment = async (user, site_id) => {
    return siteBackendFetch({
        user: user,
        site_id: site_id,
        path: API_GET_SITE_RENEWAL_STOP,
        method: methodType.Get,
    })
}
const actionGetRenewalInfo = createAsyncThunk(`${paymentSliceName}${API_GET_SITE_RENEWAL_INFO}`, async ({
                                                                                                            user,
                                                                                                            site_id
                                                                                                        }, thunkAPI) => {
    const response = await getCustomerRenewalPaymentInfo(user, site_id);
    return response;
})

const IDLE_PROMOCODE = {
    checkstatus: PROMOCODE_STATUS.IDLE,
    checkerror: null,
    amount: null,
}

export const paymentSlice = createSlice({
    name: `${paymentSliceName}`,
    initialState: {
        openPayment: false,
        wasOpenPayment: false,
        reentry: null,
        status: "succeded",
        code: null,
        setuppayment: {
            selectedindex: -1,
        },
        billing: {
            loading: SETTING_STATUS.IDLE,
        },
        settings: {
            loading: SETTING_STATUS.IDLE,
            data: {
                recommended: []
            }
        },
        payment_data: {
            currency: '',
            value: 0.00,
            recurring: true,
            promocode: "",
        },
        invoice_id: {
            id: "",
            display: ""},
        promocode: IDLE_PROMOCODE,
        vat: {
            checkstatus: VAT_STATUS.IDLE,
            payvat: true
        },
        invoices: {
            loading: SETTING_STATUS.IDLE,
            data: []
        },
        asynchronous: {
            stripe_pi: {
                status: "idle",
                client_secret: null,
            },
            stripe_payment: {
                status: "idle",
                pi_status: "unknown"
            },
            renewal: {
                loading: SETTING_STATUS.IDLE,
            },

        },
    },
    reducers: {
        actionSetOpenPayment: (state, action) => {
          state.openPayment = action.payload;
        },
        actionSetWasOpenPayment: (state, action) => {
          state.wasOpenPayment = action.payload;
        },
        actionSetReentry: (state, action) => {
            state.reentry = action.payload;
        },
        actionSetStatus: (state, action) => {
            state.status = action.payload;
        },
        actionSetCode: (state, action) => {
            state.code = action.payload;
        },
        actionSetAmount: (state, action) => {
            state.payment_data.value = parseFloat(action.payload);
        },
        actionSetCurrency: (state, action) => {
            state.payment_data.currency = action.payload;
        },
        actionSetRecurring: (state, action) => {
            state.payment_data.recurring = action.payload;
        },
        actionSetPromocode: (state, action) => {
            state.payment_data.promocode = action.payload;
            state.promocode.checkstatus = PROMOCODE_STATUS.IDLE;
        },
        actionCancelPromocode: (state, action) => {
            state.payment_data.promocode = IDLE_PROMOCODE;
        },
        actionSetDescription: (state, action) => {
            state.payment_data.description = action.payload;
        },
        actionSetSetUpPaymentIndex: (state, action) => {
            state.setuppayment.selectedindex = action.payload;
        },
        actionSetCompanyName: (state, action) => {
            state.billing.data.business_name = action.payload;
        },
        actionSetCountryCode: (state, action) => {
            state.billing.data.country_code = action.payload;
        },
        actionSetVatNumber: (state, action) => {
            state.billing.data.vatnumber = action.payload;
        },
        actionSetBillingInfoLine1: (state, action) => {
            state.billing.data.line1 = action.payload;
        },
        actionSetBillingInfoLine2: (state, action) => {
            state.billing.data.line2 = action.payload;
        },
        actionSetBillingZipCode: (state, action) => {
            state.billing.data.postal_code = action.payload;
        },
        actionSetBillingCity: (state, action) => {
            state.billing.data.city = action.payload;
        },
        actionSetInvoiceId: (state, action) => {
            state.invoice_id.id = action.payload;
        },
        actionReset: (state, action) => {
            state.setuppayment.selectedindex = -1;
            state.payment_data = {
                value: 0.00,
                description: "Fake Payment description",
                recurring: true,
                promocode: "",
            }
            state.invoice_id = {id: "", display: ""};
            state.promocode = IDLE_PROMOCODE;
            // Do not reset billing info and vat status
            state.asynchronous.stripe_pi = {
                status: "idle",
                client_secret: null,
            };
        },
        actionResetRenewalInfo: (state, action) => {
            state.asynchronous.renewal = {loading: SETTING_STATUS.IDLE,};
        },
        actionClearCustomerInfo: (state, action) => {
            state.billing = {loading: SETTING_STATUS.IDLE};
            state.asynchronous.renewal = {loading: SETTING_STATUS.IDLE,};
        },
        actionResetStripePaymentIntent: (state, action) => {
            state.asynchronous.stripe_pi = {
                status: "idle",
                client_secret: null,
            };
            state.asynchronous.stripe_payment = {
                status: "idle",
            };
        },
        actionResetChanges: (state, action) => {
            state.billing.data = state.billing.original_data;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(actionGetBillingInfo.pending, (state, action) => {
                state.billing.loading = SETTING_STATUS.PENDING;
            })
            .addCase(actionGetBillingInfo.fulfilled, (state, action) => {
                state.billing.loading = SETTING_STATUS.SUCCESS;
                state.billing.data = action.payload;
                state.billing.original_data = action.payload;
                state.payment_data.currency = action.payload.currency;
                state.vat.payvat = action.payload.payvat;
            })
            .addCase(actionGetBillingInfo.rejected, (state, action) => {
                state.billing.loading = SETTING_STATUS.ERROR;
            })
            .addCase(actionGetInvoicesInfo.pending, (state, action) => {
                state.invoices.loading = SETTING_STATUS.PENDING;
            })
            .addCase(actionGetInvoicesInfo.fulfilled, (state, action) => {
                state.invoices.data = action.payload;
                state.invoices.loading = SETTING_STATUS.SUCCESS;
            })
            .addCase(actionGetInvoicesInfo.rejected, (state, action) => {
                state.invoices.loading = SETTING_STATUS.ERROR;
            })
            .addCase(actionValidatePromocode.pending, (state, action) => {
                state.promocode.checkstatus = PROMOCODE_STATUS.PENDING;
            })
            .addCase(actionValidatePromocode.fulfilled, (state, action) => {
                state.promocode.checkstatus = (action.payload.is_valid) ?
                    PROMOCODE_STATUS.SUCCESS :
                    PROMOCODE_STATUS.ERROR;
                state.promocode.amount = action.payload.amount;
                state.promocode.checkerror = action.payload.error_code;
                state.promocode.type = action.payload.type;
            })
            .addCase(actionValidatePromocode.rejected, (state, action) => {
                state.promocode.checkstatus = PROMOCODE_STATUS.ERROR;
                state.promocode.checkerror = 666;
            })
            .addCase(actionValidateVATNumber.pending, (state, action) => {
                state.vat.checkstatus = VAT_STATUS.PENDING;
            })
            .addCase(actionValidateVATNumber.fulfilled, (state, action) => {
                state.vat.checkstatus = action.payload.isvalid ? VAT_STATUS.VALID : VAT_STATUS.INVALID;
                state.vat.businessname = action.payload.business_name ?? null;
                if (action.payload.business_name??"" !== "") {
                    state.billing.data.business_name = action.payload.business_name;
                }
                if (action.payload.payvat
                    && (europeanCountriesAllowed.find((element) => state.billing.data?.country_code === element))) {
                    state.vat.payvat = true
                } else {
                    state.vat.payvat = false
                }
            })
            .addCase(actionValidateVATNumber.rejected, (state, action) => {
                state.vat.checkstatus = VAT_STATUS.IDLE;
            })
            .addCase(getStripePaymentIntent.fulfilled, (state, action) => {
                    state.asynchronous.stripe_pi.status = "fulfilled";
                    state.asynchronous.stripe_pi.client_secret = action.payload.token;
            })
            .addCase(getStripePaymentIntent.pending, (state, action) => {
                    state.asynchronous.stripe_pi.status = "pending";
            })
            .addCase(getStripePaymentIntent.rejected, (state, action) => {
                    console.log("rejected");
                    console.log(action);
                    console.log(state);
                    state.asynchronous.stripe_pi.status = "rejected";
            })
            .addCase(checkStripePaymentIntent.fulfilled, (state, action) => {
                    state.asynchronous.stripe_payment.status = "fulfilled";
                    if (action.payload.payment_secret) {
                        state.asynchronous.stripe_pi.client_secret = action.payload.payment_secret;
                        state.asynchronous.stripe_pi.status = action.payload.status;
                    }
                    if (action.payload.status === "succeeded" || action.payload.status === "pending"){
                        state.payment_data.currency = action.payload.currency;
                        state.payment_data.value = action.payload.price_ht;
                        state.vat.payvat = action.payload.pay_vat;
                        state.asynchronous.stripe_payment.invoice_id = action.payload.paypal_invoice_id;
                        state.asynchronous.stripe_payment.invoice_id_display = action.payload.azameo_invoice_id;
                        state.asynchronous.stripe_payment.pi_status = action.payload.status
                        if (action.payload.paypal_invoice_id != "") {
                            state.invoice_id.id = action.payload.paypal_invoice_id;
                            state.invoice_id.display = action.payload.azameo_invoice_id;
                        }
                    }

            })
            .addCase(checkStripePaymentIntent.pending, (state, action) => {
                    state.asynchronous.stripe_payment.status = "pending";
            })
            .addCase(checkStripePaymentIntent.rejected, (state, action) => {
                    console.log("rejected");
                    console.log(action);
                    console.log(state);
                    state.asynchronous.stripe_payment.status = "rejected";
            })
            .addCase(actionGetRenewalInfo.pending, (state, action) => {
                    state.asynchronous.renewal.loading = SETTING_STATUS.PENDING;
            })
            .addCase(actionGetRenewalInfo.fulfilled, (state, action) => {
                    state.asynchronous.renewal.loading = SETTING_STATUS.SUCCESS;
                    state.asynchronous.renewal.data = action.payload;
                    if (state.setuppayment.selectedindex === 1) {
                        state.payment_data.recurring = !(action.payload.source);
                    }
            })
            .addCase(actionGetRenewalInfo.rejected, (state, action) => {
                    state.asynchronous.renewal.loading = SETTING_STATUS.ERROR;
                }
            )
        ;
    }
});

const {
    actionSetOpenPayment, actionSetWasOpenPayment,
    actionSetReentry, actionSetCode, actionSetStatus,
    actionSetAmount, actionSetRecurring, actionSetPromocode, actionCancelPromocode,
    actionSetDescription, actionSetSetUpPaymentIndex,
    actionSetCompanyName, actionSetCountryCode, actionSetVatNumber,
    actionSetBillingInfoLine1, actionSetBillingInfoLine2, actionSetBillingZipCode, actionSetBillingCity, actionReset,
    actionResetStripePaymentIntent, actionResetChanges,
    actionSetInvoiceId,actionClearCustomerInfo,actionResetRenewalInfo, actionSetCurrency,
} = paymentSlice.actions;


const selectOpenPayment = (state) => {
    return state.payment.openPayment;
}

const selectWasOpenPayment = (state) => {
    return state.payment.wasOpenPayment;
}

const selectReentry = (state) => {
    return state.payment.reentry;
}

const selectStatus = (state) => {
    return state.payment.status;
}

const selectCode = (state) => {
    return state.payment.code;
}

const selectSUPIndex = (state) => {
    return state.payment.setuppayment.selectedindex;
}

const selectAmount = (state) => {
    return state[`${paymentSliceName}`].payment_data.value;
}

const selectReccuring = (state) => {
    return state[`${paymentSliceName}`].payment_data.recurring;
}

const selectPromocode = (state) => {
    return state[`${paymentSliceName}`].payment_data.promocode;
}

const selectCurrency = (state) => {
    return state[`${paymentSliceName}`].payment_data.currency;
}

const selectPromocodeStatus = (state) => {
    return state[`${paymentSliceName}`].promocode;
}

const selectVATStatus = (state) => {
    return state[`${paymentSliceName}`].vat;
}

const selectTotals = (state) => {
    const promoCodeStatus = state[`${paymentSliceName}`].promocode
    const promoCodeExist = promoCodeStatus.checkstatus === PROMOCODE_STATUS.SUCCESS && promoCodeStatus.type !== PROMOCODE_TYPE.FREE_CREDIT;

    const promoAmount = promoCodeStatus.amount
    const promoRemoveCredit = promoCodeExist && (promoCodeStatus.type === PROMOCODE_TYPE.DISCOUNT_FLAT || promoCodeStatus.type === PROMOCODE_TYPE.DISCOUNT_PERCENT);
    const promoOfferCredit = promoCodeExist && (promoCodeStatus.type === PROMOCODE_TYPE.BONUS_FLAT || promoCodeStatus.type === PROMOCODE_TYPE.BONUS_PERCENT);

    const amount = state[`${paymentSliceName}`].payment_data.value;
    let vat_info = state[`${paymentSliceName}`].vat;

    if (state[`${paymentSliceName}`].billing.data?.country_code === undefined
        || !(europeanCountriesAllowed.find((element) => state[`${paymentSliceName}`].billing.data?.country_code === element))) {
        vat_info = {
            checkstatus: VAT_STATUS.IDLE,
            payvat: false,
        };
    }

    const total_ht = amount - (promoRemoveCredit ? promoAmount : 0);
    const final_amount = amount + (promoOfferCredit ? promoAmount : 0);

    const response = {
        payvat: vat_info.payvat,
        reccuring: state[`${paymentSliceName}`].payment_data.recurring,
        ht: total_ht,
        amount: final_amount,
        ttc: total_ht,
        vat_status: vat_info.checkstatus,
        promocode: {
            exist: promoCodeExist,
            code: state[`${paymentSliceName}`].payment_data.promocode,
            remove: promoRemoveCredit,
            add: promoOfferCredit,
            amount: promoCodeStatus.amount
        },
        billing_info: state[`${paymentSliceName}`].billing.data,
    }
    if (vat_info.payvat) {
        response.ttc = total_ht * 1.2;
    }
    return response;
}

const selectPaymentSettingPackageInfo = (state) => {
    return state.global.site_settings.payment?.recommended ?? {};
}

const selectPaymentSettingChangeBillingZoho = (state) => {
    return state.global.site_settings.zoho?.forms?.change_billing_info ?? "";
}

const selectPaymentSettingMinimumAmount = (state) => {
    if (state.global.site_settings.payment?.minimum_amount) {
        return state.global.site_settings.payment.minimum_amount
    }
    return MIN_VALUE;
}

export const selectBillingInfoReadOnly = (state) => {
    return state[`${paymentSliceName}`].billing.data?.read_only??true;
}

export const selectBillingInfo = (state) => {
    return {ready:state[`${paymentSliceName}`].billing.loading === 'success', billing_info:state[`${paymentSliceName}`].billing.data};
}

const selectBillingInfoCompanyName = (state) => {
    return {
        loading: state[`${paymentSliceName}`].billing.loading === SETTING_STATUS.PENDING,
        company_name: state[`${paymentSliceName}`].billing.data?.business_name
    };
}

const selectBillingInfoCountryCode = (state) => {
    return {
        loading: state[`${paymentSliceName}`].billing.loading === SETTING_STATUS.PENDING,
        country_code: state[`${paymentSliceName}`].billing.data?.country_code
    };
}

const selectBillingInfoVATNumber = (state) => {
    return {
        loading: state[`${paymentSliceName}`].billing.loading === SETTING_STATUS.PENDING,
        vatNumber: state[`${paymentSliceName}`].billing.data?.vatnumber,
        vatvalid: state[`${paymentSliceName}`].vat.checkstatus
    };
}

const selectAllowedToEnterVATNumber = (state) => {
    return state[`${paymentSliceName}`].billing.data?.country_code !== undefined && (europeanCountriesAllowed.find((element) => state[`${paymentSliceName}`].billing.data?.country_code === element));
}

const selectBillingInfoLine1 = (state) => {
    return {
        loading: state[`${paymentSliceName}`].billing.loading === SETTING_STATUS.PENDING,
        line: state[`${paymentSliceName}`].billing.data?.line1
    };
}

const selectBillingInfoLine2 = (state) => {
    return {
        loading: state[`${paymentSliceName}`].billing.loading === SETTING_STATUS.PENDING,
        line: state[`${paymentSliceName}`].billing.data?.line2
    };
}

const selectBillingZipCode = (state) => {
    return {
        loading: state[`${paymentSliceName}`].billing.loading === SETTING_STATUS.PENDING,
        zipcode: state[`${paymentSliceName}`].billing.data?.postal_code
    };
}

const selectBillingCity = (state) => {
    return {
        loading: state[`${paymentSliceName}`].billing.loading === SETTING_STATUS.PENDING,
        city: state[`${paymentSliceName}`].billing.data?.city
    };
}

const selectBillingInfoIsValid = (state) => {
    const business_name = state[`${paymentSliceName}`].billing.data?.business_name
    if (!business_name || business_name.length === 0) {
        return false;
    }
    const country_code = state[`${paymentSliceName}`].billing.data?.country_code
    if (!country_code || country_code.length === 0) {
        return false;
    }
    const vatnumber = state[`${paymentSliceName}`].billing.data?.vatnumber
    const vatvalid = state[`${paymentSliceName}`].vat.checkstatus
    if ((vatvalid !== VAT_STATUS.VALID && vatnumber > 0) || vatvalid === VAT_STATUS.PENDING) {
        return false;
    }
    const line1 = state[`${paymentSliceName}`].billing.data?.line1
    if (!line1 || line1.length === 0) {
        return false;
    }
    const postal_code = state[`${paymentSliceName}`].billing.data?.postal_code
    if (!postal_code || postal_code.length === 0) {
        return false;
    }
    const city = state[`${paymentSliceName}`].billing.data?.city
    if (!city || city.length === 0) {
        return false;
    }
    return true;
}

const selectBillingInfoIsChanged = (state) => {
    return state[`${paymentSliceName}`].billing.data?.business_name !== state[`${paymentSliceName}`].billing.original_data?.business_name ||
        state[`${paymentSliceName}`].billing.data?.country_code !== state[`${paymentSliceName}`].billing.original_data?.country_code ||
        state[`${paymentSliceName}`].billing.data?.vatnumber !== state[`${paymentSliceName}`].billing.original_data?.vatnumber ||
        state[`${paymentSliceName}`].billing.data?.line1 !== state[`${paymentSliceName}`].billing.original_data?.line1 ||
        state[`${paymentSliceName}`].billing.data?.line2 !== state[`${paymentSliceName}`].billing.original_data?.line2 ||
        state[`${paymentSliceName}`].billing.data?.postal_code !== state[`${paymentSliceName}`].billing.original_data?.postal_code ||
        state[`${paymentSliceName}`].billing.data?.city !== state[`${paymentSliceName}`].billing.original_data?.city;
}

const selectInvoicesData = (state) => {
    return {
        invoicesData: state[`${paymentSliceName}`].invoices,
        loading: state[`${paymentSliceName}`].invoices.loading === SETTING_STATUS.PENDING || state[`${paymentSliceName}`].invoices.loading === SETTING_STATUS.IDLE,
    }
}

export const selectStripePaymentIntent = (state) => {
    return state[`${paymentSliceName}`].asynchronous.stripe_pi;
}

export const selectStripePaymentIntentResult = (state) => {
    return state[`${paymentSliceName}`].asynchronous.stripe_payment;
}

export const selectInvoiceId = (state) => {
    return state[`${paymentSliceName}`].invoice_id;
}

export const selectRenewalInfo = (state) => {
    return {
        data: state[`${paymentSliceName}`].asynchronous.renewal.data??{},
        loading: state[`${paymentSliceName}`].asynchronous.renewal.loading === SETTING_STATUS.PENDING || state[`${paymentSliceName}`].asynchronous.renewal.loading === SETTING_STATUS.IDLE,
    }
}

export const selectRenewalActive = (state) => {
    return !!(state[`${paymentSliceName}`].asynchronous.renewal.data?.source);
}

export {
    selectOpenPayment, actionSetOpenPayment, selectWasOpenPayment, actionSetWasOpenPayment,
    actionSetReentry, actionSetCode, actionSetStatus,
    selectReentry, selectCode, selectStatus,
    actionGetBillingInfo,
    actionGetInvoicesInfo,
    selectBillingInfoCompanyName,
    actionSetCompanyName,
    selectBillingInfoCountryCode,
    selectBillingInfoVATNumber,
    selectAllowedToEnterVATNumber,
    actionSetVatNumber,
    actionSetCountryCode,
    selectBillingInfoLine1,
    actionSetBillingInfoLine1,
    selectBillingInfoLine2,
    actionSetBillingInfoLine2,
    actionSetBillingZipCode,
    selectBillingZipCode,
    selectBillingCity,
    actionSetBillingCity,
    selectPaymentSettingPackageInfo,
    selectPaymentSettingMinimumAmount,
    actionSetSetUpPaymentIndex,
    selectSUPIndex,
    actionSetAmount,
    selectAmount,
    actionSetRecurring,
    selectReccuring,
    actionSetPromocode,
    selectPromocode,
    actionSetDescription,
    actionValidatePromocode,
    selectPromocodeStatus,
    actionCancelPromocode,
    actionValidateVATNumber,
    selectVATStatus,
    selectBillingInfoIsValid,
    selectTotals,
    actionReset,
    selectInvoicesData,
    getStripePaymentIntent,
    actionResetStripePaymentIntent,
    actionSetInvoiceId,
    actionGetRenewalInfo,
    checkStripePaymentIntent,
    selectBillingInfoIsChanged,
    actionResetChanges,
    actionClearCustomerInfo,
    actionResetRenewalInfo,
    selectCurrency, actionSetCurrency,
};

