import {AzaGridContainer, AzaGridItem} from "../components/mui/AzaGrid";
import {AzaButton} from "../components/mui/AzaButton";
import {AzaSwitch} from "../components/mui/AzaSwitch";
import {FormControlLabel} from "@mui/material";
import {useAzameoTag} from "../features/azameoTag/AzameoTag";
import {AzaTextField} from "../components/mui/AzaTextField";
import React, {useEffect, useState} from "react";
import AzaInputNumber from "../components/azameo/AzaInputNumber";
import {AzaPage, AzaPageItem} from "../components/azameo/AzaPage";

const NavigationTagSample = () => {
    const {navigationEvent} = useAzameoTag();
    const [url, setUrl] = useState("");
    return (
        <>
            <h2>Navigation Tag</h2>
            <AzaTextField
                label={"URL"}
                value={url}
                onChange={(event) => setUrl(event.target.value)}
            />
            <AzaButton
                variant={"contained"}
                onClick={() => navigationEvent(url)}
            >
                Send Navigation
            </AzaButton>
        </>
    )
}

const ConversionTagSample = () => {
    const {conversionEvent} = useAzameoTag();

    const [conversationName, setConversationName] = useState("Test");
    const [conversationRef, setConversationRef] = useState("#123456789");
    const [conversationPrice, setConversationPrice] = useState(10.0);
    const [conversationTax, setConversationTax] = useState(2.0);
    const [conversationShipping, setConversationShipping] = useState(5.0);
    const [conversationSequence, setConversationSequence] = useState("validation");
    const [conversationCart, setConversationCart] = useState('[["item1", 10, 1]]');

    return (
        <>
            <h2>Conversion Tag</h2>
            <AzaTextField
                label={"Name"}
                value={conversationName}
                onChange={(event) => setConversationName(event.target.value)}
            />
            <AzaTextField
                label={"Ref"}
                value={conversationRef}
                onChange={(event) => setConversationRef(event.target.value)}
            />
            <AzaGridContainer direction={"row"}>
                <AzaGridItem>
                    <AzaInputNumber
                        label={"Price"}
                        value={conversationPrice}
                        onChange={(event) => setConversationPrice(event.target.value)}
                    />
                </AzaGridItem>
                <AzaGridItem>
                    <AzaInputNumber
                        label={"Tax"}
                        value={conversationTax}
                        onChange={(event) => setConversationTax(event.target.value)}
                    />
                </AzaGridItem>
                <AzaGridItem>
                    <AzaInputNumber
                        label={"Shipping"}
                        value={conversationShipping}
                        onChange={(event) => setConversationShipping(event.target.value)}
                    />
                </AzaGridItem>
            </AzaGridContainer>
            <AzaTextField
                label={"Sequence"}
                value={conversationSequence}
                onChange={(event) => setConversationSequence(event.target.value)}
            />
            <AzaTextField
                label={"Cart"}
                value={conversationCart}
                onChange={(event) => setConversationCart(event.target.value)}
            />
            <AzaButton
                variant={"contained"}
                onClick={() => {
                    conversionEvent(conversationName, conversationRef, conversationPrice, conversationTax, conversationShipping, conversationSequence, JSON.parse(conversationCart))
                }
                }>
                Send Conversion
            </AzaButton>
        </>
    )
}

const LeadTagSample = () => {
    const {leadEvent} = useAzameoTag();

    const [leadName, setLeadName] = useState("Test");
    const [leadRef, setLeadRef] = useState("#123456789");
    const [leadCategory, setLeadCategory] = useState("contact");

    return (
        <>
            <h2>Lead Tag</h2>
            <AzaTextField
                label={"Name"}
                value={leadName}
                onChange={(event) => setLeadName(event.target.value)}
            />
            <AzaTextField
                label={"Ref"}
                value={leadRef}
                onChange={(event) => setLeadRef(event.target.value)}
            />
            <AzaTextField
                label={"Category"}
                value={leadCategory}
                onChange={(event) => setLeadCategory(event.target.value)}
            />
            <AzaButton
                variant={"contained"}
                onClick={() => leadEvent(leadName, leadRef, leadCategory)}
            >
                Send Lead
            </AzaButton>
        </>
    )
}

const ProfileEventTagSample = () => {
    const {profileEvent} = useAzameoTag();

    const [profileName, setProfileName] = useState("Test");
    const [profileRef, setProfileRef] = useState("#123456789");
    const [profileCategory, setProfileCategory] = useState("contact");

    return (
        <>
            <h2>Profile Event Tag</h2>
            <AzaTextField
                label={"Name"}
                value={profileName}
                onChange={(event) => setProfileName(event.target.value)}
            />
            <AzaTextField
                label={"Ref"}
                value={profileRef}
                onChange={(event) => setProfileRef(event.target.value)}
            />
            <AzaTextField
                label={"Category"}
                value={profileCategory}
                onChange={(event) => setProfileCategory(event.target.value)}
            />
            <AzaButton
                variant={"contained"}
                onClick={() => profileEvent(profileName, profileRef, profileCategory)}
            >
                Send Profile Event
            </AzaButton>
        </>
    )
}

const UserConsentTagSample = () => {
    const {setUserConsent} = useAzameoTag();
    const [consent, setConsent] = useState(!(window.azameoSilent === 1));

    const userConsentChange = (event) => {
        setConsent(event.target.checked);
    }

    useEffect(() => {
        setUserConsent(consent);
    }, [consent, setUserConsent])

    return (
        <>
            <h2>Manage user consent</h2>
            <FormControlLabel
                control={<AzaSwitch
                    checked={consent}
                    onChange={userConsentChange}/>}
                label={"User Consent (not persisted)"}/>
        </>
    )
}

const page_items = [
    <AzaPageItem maptitle={"Navigation Tag"}><NavigationTagSample/></AzaPageItem>,
    <AzaPageItem maptitle={"Conversion Tag"}><ConversionTagSample/></AzaPageItem>,
    <AzaPageItem maptitle={"Lead Tag"}><LeadTagSample/></AzaPageItem>,
    <AzaPageItem maptitle={"Profile Event Tag"}><ProfileEventTagSample/></AzaPageItem>,
    <AzaPageItem maptitle={"User Consent"}><UserConsentTagSample/></AzaPageItem>
];

export const SampleAzameoTag = () => {
    return (

        <AzaPage pageItems={page_items}/>
        // <AzaPage pageItems={page_items.sort((a,b) => {
        //     if (a.props.maptitle < b.props.maptitle) {
        //         return -1;
        //     }
        //     if (a.props.maptitle > b.props.maptitle) {
        //         return 1;
        //     }
        //     return 0;
        // })} />

    )
}

