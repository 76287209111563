import {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {AzaBox} from "../../../components/mui/AzaBox";
import {selectCampaignParams} from "../reducers/editCampaignSlice";
import CampaignBudget from "../../../components/campaignComponents/CampaignBudget";
import AzaCardTemplate from "../../../components/azameo/AzaCardTemplate";
import {AzaButton} from "../../../components/mui/AzaButton";
import {MIN_TARGET_ROI} from "../../createNewCampaign/createNewCampaignUtile/createNewCamaignConst";
import OptimisationBudgetRoas from "../../../components/campaignComponents/OptimisationBudgetRoas";

const EditBudget = () => {
    const {t} = useTranslation();
    const campaignParams = useSelector(selectCampaignParams);
    const budget = campaignParams.campaign_params.budget;
    // is it better to use 'useState' here, anyway when we live this page we don't need to keep data (?)
    // to see how to get info when reload editCampaign page (with campaign id and getEditCampaignData?)
    const [dailyBudget, setDailyBudget] = useState({daily_budget: budget.daily});
    const [exceedDaily, setExceedDaily] = useState({
        exceed_daily_budget: budget.target_roi.roi ? budget.target_roi.roi : MIN_TARGET_ROI
    });
    const [roiExceedChecked, setRoiExceedChecked] = useState(budget?.target_roi.enable_exceed);

    const handleUpdate = () => {
        // if needed to update send setUpdate()
        console.log('Functionality to edit components not ready yet');
    }
    const handleBudgetInput = useCallback((e) => {
        // if amount to big...
        setDailyBudget({daily_budget: e.target.value});
    }, []);

    const handleCheckboxChange = useCallback((e) => {
        setRoiExceedChecked(e.target.checked);
    }, []);

    const handleExceedInput = useCallback((e) => {
        // if amount to big...
        setExceedDaily({exceed_daily_budget: e.target.value});
    }, []);

    return (
        <AzaBox sx={{mb: '30px'}}>
            <AzaCardTemplate title={t('edit.edit-budget')}>
                <CampaignBudget
                    handleBudgetInput={handleBudgetInput}
                    handleCheckboxChange={handleCheckboxChange}
                    handleExceedInput={handleExceedInput}
                    dailyBudgetData={dailyBudget}
                    exceedDailyBudgetData={exceedDaily}
                    isExceedChecked={roiExceedChecked}
                    edit={true}
                />
                <OptimisationBudgetRoas/>
                <AzaButton variant="contained" onClick={handleUpdate}>{t('common.buttons.validate')}</AzaButton>
            </AzaCardTemplate>
        </AzaBox>
    );
};

export default EditBudget;