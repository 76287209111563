import {Help} from "@mui/icons-material";

import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaTooltip} from "../../../components/mui/AzaTooltip";

const ToolTips = ({toolTipsText}) => {
    // different appearance
    // const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    //     <AzaTooltip {...props} classes={{ popper: className }} />
    // ))(({ theme }) => ({
    //     [`& .${tooltipClasses.tooltip}`]: {
    //         backgroundColor: '#f5f5f9',
    //         color: 'rgba(0, 0, 0, 0.87)',
    //         maxWidth: 220,
    //         fontSize: theme.typography.pxToRem(12),
    //         border: '1px solid #dadde9',
    //     },
    // }));

    return (
        <AzaBox sx={{color: 'dimgrey'}}>
            <AzaTooltip placement="top" arrow title={toolTipsText}>
                <Help sx={{fontSize: '17px'}}/>
            </AzaTooltip>
        </AzaBox>
    );
};

export default ToolTips;