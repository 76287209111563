/*
* Doc: https://azameo.atlassian.net/wiki/spaces/D/pages/2082570241/Customizations
 */

import {useDbItem} from "../../utils/rdbHooks";
import {RDB_CUSTOMIZATION, RDB_USER} from "../../utils/constant";
import {useCallback, useMemo} from "react";
import {useSiteId, useUser} from "../../app/globalHooks";
import {useSelector} from "react-redux";
import {selectDefaultCampaignDisplay, selectSiteSettingsReady} from "../../app/globalSlice";

export const useCustomization = () => {

    const {user} = useUser()
    const siteID = useSiteId()
    const settingsReady = useSelector(selectSiteSettingsReady);
    const defaultCampaignDisplayCustomization = useSelector(selectDefaultCampaignDisplay);

    const {
        item: customization,
        loading: loadingCustomization,
        updateField: updateCustomization
    } = useDbItem(`${RDB_USER}/${user?.uid}/${RDB_CUSTOMIZATION}`, siteID)


    // Done here mostly to set default value
    const attributionWidgets = useMemo(() => {
        return customization?.["attributionWidgets"] || defaultCampaignDisplayCustomization.campaigns?.statistics?.split(",")|| []
    }, [customization, defaultCampaignDisplayCustomization])

    const updateAttributionWidgets = useCallback((value) => {
        updateCustomization('attributionWidgets', value)
    }, [updateCustomization])


    // Done here mostly to set default value
    const attributionColumns = useMemo(() => {
        return customization?.["attributionColumns"] || defaultCampaignDisplayCustomization.campaigns?.data?.split(",") || []
    }, [customization, defaultCampaignDisplayCustomization])

    const updateAttributionColumns = useCallback((value) => {
        updateCustomization('attributionColumns', value)
    }, [updateCustomization])

    const loading = useMemo(() => {
        return loadingCustomization || !settingsReady
    }, [settingsReady, loadingCustomization])

    return {
        customization,
        loading,
        attributionWidgets,
        updateAttributionWidgets,
        attributionColumns,
        updateAttributionColumns,
        updateCustomization,
    }
}

export const useGlobalCustomization = () => {

    const {user} = useUser()

    const {
        item: customization,
        loading,
        updateField: updateCustomization
    } = useDbItem(`${RDB_USER}/${user?.uid}/${RDB_CUSTOMIZATION}`, 'Global')

    const drawerOpen = useMemo(() => {
        return customization?.drawerOpen ?? false
    }, [customization])

    const updateDrawerOpen = useCallback((value) => {
        return updateCustomization('drawerOpen', value)
    }, [updateCustomization])

    return {
        loading,
        drawerOpen,
        updateDrawerOpen
    }
}
