import {useSelector} from "react-redux";

import {AzaBox} from "../../components/mui/AzaBox";
import EditBudget from "./editCampaignGroup/EditBudget";
import EditTitle from "./editCampaignGroup/EditTitle";
// import EditAssets from "./editCampaignGroup/EditAssets";
import CustomizableSkeleton from "../../components/skeleton/CustomizableSkeleton";
import {selectOnLoading} from "./reducers/editCampaignSlice";

const EditCampaign = () => {
    const isOnLoading = useSelector(selectOnLoading);
    /* not sure that we need reducer:
    on click on Link (CampaignGroup.js -> SubDataCampaign) set campaign id to API function (!not ready yet) to get data
    about chose campaign
    Probably we will need redux to put data from back, like intermediate
     */

    return !isOnLoading ?
        (<AzaBox>
            <AzaBox>
                <EditTitle/>
            </AzaBox>
            <AzaBox>
                <EditBudget/>
            </AzaBox>
            {/*<AzaBox>*/}
            {/*    <EditAssets/>*/}
            {/*</AzaBox>*/}
        </AzaBox>) : (<>
        <CustomizableSkeleton numbCols={1} numbRows={2} />
        <CustomizableSkeleton numbCols={1} numbRows={2} />
        <CustomizableSkeleton numbCols={1} numbRows={2} />
        {/*<CustomizableSkeleton numbCols={2} numbRows={2} />*/}
    </>);
};

export default EditCampaign;