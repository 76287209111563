import React, {useState} from 'react';
import {AzaIconButton} from "../../components/mui/AzaButton";
import {AzaTextField} from "../../components/mui/AzaTextField";
import {AzaBox} from "../../components/mui/AzaBox";
import {AzaSend} from "../../components/mui/AzaIcons";
import {AzaInputAdornment} from "../../components/mui/AzaInputAdornment";
import {useTheme} from "@mui/material";
import {SuggestionsPannel} from "./Suggestions";
import {AzaSkeleton} from "../../components/mui/AzaSkeleton";
import {useChatProvider} from "./chatContext";
import {useTranslation} from "react-i18next";

const MessageInput = ({onSubmit, disabled, inputRef, promptdisabled}) => {
    const [input, setInput] = useState('');
    const theme = useTheme();
    const {t} = useTranslation()

    const {placeholder} = useChatProvider();

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(input);
        setInput('');
    };

    return (<form onSubmit={handleSubmit}>
        <SuggestionsPannel/>
        <AzaBox display="flex" alignItems="flex-end" mt={2}>
            {!promptdisabled && <AzaTextField
                // fullWidth
                disabled={disabled}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={t(placeholder)}
                variant="outlined"
                // autoFocus
                inputRef={inputRef}
                inputProps={{
                    endAdornment: <AzaInputAdornment position={"end"}>
                        <AzaIconButton type={"submit"}>
                            <AzaSend/>
                        </AzaIconButton>
                    </AzaInputAdornment>,
                }}
                style={{backgroundColor: theme.palette.background.paper}}
            />}
            {promptdisabled && <AzaSkeleton variant={"rectangular"} animation={false} sx={{
                width: 1, height: "42px", border: 1, borderRadius: 2, borderColor: theme.palette.secondary.light
            }}/>}
        </AzaBox>
    </form>);
};

export default MessageInput;
