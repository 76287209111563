import {useTranslation} from "react-i18next";

import {useScreenSize} from "../../app/globalHooks";

import {AzaTypography} from "../../components/mui/AzaTypography";
import { AzaGridContainer, AzaGridItem} from "../../components/mui/AzaGrid";

import {AttributionWidgets} from "./AttributionWidgets/AttributionWidgets";
import {CampaignData} from "./CampaignData/CampaignData";
import FunctionalButtonsSection from "./CampaignData/FunctionalButtonsSection";
import {AzaBox} from "../../components/mui/AzaBox";

export const Attribution = () => {
    const {t} = useTranslation();
    const {isSmallScreen} = useScreenSize();

    return (
        <AzaBox
            sx={{
                overflow: "visible",
                width: "100%"

            }}
        >
            <AzaBox>
                <AzaTypography variant={isSmallScreen?"h6":"h4"}>{t("attribution.campaigns")}</AzaTypography>
            </AzaBox>
            <AzaBox sx={{
                position:"sticky",
                top: "70px",
                width: "100%",
                zIndex: 100,
            }}>
                <FunctionalButtonsSection/>
            </AzaBox>
            <AzaBox>
                <AzaGridContainer
                    direction={"row"}
                >
                    <AzaGridItem xs={12}>
                        <AttributionWidgets/>
                    </AzaGridItem>
                    <AzaGridItem xs={12}>
                        <CampaignData/>
                    </AzaGridItem>
                </AzaGridContainer>
            </AzaBox>
        </AzaBox>
    );
}
