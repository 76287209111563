import React, {useEffect, useState} from 'react';

import CreditsBalance from "../../../containers/creditsSettings/creditsSettingsGroup/CreditsBalance";
import CreditsRenewal from "../../../containers/creditsSettings/creditsSettingsGroup/CreditsRenewal";
import CreditsServicePlan from "../../../containers/creditsSettings/creditsSettingsGroup/CreditsServicePlan";
import CreditsCreditTransfer from "../../../containers/creditsSettings/creditsSettingsGroup/CreditsCreditTransfer";
import CreditsBills from "../../../containers/creditsSettings/creditsSettingsGroup/CreditsBills";

import {AzaPage, AzaPageItem} from "../../../components/azameo/AzaPage";
import {useSelector} from "react-redux";
import {
    selectRenewalInfo
} from "../../../containers/payment/paymentGroup/paymentSlice";
import {useTranslation} from "react-i18next";
import {usePaymentMode} from "../../../containers/payment/paymentContext";



const CreditsSettings = () => {
    const [renewal, setRenewal] = useState(false);
    const {t} = useTranslation();

    const {data, loading} = useSelector(selectRenewalInfo);

    useEffect( () => {
            if (!loading && data.source) {
                setRenewal(false);
            }
        }, [loading, data, setRenewal]);

    const page_items = [
            <AzaPageItem maptitle={t('container.credits.balance.title')} anchor_id={'balance'}><CreditsBalance title={t('container.credits.balance.title')} /></AzaPageItem>,
            <AzaPageItem maptitle={t('container.credits.renewal.title')} anchor_id={'renewal'}><CreditsRenewal title={t('container.credits.renewal.title')} /></AzaPageItem>,
            // <AzaPageItem maptitle={'Service plan'} anchor_id={'service-plan'}><CreditsServicePlan /></AzaPageItem>,
            // <AzaPageItem maptitle={'Credit transfer'} anchor_id={'credit-transfer'}><CreditsCreditTransfer /></AzaPageItem>,
            <AzaPageItem maptitle={t('container.credits.bills.title')} anchor_id={'bill'}><CreditsBills title={t('container.credits.bills.title')} anchor_id={'bill'}/></AzaPageItem>,
    ];

    return (
        <>
            <AzaPage pageItems={page_items}/>
        </>
    );
};

export default CreditsSettings;
