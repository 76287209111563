import {useDispatch, useSelector} from "react-redux";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {
    actionCancelPromocode,
    actionSetPromocode,
    actionValidatePromocode,
    PROMOCODE_STATUS,
    PROMOCODE_TYPE,
    selectPromocode,
    selectPromocodeStatus
} from "./paymentSlice";
import React, {useCallback} from "react";
import {InputBase, Paper} from "@mui/material";
import {AzaButton} from "../../../components/mui/AzaButton";
import {useTranslation} from "react-i18next";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import {usePayment} from "./paymentHook";

const PromocodeAlert = ({promoCodeStatus}) => {
    const {t} = useTranslation();
    const {currency} = usePayment()
    if (promoCodeStatus.checkstatus === PROMOCODE_STATUS.SUCCESS) {
        const type = promoCodeStatus.type;
        const amount = promoCodeStatus.amount;
        return <AzaAlert severity={"success"}>{t(`payment.promocode.confirmed.${type}`, {
            val: amount,
            formatParams: {
                val: {currency: currency,}
            }
        })}</AzaAlert>
    } else if (promoCodeStatus.checkstatus === PROMOCODE_STATUS.ERROR) {
        const errorcode = promoCodeStatus.checkerror;
        return <AzaAlert severity={"error"}>{t(`payment.promocode.error.${errorcode}`)}</AzaAlert>
    }
    return <></>
};

const PromocodeButton = ({promoCode, promoCodeStatus}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const siteId = useSiteId();
    const {user} = useUser();

    const handlePromoCode = useCallback(() => {
        dispatch(actionValidatePromocode({site_id: siteId, user: user, promocode: promoCode}));
    }, [user, dispatch, siteId, promoCode]);

    const handleCancelPromoCode = useCallback(() => {
        dispatch(actionCancelPromocode({}));
    }, [dispatch]);

    if (promoCodeStatus.checkstatus === PROMOCODE_STATUS.SUCCESS &&
        promoCodeStatus.type === PROMOCODE_TYPE.FREE_CREDIT) {
        return <></>
    } else if (promoCodeStatus.checkstatus === PROMOCODE_STATUS.SUCCESS) {
        return <AzaButton
            onClick={handleCancelPromoCode}
            variant={"text"}>
            {t("payment.promocode.cancel")}
        </AzaButton>
    } else {
        return <AzaButton
            onClick={handlePromoCode}
            variant={"text"}
            loading={promoCodeStatus.checkstatus === PROMOCODE_STATUS.PENDING}
            disabled={promoCodeStatus.checkstatus === PROMOCODE_STATUS.PENDING || promoCode.length === 0}>
            {t("payment.promocode.button")}
        </AzaButton>
    }
}

export const PromocodeInput = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const promoCode = useSelector(selectPromocode);
    const promoCodeStatus = useSelector(selectPromocodeStatus)

    const handlePromoCodeChange = (e) => {
        dispatch(actionSetPromocode(e.target.value));
    };

    return <>
        <Paper
            component="form"
            sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                border: (promoCodeStatus.checkstatus === PROMOCODE_STATUS.ERROR) ? "1px solid rgb(255, 0, 0)" : "1px solid rgba(0, 0, 0, 0.12)",
                marginBottom: "10px",
                boxShadow: "none",
            }}
        >
            <InputBase
                sx={{ml: 1, flex: 1}}
                placeholder={t('payment.promocode.placeholder')}
                onChange={handlePromoCodeChange}
                value={promoCode}
            />
            <PromocodeButton
                promoCode={promoCode}
                promoCodeStatus={promoCodeStatus}
            />
        </Paper>
        <PromocodeAlert
            promoCodeStatus={promoCodeStatus}
        />
    </>;
}