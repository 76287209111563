import {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import AzaCardTemplate from "../../../../components/azameo/AzaCardTemplate";
import {AzaBox} from "../../../../components/mui/AzaBox";
import {keywordHandler} from "../addAssetsGroup/addAssetsUtility/inputConfigData";
import {AzaGridContainer, AzaGridItem} from "../../../../components/mui/AzaGrid";
import {
    actionSetKeywords,
    selectKeywords,
    actionUpdateAssets,
    selectProductAssetSelected,
    selectProductInfoSelected, selectAssetScope, selectIsMultipleProducts
} from "../../reducers/createNewCampaignSlice";
import InputCreation from "../addAssetsGroup/addAssetsUtility/InputCreation";
import {allGrey} from "../../../../utils/colors";
import {AzaTextField} from "../../../../components/mui/AzaTextField";
import {useSiteId} from "../../../../app/globalHooks";
import {AzaAlert} from "../../../../components/mui/AzaAlert";
import AddNewInputBtn from "../../createNewCampaignUtile/AddNewInputBtn";


const Keywords = () => {
    const {t} = useTranslation();
    const assetsDataSite = useSelector(selectKeywords);
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [warning, setWarning] = useState({status: false, message: ''}); // warning an obj: {status: true, message: "Can't add empty input"}
    const currentSiteId = useSiteId();
    const assetsScopeData = useSelector(selectAssetScope);
    const initialAssetsScope = assetsScopeData.scopeName;
    const assetsDataProduct = useSelector(selectProductAssetSelected);
    const productId = useSelector(selectProductInfoSelected).prodId;
    const isMultipleProducts = useSelector(selectIsMultipleProducts).is_multiple_product;
    const minKeywords = keywordHandler.minInputs;
    const maxKeywords = keywordHandler.maxInputs;
    const maxLength = keywordHandler.maxLength;

    // Hardcoded data. 'Site' scope VS 'Product' scope, to see how to manage this
    const isMultiline = false;
    const componentClass = 'keyword';
    const language = 'fr';
    const inputName = 'keyword';
    const textType = 'Keyword';
    const siteId = currentSiteId;

    const assetsScope = isMultipleProducts ? "Site" : initialAssetsScope;

    const assetsData = useMemo(() => {
        return (assetsScope === 'Site') ? assetsDataSite : (assetsDataProduct[textType] ? assetsDataProduct[textType] : []);
    }, [assetsScope, assetsDataSite, assetsDataProduct]);

    const generateNewInputObj = useCallback((assetId, value) => {
        return {
            cleaned_text: value,
            enabled: true,
            id: assetId,
            language: language,
            origin: "Customer",
            product_id: (assetsScope === 'Site') ? null : productId,
            scope: assetsScope,
            site_id: siteId,
            text: value,
            text_type: textType,
        }
    }, [productId, assetsScope, siteId]);

    const handleAddMultipleInput = useCallback((inputsArr) => {
        const arrOfNewObj =[];
        const keywordsLeft = maxKeywords - assetsData.length;
        let limitInputs;

        if(inputsArr.length > keywordsLeft) {
            limitInputs = keywordsLeft;
            const arrKeywordsLeft = inputsArr.slice(keywordsLeft);
            let keywordsLeftToString = arrKeywordsLeft.map((str) => `"${str}"`).join(', ');
            // hardcoded, need translation
            let message = t('audience.error.keywords-numbers-exceeded', {val: keywordsLeftToString});
            setWarning({status: true, message: message});
        } else {
            limitInputs = inputsArr.length;
        }
        for(let i = 0; i < limitInputs; i++) {
            const keyHolder = Date.now() + '-' + Math.floor(Math.random() * 1000);
            arrOfNewObj.push(generateNewInputObj(keyHolder, inputsArr[i]));
        }
        const inputData = {data: [...assetsData, ...arrOfNewObj], scope: assetsScope, productId: productId}
        dispatch(actionSetKeywords(inputData));
        setValue('');
    }, [t, maxKeywords, assetsData, generateNewInputObj, dispatch, assetsScope, productId]);

    const handleAddNewInput = useCallback((val) => {
        if(val.length <= 0) {
            setWarning({status: true, message: t('audience.error.add-empty')})
            return;
        }

        if(warning.status) {
            setWarning({status: false, message: ''})
        }
        const splitStr = val.split('\n');
        if(splitStr.length > 1) {
            handleAddMultipleInput(splitStr);
            return;
        }
        let controlNum = assetsData.length;
        if(controlNum < maxKeywords) {
            const keyHolder = Date.now() + '-' + Math.floor(Math.random() * 1000);
            const newObj = generateNewInputObj(keyHolder, val);
            const inputData = {data: [...assetsData, newObj], scope: assetsScope, productId: productId};
            dispatch(actionSetKeywords(inputData));
            setValue('');
        } else {
            // create alert of warning
            console.log(`You can add ${maxKeywords} description maximum`);
        }
    }, [t, warning.status, handleAddMultipleInput, assetsData, maxKeywords, generateNewInputObj, assetsScope, productId, dispatch]);

    const handleRemoveInput = useCallback((index) => {
        if(warning.status) {
            setWarning({status: false, message: ''});
        }
        const newAssets = assetsData.filter((el) => el.id !== index);
        dispatch(actionUpdateAssets(newAssets));
    }, [warning.status, assetsData, dispatch]);

    const inputs = useMemo(() => {
        // in future version possible situation : assetsData.length > maxQuantity, need to manage it
        return assetsData.map((el) => {
            return(
                <AzaBox key={el.id} sx={{pt: '16px'}}>
                    <InputCreation
                        inputName={inputName}
                        maxLength={maxLength}
                        handleRemoveInput={() => handleRemoveInput(el.id)}
                        minQuantity={minKeywords}
                        nameOfClass={componentClass}
                        isMultiline={isMultiline}
                        assetValue={el.cleaned_text}
                        assetData={el}
                        selectAssetsFunc={assetsScope === 'Site' ? selectKeywords : selectProductAssetSelected}
                    />
                </AzaBox>)
        });
    }, [assetsData, maxLength, handleRemoveInput, minKeywords, isMultiline, assetsScope]);

    const handleValue = (e) => {
        setValue(e.target.value);
    }

    return (
        <AzaBox sx={{marginBottom: '30px'}}>
            <AzaCardTemplate title={t('audience.keywords-targeting')}>
                <AzaBox sx={{mb: '20px', color: allGrey[500]}}>
                    {t('add-assets', {
                        from: minKeywords, to: maxKeywords, val: '$t(keywords)',
                        interpolation: {
                            skipOnVariables: false,
                        },
                    })} ({assetsData.length})
                </AzaBox>
                <AzaGridContainer container spacing={2}>
                    <AzaGridItem xs={12} md={6}>
                        {inputs}
                    </AzaGridItem>
                    <AzaGridItem xs={12} md={6}>
                        <AzaTextField
                            sx={{pt: '16px', pl: '16px'}}
                            placeholder={t('audience.add-keywords')}
                            multiline
                            rows={6}
                            value={value}
                            onChange={handleValue}
                        />
                        {warning.status &&
                            <AzaAlert severity="warning" sx={{ml: '16px', mt: '15px'}}>
                                {warning.message}
                            </AzaAlert>}
                        <AddNewInputBtn
                            assetsData={assetsData}
                            maxQuantity={maxKeywords}
                            handleAddNewInput={() => handleAddNewInput(value)}
                        >
                            {t('audience.add-keywords-btn')}
                        </AddNewInputBtn>
                    </AzaGridItem>
                </AzaGridContainer>
            </AzaCardTemplate>
        </AzaBox>
    );
};

export default Keywords;