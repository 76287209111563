import {Divider, Grid} from "@mui/material";

export const AzaGridContainer = (props) => {
  return (
    <Grid container spacing={2} {...props}/>
  );
}

export const AzaGridItem = (props) => {
  return (
      <Grid item {...props}/>
  );
}

export const AzaGrid = (props) => {
  return (
      <Grid {...props}/>
  );
}

export const AzaGridDivider = (props) => {
  return (
      <Grid item >
        <Divider {...props}/>
      </Grid>
  );
}
