import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    actionGetBillingInfo,
    actionResetChanges,
    actionSetBillingCity,
    actionSetBillingInfoLine1,
    actionSetBillingInfoLine2,
    actionSetBillingZipCode,
    actionSetCompanyName,
    actionSetCountryCode,
    actionSetVatNumber,
    selectAllowedToEnterVATNumber,
    selectBillingCity, selectBillingInfo,
    selectBillingInfoCompanyName,
    selectBillingInfoCountryCode, selectBillingInfoIsChanged,
    selectBillingInfoIsValid,
    selectBillingInfoLine1,
    selectBillingInfoLine2,
    selectBillingInfoReadOnly,
    selectBillingInfoVATNumber,
    selectBillingZipCode,
    VAT_STATUS
} from "./paymentSlice";


import {AzaTextField} from "../../../components/mui/AzaTextField";
import {AzaCountrySelector} from "../../../components/azameo/AzaCountrySelector";
import {useAdminMode} from "../../../features/adminMode/adminModeContext";
import {AzaBox} from "../../../components/mui/AzaBox";
import {RequestChangeBillingInfoZohoForm} from "../../../components/azameo/ZohoForm";
import {AzaButton} from "../../../components/mui/AzaButton";
import {backendFetchPromise} from "../../../utils/backendHelper";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {API_GET_BILLING_INFO} from "../../../utils/constant";
import {AdminModeRequired} from "../../../features/adminMode/adminModeComponents";
import { AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";


const useIsEditable = () => {
    const readOnly = useSelector(selectBillingInfoReadOnly);
    const {adminMode} = useAdminMode();

    const editable = !readOnly;
    const adminEditable = !readOnly || adminMode;

    return {editable, adminEditable};
}


const InputBusinessName = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const handleChange = useCallback((event) => {
        dispatch(actionSetCompanyName(event.target.value))
    }, [dispatch]);
    const {loading, company_name} = useSelector(selectBillingInfoCompanyName);

    const {adminEditable} = useIsEditable();

    return (
        <AzaTextField
            label={t('payment.billing.companyname')}
            value={company_name}
            onChange={handleChange}
            loading={loading}
            disabled={!adminEditable}
        />
    )
}

const InputCountry = () => {
    const dispatch = useDispatch()
    const {loading, country_code} = useSelector(selectBillingInfoCountryCode);
    const {loading: vatLoading} = useSelector(selectBillingInfoVATNumber);
    const {adminEditable} = useIsEditable();

    const handleChange = useCallback((event) => {
        if (event) {
            dispatch(actionSetCountryCode(event))
        }
    }, [dispatch]);

    return (
        <AzaCountrySelector
            country_code={country_code}
            disabled={vatLoading || !adminEditable}
            loading={loading || vatLoading}
            onChange={handleChange}
        />
    )
}

const InputTVA = () => {
    const {loading, vatNumber, vatvalid} = useSelector(selectBillingInfoVATNumber);
    const canEnterVATNumber = useSelector(selectAllowedToEnterVATNumber);
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {adminEditable} = useIsEditable()

    const [number, setNumber] = useState(vatNumber);

    const handleChange = useCallback((event) => {
        setNumber(event.target.value);
    }, [setNumber]);  // Do nothing. We don't want to update the vat number until the user leaves the field

    const handleFocusOut = useCallback((event) => {
        dispatch(actionSetVatNumber(event.target.value))
    }, [dispatch]);

    const [error, setError] = useState(false);

    useEffect(() => {
        setNumber(vatNumber);
    }, [vatNumber, setNumber])

    useEffect(() => {
        if (vatNumber === null || vatNumber === "" || !canEnterVATNumber) {
            setError(false);
        } else if (vatvalid === VAT_STATUS.INVALID) {
            setError(true);

        } else if (vatvalid === VAT_STATUS.VALID) {
            setError(false);
        }
    }, [vatvalid, vatNumber, setError, canEnterVATNumber])

    return (
        <AzaTextField
            label={t('payment.billing.vatnumber')}
            value={canEnterVATNumber ? number : ""}
            onChange={handleChange}
            loading={loading}
            required={false}
            error={error}
            helperText={t('payment.billing.invalidvatnumber')}
            disabled={!canEnterVATNumber || !adminEditable}
            onBlur={handleFocusOut}
        />
    )
}

const InputAddressLine = ({loading, line, setLine, required, label}) => {
    const dispatch = useDispatch()
    const handleChange = useCallback((event) => {
        dispatch(setLine(event.target.value))
    }, [dispatch, setLine]);
    const {adminEditable} = useIsEditable();

    return (
        <AzaTextField
            label={label}
            value={line}
            onChange={handleChange}
            loading={loading}
            required={required}
            disabled={!adminEditable}
        />
    )
}

const InputAddressLine1 = () => {
    const {loading, line} = useSelector(selectBillingInfoLine1);
    const {t} = useTranslation();
    const {adminEditable} = useIsEditable();
    return (
        <InputAddressLine
            loading={loading}
            line={line}
            setLine={actionSetBillingInfoLine1}
            label={t('payment.billing.address1')}
            disabled={!adminEditable}
        />
    )
}

const InputAddressLine2 = () => {
    const {loading, line} = useSelector(selectBillingInfoLine2);
    const {t} = useTranslation();
    const {adminEditable} = useIsEditable();
    return (
        <InputAddressLine
            loading={loading}
            line={line}
            setLine={actionSetBillingInfoLine2}
            required={false}
            label={t('payment.billing.address2')}
            disabled={!adminEditable}
        />
    )
}

const InputZipCode = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const handleChange = useCallback((event) => {
        dispatch(actionSetBillingZipCode(event.target.value))
    }, [dispatch]);
    const {loading, zipcode} = useSelector(selectBillingZipCode);
    const {adminEditable} = useIsEditable();

    return (
        <AzaTextField
            label={t('payment.billing.postalcode')}
            value={zipcode}
            onChange={handleChange}
            loading={loading}
            disabled={!adminEditable}
        />
    )
}


const InputCity = () => {

    const dispatch = useDispatch()
    const {t} = useTranslation();
    const handleChange = useCallback((event) => {
        dispatch(actionSetBillingCity(event.target.value))
    }, [dispatch]);
    const {loading, city} = useSelector(selectBillingCity);
    const {adminEditable} = useIsEditable();

    return (
        <AzaTextField
            label={t('payment.billing.city')}
            value={city}
            onChange={handleChange}
            loading={loading}
            disabled={!adminEditable}
        />
    )
}

const SaveButton = () => {

    const {user} = useUser();
    const site_id = useSiteId();
    const {ready, billing_info} = useSelector(selectBillingInfo);
    const {t} = useTranslation();
    const isChanged = useSelector(selectBillingInfoIsChanged);
    const dispatch = useDispatch();

    const saveBillingInfo = useCallback(async () => {
        if (!ready) {
            return;
        }
        await backendFetchPromise({
            user,
            path: API_GET_BILLING_INFO,
            queryParams: {
                site_id: site_id,
            },
            data: {billing_info: billing_info},
            method: 'POST'
        }).then((res) => {
            if (res.status === 200) {
                dispatch(actionGetBillingInfo({user: user, site_id: site_id}))
                return res.json()
            } else {
                return {result: false};
            }
        }).then(() => {
        })
    }, [ready, user, site_id, billing_info, dispatch]);

    return (
        <AdminModeRequired>
            <AzaBox
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "left",
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <AzaButton
                    size={"small"}
                    onClick={saveBillingInfo}
                    disabled={!isChanged}
                >
                    {t('payment.billing.adminsave')}
                </AzaButton>
                <br/>
            </AzaBox>
        </AdminModeRequired>
    )
}


const BillingPayment = ({setCanNext}) => {

    const {t} = useTranslation();
    const cannext = useSelector(selectBillingInfoIsValid)
    const {editable, adminEditable} = useIsEditable();
    const isChanged = useSelector(selectBillingInfoIsChanged);
    const dispatch = useDispatch();

    useEffect(() => {
        if (adminEditable) {
            setCanNext(!isChanged && cannext);
        } else {
            if (isChanged) {
                dispatch(actionResetChanges());
            }
            setCanNext(cannext);
        }
    }, [cannext, setCanNext, isChanged, adminEditable, dispatch]);

    return (
        <AzaBox style={{marginBottom: "20px"}}>
            <AzaBox component={"h4"}>
                {t('payment.billing.title')}
            </AzaBox>
            <AzaBox>
                <AzaGridContainer direction={"column"}>
                    <AzaGridItem>
                        {!adminEditable ?
                            <AzaBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "left",
                                    flexDirection: "row",
                                    width: "100%",
                                }}
                            >
                                <RequestChangeBillingInfoZohoForm/>
                            </AzaBox>
                            : (!editable) ? <SaveButton/> : null}
                    </AzaGridItem>
                    <AzaGridItem>
                        <InputBusinessName/>
                    </AzaGridItem>
                    <AzaGridItem>
                        <InputCountry/>
                    </AzaGridItem>
                    <AzaGridItem>
                        <InputTVA/>
                    </AzaGridItem>
                    <AzaGridItem>
                        <InputAddressLine1/>
                    </AzaGridItem>
                    <AzaGridItem>
                        <InputAddressLine2/>
                    </AzaGridItem>
                    <AzaGridItem container direction={"row"} spacing={2}>
                        <AzaGridItem sm={12} md={4} >
                            <InputZipCode/>
                        </AzaGridItem>
                        <AzaGridItem sm={12} md={8} >
                            <InputCity/>
                        </AzaGridItem>
                    </AzaGridItem>
                </AzaGridContainer>
            </AzaBox>
        </AzaBox>
    );
};

export default BillingPayment;
