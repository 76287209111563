import {useCallback} from "react";

import {FormControl, InputAdornment, MenuItem} from "@mui/material";
import {Language} from "@mui/icons-material";

import {SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES_NAMES} from "../../utils/constant";
import {AzaSelect} from "../../components/mui/AzaSelect";
import {useLanguage} from "./languageSelectorHook";

export const LanguageSelector = ({sx, large, withIcon=true}) => {
    const {language, changeLanguage} = useLanguage();

    const changeUserLanguage = useCallback((e) => {
        changeLanguage(e.target.value);
    }, [changeLanguage]);


    const getLanguagesItems = () => {
        return SUPPORTED_LANGUAGES.map((lang) => {
            return (
                <MenuItem key={lang} value={lang}>{SUPPORTED_LANGUAGES_NAMES[lang]}</MenuItem>
            )
        });
    };

    return (
        <FormControl
            sx={{width: "auto", ...sx}}
            variant="standard">
            <AzaSelect
                value={language}
                disableUnderline
                onChange={changeUserLanguage}
                renderValue={(value) => {
                    if (large) {
                        return SUPPORTED_LANGUAGES_NAMES[value];
                    } else {
                        return value;
                    }
                }}
                startAdornment={
                    (withIcon&&<InputAdornment position={"start"}><Language/></InputAdornment>)
                }
            >
                {getLanguagesItems()}
            </AzaSelect>
        </FormControl>
    )
}
